import useDimensions from 'hooks/useDimensions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  RightContainer,
  Title,
  Body,
  BookTimeToMeetButton,
} from './AfterSchoolAttendanceStyles';
import { Logo } from './icons';
import LeftImage from './academics2.png';

const afterSchoolText = 'Out of School';
const attendanceText = 'Attendance';

const body =
  'Furthermore, consistent attendance in both in-school and out-of-school activities helps students develop a strong work ethic and sense of responsibility, which can benefit them in all areas of their lives.';

const bookTimeToMeetText = 'Book Time to Meet';

export const AfterSchoolAttendance = ({
  setCalendlyModalOpen,
}: {
  setCalendlyModalOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  const tAfterSchool = t(afterSchoolText);
  const tAttendance = t(attendanceText);
  const tBody = t(body);
  const tBookTimeToMeet = t(bookTimeToMeetText);

  const { ref, dimensions } = useDimensions();

  const isMobile = useMemo(() => dimensions.width && dimensions.width <= 1300, [
    dimensions.width,
  ]);

  const isXS = useMemo(() => dimensions.width && dimensions.width <= 470, [
    dimensions.width,
  ]);

  return (
    <Container ref={ref} id="out-school">
      <img
        src={LeftImage}
        alt="After School Attendance"
        width={
          isMobile ? (dimensions.width ? dimensions.width * 0.9 : '50%') : '50%'
        }
        height={isMobile ? 'auto' : '100%'}
      />
      <RightContainer>
        <Logo
          width={isXS ? '60' : undefined}
          height={isXS ? '60' : undefined}
        />
        <Title>
          {tAfterSchool}
          {!isMobile && <br />}
          {tAttendance}
        </Title>
        <Body>{tBody}</Body>
        <BookTimeToMeetButton onClick={() => setCalendlyModalOpen(true)}>
          {tBookTimeToMeet}
        </BookTimeToMeetButton>
      </RightContainer>
    </Container>
  );
};
