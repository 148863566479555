import React from 'react';
import { Trans } from 'react-i18next';
import { HeaderGroup } from 'react-table';

import { ArrowIcon, MoreIcon } from 'components/icons';
import { Checkbox } from 'components/checkbox';

import { TableRow } from '../row/RowStyles';
import {
  ArrowContainer,
  CheckboxContainer,
  Container,
  FilterContainer,
  HeaderCell,
  TextContainer,
} from './HeaderStyles';

interface ITableHeaderProps {
  canSelectAll: boolean;
  columnsSpans?: string;
  isAllSelected?: boolean;
  isFilterActive?: boolean;
  optionsMenu?: boolean;
  rowsCount: number;
  selectedRows?: string[];
  setIsFilterActive?: (value: ((prev: boolean) => boolean) | boolean) => void;
  toggleSelectAll?: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerGroups: HeaderGroup<any>[];
}

export const TableHeader: React.FC<ITableHeaderProps> = ({
  canSelectAll,
  columnsSpans,
  headerGroups,
  isAllSelected,
  isFilterActive = false,
  optionsMenu,
  rowsCount,
  selectedRows,
  setIsFilterActive,
  toggleSelectAll,
}: ITableHeaderProps) => {
  const handleClick = (column: HeaderGroup) => {
    if (column.canFilter && setIsFilterActive) {
      return setIsFilterActive((prev) => !prev);
    }

    if (column.canSort) {
      return column.toggleSortBy(!column.isSortedDesc);
    }
  };

  const showCheckbox = canSelectAll && toggleSelectAll;

  return (
    <Container>
      {headerGroups.map((headerGroup, index) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          key={index}
          length={headerGroup.headers.length}
          optionsMenu={optionsMenu}
          columnsSpans={columnsSpans}
        >
          {headerGroup.headers.map((column, index) => (
            <HeaderCell
              optionsMenu={optionsMenu}
              {...column.getHeaderProps(
                column.getSortByToggleProps
                  ? column.getSortByToggleProps({
                      title: undefined,
                    })
                  : undefined,
              )}
              canFilter={column.canFilter}
              canSort={column.canSort}
              key={index}
              onClick={() => handleClick(column)}
            >
              {!!showCheckbox && column.id === 'checkbox' ? (
                <CheckboxContainer>
                  <Checkbox
                    handleCheckClick={toggleSelectAll}
                    isSelected={
                      isAllSelected === undefined
                        ? !!selectedRows?.length && selectedRows.length > 0
                        : isAllSelected
                    }
                    isDisabled={!showCheckbox || !rowsCount}
                    icon={
                      isAllSelected || selectedRows?.length ? 'minus' : 'check'
                    }
                  />
                </CheckboxContainer>
              ) : (
                <TextContainer>
                  <Trans>{column.render('Header')}</Trans>
                </TextContainer>
              )}

              {column.canSort && (
                <ArrowContainer>
                  <ArrowIcon
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc || false}
                  />
                </ArrowContainer>
              )}

              {column.canFilter && (
                <>
                  <FilterContainer>
                    <MoreIcon isActive={isFilterActive} small />
                  </FilterContainer>

                  {isFilterActive &&
                    column.render('Filter', { setIsFilterActive })}
                </>
              )}
            </HeaderCell>
          ))}
        </TableRow>
      ))}
    </Container>
  );
};
