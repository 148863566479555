import React from 'react';

import { IPassDetails } from '../classpass.types';
import { Theme } from 'assets/styles';
import AppLogo from '../../../assets/images/app-logo.svg';
import DateIcon from '../../../assets/images/icons/date.svg';
import TimeIcon from '../../../assets/images/icons/time.svg';
import GradeIcon from '../../../assets/images/icons/grade.svg';

const ClassPass: React.FC<IPassDetails> = ({
  grade,
  schoolName,
  studentName,
  logoUrl,
}: IPassDetails) => {
  return (
    <div
      style={{
        backgroundColor: '#b2d4eb',
        width: '100%',
        textAlign: 'center',
        borderRadius: 15,
      }}
    >
      <div style={{ padding: 10 }}>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            backgroundColor: Theme.primaryWhite,
            paddingTop: 20,
            paddingBottom: 20,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          }}
        >
          {/* img placeholder */}
          {!!logoUrl && <img height={150} src={logoUrl} />}
          <p
            style={{
              fontSize: 30,
              color: Theme.primaryBlueDark,
              marginBottom: 0,
              marginTop: 0,
            }}
          >
            {schoolName ?? ''}
          </p>
          <p
            style={{
              fontSize: 40,
              fontWeight: 'bold',
              marginBottom: 0,
              marginTop: 5,
              color: Theme.primaryBlueLight,
            }}
          >
            {studentName}
          </p>
          <table
            style={{ width: '100%', paddingLeft: '25%', paddingTop: '5%' }}
          >
            <tbody>
              <tr>
                <td>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <div
                      style={{
                        alignSelf: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: 10,
                        padding: 10,
                      }}
                    >
                      <img src={DateIcon} />
                    </div>
                    <div>
                      <p
                        style={{
                          color: Theme.secondaryBlack,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        Date
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          color: Theme.secondaryBlack,
                          fontFamily: Theme.fontBold,
                        }}
                      >
                        {new Date().toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 10,
                      borderRadius: 10,
                      paddingLeft: 10,
                      width: '70%',
                    }}
                  >
                    <div
                      style={{
                        alignSelf: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: 10,
                        padding: 10,
                      }}
                    >
                      <img src={TimeIcon} />
                    </div>
                    <div>
                      <p
                        style={{
                          color: Theme.secondaryBlack,
                          fontWeight: 'bold',
                          marginBottom: 10,
                        }}
                      >
                        Time
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          color: Theme.secondaryBlack,
                          fontFamily: Theme.fontBold,
                        }}
                      >
                        {new Date().toLocaleTimeString()}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <img src={AppLogo} style={{ paddingTop: 10, paddingBottom: 10 }} />
    </div>
  );
};

export default ClassPass;
