import { Reducer } from 'redux';
import { Person } from './CheckInActions.types';
import * as Actions from './CheckInActions.types';

const duplicateCheckInText = (name: string) =>
  `Don't worry, ${name}, you've already been checked in!`;
const duplicateCheckOutText = (name: string) =>
  `Don't worry, ${name}, you've already been checked out!`;
const scanFailText = 'Error, Please check in with teacher';

export interface State {
  checkedInPersons: Person[];
  checkedOutPersons: Person[];
  isLoading: boolean;
  isSuccessful: boolean;
  mostRecentPerson: Person | null;
  notification?: { text: string; type: Actions.NotificationType };
  shouldShowStatus?: boolean;
}

export const initialState = {
  checkedInPersons: [],
  checkedOutPersons: [],
  isLoading: false,
  isSuccessful: false,
  mostRecentPerson: null,
  notification: undefined,
  shouldShowStatus: false,
};

const checkInReducer: Reducer<State> = (
  state: State = initialState,
  action: Actions.AttendanceCheckInActionTypes,
) => {
  switch (action.type) {
    case Actions.CHECK_IN__DUPLICATE_FACE_SCAN:
    case Actions.CHECK_IN__DUPLICATE_QR_SCAN: {
      return {
        ...state,
        mostRecentPerson: action.person,
        notification: {
          text: action.checkOut
            ? duplicateCheckOutText(action.name)
            : duplicateCheckInText(action.name),
          type: Actions.NotificationType.success,
        },
      };
    }

    case Actions.CHECK_IN__FACE_SCAN_FAIL:
    case Actions.CHECK_IN__QR_SCAN_FAIL: {
      return {
        ...state,
        isSuccessful: false,
        mostRecentPerson: null,
        notification: {
          text: scanFailText,
          type: Actions.NotificationType.error,
        },
      };
    }

    case Actions.CHECK_IN__FACE_SCAN_SUCCESS: {
      return state;
    }

    case Actions.CHECK_IN__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.CHECK_IN__SET_NOTIFICATION: {
      return {
        ...state,
        notification: {
          text: action.notification.text,
          type: action.notification.type,
        },
      };
    }

    case Actions.CHECK_IN__SET_SHOULD_SHOW_STATUS: {
      return {
        ...state,
        shouldShowStatus: action.shouldShowStatus,
      };
    }

    default:
      return state;
  }
};

export default checkInReducer;
