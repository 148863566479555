import API from 'data/axios';
import { busMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBus } from 'data/types/schoolBusApi.types';
import { IBus } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../../types/api.types';

export default async (): Promise<Adapter<IBus[]>> =>
  API.get<ArrayResponse<IApiBus>>(`/bus/buses`)
    .then(({ data: response }) => {
      const buses = response.data.map(({ data }) => busMapper(data));

      return { success: true, data: buses };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
