import React, { Fragment, useEffect, useMemo } from 'react';
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { useColumns } from './useColumns';
import { useOnScreen } from './useOnScreen';

import { PaginationRow } from '../../pagination';
import { Row } from '../components/row';

import { IUseAppTableProps } from '../types';

type hook = typeof useSortBy | typeof usePagination | typeof useFilters;

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export default <T extends Object = any>({
  columns: inputColumns,
  columnsSpans,
  data,
  defaultSortBy,
  desc = false,
  hasWriteAccess = true,
  isLoading = false,
  isSortingEnabled = false,
  onChange = () => null,
  onPageSizeChange,
  onRowClick,
  options,
  placeholder = undefined,
  selectProps,
  emptyMessage,
  showOptions = false,
  ...handlers
}: IUseAppTableProps<T>) => {
  const allColumns = useMemo<Column[]>(
    () =>
      useColumns({
        columns: inputColumns,
        showOptions,
        hasWriteAccess,
        handlers,
        ...(selectProps || {}),
      }),
    [inputColumns, selectProps],
  );

  const hooks: hook[] = [];
  if (options?.useFilters) hooks.push(useFilters);
  if (defaultSortBy || isSortingEnabled) hooks.push(useSortBy);
  if (options?.usePagination) hooks.push(usePagination);

  const tableInstance = useTable(
    {
      columns: allColumns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: defaultSortBy ? [{ id: defaultSortBy, desc }] : [],
        pageSize: options?.pageSize || 5,
        pageIndex: options?.pageIndex || 0,
      },
      // pageCount: options?.pageCount || -1,
      autoResetPage: false,
    },
    ...hooks,
  );

  const {
    canNextPage,
    canPreviousPage,
    columns,
    filteredRows,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setFilter,
    setPageSize,
    state: { pageIndex },
  } = tableInstance;

  useEffect(() => {
    onChange(tableInstance);
  }, [tableInstance]);

  useEffect(() => {
    options?.pageSize && setPageSize(options.pageSize);
  }, [options?.pageSize]);

  const renderRows = () => {
    const dataRows = options?.usePagination ? page : rows;

    if (isLoading || !dataRows.length) {
      return (
        <Row
          emptyMessage={emptyMessage}
          loading={isLoading}
          placeholder={placeholder}
        />
      );
    }

    const hasMore = options?.canNextPage;
    const lazyLoading = options?.lazyLoading;
    const fetchNextPage = options?.fetchNextPage;

    return (
      <Fragment>
        {dataRows.map((row, index) => {
          prepareRow(row);
          return (
            <Row
              checkboxCol={!!selectProps?.onSelect}
              columnsSpans={columnsSpans}
              key={index}
              onClick={onRowClick}
              optionsMenu={showOptions}
              row={row}
            />
          );
        })}

        {/* lazy loading */}
        {lazyLoading && fetchNextPage && hasMore && (
          <Row useOnScreen={useOnScreen(fetchNextPage)} loading={true} />
        )}
      </Fragment>
    );
  };

  const PaginationRowComponent = () => {
    if (!options?.usePagination) return null;

    return (
      <PaginationRow
        canNextPage={options?.canNextPage || canNextPage}
        canPreviousPage={options?.canPreviousPage || canPreviousPage}
        fetchNextPage={options?.fetchNextPage || options?.fetchNextPage}
        gotoPage={options?.gotoPage || gotoPage}
        loading={options?.loading || isLoading}
        onPageSizeChange={onPageSizeChange}
        pageCount={options?.pageCount || pageCount}
        pageIndex={options?.pageIndex || pageIndex}
        previousPage={options?.previousPage || previousPage}
        pageSize={options?.pageSize || options?.pageSize}
      />
    );
  };

  return {
    columns,
    filteredRows,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    PaginationRowComponent,
    renderRows,
    rows: options?.usePagination ? page : rows,
    setFilter,
    usePagination: !!options?.usePagination,
  };
};
