import styled from 'styled-components';

export const OptionsRow = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1.2fr 0.6fr 1.5fr 0.6fr 1.5fr 1.5fr;
  align-items: center;
  justify-items: end;
  height: 100%;
  width: 100%;
  max-height: 50px;
  padding: 0px 20px;
`;

export const OptionLabel = styled.span`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  margin-right: 20px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const SelectionsRow = styled.div<{ visible: boolean }>`
  display: grid;
  grid-template-columns: 1.5fr 0.6fr 1.1fr 2.3fr;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: 50px;
  padding: 0px 20px;
  transition: opacity 200ms;
  transition-delay: 200ms;

  ${({ visible }) => !visible && `opacity: 0`};
`;

export const Label = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const StatusDropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  width: 100%;
`;
