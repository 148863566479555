import styled from 'styled-components';

export const Container = styled.button<{ withShadow: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ withShadow }) => (withShadow ? `10px` : '0px')};
  z-index: 1;
  user-select: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: ${({ theme }) => theme.secondaryGrayDarkTrans};
  border-radius: ${({ theme }) => theme.borderRadiusSmall};
  box-shadow: ${({ withShadow, theme }) =>
    withShadow ? `0px 1px 3px 0px ${theme.secondaryGrayDarkTrans}` : 'none'};

  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.secondaryGrayLight};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    width: 100%;
  }
`;
