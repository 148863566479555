import styled from 'styled-components';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Minus } from './assets/minus.svg';

interface ILabel {
  isDisabled: boolean;
  checked?: boolean;
}

export const CheckIcon = styled(Check)`
  width: 11px;
  height: 9px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const IconContainer = styled.div`
  width: 101%;
  height: 100%;
  background-color: ${({ theme }) => theme.secondaryGreenDark};
  z-index: ${({ theme }) => theme.z1};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

export const Input = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const Label = styled.label<ILabel>`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  .result-check-mark {
    position: relative;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryWhite};
    border-radius: 4px;
    border: 1px solid
      ${({ theme, checked }) => (checked ? 'none' : theme.lightGray)};

    &:hover {
      background-color: ${({ isDisabled, theme }) =>
        !isDisabled && theme.secondaryGrayLight};
    }
  }
`;

export const MinusIcon = styled(Minus)`
  width: 11px;
  height: 3px;
  color: ${({ theme }) => theme.primaryWhite};
`;
