import { parseError } from 'utils/errors.utils';
import getUserData from 'data/api/queries/users/getUserData';

import { Adapter } from '../../types/api.types';
import { IUser } from 'types/user.types';

interface IGetUserDataArgs {
  userID: string;
}

export default async ({ userID }: IGetUserDataArgs): Promise<Adapter<IUser>> =>
  getUserData({ userID })
    .then((user) => ({ success: true, data: user }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
