import styled from 'styled-components';

interface IPath1 {
  fill?: string;
}

export const Path1 = styled.path<IPath1>`
  fill: ${({ fill, theme }) => fill || theme.primaryWhite};
`;

export const Svg = styled.svg`
  height: 19px;
  width: 19px;
`;
