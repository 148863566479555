import forgotUserPassword from 'data/adapters/auth/forgotUserPassword';
import getLoggedInUser from 'data/adapters/auth/getLoggedInUser';
import getOauthDistricts from 'data/adapters/auth/getOauthDistricts';
import loginUser from 'data/adapters/auth/loginUser';
import logoutUser from 'data/adapters/auth/logoutUser';
import oauthLoginUser from 'data/adapters/auth/oauthLoginUser';
import resetUserPassword from 'data/adapters/auth/resetUserPassword';
import GetDistrictDetails from 'data/adapters/districts/GetDistrictDetails';
import { generateHmac } from 'hooks/generateHmac';

import { parseError } from 'utils/errors.utils';

import * as Actions from './AuthActions.types';

export const clearError: Actions.TClearError = () => ({
  type: Actions.AUTH__CLEAR_ERROR,
});

export const forgotPassword: Actions.TForgotPassword = ({ email }) => async (
  dispatch,
) => {
  dispatch(setIsLoading(true));

  try {
    const { success, error } = await forgotUserPassword({
      email,
    });

    if (!success) {
      throw error;
    }

    dispatch({ type: Actions.AUTH__FORGOT_PASSWORD_SUCCESS });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    dispatch({
      type: Actions.AUTH__FAIL,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const GetDistrictSearchResults: Actions.TGetDistrictSearchResults = ({
  provider,
  searchKeyword,
}) => async (dispatch) => {
  try {
    if (searchKeyword.length < 2) {
      dispatch({
        type: Actions.AUTH__SET_DISTRICT_SEARCH_RESULTS,
        districts: [],
      });
      return;
    }

    const { data, error, success } = await getOauthDistricts({
      provider: provider.toLowerCase(),
      searchKeyword,
    });

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.AUTH__SET_DISTRICT_SEARCH_RESULTS,
      districts: data,
    });
  } catch (err) {
    const stringError = parseError(err);

    dispatch({
      error: stringError,
      type: Actions.AUTH__FAIL,
    });
  }
};

export const getUserAuth: Actions.TGetUserAuth = () => async (dispatch) => {
  dispatch(setIsLoadingUser(true));

  try {
    const { data: user, error, success } = await getLoggedInUser();

    if (!user || !success) {
      throw error;
    }

    const {
      data: district,
      error: districtError,
      success: districtSuccess,
    } = await GetDistrictDetails({ id: user.districtID });

    if (!district || !districtSuccess) {
      throw districtError;
    }

    let activeSchoolID: string | undefined;
    const schoolIdInStorage = window.localStorage.getItem('activeSchool');
    if (schoolIdInStorage) {
      activeSchoolID = schoolIdInStorage;
    } else if (user.schools && user.schools.length) {
      activeSchoolID = user.schools[0].id;
      await dispatch(setActiveSchool({ schoolID: activeSchoolID }));
    }

    const getUserStaffRolesSchool =
      user.roles.staff?.roles?.filter(
        (role) => role.school === activeSchoolID,
      ) || [];
    const isAdmin = !!getUserStaffRolesSchool.some(
      (role) => role.key === 'school_admin' || role.key === 'district_admin',
    );
    const isPupilAccounting = !!getUserStaffRolesSchool.find(
      ({ key }) => key === 'pupil_accounting',
    );

    const canCustomizeIdCards = getUserStaffRolesSchool?.some(
      (role) =>
        role.key === 'school_admin' ||
        role.key === 'site_coordinator' ||
        role.key === 'pupil_accounting' ||
        role.key === 'staff' ||
        role.key === 'teacher' ||
        role.key === 'district_admin',
    );

    dispatch({
      user: {
        ...user,
        userHMAC: generateHmac(user.id),
        isAdmin,
        canCustomizeIdCards,
        isPupilAccounting,
      },
      district,
      activeSchoolID,
      type: Actions.AUTH__GET_USER_AUTH_SUCCESS,
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    if (stringError !== 'token_not_valid') {
      dispatch({
        error: stringError,
        type: Actions.AUTH__FAIL,
      });
    }

    return { success: false, error: stringError };
  } finally {
    dispatch(setIsLoadingUser(false));
  }
};

export const login: Actions.TLogin = ({ email, password }) => async (
  dispatch,
) => {
  dispatch(setIsLoading(true));

  try {
    const { success, error } = await loginUser({
      email,
      password,
    });

    if (!success) {
      throw error;
    }

    const { success: userExists, error: userError } = await dispatch(
      getUserAuth(),
    );

    if (!userExists) {
      throw userError;
    }

    dispatch({ type: Actions.AUTH__LOGIN_SUCCESS });
  } catch (err) {
    const stringError = parseError(err);

    dispatch({
      type: Actions.AUTH__FAIL,
      error: stringError,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const logout: Actions.TLogout = () => async (dispatch) => {
  try {
    const { success, error } = await logoutUser();

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.AUTH__LOGOUT_SUCCESS,
    });
  } catch (err) {
    const stringError = parseError(err);
    dispatch({
      type: Actions.AUTH__LOGIN_FAIL,
      error: stringError,
    });
  }
};

export const oauthLogin: Actions.TOAuthLogin = ({
  code,
  provider,
  district,
}) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { success, error } = await oauthLoginUser({
      code,
      provider,
      district,
    });

    if (!success) {
      throw error;
    }

    const { success: userExists, error: userError } = await dispatch(
      getUserAuth(),
    );

    if (!userExists) {
      throw userError;
    }

    dispatch({ type: Actions.AUTH__LOGIN_SUCCESS });
  } catch (err) {
    const stringError = parseError(err);

    dispatch({
      type: Actions.AUTH__FAIL,
      error: stringError,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const resetPassword: Actions.TResetPassword = ({
  oobCode,
  newPassword,
}) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { success, error } = await resetUserPassword({
      code: oobCode,
      newPassword,
    });

    if (!success) {
      throw error;
    }

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);
    dispatch({
      type: Actions.AUTH__FAIL,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const setActiveSchool: Actions.TSetActiveOrganization = ({
  schoolID,
}) => async (dispatch) => {
  try {
    window.localStorage.setItem('activeSchool', schoolID);

    dispatch({
      activeSchoolID: schoolID,
      type: Actions.AUTH__SET_ACTIVE_SCHOOL,
    });

    return { isSuccessful: true };
  } catch (error) {
    return { isSuccessful: false };
  }
};

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => {
  return {
    isLoading,
    type: Actions.AUTH__SET_IS_LOADING,
  };
};

export const setIsLoadingForgotPassword: Actions.TSetIsLoadingForgotPassword = (
  isLoadingForgotPassword,
) => {
  return {
    isLoadingForgotPassword,
    type: Actions.AUTH__SET_IS_LOADING_FORGOT_PASSWORD,
  };
};

export const setIsLoadingUser: Actions.TSetIsLoadingUser = (isLoadingUser) => {
  return {
    isLoadingUser,
    type: Actions.AUTH__SET_IS_LOADING_USER,
  };
};
