import { OptionsType, OptionTypeBase } from 'react-select';

/* eslint-disable @typescript-eslint/no-explicit-any */

const getSelectOptions = (
  data: any[],
  labelAccessor = 'name',
  valueAccessor = 'id',
): OptionsType<OptionTypeBase> => {
  if (!data) return [];

  const result = data.map(
    (option: any) => ({
      label: option[labelAccessor],
      value: option[valueAccessor],
    }),
    [],
  );

  return result;
};

export default getSelectOptions;
