import { TFrontOfficeFetchingTypes } from 'state/front-office/FrontOfficeActions.types';
import {
  IAttendanceStatus,
  StatusEnum,
  StatusTypesEnum,
} from 'types/attendance.types';

export const isStatusAbsentUnaxecused = (
  status?: Partial<IAttendanceStatus>,
) => {
  if (!status) {
    return false;
  }
  return (
    status.type === StatusTypesEnum.ABSENT ||
    status.sisID === 'A-U' ||
    status.name?.match('Unexcused')
  );
};

export const isStatusCNO = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  status.abbr === StatusEnum.CNO || status.name?.match('CNO');
};

export const isStatusHomebound = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  status.abbr === StatusEnum.HB || status.name?.match('Homebound');
};

export const isStatusAbsent = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  return status.type === StatusTypesEnum.ABSENT;
};

export const isStatusPresent = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  return status.type === StatusTypesEnum.PRESENT;
};

export const isStatusTardy = (status?: Partial<IAttendanceStatus>) => {
  if (!status) {
    return false;
  }
  return status.type === StatusTypesEnum.TARDY;
};

export const getStatusOptions = (
  statuses: IAttendanceStatus[],
  trackingMethod: string,
  fetchingType: TFrontOfficeFetchingTypes,
) => {
  let filteredStatuses = statuses;
  if (trackingMethod === 'section' && fetchingType === 'front-office-type') {
    filteredStatuses = filteredStatuses.filter((item) => item.abbr === 'T');
  }
  return filteredStatuses.map((item) => ({
    label: item.name,
    value: item.id,
  }));
};
