import { Reducer } from 'redux';
import { Row } from 'react-table';
import * as Actions from './ReportsActions.types';

export interface State {
  filteredRows: Row<Record<string, unknown>>[];
  isLoading: boolean;
  searchText: string;
}

export const initialState: State = {
  filteredRows: [],
  isLoading: false,
  searchText: '',
};

const ReportsReducer: Reducer<State, Actions.MedicalReportsActionTypes> = (
  state: State = initialState,
  action: Actions.MedicalReportsActionTypes,
): State => {
  switch (action.type) {
    case Actions.REPORTS__CLEAR_SEARCH_TEXT: {
      return {
        ...state,
        searchText: '',
      };
    }

    case Actions.REPORTS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.data,
      };
    }

    case Actions.REPORTS__ON_SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }

    case Actions.REPORTS__SET_FILTERED_ROWS: {
      return {
        ...state,
        filteredRows: action.filteredRows,
      };
    }

    default:
      return state;
  }
};

export default ReportsReducer;
