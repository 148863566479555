import { StrictSchema } from 'morphism';
import { courseMapper } from '../course.mappers';
import { IApiSection, IApiSectionPeriod } from '../../types/sectionsApi.types';
import { ISection, ISectionPeriod } from 'types/section.types';
import { sectionPeriodMapper } from '../section.mappers';

export const ISectionPeriodSchema: StrictSchema<
  ISectionPeriod,
  IApiSectionPeriod
> = {
  bellScheduleItem: 'bell_schedule_item',
  day: 'day',
  endTime: 'end_time',
  id: 'id',
  instructionalMinutes: 'instructional_minutes',
  name: 'name',
  periodNumber: 'period_number',
  school: 'school',
  schoolYear: 'school_year',
  sisId: 'sis_id',
  startTime: 'start_time',
};

export const ISectionSchema: StrictSchema<ISection, IApiSection> = {
  course: (iteratee) => courseMapper(iteratee.course),
  name: 'name',
  grade: 'grade',
  id: 'id',
  primaryTeacherID: 'primary_teacher',
  studentIDs: (iteratee) =>
    iteratee.students.map((student) => student?.user_account_id),
  teachersIDs: (iteratee) =>
    iteratee.teachers.map((teacher) => teacher.user_account_id),
  teachers: 'teachers',
  students: (iteratee) =>
    iteratee.students.map((student) => {
      return {
        id: student.user_account_id,
        firstName: student.first_name,
        lastName: student.last_name,
        photoUrl: student.photo_url,
        attendance_status: student.attendance_status
          ? {
              ...student.attendance_status,
              event_date: student.attendance_status?.event_date
                ? new Date(student.attendance_status.event_date)
                : null,
            }
          : undefined,
      };
    }),
  period: (iteratee) => {
    if (!iteratee.period) return undefined;

    return sectionPeriodMapper(iteratee.period);
  },
};
