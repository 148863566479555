export enum AnalyticsEventsEnum {
  UPDATE_ATTENDANCE_STATUS = 'Attendance_Data',
}

export type TAnalyticsEventParameters = {
  [AnalyticsEventsEnum.UPDATE_ATTENDANCE_STATUS]: {
    district_id: string;
    school_id: string;
    auditUser_id: string;
  };
};
