import { ISurveyReport, ISurveyResponse } from 'types/reports.types';
import { UserRolesEnum } from 'types/user-old.types';
import { formatName } from 'utils/person.utils';

const prepareSurveyResponseData = (
  surveyReponseData: ISurveyResponse[],
): ISurveyReport[] => {
  return surveyReponseData.map((surveyResponse) => {
    const { survey, userAccount, updatedAt } = surveyResponse;

    const questionCount = survey.questions.length;
    const answerCount = surveyResponse.answers.length;
    const isComplete = answerCount === questionCount;

    const { student, teacher, staff } = userAccount.roles;
    const userRoles = [];

    student && userRoles.push(UserRolesEnum.student);
    teacher && userRoles.push(UserRolesEnum.teacher);
    staff && userRoles.push(UserRolesEnum.staff);

    const name = formatName(userAccount.name);

    const date = new Date(updatedAt);

    return {
      isComplete,
      userRoles,
      userAccountId: userAccount.id,
      name,
      date,
    };
  });
};

export default prepareSurveyResponseData;
