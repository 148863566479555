import { morphism, StrictSchema } from 'morphism';
import { IApiCalendarDate, IApiSchool } from '../types/schoolApi.types';
import { ICalendarDate, ISchool } from 'types/school.types';
import {
  ICalendarDateSchema,
  ISchoolSchema,
} from './schemas/schoolApi.schemas';

export const schoolMapper = (school: IApiSchool) =>
  morphism<StrictSchema<ISchool, IApiSchool>>(ISchoolSchema, school);

export const CalendarDateMapper = (calendarDate: IApiCalendarDate) =>
  morphism<StrictSchema<ICalendarDate, IApiCalendarDate>>(
    ICalendarDateSchema,
    calendarDate,
  );
