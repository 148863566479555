import { AnyAction } from 'redux';
import { camelCase } from 'lodash';
import { FirebaseError } from '@firebase/util';
import { ThunkDispatch } from 'redux-thunk';
import axios, { AxiosError } from 'axios';

import { logout } from 'state/auth/AuthActions';
import store, { RootState } from 'state/store';

import { PATHS } from 'routes';
import { ErrorResponse } from 'data/types/api.types';

export const parseError = (err: unknown, defaultMessage = ''): string => {
  if (!err) return 'Unknown error';

  let errorMessage = defaultMessage;

  if (err instanceof FirebaseError) {
    // Firebase errors
    errorMessage = parseFirebaseErrorInstance(err);
  } else if (axios.isAxiosError(err)) {
    // Axios errors
    errorMessage = parseAxiosErrorInstance(err, defaultMessage);
  } else if (err instanceof Error && err.message) {
    // Error object
    errorMessage = parseErrorInstance(err);
  } else if ((err as ErrorResponse).error) {
    // API response errors
    errorMessage = parseErrorResponseInstance(err as ErrorResponse);
  } else if (typeof err === 'string') {
    // strings
    const { message } = findMissingField(err);
    errorMessage = message ?? err;
  }

  return errorMessage;
};

export const findMissingField = (erroMessage: string) => {
  const regexPattern = /\{"([^"]+)":\["This field may not be null."\]\}/g;
  const matches = new Set<string>();

  for (let match; (match = regexPattern.exec(erroMessage)) !== null; ) {
    matches.add(match[1]);
  }

  return {
    missingFields: [...matches],
    message: matches.size
      ? `The following fields are missing: ${[...matches]
          .map((f) => camelCase(f))
          .join(', ')}.`
      : null,
  };
};

const logoutUser = () => {
  if (
    location.pathname !== PATHS.login &&
    location.pathname !== PATHS.forgotPassword &&
    !location.pathname.startsWith('/oauth') &&
    !location.pathname.startsWith(PATHS.resetPassword)
  ) {
    const dispatch = store.dispatch as ThunkDispatch<
      RootState,
      void,
      AnyAction
    >;
    dispatch(logout());
  }
};

const parseAxiosErrorInstance = (err: AxiosError, defaultMessage?: string) => {
  let errorMessage = defaultMessage || '';

  if (err.response && err.response.data && err.response.data.error) {
    errorMessage = parseErrorResponseInstance(err.response?.data);
  } else if (!defaultMessage) {
    errorMessage = `${err.response?.status ? err.response?.status + ':' : ''} ${
      err.message
    }`;
  }

  if (err.response?.status === 401 || err.response?.status === 403) {
    logoutUser();
  }

  return errorMessage;
};

const parseErrorInstance = (err: Error) => {
  if (err.message.includes('status code 403')) {
    logoutUser();
    return `User not found`;
  } else if (err.message.includes('status code 401')) {
    location.replace(PATHS.deniedAccess);
    return `Unauthorized Access`;
  } else {
    const { message } = findMissingField(err.message);
    return message ?? err.message;
  }
};

const parseErrorResponseInstance = (err: ErrorResponse) => {
  const {
    error: { code, detail },
  } = err;

  if (code === 'token_not_valid') {
    return 'token_not_valid';
  }

  if (detail?.length) {
    const text = typeof detail === 'string' ? detail : JSON.stringify(detail);
    const { message } = findMissingField(text);
    return message ?? text;
  }

  return `something went wrong!`;
};

const parseFirebaseErrorInstance = (err: FirebaseError) => {
  switch (err.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'The email or password is incorrect';
    case 'auth/too-many-requests':
    default:
      return err.message;
  }
};
