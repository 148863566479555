import styled from 'styled-components';

interface IPath {
  fill?: string;
}

export const Path = styled.path<IPath>`
  fill: ${({ theme, fill }) => fill || theme.primaryWhite};
`;

export const Svg = styled.svg`
  width: 13px;
  height: 14px;
`;
