import { Reducer } from 'redux';
import { IAttendance } from 'types/attendance.types';
import { ISection } from 'types/section.types';
import { IStudent } from 'types/student.types';

import * as Actions from './HomeroomActions.types';

export interface State {
  currentSectionID?: string;
  endDate: Date;
  error: string;
  isLoading: boolean;
  isUpdating: boolean;
  sectionAttendances: IAttendance[];
  sections: ISection[];
  startDate: Date;
  students: IStudent[];
}

const today = new Date();
const initialState = {
  currentSectionID: undefined,
  endDate: today,
  error: '',
  isLoading: false,
  isUpdating: false,
  sectionAttendances: [],
  sections: [],
  startDate: today,
  students: [],
};

const homeroomReducer: Reducer<State, Actions.HomeroomActionTypes> = (
  state: State = initialState,
  action: Actions.HomeroomActionTypes,
) => {
  switch (action.type) {
    case Actions.HOMEROOM__GET_ATTENDANCES: {
      return {
        ...state,
        error: '',
        sectionAttendances: action.sectionAttendances,
      };
    }

    case Actions.HOMEROOM__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.HOMEROOM__LAUNCH_SUCCESS: {
      return {
        ...state,
        currentSectionID: undefined,
        error: '',
        sectionAttendances: [],
        sections: action.sections,
      };
    }

    case Actions.HOMEROOM__SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSectionID: action.sectionID,
        error: '',
        students: action.students,
      };
    }

    case Actions.HOMEROOM__SET_DATE_RANGE: {
      return {
        ...state,
        endDate: action.endDate,
        startDate: action.startDate,
      };
    }

    case Actions.HOMEROOM__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.HOMEROOM__SET_IS_UPDATING: {
      return {
        ...state,
        isUpdating: action.bool,
      };
    }

    default:
      return state;
  }
};

export default homeroomReducer;
