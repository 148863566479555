import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../types/api.types';
import {
  AttendanceMapper,
  ReverseUpdatedAttendanceMapper,
} from 'data/mappers/attendace.mappers';
import {
  IApiAttendance,
  TUpadtedAttendance,
} from 'data/types/attendanceApi.types';
import { IAttendance } from 'types/attendance.types';

interface IUpdateAttendancesArgs {
  updated: TUpadtedAttendance | TUpadtedAttendance[];
}

export default async ({
  updated,
}: IUpdateAttendancesArgs): Promise<Adapter<IAttendance[]>> => {
  const updates = Array.isArray(updated) ? updated : [updated];
  const data = updates.map(ReverseUpdatedAttendanceMapper);

  return API.post<IApiAttendance[]>(`/attendances`, {
    data,
  })
    .then(({ data }) => {
      const updatedRecord = data.map((rec) => AttendanceMapper(rec));
      return { success: true, data: updatedRecord };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
