import API from 'data/axios';
import { reversePlanStudentMapper } from 'data/mappers/schoolBus.mappers';

import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../../../types/api.types';

interface IAddStudentToPlanArgs {
  data: {
    busStopID: string;
    routePlanID: string;
    studentID: string;
  }[];
}

export default async ({ data }: IAddStudentToPlanArgs): Promise<Adapter> => {
  const newPlanStudents = data.map(reversePlanStudentMapper);

  return API.post(`/bus/route_plans/students`, { data: newPlanStudents })
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
