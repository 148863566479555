import { format } from 'date-fns';
import API from 'data/axios';

import { AttendanceMapper } from '../../mappers/attendace.mappers';
import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiAttendance } from '../../types/attendanceApi.types';
import { IAttendance } from 'types/attendance.types';

interface IGetSectionAttendancesArgs {
  endDate?: Date;
  sectionID: string;
  startDate?: Date;
  pageSize?: number;
}

export default async ({
  endDate,
  sectionID,
  startDate,
  pageSize,
}: IGetSectionAttendancesArgs): Promise<Adapter<IAttendance[]>> => {
  const calendar_date_start = startDate ? format(startDate, 'yyyy-MM-dd') : '';
  const calendar_date_end = endDate ? format(endDate, 'yyyy-MM-dd') : '';
  let url = `/sections/${sectionID}/attendances?calendar_date_start=${calendar_date_start}&calendar_date_end=${calendar_date_end}`;

  if (pageSize) url += `&page_size=${pageSize}`;

  return API.get<ArrayResponse<IApiAttendance>>(url)
    .then(({ data: response }) => {
      const attendances = response.data.map(({ data }) =>
        AttendanceMapper(data),
      );
      const next = response.links.filter(({ rel }) => rel === 'next')[0]?.uri;

      return { success: true, data: attendances, next };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
