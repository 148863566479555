import React from 'react';
import { LoaderContainer, Loader, Circle } from './SpinnerStyles';

interface Props {
  size?: number;
}

const Spinner: React.FC<Props> = ({ size = 40 }: Props) => {
  return (
    <LoaderContainer className="spinner">
      <Loader>
        <Circle size={size} />
      </Loader>
    </LoaderContainer>
  );
};

export default Spinner;
