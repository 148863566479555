import API from 'data/axios';
import { parseError } from 'utils/errors.utils';
import { Adapter } from '../../../types/api.types';

interface ICreateNewBusArgs {
  id: string;
}

export default async ({ id }: ICreateNewBusArgs): Promise<Adapter> =>
  API.delete(`/bus/buses/${id}`)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
