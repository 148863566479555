import moment from 'moment';

export const getAuthToken = () => {
  const data = window.localStorage.getItem('auth_token');

  if (!data) return null;

  const { token, expiry } = JSON.parse(data);

  const now = moment().unix();
  if (now >= expiry) {
    removeAuthToken();
    window.localStorage.clear();
    return null;
  }

  return token;
};

export const removeAuthToken = () => {
  window.localStorage.removeItem('auth_token');
};

export const setAuthToken = (token: string, expiry = 604800) => {
  const now = moment().unix();
  const data = {
    token,
    expiry: now + expiry, // expires in 24 hours by default
  };

  window.localStorage.setItem('auth_token', JSON.stringify(data));
};
