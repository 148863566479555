import * as Actions from './videosTutorialActions.types';
import { IVideoTutorial } from 'types/videoTutorial.types';
import getVideos from 'data/api/queries/video-tutorial/getVideos';
import { functions } from 'data/firebase';

export const fetchVideosData: Actions.TFetchVideos = ({
  from,
  userRole,
  appendData,
}) => async (dispatch, getState) => {
  if (from) {
    dispatch(setIsVideosLoadingMore(true));
  } else {
    dispatch(setIsVideosLoading(true));
  }

  try {
    const state = getState();
    const user = state.auth.user;
    const schoolID = state.auth.activeSchool?.id ?? '';
    const { data, nextPage: newPage } = await getVideos({
      from,
      userRole,
      schoolID,
      userID: user?.id ?? '',
    });

    dispatch({
      data: data as IVideoTutorial[],
      nextPage: newPage,
      allFetched: newPage ? false : true,
      type: Actions.VIDEOS__FETCH_SUCCESS,
      appendData: appendData,
    });
  } catch (err) {
    //catch error
  } finally {
    if (from) {
      dispatch(setIsVideosLoadingMore(false));
    } else {
      dispatch(setIsVideosLoading(false));
    }
  }
};

export const setVideoAsWatched: Actions.TSetVideoAsWatched = ({
  video,
}) => async (dispatch, getState) => {
  try {
    const state = getState();
    const user = state.auth.user;
    const activeSchool = state.auth.activeSchool;
    const district = state.auth.district;
    const logTrainingVideoWatchStatus = functions.httpsCallable(
      'logTrainingVideoWatchStatus',
    );
    await logTrainingVideoWatchStatus({
      userID: user?.id ?? '',
      userEmail: user?.email,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      schoolDistrict: district?.id ?? '',
      schoolID: activeSchool?.id,
      videoID: video.id,
      videoTitle: video.title,
      watchedComplete: true,
    });
    dispatch({
      video: video,
      type: Actions.VIDEOS__SET_WATCHED,
    });
  } catch (err) {
    //catch error
  } finally {
    //finally
  }
};

export const setIsVideosLoading: Actions.TSetIsLoading = (bool) => ({
  type: Actions.VIDEOS__SET_IS_LOADING,
  bool,
});

export const setSelectedVideo: Actions.TSetSelectedVideo = (video) => ({
  type: Actions.VIDEOS__SET_SELECTED_VIDEO,
  video,
});

export const setIsVideosLoadingMore: Actions.TSetIsLoadingMore = (bool) => ({
  type: Actions.VIDEOS__SET_IS_LOADING_MORE,
  bool,
});

export const ResetTutorial: Actions.TReset = () => ({
  type: Actions.VIDEOS__RESET,
});
