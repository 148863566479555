import { StrictSchema } from 'morphism';
import { IApiUser } from '../../types/userApi.types';
import {
  IRoleBusDriver,
  IRoleContact,
  IRoleDistrictAdmin,
  IRoleStaff,
  IRoleStudent,
  IRoleTeacher,
  IUser,
} from 'types/user.types';

export const IUserSchema: StrictSchema<IUser, IApiUser> = {
  districtID: 'district',
  email: 'email',
  firstName: 'first_name',
  gender: 'gender',
  race: 'race',
  id: 'id',
  isABCTrackerEnabled: 'is_abc_tracker_enabled',
  isActive: 'is_active',
  isADAEnabled: 'is_ada_enabled',
  isAssistantEnabled: 'is_assistant_enabled',
  isAttendanceEnabled: 'is_attendance_enabled',
  isInSchoolUser: 'is_attendance_enabled',
  isOutOfSchoolUser: 'is_out_of_school_enabled',
  isReportEnabled: 'is_report_enabled',
  lastName: 'last_name',
  isAssignmentsEnabled: 'is_edlytics_assignments_enabled',
  isAttendanceRecordsEnabled: 'is_edlytics_attendance_enabled',
  isClassScheduleEnabled: 'is_edlytics_schedule_enabled',
  isContactInfoEnabled: 'is_edlytics_contact_enabled',
  isDisciplineDataEnabled: 'is_edlytics_discipline_enabled',
  isReportCardsEnabled: 'is_edlytics_reportcards_enabled',
  isMaapScoresEnabled: 'is_edlytics_maap_analysis_enabled',
  isNineWeeksTestScoresEnabled: 'is_edlytics_nine_weeks_enabled',
  isNineWeeksTestScoresAnalysisEnabled:
    'is_edlytics_nine_weeks_analysis_enabled',
  phone: ({ phone }) => (phone ? phone : undefined),
  photoUrl: ({ photo_url }) => (photo_url ? photo_url : undefined),
  roles: (iteratee) => {
    const roles = iteratee.roles;
    const morphedRoles: IUser['roles'] = {};
    if (!roles) return morphedRoles;

    if ('bus_driver' in roles) {
      morphedRoles['busDriver'] = {
        driverNumber: roles.bus_driver.driver_number || '',
        id: roles.bus_driver.id,
      };
    }

    if ('contact' in roles) {
      morphedRoles['contact'] = {
        createdAt: new Date(roles.contact.created_at || ''),
        id: roles.contact.id,
        students: roles.contact.students,
        sisID: roles.contact.sis_id || '',
        updatedAt: new Date(roles.contact.updated_at || ''),
      };
    }

    if ('district_admin' in roles) {
      morphedRoles['districtAdmin'] = {
        createdAt: new Date(roles.district_admin.created_at || ''),
        id: roles.district_admin.id,
        title: roles.district_admin.title || undefined,
        updatedAt: new Date(roles.district_admin.updated_at || ''),
      };
    }

    if ('staff' in roles) {
      morphedRoles['staff'] = {
        createdAt: new Date(roles.staff.created_at || ''),
        department: roles.staff.department || '',
        id: roles.staff.id,
        title: roles.staff.title || '',
        updatedAt: new Date(roles.staff.updated_at || ''),
        roles: roles.staff.roles || [],
      };
    }

    if ('student' in roles) {
      morphedRoles['student'] = {
        dateOfBirth: roles.student.date_of_birth
          ? new Date(roles.student.date_of_birth)
          : undefined,
        gender: roles.student.gender || '',
        // gradeID: roles.student.grade_id || undefined,//deprecated use grade object
        grade: roles.student.grade || undefined,
        raceID: roles.student.race_id || undefined,
        genderID: roles.student.gender_id || undefined,
        graduationYear: roles.student.graduation_year || '',
        id: roles.student.id,
        race: roles.student.race || '',
        schoolID: roles.student.school || '',
        stateID: roles.student.state_id || '',
        studentCode: roles.student.student_code || '',
      };
    }

    if ('teacher' in roles) {
      morphedRoles['teacher'] = {
        createdAt: new Date(roles.teacher.created_at || ''),
        id: roles.teacher.id,
        schoolID: roles.teacher.school || '',
        sisID: roles.teacher.sis_id || '',
        stateID: roles.teacher.state || '',
        teacherNumber: roles.teacher.teacher_number || '',
        title: roles.teacher.title || '',
        updatedAt: new Date(roles.teacher.updated_at || ''),
      };
    }

    return morphedRoles;
  },
  sisID: 'sis_id',
};

export const IReverseUserSchema: StrictSchema<IApiUser, Partial<IUser>> = {
  district: 'districtID',
  email: 'email',
  first_name: 'firstName',
  id: 'id',
  is_active: 'isActive',
  last_name: 'lastName',
  phone: 'phone',
  photo_url: 'photoUrl',
  is_attendance_enabled: 'isAttendanceEnabled',
  is_out_of_school_enabled: 'isOutOfSchoolUser',
  is_abc_tracker_enabled: 'isABCTrackerEnabled',
  is_ada_enabled: 'isADAEnabled',
  is_report_enabled: 'isReportEnabled',
  is_assistant_enabled: 'isAssistantEnabled',
  is_edlytics_assignments_enabled: 'isEdlyticsAssignmentsEnabled',
  is_edlytics_attendance_enabled: 'isEdlyticsAttendanceEnabled',
  is_edlytics_schedule_enabled: 'isEdlyticsScheduleEnabled',
  is_edlytics_contact_enabled: 'isEdlyticsContactEnabled',
  is_edlytics_discipline_enabled: 'isEdlyticsDisciplineEnabled',
  is_edlytics_reportcards_enabled: 'isEdlyticsReportCardsEnabled',
  is_edlytics_maap_analysis_enabled: 'isEdlyticsMaapScoresEnabled',
  is_edlytics_nine_weeks_enabled: 'isEdlyticsNineWeeksTestScoresEnabled',
  is_edlytics_nine_weeks_analysis_enabled:
    'isEdlyticsNineWeeksTestScoresAnalysisEnabled',
  roles: (iteratee) => {
    const roles = iteratee.roles;
    const morphedRoles: IApiUser['roles'] = {};
    if (!roles) return morphedRoles;

    if ('busDriver' in roles) {
      const role = roles.busDriver as IRoleBusDriver;

      morphedRoles['bus_driver'] = {
        driver_number: role.driverNumber,
        id: role.id,
      };
    }

    if ('contact' in roles) {
      const role = roles.contact as IRoleContact;

      morphedRoles['contact'] = {
        created_at: new Date(role.createdAt).toISOString(),
        id: role.id,
        sis_id: role.sisID || '',
        students: role.students,
        updated_at: new Date(role.updatedAt).toISOString(),
      };
    }

    if ('districtAdmin' in roles) {
      const role = roles.districtAdmin as IRoleDistrictAdmin;

      morphedRoles['district_admin'] = {
        created_at: new Date(role.createdAt).toISOString(),
        id: role.id,
        title: role.title || '',
        updated_at: new Date(role.updatedAt).toISOString(),
      };
    }

    if ('staff' in roles) {
      const role = roles.staff as IRoleStaff;

      morphedRoles['staff'] = {
        created_at: new Date(role.createdAt).toISOString(),
        department: role.department || '',
        id: role.id,
        title: role.title || '',
        updated_at: new Date(role.updatedAt).toISOString(),
      };
    }

    if ('student' in roles) {
      const role = roles.student as IRoleStudent;
      morphedRoles['student'] = {
        date_of_birth: role.dateOfBirth
          ? new Date(role.dateOfBirth).toISOString()
          : null,
        gender: role.gender || null,
        // grade_id: role.gradeID || null,//deprecated -> use grade object
        grade: role.grade || undefined,
        graduation_year: role.graduationYear || null,
        id: role.id,
        race: role.race,
        school: role.schoolID,
      };
    }

    if ('teacher' in roles) {
      const role = roles.teacher as IRoleTeacher;

      morphedRoles['teacher'] = {
        created_at: new Date(role.createdAt).toISOString(),
        id: role.id,
        school: role.schoolID,
        sis_id: role.sisID,
        state: role.stateID,
        teacher_number: role.teacherNumber,
        title: role.title || '',
        updated_at: new Date(role.updatedAt).toISOString(),
      };
    }

    return morphedRoles;
  },
  sis_id: 'sisID',
};
