import editUser from 'data/adapters/users/editUser';
import getNext from 'data/adapters/getNext';
import getSchoolUsers from 'data/adapters/school/getSchoolUsers';
import getUserData from 'data/adapters/users/getUserData';

import { IStaff } from 'types/staff.types';
import { UserRolesEnum } from 'types/user.types';
import * as Actions from './StaffActions.types';

export const clearCurrentStaffMember: Actions.TClearCurrentStaffMember = () => ({
  type: Actions.STAFF__CLEAR_CURRENT_STAFF,
});

export const clearSearchText: Actions.TClearSearchText = () => ({
  type: Actions.STAFF__CLEAR_SEARCH_TEXT,
});

export const EditStaff: Actions.TEditStaff = (staff) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const { success, error, data } = await editUser({
      userData: staff,
    });

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.STAFF__EDIT_SUCCESS,
    });

    await dispatch(launchStaffMember({ id: data.id }));

    return { success: true, data };
  } catch {
    dispatch({ type: Actions.STAFF__FAIL });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNextBatchOfMembers: Actions.TGetNextPage = (
  newPageSize,
) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));

    const state = getState();
    let nextPath = state.staff.next;

    if (!nextPath) throw 'No more data available';

    if (newPageSize) {
      nextPath = nextPath?.replace(
        /page_size=[0-9][0-9]?/,
        `page_size=${newPageSize}`,
      );
    }

    const { data, error, success, next } = await getNext<IStaff>({
      path: nextPath,
      type: 'user',
    });

    if (!success || !data) throw error;

    dispatch({
      data,
      next,
      type: Actions.STAFF__GET_NEXT_BATCH_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Actions.STAFF__FAIL });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNextStaffResults: Actions.TStaffThunkAction = () => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsLoading(true));

    const state = getState();
    const nextPath = state.staff.nextSearchLink;

    if (!nextPath) throw 'No more data available';

    const { data, error, success, next } = await getNext<IStaff>({
      path: nextPath,
      type: 'user',
    });

    if (!success || !data) throw error;

    dispatch({
      data,
      next,
      type: Actions.STAFF__GET_NEXT_RESULTS_PAGE,
    });
  } catch (err) {
    dispatch({ type: Actions.STAFF__FAIL });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const handleSearchInputChange: Actions.THandleSearchInputChange = (
  searchText,
) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  try {
    const schoolID = getState().auth.activeSchool?.id;
    if (!schoolID) throw 'Cannot get current organization';

    const { data, error, success, next } = await getSchoolUsers({
      schoolID,
      role: UserRolesEnum.staff,
      searchText,
    });

    if (!success || !data) throw error;

    dispatch({
      data: data as IStaff[],
      next,
      searchText,
      type: Actions.STAFF__HANDLE_SEARCH_TEXT_CHANGE,
    });
  } catch (err) {
    dispatch({ type: Actions.STAFF__FAIL });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const launchStaffMember: Actions.TLaunchStaffMember = ({ id }) => async (
  dispatch,
) => {
  dispatch(setIsLoading(true));

  try {
    const { data, error, success } = await getUserData({ userID: id });

    if (!data || !success) {
      throw error;
    }

    dispatch({
      data: data as IStaff,
      type: Actions.STAFF__LAUNCH_STAFF_VIEW,
    });
  } catch (err) {
    dispatch({
      type: Actions.STAFF__LAUNCH_STAFF_VIEW_FAIL,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const launchStaffSelection: Actions.TStaffThunkAction = () => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const state = getState();

    const schoolID = getState().auth.activeSchool?.id;
    const pageSize = parseInt(
      window.localStorage.getItem('staff_page_size') ||
        `${state.staff.pageSize || 20}`,
    );

    if (!schoolID) {
      throw 'Cannot get current organization';
    }

    const { data, error, success, next } = await getSchoolUsers({
      schoolID,
      role: UserRolesEnum.staff,
      pageSize,
    });

    if (!success || !data) throw error;

    dispatch({
      data: data as IStaff[],
      next,
      pageSize,
      type: Actions.STAFF__LAUNCH_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: Actions.STAFF__FAIL,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.STAFF__SET_IS_LOADING,
});

export const setStaffPageSize: Actions.TSetPageSize = (size) => async (
  dispatch,
  getState,
) => {
  window.localStorage.setItem('staff_page_size', size + '');

  const state = getState();
  const { students, next } = state.students;

  if (!!next && students.length < size) {
    dispatch(getNextBatchOfMembers(size));
  }

  dispatch({
    type: Actions.STAFF__SET_PAGE_SIZE,
    size,
  });
};
