import { Theme } from 'assets/styles';
import toast, { Toast } from 'react-hot-toast';

export default (text: string, options?: Toast) =>
  toast.error(text, {
    ...options,
    duration: 9000,
    position: 'top-center',
    // // Change colors of success/error/loading icon
    iconTheme: options?.iconTheme || {
      primary: Theme.colorDanger,
      secondary: '#fff',
    },
  });
