import { parseError } from 'utils/errors.utils';

import getSchools from 'data/api/queries/school/getSchools';

import { Adapter } from '../../types/api.types';
import { ISchool } from 'types/school.types';

interface IGetSchoolsArgs {
  pageSize?: number;
}

export default async (args?: IGetSchoolsArgs): Promise<Adapter<ISchool[]>> => {
  return getSchools(args)
    .then((school) => ({ success: true, data: school }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
