import { endOfDay, startOfDay, sub } from 'date-fns';
import { Reducer } from 'redux';
import * as Actions from './AttendanceDashboardAction.types';
import {
  AttendanceViewsEnum,
  IAttendanceByData,
  IAttendanceCountByDayDatum,
  IAttendanceCountByStudentData,
  IAttendanceStatusByStudentData,
} from 'types/attendance.types';

export interface State {
  attendanceByData: IAttendanceByData;
  attendanceCountsByDay: IAttendanceCountByDayDatum[];
  attendanceCountsByStudent: IAttendanceCountByStudentData[];
  attendanceStatusByStudent: IAttendanceStatusByStudentData[];
  endDate: Date;
  isAbsentSelected: boolean;
  isDashboardLoading: boolean;
  isPendingSelected: boolean;
  isPresentSelected: boolean;
  startDate: Date;
  view: string;
}

const initialState = {
  attendanceByData: {
    TotalPresent: 0,
    TotalAbsent: 0,
    TotalPending: 0,
    Total: 0,
  },
  attendanceCountsByDay: [],
  attendanceCountsByStudent: [],
  attendanceStatusByStudent: [],
  endDate: endOfDay(new Date()),
  isAbsentSelected: true,
  isDashboardLoading: false,
  isPendingSelected: false,
  isPresentSelected: true,
  startDate: startOfDay(sub(new Date(), { days: 6 })),
  view: AttendanceViewsEnum.totals,
};

const attendanceDashboardReducer: Reducer<
  State,
  Actions.AttendanceDashboardActionTypes
> = (
  state: State = initialState,
  action: Actions.AttendanceDashboardActionTypes,
) => {
  switch (action.type) {
    case Actions.DASHBOARD__SET_VIEW: {
      const isPresentSelected = true;
      const isAbsentSelected =
        action.view === AttendanceViewsEnum.totals ? true : false;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        view: action.view,
      };
    }

    case Actions.DASHBOARD__SET_IS_LOADING: {
      return {
        ...state,
        isDashboardLoading: action.bool,
      };
    }

    case Actions.DASHBOARD__GET_ATTENDANCE_COUNTS_SUCCESS: {
      return {
        ...state,
        attendanceCountsByDay: action.attendanceCountsByDayData,
        attendanceCountsByStudent: action.attendanceCountsByStudentData,
        attendanceStatusByStudent: action.attendanceStatusByStudentData,
        attendanceByData: action.attendanceByData,
      };
    }

    case Actions.DASHBOARD__GET_ATTENDANCE_COUNTS_FAIL:
    case Actions.DASHBOARD__GENERIC_FAIL: {
      return state;
    }

    case Actions.DASHBOARD__HANDLE_DATE_RANGE_CHANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }
    case Actions.DASHBOARD__TOGGLE_IS_PENDING_SELECTED: {
      return {
        ...state,
        isAbsentSelected: false,
        isPresentSelected: false,
        isPendingSelected: true,
      };
    }

    case Actions.DASHBOARD__TOGGLE_IS_ABSENT_SELECTED: {
      const isPresentSelected =
        state.view == AttendanceViewsEnum.totals
          ? state.isPresentSelected
          : false;
      const isAbsentSelected =
        state.view == AttendanceViewsEnum.totals
          ? !state.isAbsentSelected
          : true;
      return {
        ...state,
        isAbsentSelected,
        isPresentSelected,
        isPendingSelected: false,
      };
    }

    case Actions.DASHBOARD__TOGGLE_IS_PRESENT_SELECTED: {
      const isPresentSelected =
        state.view == AttendanceViewsEnum.totals
          ? !state.isPresentSelected
          : true;
      const isAbsentSelected =
        state.view == AttendanceViewsEnum.totals
          ? state.isAbsentSelected
          : false;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        isPendingSelected: false,
      };
    }

    default:
      return state;
  }
};

export default attendanceDashboardReducer;
