import React, { useMemo } from 'react';
import {
  BigText,
  Container,
  DownloadContainer,
  DownloadText,
  HorizontalLine,
  LeftContainer,
  MobileContainer,
  RightContainer,
  SmallText,
} from './HeroStyles';

import { ReactComponent as Svg } from './assets/hero_img.svg';

import { BookTimeToMeetButton } from './components/book-btn/BookTimeToMeetButton';
import { DownloadButton } from './components/download-btn/DownloadButton';
import { useTranslation } from 'react-i18next';

import { AppStoreIcon, PlayStoreIcon } from './assets/icons';
import useDimensions from 'hooks/useDimensions';

const smallText = 'Don’t just manage';
const bigText = 'Learn. Grow. Succeed.';
const downloadText = 'DOWNLOAD our app';

export const HeroAcademics = ({
  setCalendlyModalOpen,
}: {
  setCalendlyModalOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  const tSmallText = t(smallText);
  const tBigText = t(bigText);
  const tDownloadText = t(downloadText);

  const { ref, dimensions } = useDimensions();
  const isMobile = useMemo(
    () => (dimensions?.width ? dimensions.width <= 1300 : false),
    [dimensions],
  );

  return (
    <Container ref={ref}>
      {isMobile ? (
        <MobileContainer>
          <SmallText>{tSmallText}</SmallText>
          <BigText>{tBigText}</BigText>
          <RightContainer>
            <Svg
              style={{
                width: dimensions?.width ? dimensions.width * 0.9 : 0,
                height: 'auto',
              }}
            />
          </RightContainer>
          <BookTimeToMeetButton setCalendlyModalOpen={setCalendlyModalOpen} />
          <HorizontalLine />
          <DownloadText>{tDownloadText}</DownloadText>
          <DownloadContainer>
            <DownloadButton
              link="https://apps.apple.com/us/app/schoolday-ai/id1625011643"
              title="download on the"
              text="App Store"
              icon={<AppStoreIcon />}
            />
            <DownloadButton
              link="https://play.google.com/store/apps/details?id=com.safermgmt.district"
              title="get it on"
              text="Google Play"
              icon={<PlayStoreIcon />}
            />
          </DownloadContainer>
        </MobileContainer>
      ) : (
        <React.Fragment>
          <LeftContainer>
            <SmallText>{tSmallText}</SmallText>
            <BigText>{tBigText}</BigText>
            <BookTimeToMeetButton setCalendlyModalOpen={setCalendlyModalOpen} />
            <HorizontalLine />
            <DownloadText>{tDownloadText}</DownloadText>
            <DownloadContainer>
              <DownloadButton
                link="https://apps.apple.com/us/app/schoolday-ai/id1625011643"
                title="download on the"
                text="App Store"
                icon={<AppStoreIcon />}
              />
              <DownloadButton
                link="https://play.google.com/store/apps/details?id=com.safermgmt.district"
                title="get it on"
                text="Google Play"
                icon={<PlayStoreIcon />}
              />
            </DownloadContainer>
          </LeftContainer>
          <RightContainer>
            <Svg />
          </RightContainer>
        </React.Fragment>
      )}
    </Container>
  );
};
