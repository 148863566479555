import React, { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from 'components/buttons';
import { Spinner } from 'components/spinner';

import {
  Container,
  ContentContainer,
  HeaderContainer,
  HeaderText,
  Img,
  ModalContent,
  Overlay,
  TopContainer,
  TopWithIconContainer,
} from './ModalStyles';

interface Props {
  children: ReactNode;
  handleClose: ((e: React.MouseEvent) => void) | (() => void);
  headerMessage?: string;
  isLoading?: boolean;
  shouldShow: boolean;
  width?: string;
  icon?: string;
}

export const Modal: React.FC<Props> = ({
  children,
  handleClose,
  headerMessage = '',
  isLoading = false,
  shouldShow = false,
  width = '430px',
  icon,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Container shouldShow={shouldShow}>
      <Overlay onClick={handleClose} />
      <ContentContainer width={width}>
        {icon && (
          <>
            <TopWithIconContainer>
              {icon && <Img src={icon} />}
              <CloseButton onClick={handleClose} />
            </TopWithIconContainer>
            <HeaderContainer>
              <HeaderText>{t(headerMessage)}</HeaderText>
            </HeaderContainer>
          </>
        )}
        {!icon && (
          <TopContainer>
            <HeaderText>{t(headerMessage)}</HeaderText>
            <CloseButton onClick={handleClose} />
          </TopContainer>
        )}

        <ModalContent>{isLoading ? <Spinner /> : children}</ModalContent>
      </ContentContainer>
    </Container>
  );
};
