import API from 'data/axios';
import { busRoutePlanMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBusRoutePlan } from 'data/types/schoolBusApi.types';
import { IBusRoutePlan } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../../types/api.types';

export default async (): Promise<Adapter<IBusRoutePlan[]>> =>
  API.get<ArrayResponse<IApiBusRoutePlan>>(`/bus/route_plans`)
    .then(({ data: response }) => {
      const plans = response.data.map(({ data }) => busRoutePlanMapper(data));

      return { success: true, data: plans };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
