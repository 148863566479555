import { Reducer } from 'redux';
import { endOfDay, startOfDay } from 'date-fns';

import {
  IBusDriver,
  IBusRoutePlan,
  ManageRoutesViewsEnum,
} from 'types/schoolBus.types';
import { IStudent } from 'types/student.types';
import * as Actions from './BusRoutesActions.types';

/* Bus routes plans, Bus routes instances & Bus drivers */

export interface State {
  allStudents: IStudent[];
  busDrivers: IBusDriver[];
  endDate: Date;
  error: string;
  isLoading: boolean;
  isLoadingStudents: boolean;
  routePlans: IBusRoutePlan[];
  startDate: Date;
  students: IStudent[];
  view: string;
}

const today = new Date();
const initialState: State = {
  allStudents: [],
  busDrivers: [],
  endDate: startOfDay(today),
  error: '',
  isLoading: false,
  isLoadingStudents: false,
  routePlans: [],
  startDate: endOfDay(today),
  students: [],
  view: ManageRoutesViewsEnum.info,
};

const BusRoutesReducer: Reducer<State, Actions.BusRoutesActionTypes> = (
  state: State = initialState,
  action: Actions.BusRoutesActionTypes,
) => {
  switch (action.type) {
    case Actions.BUS_RTS__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.BUS_RTS__GET_ALL_STUDENTS: {
      return {
        ...state,
        allStudents: action.students,
      };
    }

    case Actions.BUS_RTS__GET_ROUTE_PLANS: {
      return {
        ...state,
        routePlans: action.plans,
        students: action.students,
        error: '',
      };
    }

    case Actions.BUS_RTS__LAUNCH_DRIVERS: {
      return {
        ...state,
        busDrivers: action.drivers,
      };
    }

    case Actions.BUS_RTS__SET_DATE_RANGE: {
      return {
        ...state,
        endDate: action.endDate,
        startDate: action.startDate,
      };
    }

    case Actions.BUS_RTS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.BUS_RTS__SET_IS_LOADING_STUDENTS: {
      return {
        ...state,
        isLoadingStudents: action.bool,
      };
    }

    case Actions.BUS_RTS__SET_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }

    default:
      return state;
  }
};

export default BusRoutesReducer;
