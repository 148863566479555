import { Reducer } from 'redux';

import { ICardConfig } from 'types/idCards.types';
import { IFailedUser } from 'types/idCards.types';
import { IUser, UserRolesEnum } from 'types/user.types';

import * as Actions from './IdCardsActions.types';

export type State = {
  canDeselectAll: boolean;
  config: ICardConfig[];
  currentContactId: string | null;
  currentPerson: IUser | null;
  errorMessage: string | null;
  failedUsers: IFailedUser[];
  hasRecentlyFailed: boolean;
  hasRecentlySucceeded: boolean;
  isLoading: boolean;
  isPerformingAction: boolean;
  pageCursor?: string;
  pageSize: number;
  roleFilter: UserRolesEnum;
  schoolAddress?: Actions.IAddress;
  schoolYear?: string | undefined;
  searchText: string;
  selectedIDs: string[];
  sentIDs: string[];
};

const initialState: State = {
  canDeselectAll: false,
  config: [],
  currentContactId: null,
  currentPerson: null,
  errorMessage: null,
  failedUsers: [],
  hasRecentlyFailed: false,
  hasRecentlySucceeded: false,
  isLoading: false,
  isPerformingAction: false,
  pageSize: 20,
  roleFilter: UserRolesEnum.student,
  selectedIDs: [],
  sentIDs: [],
  schoolYear: '',
  searchText: '',
  schoolAddress: {
    line1: '',
    line2: '',
  },
};

const IdCardsReducer: Reducer<State, Actions.IdCardsActionTypes> = (
  state: State = initialState,
  action: Actions.IdCardsActionTypes,
): State => {
  switch (action.type) {
    case Actions.IDS__CLEAR_FAILED_USERS: {
      return {
        ...state,
        failedUsers: [],
        hasRecentlyFailed: false,
        errorMessage: null,
      };
    }

    case Actions.IDS__DESELECT_ALL: {
      return {
        ...state,
        canDeselectAll: false,
        selectedIDs: [],
      };
    }

    case Actions.IDS__GENERATION_FAIL: {
      const failedUsers = action?.failedUsers || [];

      const successfullySentIds = action?.failedUsers
        ? action.digitalIds
            ?.filter((idCard) => {
              return !failedUsers.some(
                (failedUser) => failedUser.id === idCard.userAccountID,
              );
            })
            .map((idCard) => idCard.userAccountID) || []
        : [];

      return {
        ...state,
        errorMessage: failedUsers.length
          ? 'The following ID cards failed to send'
          : 'There was a problem sending some of the ID cards',
        failedUsers,
        sentIDs: successfullySentIds,
      };
    }

    case Actions.IDS__GENERATION_SUCCESS: {
      const successfullySentIds = action.digitalIds.map((idCard) => {
        return idCard.userAccountID;
      });

      return {
        ...state,
        errorMessage: null,
        failedUsers: [],
        sentIDs: successfullySentIds,
      };
    }

    case Actions.IDS__LAUNCH_SUCCESS: {
      return {
        ...state,
        config: action.config,
        schoolYear: action.schoolYear,
        schoolAddress: action.schoolAddress,
      };
    }

    case Actions.IDS__RESET: {
      return {
        ...initialState,
      };
    }

    case Actions.IDS__SELECT_SEARCH_RESULT: {
      return {
        ...state,
        currentPerson: action.newCurrentPerson || null,
        currentContactId: null,
      };
    }

    case Actions.IDS__SET_CURSOR: {
      return {
        ...state,
        pageCursor: action.cursor,
      };
    }

    case Actions.IDS__SET_HAS_RECENTLY_FAILED: {
      return {
        ...state,
        hasRecentlySucceeded: false,
        hasRecentlyFailed: action.bool,
      };
    }

    case Actions.IDS__SET_HAS_RECENTLY_SUCCEEDED: {
      return {
        ...state,
        hasRecentlySucceeded: action.bool,
        hasRecentlyFailed: false,
      };
    }

    case Actions.IDS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.IDS__SET_IS_PERFORMING_ACTION: {
      return {
        ...state,
        isPerformingAction: action.isPerformingAction,
      };
    }

    case Actions.IDS__SET_ROLE_FILTER: {
      return {
        ...state,
        canDeselectAll: false,
        currentContactId: null,
        currentPerson: null,
        roleFilter: action.role,
        selectedIDs: [],
        sentIDs: [],
      };
    }

    case Actions.IDS__SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.text,
      };
    }

    case Actions.IDS__TOGGLE_PERSON_RESULT_SELECTION: {
      const isAlreadySelected = state.selectedIDs.find(
        (id) => id === action.id,
      );
      const newSelectedIDs = isAlreadySelected
        ? state.selectedIDs.filter((id) => id !== action.id)
        : [...state.selectedIDs, action.id];

      return {
        ...state,
        canDeselectAll: !!newSelectedIDs.length,
        selectedIDs: [...newSelectedIDs],
      };
    }

    case Actions.IDS__TOGGLE_SELECTION: {
      return {
        ...state,
        canDeselectAll: !state.selectedIDs.length,
        selectedIDs: state.selectedIDs.length
          ? []
          : action.AllUsers.map(({ id }) => id),
      };
    }

    case Actions.IDS__UPDATE_CONFIG: {
      return {
        ...state,
        config: action.config,
      };
    }

    default:
      return state;
  }
};

export default IdCardsReducer;
