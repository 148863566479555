import { uniq } from 'lodash';

import { getLocationDetails } from 'utils/mapLocations.utils';
import { parseError } from 'utils/errors.utils';

import { getRouteLabels, getStates } from 'state/resources';
import {
  addStudentsToRoutePlan as addStudentsToPlanAdapter,
  createNewRoutePlan,
  createNewRoutePlanLocation,
  deleteBusRoutePlan as deleteBusRoutePlanAdapter,
  deleteRoutePlanStudent,
  editBusRoutePlan,
  editRoutePlan as editRoutePlanAdapter,
  editRoutePlanLocation,
  editRoutePlanStudent as editRoutePlanStudentAdapter,
  getBusRoutePlans,
} from 'data/adapters/school-bus/route-plan';
import deleteBusRouteAdapter from 'data/adapters/school-bus/route-instances/deleteBusRoute';
import deleteBusRoutePlanLocationAdapter from 'data/adapters/school-bus//route-plan/locations/deleteBusRoutePlanLocation';
import getAllUsers from 'data/adapters/users/getAllUsers';
import getUserData from 'data/adapters/users/getUserData';

import { IBusDriver } from 'types/schoolBus.types';
import { IStudent } from 'types/student.types';
import { UserRolesEnum } from 'types/user.types';
import * as Actions from './BusRoutesActions.types';

export const addNewPlan: Actions.TAddNewPlan = ({ data }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const state = getState();
    const districtID = state.auth.district?.id;

    if (!districtID) {
      throw 'An error occurred while loading district data';
    }

    const { success, error } = await createNewRoutePlan({
      data: {
        ...data,
        districtID,
      },
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const addNewPlanLocation: Actions.TAddNewPlanLocation = ({
  data,
}) => async (dispatch, getState) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const state = getState();
    const states = state.resources.states;
    const routePlans = state.busRoutes.routePlans;
    const plan = (routePlans || []).find(({ id }) => id === data.routePlanID);

    const newLocation = await getLocationDetails(data, states, plan);

    const { success, error } = await createNewRoutePlanLocation({
      data: { ...newLocation },
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const addStudentToRoutePlan: Actions.TAddStudentToPlan = ({
  busStopID,
  routePlanID,
  studentIDs,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await addStudentsToPlanAdapter({
      data: studentIDs.map((studentID) => ({
        busStopID,
        routePlanID,
        studentID,
      })),
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const deleteBusRouteInstance: Actions.TDeleteBusRoute = ({
  id,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await deleteBusRouteAdapter({ id });

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.BUS_RTS__DELETE_ROUTE,
    });

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const deleteBusRoutePlan: Actions.TDeleteBusRoutePlan = ({
  id,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await deleteBusRoutePlanAdapter({ id });
    if (!success) throw error;

    dispatch({ type: Actions.BUS_RTS__DELETE_ROUTE });
    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const deleteBusRoutePlanLocation: Actions.TDeleteBusRoutePlanLocation = ({
  id,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await deleteBusRoutePlanLocationAdapter({ id });
    if (!success) throw error;

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const deleteStudentFromRoutePlan: Actions.TDeleteStudentPlan = ({
  id,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await deleteRoutePlanStudent({ id });
    if (!success) throw error;

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const editRoutePlan: Actions.TEditRoutePlan = ({ data }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const state = getState();
    const districtID = state.auth.district?.id;

    if (!districtID) {
      throw 'Could not load district details';
    }

    const { success, error } = await editRoutePlanAdapter({
      data: { ...data, districtID },
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const editRoutePlanStudent: Actions.TEditRoutePlanStudent = ({
  data,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await editRoutePlanStudentAdapter({
      data,
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const editPlanLocationData: Actions.TEditPlanLocationData = ({
  data,
}) => async (dispatch, getState) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const state = getState();
    const states = state.resources.states;
    const routePlans = state.busRoutes.routePlans;
    const plan = (routePlans || []).find(({ id }) => id === data.routePlanID);

    const newLocation = await getLocationDetails(data, states, plan);

    const { success, error } = await editRoutePlanLocation({
      data: { ...newLocation },
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });

    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const launchBusDrivers: Actions.TBusRoutesThunkAction = () => async (
  dispatch,
) => {
  await dispatch(loadBusRoutePlans());
  await dispatch(loadAllBusDrivers());
};

export const launchPlansManager: Actions.TBusRoutesThunkAction = () => async (
  dispatch,
) => {
  dispatch(getRouteLabels());
  dispatch(getStates());
  dispatch(loadAllStudents());
  await dispatch(loadAllBusDrivers());
  await dispatch(loadBusRoutePlans());
};

export const loadAllBusDrivers: Actions.TBusRoutesThunkAction = () => async (
  dispatch,
) => {
  dispatch(setIsBusRoutesLoading(true));

  try {
    const { data, error, success } = await getAllUsers({
      role: UserRolesEnum.busDriver,
    });

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.BUS_RTS__LAUNCH_DRIVERS,
      drivers: data as IBusDriver[],
    });
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: errorMessage,
    });
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const loadBusRoutePlans: Actions.TBusRoutesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { data = [], success, error } = await getBusRoutePlans();

    if (!success) {
      throw error;
    }

    const results = await Promise.all(
      uniq(
        data
          .map(({ students }) => students.map(({ studentID }) => studentID))
          .flat()
          .filter((id) => !!id),
      ).map(async (userID) => (userID ? await getUserData({ userID }) : null)),
    );

    const students: IStudent[] = [];

    results.forEach((result) => {
      if (result && result.data) {
        students.push(result.data as IStudent);
      }
    });

    dispatch({
      type: Actions.BUS_RTS__GET_ROUTE_PLANS,
      plans: data,
      students: students,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};

export const loadAllStudents: Actions.TBusRoutesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(setIsBusRoutesLoadingStudents(true));

    const { data = [], success, error } = await getAllUsers({
      role: UserRolesEnum.student,
    });

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.BUS_RTS__GET_ALL_STUDENTS,
      students: data as IStudent[],
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(setIsBusRoutesLoadingStudents(false));
  }
};

export const setBusRoutesDateRange: Actions.TSetDateRange = ({
  endDate,
  startDate,
}) => ({
  endDate,
  startDate,
  type: Actions.BUS_RTS__SET_DATE_RANGE,
});

export const setIsBusRoutesLoading: Actions.TSetIsLoading = (bool) => ({
  type: Actions.BUS_RTS__SET_IS_LOADING,
  bool,
});
export const setIsBusRoutesLoadingStudents: Actions.TSetIsLoading = (bool) => ({
  type: Actions.BUS_RTS__SET_IS_LOADING_STUDENTS,
  bool,
});

export const setRoutesManagerView: Actions.TSetView = (view) => ({
  type: Actions.BUS_RTS__SET_VIEW,
  view,
});

export const updateBusRoutePlan: Actions.TUpdateBusRoutePlan = ({
  plan,
}) => async (dispatch) => {
  try {
    dispatch(setIsBusRoutesLoading(true));

    const { success, error } = await editBusRoutePlan({
      data: plan,
    });

    if (!success) {
      throw error;
    }

    await dispatch(loadBusRoutePlans());
    return { success: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.BUS_RTS__ERROR,
      error: stringError,
    });
    return { success: false };
  } finally {
    dispatch(setIsBusRoutesLoading(false));
  }
};
