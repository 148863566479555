import { TUpadtedAttendance } from 'data/types/attendanceApi.types';
import { SCANNING_TYPES } from 'domains/front-office/office-attendance/components/options-bar/OptionsBar';
import { TFrontOfficeFetchingTypes } from 'state/front-office/FrontOfficeActions.types';
import {
  IAttendance,
  IStudentAttendance,
  StatusEnum,
} from 'types/attendance.types';
import { ICalendarDate } from 'types/school.types';
import {
  ClassRoomAttendanceRecord,
  ISectionStudent,
} from 'types/section.types';
import { IStudent } from 'types/student.types';
import { IUser } from 'types/user.types';
import { isCalendarReadOnly } from './calendar.utils';

interface IUserInput extends Partial<IUser> {
  firstName: string;
  id: string;
  lastName: string;
}

export const getStudentAttendanceTemplate = (
  student: IUserInput,
  attendanceRecord?: IAttendance,
  overrides: Partial<IStudent & IAttendance> = {},
) => {
  const template: IStudentAttendance = {
    ...student,
    createdAt: attendanceRecord?.createdAt || new Date(),
    calendarDate: attendanceRecord?.calendarDate || {
      day: '',
      id: '',
      isStudentDay: true,
      isTeacherDay: false,
      isReadonly: false,
    },
    comment: attendanceRecord?.comment || '',
    eventDate: attendanceRecord?.eventDate || null,
    eventType: attendanceRecord?.eventType || '',
    id: attendanceRecord?.id || student.id,
    isStudentDay: attendanceRecord?.isStudentDay || false,
    isTeacherDay: attendanceRecord?.isTeacherDay || false,
    schoolID:
      attendanceRecord?.schoolID || student.roles?.student?.schoolID || '',
    status: {
      ...(attendanceRecord?.status || {}),
      abbr: attendanceRecord?.status?.abbr || StatusEnum.UNK,
      name: attendanceRecord?.status?.name || '',
    },
    studentID: attendanceRecord?.studentID || student.id || '',
    attendanceID: attendanceRecord?.id,
    trackingMethod: attendanceRecord?.trackingMethod || undefined,
    transportationType: attendanceRecord?.transportationType || '',
    sync: attendanceRecord?.sync,
    ...overrides,
  };

  return template;
};

//used to map users fetched from /sections into class roster item
export const mapStudentToClassRoster = (
  student: ISectionStudent,
  overrides: {
    sectionID?: string;
    schoolID: string;
    currentLoggedUser?: IUser;
    selectedDate?: Date;
    calendarDates?: ICalendarDate[];
  },
) => {
  const notEditableReason = getNotEditableReason(
    overrides.currentLoggedUser,
    student.attendance_status?.is_readonly,
    overrides.selectedDate,
    overrides.calendarDates,
  );
  const template: ClassRoomAttendanceRecord = {
    ...student,
    comment: student?.attendance_status?.comment || '',
    eventDate: student?.attendance_status?.event_date || null,
    calendarDate: {
      day: '',
      id: '',
      isStudentDay: true,
      isTeacherDay: false,
      isReadonly: false,
    },
    studentID: student?.id,
    id: student.id,
    attendanceID: student?.attendance_status?.attendance_id,
    schoolID: overrides.schoolID,
    sectionID: overrides.sectionID,
    status: {
      id: student?.attendance_status?.status_id || '',
      name: student?.attendance_status?.name || '',
      abbr: student.attendance_status?.abbr || StatusEnum.UNK,
      type: student?.attendance_status?.type,
      isReadOnly: student.attendance_status?.is_readonly,
    },
    notEditableReason: notEditableReason,
    sync: student?.attendance_status?.sync,
  };

  return template;
};

//used to map attendances fetched from /attendances into class roster item
export const mapAttendanceToClassRoster = (
  student: Partial<IStudent> | Partial<IUser>,
  attendanceRecord: IAttendance,
  overrides: {
    sectionID?: string;
    schoolID: string;
    currentLoggedUser?: IUser;
    selectedDate?: Date;
    calendarDates?: ICalendarDate[];
  },
) => {
  const notEditableReason = getNotEditableReason(
    overrides.currentLoggedUser,
    false,
    overrides.selectedDate,
    overrides.calendarDates,
  );

  const template: ClassRoomAttendanceRecord = {
    ...student,
    firstName: student.firstName || '',
    lastName: student.lastName || '',
    photoUrl: student.photoUrl || '',
    comment: attendanceRecord.comment || '',
    eventDate: attendanceRecord.eventDate,
    calendarDate: attendanceRecord?.calendarDate || {
      day: '',
      id: '',
      isStudentDay: true,
      isTeacherDay: false,
      isReadonly: false,
    },
    trackingMethod: attendanceRecord?.trackingMethod || undefined,
    studentID: attendanceRecord.studentID,
    id: attendanceRecord?.id || student.id || attendanceRecord?.studentID,
    attendanceID: attendanceRecord.id,
    schoolID: overrides.schoolID,
    sectionID: overrides.sectionID || attendanceRecord.sectionID,
    status: {
      id: attendanceRecord?.status?.id || '',
      abbr: attendanceRecord?.status?.abbr || StatusEnum.UNK,
      name: attendanceRecord?.status?.name || '',
      type: attendanceRecord?.status?.type,
      isReadOnly: false,
    },
    notEditableReason: notEditableReason,
    sync: attendanceRecord?.sync,
  };

  return template;
};

const getNotEditableReason = (
  currentLoggedUser?: IUser,
  isReadOnly?: boolean,
  currentSelectedDate?: Date,
  calendarDates?: ICalendarDate[],
) => {
  if (currentLoggedUser && currentSelectedDate && calendarDates) {
    if (isCalendarReadOnly(currentSelectedDate, calendarDates)) {
      return "Attendance records can't be modified for the selected date";
    }
  }
  if (isReadOnly) {
    return "Status can't be changed";
  }
  return undefined;
};

export const checkValidAttendanceRequests = (
  records: TUpadtedAttendance | TUpadtedAttendance[],
) => {
  const list = Array.isArray(records) ? records : [records];

  return {
    invalid: list.filter(
      (a) => !a.status || !a.trackingMethod || !a.calendarDate || !a.schoolID,
    ),
    valid: list.filter(
      (a) => a.status && a.trackingMethod && a.calendarDate && a.schoolID,
    ),
  };
};

export const findTrackingMethod = (
  targetTrackingMethod: string,
  filterType: TFrontOfficeFetchingTypes,
) => {
  if (filterType === 'front-office-type') {
    if (targetTrackingMethod === 'section') {
      return { label: 'Tardy', value: 'section' };
    }
  }
  return SCANNING_TYPES.find((item) => item.value === targetTrackingMethod);
};
