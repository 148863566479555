import { format, isBefore } from 'date-fns';
import { ICalendarDate } from 'types/school.types';

/**
 * Select the closest calendar date (current or future).
 * If no future dates are available return the closest date from the past.
 * @param date
 * @param calendarDates
 * @returns ICalendarDate | undefined
 */
export const getClosestCalendarDate = (
  date: Date,
  calendarDates: ICalendarDate[],
) => {
  // if today is a students day then return it
  const formattedDate = format(date, 'yyyy-MM-dd');
  const index = calendarDates.findIndex(
    ({ day, isStudentDay }) => day === formattedDate && isStudentDay,
  );

  if (index > -1) return calendarDates[index];

  // return the closest students day from the past
  const dateToCompare = new Date(date);
  dateToCompare.setHours(0, 0, 0, 0);

  for (let i = calendarDates.length - 1; i >= 0; i--) {
    const currDate = new Date(calendarDates[i].day);
    currDate.setHours(0, 0, 0, 0);

    if (isBefore(currDate, dateToCompare) && calendarDates[i].isStudentDay) {
      return calendarDates[i];
    }
  }

  return undefined;
};

export const isCalendarReadOnly = (
  date: Date,
  calendarDates: ICalendarDate[],
) => {
  const formattedDate = format(date, 'yyyy-MM-dd');
  const index = calendarDates.findIndex(({ day }) => day === formattedDate);

  if (index > -1) return calendarDates[index]?.isReadonly;
  return false;
};
