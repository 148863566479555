import { analytics } from '..';
import { TAnalyticsEventParameters, AnalyticsEventsEnum } from './types';
import { browserName } from 'react-device-detect';

class AnalyticsService {
  logEvent<T extends AnalyticsEventsEnum>(
    eventName: T,
    parameters?: TAnalyticsEventParameters[T],
  ) {
    analytics.logEvent(eventName as string, {
      source: 'website',
      browser_type: browserName,
      device_type: this.getDeviceType(),
      ...parameters,
    });
  }

  getDeviceType = () => {
    const userAgent = navigator.userAgent;
    let deviceType = userAgent;
    if (userAgent.match(/Macintosh/i)) {
      deviceType = 'MacBook';
    } else if (userAgent.match(/Chromebook/i)) {
      deviceType = 'Chromebook';
    } else if (userAgent.match(/Android/i)) {
      deviceType = 'Android Browser';
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      deviceType = 'iOS Browser';
    }
    return deviceType;
  };
}

export const Analytics = new AnalyticsService();
