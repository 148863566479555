import React from 'react';
import Select, { OptionProps, OptionTypeBase } from 'react-select';
import useDimensions from 'hooks/useDimensions';

import { Pagination } from './Pagination';
import Option from 'components/select-dropdown/components/option/Option';
import ValueContainer from 'components/select-dropdown/components/value-container/ValueContainer';

import { PaginationProps } from '.';
import pageSizeDropdownStyles from './pageSizeDropdownStyles';
import {
  RowWrapper,
  RowContainer,
  SelectorContainer,
} from './PaginationStyles';

interface Props extends PaginationProps {
  onPageSizeChange?: (size: number) => void;
  pageSize?: number;
}

export const PaginationRow: React.FC<Props> = ({
  onPageSizeChange,
  pageSize = 5,
  ...props
}: Props) => {
  const { ref, dimensions } = useDimensions();

  const WrappedOption = (props: OptionProps<OptionTypeBase, boolean>) => {
    return <Option {...props} dimensions={dimensions} />;
  };

  const handleChange = (option: OptionTypeBase | null) => {
    if (!option || !onPageSizeChange) return;

    onPageSizeChange(option.value);
  };

  const components = {
    ValueContainer,
    Option: WrappedOption,
  };

  return (
    <RowWrapper>
      <RowContainer>
        <SelectorContainer ref={ref}>
          {onPageSizeChange && (
            <Select
              components={components}
              defaultValue={{ label: pageSize, value: pageSize }}
              isDisabled={!!props.loading}
              menuPlacement="top"
              menuPortalTarget={document.body}
              onChange={handleChange}
              styles={pageSizeDropdownStyles}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
              ]}
            />
          )}
        </SelectorContainer>
        <Pagination {...props} />
      </RowContainer>
    </RowWrapper>
  );
};
