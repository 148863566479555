import React from 'react';

import useOfficeOptionsBar from './hooks/useOfficeOptionsBar';

import {
  LightButton,
  OptionsBarContainer,
  SelectedStudentsAvatars,
} from 'components/table-options-bar';
import { SelectDropdown } from 'components/select-dropdown';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';

import { useTranslation } from 'react-i18next';
import {
  OptionsRow,
  OptionContainer,
  OptionLabel,
  SelectionsRow,
  Label,
} from './OptionsBarStyles';
import { useAppSelector } from 'state/hooks';
import { Spinner } from 'components/spinner';
import { findTrackingMethod } from 'utils/attendance.utils';

export type IScanType = {
  label: string;
  value: 'check_in' | 'check_out' | 'section';
};
export const SCANNING_TYPES: IScanType[] = [
  { label: 'Check In', value: 'check_in' },
  { label: 'Check Out', value: 'check_out' },
  { label: 'Section', value: 'section' },
];

export const OptionsBar: React.FC = () => {
  const { t } = useTranslation();

  const {
    autoStatus,
    customizedStatuses,
    isPrinting,
    isResourcesLoading,
    selectedStatus: selectedDefaultStatus,
    setIsPrinting,
    setSelectedDefaultStatus,
    defaultTrackMethod,
    setDefaultTrackMethod,
    selectedDefaultReason,
    setSelectedDefaultReason,
    isUpdating,
    selectedRecords,
    canGroupUpdate,
    groupStatus,
    setGroupStatus,
    selectedStudents,
    updateGroupStatus,
    statusOptions,
    trackingMethodsOptions,
    fetchingType,
  } = useOfficeOptionsBar();

  const { reasons } = useAppSelector(({ resources }) => resources);
  const reasonOptions = reasons.map((item) => ({
    label: item.description,
    value: item.id,
  }));
  const unKnownReason = reasons.find((reason) => reason.code === 'Unk');

  return (
    <OptionsBarContainer isExpanded={!!selectedRecords.length}>
      {!selectedStudents.length && (
        <OptionsRow>
          <div>
            <OptionLabel>{t('Type')}:</OptionLabel>
          </div>
          <OptionContainer>
            <SelectDropdown
              custom={{
                height: '30px',
                padding: '1px 10px',
                color: 'white',
                backgroundColor: 'transparent',
              }}
              labelAccessor="label"
              valueAccessor="value"
              data={trackingMethodsOptions}
              value={findTrackingMethod(
                defaultTrackMethod?.value ?? '',
                fetchingType,
              )}
              onChange={setDefaultTrackMethod}
            />
          </OptionContainer>
          <div>
            <OptionLabel>{t('Status')}:</OptionLabel>
          </div>
          <OptionContainer>
            <SelectDropdown
              custom={{
                height: '30px',
                padding: '1px 10px',
                color: 'white',
                backgroundColor: 'transparent',
              }}
              labelAccessor="label"
              valueAccessor="value"
              data={customizedStatuses}
              value={
                selectedDefaultStatus?.value
                  ? selectedDefaultStatus
                  : autoStatus
              }
              onChange={setSelectedDefaultStatus}
              isDisabled={isResourcesLoading}
            />
          </OptionContainer>
          <div>
            <OptionLabel>{t('Reason')}:</OptionLabel>
          </div>
          <OptionContainer>
            <SelectDropdown
              custom={{
                height: '30px',
                padding: '1px 10px',
                color: 'white',
                backgroundColor: 'transparent',
              }}
              labelAccessor="label"
              valueAccessor="value"
              data={reasonOptions}
              value={{
                value: selectedDefaultReason?.value ?? unKnownReason?.id,
                label:
                  reasons.find(
                    (reason) => reason.id === selectedDefaultReason?.value,
                  )?.description ??
                  unKnownReason?.description ??
                  '',
              }}
              onChange={setSelectedDefaultReason}
              isDisabled={isResourcesLoading}
            />
          </OptionContainer>
          <OptionContainer>
            <ToggleSwitch
              light
              labelAlignment="left"
              labels={{
                on: 'Printing On',
                off: 'Printing Off',
              }}
              isOn={isPrinting}
              onChange={() => setIsPrinting((v) => !v)}
            />
          </OptionContainer>
        </OptionsRow>
      )}

      <SelectionsRow visible={!!selectedStudents.length}>
        <SelectedStudentsAvatars selectedAttendances={selectedStudents} />

        <Label>
          <OptionLabel>{t('Status')}:</OptionLabel>
        </Label>
        <OptionContainer>
          <SelectDropdown
            custom={{
              height: '30px',
              padding: '1px 10px',
              color: 'white',
              backgroundColor: 'transparent',
            }}
            labelAccessor="label"
            valueAccessor="value"
            data={statusOptions}
            value={groupStatus}
            onChange={setGroupStatus}
            isDisabled={isResourcesLoading}
            placeholder="Select Status..."
          />
        </OptionContainer>

        <LightButton
          isDisabled={!groupStatus || !canGroupUpdate}
          onClick={updateGroupStatus}
        >
          {isUpdating ? (
            <Spinner size={12} />
          ) : (
            `${t('Update')} (${selectedRecords.length})`
          )}
        </LightButton>
      </SelectionsRow>
    </OptionsBarContainer>
  );
};
