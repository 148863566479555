import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IAttendanceCountByStudentData,
  IAttendanceStatusByStudentData,
  IAttendanceCountByDayDatum,
  IAttendanceByData,
} from 'types/attendance.types';

export const DASHBOARD__GENERIC_FAIL = 'DASHBOARD__GENERIC_FAIL';
export const DASHBOARD__GET_ATTENDANCE_COUNTS_FAIL =
  'DASHBOARD__GET_ATTENDANCE_COUNTS_FAIL';
export const DASHBOARD__GET_ATTENDANCE_COUNTS_SUCCESS =
  'DASHBOARD__GET_ATTENDANCE_COUNTS_SUCCESS';
export const DASHBOARD__HANDLE_DATE_RANGE_CHANGE =
  'DASHBOARD__HANDLE_DATE_RANGE_CHANGE';
export const DASHBOARD__SET_IS_LOADING = 'DASHBOARD__SET_IS_LOADING';
export const DASHBOARD__SET_VIEW = 'DASHBOARD__SET_VIEW';
export const DASHBOARD__SHOW_ABSENT = 'DASHBOARD__SHOW_ABSENT';
export const DASHBOARD__SHOW_PRESENT = 'DASHBOARD__SHOW_PRESENT';
export const DASHBOARD__TOGGLE_IS_ABSENT_SELECTED =
  'DASHBOARD__TOGGLE_IS_ABSENT_SELECTED';
export const DASHBOARD__TOGGLE_IS_PRESENT_SELECTED =
  'DASHBOARD__TOGGLE_IS_PRESENT_SELECTED';
export const DASHBOARD__TOGGLE_IS_PENDING_SELECTED =
  'DASHBOARD__TOGGLE_IS_PENDING_SELECTED';

export interface SetDashboardViewAction {
  type: typeof DASHBOARD__SET_VIEW;
  view: string;
}

export interface GenericFail {
  type: typeof DASHBOARD__GENERIC_FAIL;
}

export interface LaunchAttendanceDashboardSuccessAction {
  type: typeof DASHBOARD__GET_ATTENDANCE_COUNTS_SUCCESS;
  attendanceCountsByDayData: IAttendanceCountByDayDatum[];
  attendanceCountsByStudentData: IAttendanceCountByStudentData[];
  attendanceStatusByStudentData: IAttendanceStatusByStudentData[];
  attendanceByData: IAttendanceByData;
}

export interface LaunchAttendanceDashboardFailAction {
  type: typeof DASHBOARD__GET_ATTENDANCE_COUNTS_FAIL;
}

export interface SetDashboardIsLoadingAction {
  type: typeof DASHBOARD__SET_IS_LOADING;
  bool: boolean;
}

export interface HandleDateRangeChangeAction {
  type: typeof DASHBOARD__HANDLE_DATE_RANGE_CHANGE;
  startDate: Date;
  endDate: Date;
}

export interface ToggleIsAbsentSelectedAction {
  type: typeof DASHBOARD__TOGGLE_IS_ABSENT_SELECTED;
}

export interface ToggleIsPresentSelectedAction {
  type: typeof DASHBOARD__TOGGLE_IS_PRESENT_SELECTED;
}

export interface ToggleIsPendingAction {
  type: typeof DASHBOARD__TOGGLE_IS_PENDING_SELECTED;
}

export type AttendanceDashboardActionTypes =
  | GenericFail
  | HandleDateRangeChangeAction
  | LaunchAttendanceDashboardFailAction
  | LaunchAttendanceDashboardSuccessAction
  | SetDashboardIsLoadingAction
  | SetDashboardViewAction
  | ToggleIsAbsentSelectedAction
  | ToggleIsPresentSelectedAction
  | ToggleIsPendingAction;

export type TGetAttendanceCounts = (args: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, AttendanceDashboardActionTypes>;

export type THandleDateRangeChange = (args: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, AttendanceDashboardActionTypes>;

export type TLaunchAttendanceDashboard = () => ThunkAction<
  void,
  RootState,
  unknown,
  AttendanceDashboardActionTypes
>;

export type TSetDashboardView = (view: string) => SetDashboardViewAction;

export type TSetIsDashboardLoading = (
  bool: boolean,
) => SetDashboardIsLoadingAction;

export type TToggleIsAbsentSelected = () => ToggleIsAbsentSelectedAction;

export type TToggleIsPresentSelected = () => ToggleIsPresentSelectedAction;

export type TTogglePendingSelected = () => ToggleIsPendingAction;
