import styled from 'styled-components';

interface ISvg {
  location?: 'filter' | 'notification' | 'table';
}

export const Path1 = styled.path`
  stroke-width: 20px;
`;

export const Svg = styled.svg<ISvg>`
  height: ${({ location, theme }) =>
    (location === 'filter' && theme.filterIconSize) ||
    (location === 'notification' && theme.notificationIconSize) ||
    (location === 'table' && theme.tableIconSize) ||
    '19px'};
  width: ${({ location, theme }) =>
    (location === 'filter' && theme.filterIconSize) ||
    (location === 'notification' && theme.notificationIconSize) ||
    (location === 'table' && theme.tableIconSize) ||
    '19px'};

  height: ${({ theme }) => theme.filterIconSize};
  width: ${({ theme }) => theme.filterIconSize};

  @media (max-width: 700px) {
    width: 20px;
    height: 20px;
  }
`;
