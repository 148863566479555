import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { userMapper } from 'data/mappers/user.mappers';
import { IUser } from 'types/user.types';
import { IApiUser } from 'data/types/userApi.types';

interface IGetStudentContactsArgs {
  studentID: string;
}

export default async ({
  studentID,
}: IGetStudentContactsArgs): Promise<Adapter<IUser[]>> => {
  return API.get<ArrayResponse<IApiUser>>(`/users/${studentID}/mycontacts`)
    .then(({ data: response }) => {
      const contacts = response.data.map(({ data }) => userMapper(data));

      return { success: true, data: contacts };
    })
    .catch((err) => {
      const stringError = parseError(
        err,
        'Failed to retrieve student contacts',
      );

      return { error: stringError, success: false };
    });
};
