import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IBusDriver,
  IBusRoutePlan,
  IBusRoutePlanLocation,
  IBusRoutePlanStudent,
} from 'types/schoolBus.types';
import { IStudent } from 'types/student.types';

// Actions
export const BUS_RTS__DELETE_ROUTE = 'BUS_RTS__DELETE_ROUTE';
export const BUS_RTS__ERROR = 'BUS_RTS__ERROR';
export const BUS_RTS__GET_ALL_STUDENTS = 'BUS_RTS__GET_ALL_STUDENTS';
export const BUS_RTS__GET_ROUTE_PLANS = 'BUS_RTS__GET_ROUTE_PLANS';
export const BUS_RTS__LAUNCH_DRIVERS = 'BUS_RTS__LAUNCH_DRIVERS';
export const BUS_RTS__SET_DATE_RANGE = 'BUS_RTS__SET_DATE_RANGE';
export const BUS_RTS__SET_IS_LOADING = 'BUS_RTS__SET_IS_LOADING';
export const BUS_RTS__SET_IS_LOADING_STUDENTS =
  'BUS_RTS__SET_IS_LOADING_STUDENTS';
export const BUS_RTS__SET_VIEW = 'BUS_RTS__SET_VIEW';

// Action types
interface BusRoutesDeleteAction {
  type: typeof BUS_RTS__DELETE_ROUTE;
}

interface BusRoutesErrorAction {
  type: typeof BUS_RTS__ERROR;
  error: string;
}

interface GetAllStudentsAction {
  type: typeof BUS_RTS__GET_ALL_STUDENTS;
  students: IStudent[];
}

interface GetBusRoutePlansAction {
  type: typeof BUS_RTS__GET_ROUTE_PLANS;
  plans: IBusRoutePlan[];
  students: IStudent[];
}

interface LaunchBusDriversAction {
  type: typeof BUS_RTS__LAUNCH_DRIVERS;
  drivers: IBusDriver[];
}

interface SetDateRangeAction {
  type: typeof BUS_RTS__SET_DATE_RANGE;
  startDate: Date;
  endDate: Date;
}

interface SetIsLoadingAction {
  type: typeof BUS_RTS__SET_IS_LOADING;
  bool: boolean;
}

interface SetIsLoadingStudentsAction {
  type: typeof BUS_RTS__SET_IS_LOADING_STUDENTS;
  bool: boolean;
}

interface SetViewAction {
  type: typeof BUS_RTS__SET_VIEW;
  view: string;
}

export type BusRoutesActionTypes =
  | BusRoutesDeleteAction
  | BusRoutesErrorAction
  | GetAllStudentsAction
  | GetBusRoutePlansAction
  | LaunchBusDriversAction
  | SetDateRangeAction
  | SetIsLoadingAction
  | SetIsLoadingStudentsAction
  | SetViewAction;

// Action handlers
export type TBusRoutesThunkAction = () => ThunkAction<
  void,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TAddNewPlan = (args: {
  data: Partial<IBusRoutePlan>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TAddNewPlanLocation = (args: {
  data: { isStop: boolean; lat: number; lng: number; routePlanID: string };
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TAddStudentToPlan = (args: {
  busStopID: string;
  routePlanID: string;
  studentIDs: string[];
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TDeleteBusRoute = (range: {
  id: string;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TEditRoutePlan = (range: {
  data: Partial<IBusRoutePlan>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TEditRoutePlanStudent = (range: {
  data: Partial<IBusRoutePlanStudent>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TEditPlanLocationData = (range: {
  data: Partial<IBusRoutePlanLocation>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TDeleteBusRoutePlan = (args: {
  id: string;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TDeleteBusRoutePlanLocation = (args: {
  id: string;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TDeleteStudentPlan = (args: {
  id: string;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;

export type TGetBusRoutesData = (range?: {
  endDate: Date;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, BusRoutesActionTypes>;

export type TSetDateRange = (range: {
  endDate: Date;
  startDate: Date;
}) => BusRoutesActionTypes;

export type TSetIsLoading = (
  bool: boolean,
) => SetIsLoadingAction | SetIsLoadingStudentsAction;

export type TSetView = (view: string) => SetViewAction;

export type TUpdateBusRoutePlan = (args: {
  plan: Partial<IBusRoutePlan>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusRoutesActionTypes
>;
