import { combineReducers } from 'redux';

import { AllSurveysReducer } from './surveys';
import {
  attendanceDashboardReducer,
  checkInReducer,
  homeroomReducer,
  classroomReducer,
  attendanceLogsReducer,
} from './attendance';
import {
  BusAttendenceReducer,
  BusReducer,
  BusRoutesReducer,
} from './school-bus';
import {
  auditLogReducer,
  covidReportReducer,
  ReportsReducer,
  SurveyReportsReducer,
} from './reports';
import { attendanceReducer, SectionsReducer } from './event';
import { HealthCasesReducer, medicalAlertsReducer } from './medical';
import { resourcesReducer } from './resources';
import { StaffReducer, StudentsReducer } from './people';
import authReducer from './auth/AuthReducer';
import CheckpointReducer from './checkpoint/CheckpointReducer';
import FrontOfficeReducer from './front-office/FrontOfficeReducer';
import IdCardsReducer from './id-cards/IdCardsReducer';
import NewDashboardReducer from './attendance/new-dashboard/NewDashboardReducer';
import { VideosTutorialReducer } from './video-tutorial';
import LocationManagementReducer from './checkpoint/locations-management/LocationManagementReducer';

export default combineReducers({
  attendanceCheckIn: checkInReducer,
  attendanceDashboard: attendanceDashboardReducer,
  attendanceLogs: attendanceLogsReducer,
  auditLogs: auditLogReducer,
  auth: authReducer,
  bus: BusReducer,
  busAttendance: BusAttendenceReducer,
  busRoutes: BusRoutesReducer,
  checkpoint: CheckpointReducer,
  classroom: classroomReducer,
  locationManagement: LocationManagementReducer,
  covidReport: covidReportReducer,
  eventAttendance: attendanceReducer,
  eventSections: SectionsReducer,
  frontOffice: FrontOfficeReducer,
  healthCases: HealthCasesReducer,
  homeroom: homeroomReducer,
  idCards: IdCardsReducer,
  medicalAlerts: medicalAlertsReducer,
  newDashboard: NewDashboardReducer,
  reports: ReportsReducer,
  resources: resourcesReducer,
  staff: StaffReducer,
  students: StudentsReducer,
  surveyReports: SurveyReportsReducer,
  surveys: AllSurveysReducer,
  tutorials: VideosTutorialReducer,
});
