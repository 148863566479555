import React from 'react';
import { Container, Path, Svg } from './BackButtonStyles';

interface Props {
  onClick: () => void;
}

const BackButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <Svg
        width="15"
        height="26"
        viewBox="0 0 15 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M13 2L2 13L13 24"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    </Container>
  );
};

export default BackButton;
