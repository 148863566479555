import styled from 'styled-components';

export const Container = styled.div`
  padding-right: 20px;
  cursor: pointer;
`;

export const Path = styled.path`
  stroke: ${({ theme }) => theme.primaryBlueDark};
`;

export const Svg = styled.svg`
  width: 15px;
  height: 26px;
`;
