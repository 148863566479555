import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, QuotationMark } from './icons';

import {
  BePresentText,
  Container,
  LeftContainer,
  LeftText,
  RightContainer,
  RightText,
  InnerRightTextContainer,
  RightTextContainer,
  AuthorContainer,
  AuthorName,
  AuthorWork,
  AuthorTextContainer,
} from './StudentSuccessStyles';

// import Avatar from './Avatar.png';
import useDimensions from 'hooks/useDimensions';

const yourStudentsText = `Your students'`;
const successIsAllText = 'success is all';
const aboutText = 'about';
const bePresentText = 'being present';

const rightText =
  "Students who attend school regularly have been shown to achieve at higher levels than students who do not have regular attendance. This relationship between attendance and achievement may appear early in a child's school career. A recent study looking at young children found that absenteeism in kindergarten was associated with negative first grade outcomes such as greater absenteeism in subsequent years and lower achievement in reading, math, and general knowledge.";

const authorNameText = 'Romero, M., and Lee, Y. (2007)';
const authorWorkTextFH = `A National Portrait of Chronic Absenteeism in the Early Grades. New York, NY`;
const authorWorkTextSH = `The National Center for Children in Poverty`;

export const StudentSuccess = () => {
  const { t } = useTranslation();

  const tYourStudentsText = t(yourStudentsText);
  const tSuccessIsAllText = t(successIsAllText);
  const tAboutText = t(aboutText);
  const tBePresentText = t(bePresentText);

  const tRightText = t(rightText);

  const tAuthorNameText = t(authorNameText);
  const tAuthorWorkTextFH = t(authorWorkTextFH);
  const tAuthorWorkTextSH = t(authorWorkTextSH);

  const { ref, dimensions } = useDimensions();

  const isXS = useMemo(
    () =>
      dimensions?.width
        ? dimensions?.width <= 500 && dimensions?.width >= 0
        : false,
    [dimensions?.width],
  );

  return (
    <Container ref={ref}>
      <LeftContainer>
        <Logo
          width={isXS ? '60px' : undefined}
          height={isXS ? '60px' : undefined}
        />

        <LeftText>
          <span>{tYourStudentsText}</span>
          <br />
          <span>{tSuccessIsAllText}</span>
          {!isXS ? <br /> : <span>&nbsp;</span>}
          <span>{tAboutText}</span> {isXS ? <br /> : <span>&nbsp;</span>}
          <BePresentText>{tBePresentText}</BePresentText>
        </LeftText>
      </LeftContainer>
      <RightContainer>
        <InnerRightTextContainer>
          <QuotationMark
            width={isXS ? '120' : undefined}
            height={isXS ? '28' : undefined}
          />
          <RightTextContainer>
            <RightText>{tRightText}</RightText>
            <AuthorContainer>
              {/* <img src={Avatar} alt="Avatar" width="50px" height="50px" /> */}
              <AuthorTextContainer>
                <AuthorName>{tAuthorNameText}</AuthorName>
                <AuthorWork>{`${tAuthorWorkTextFH}: ${tAuthorWorkTextSH}`}</AuthorWork>
              </AuthorTextContainer>
            </AuthorContainer>
          </RightTextContainer>
        </InnerRightTextContainer>
      </RightContainer>
    </Container>
  );
};
