import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IAttendance,
  IStudentAttendance,
  PartialAttendance,
} from 'types/attendance.types';
import { ICurrentSchoolData } from 'types/resources.types';
import { ISection } from 'types/section.types';
import { IStudent } from 'types/student.types';
import { IAttendanceByData } from './AttendanceReducer';

// Actions
export const EVENT__ERROR = 'EVENT__ERROR';
export const EVENT__GET_ATTENDANCES = 'EVENT__GET_ATTENDANCES';
export const EVENT__GET_SECTION_STUDENTS = 'EVENT__GET_SECTION_STUDENTS';
export const RSRCS__GET_CURRENT_SCHOOL_DATA = 'RSRCS__GET_CURRENT_SCHOOL_DATA';
export const EVENT__LAUNCH_SUCCESS = 'EVENT__LAUNCH_SUCCESS';
export const EVENT__SET_CURRENT_SECTION = 'EVENT__SET_CURRENT_SECTION';
export const EVENT__SET_DATE_RANGE = 'EVENT__SET_DATE_RANGE';
export const EVENT__SET_IS_LOADING = 'EVENT__SET_IS_LOADING';
export const EVENT__SET_IS_UPDATING = 'EVENT__SET_IS_UPDATING';
export const EVENT__UPDATE_ATTENDANCES = 'EVENT__UPDATE_ATTENDANCES';
export const ATTENDANCE__SET_VIEW = 'ATTENDANCE__SET_VIEW';
export const ATTENDANCE__TOGGLE_IS_ABSENT_SELECTED =
  'ATTENDANCE__TOGGLE_IS_ABSENT_SELECTED';
export const ATTENDANCE__TOGGLE_IS_PRESENT_SELECTED =
  'ATTENDANCE__TOGGLE_IS_PRESENT_SELECTED';
export const ATTENDANCE__TOGGLE_IS_ALL_SELECTED =
  'ATTENDANCE__TOGGLE_IS_ALL_SELECTED';

export const EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN =
  'EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN';

export const EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT =
  'EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT';

export const EVENT__ATTENDANCE__SET_MISSED_STUDENTS =
  'EVENT__ATTENDANCE__SET_MISSED_STUDENTS';

export const EVENT__COUNTS = 'EVENT__COUNTS';

// Action types

export interface SetMissedStudentsAction {
  type: typeof EVENT__ATTENDANCE__SET_MISSED_STUDENTS;
  missedStudents: string[];
}

export interface SetCheckedStudentsCheckInAction {
  type: typeof EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN;
  checkedStudentsCheckIn: IStudentAttendance[];
}

export interface SetCheckedStudentsCheckOutAction {
  type: typeof EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT;
  checkedStudentsCheckOut: IStudentAttendance[];
}
export interface SetAttendanceViewAction {
  type: typeof ATTENDANCE__SET_VIEW;
  view: string;
}

export interface ToggleIsAllSelectedAction {
  type: typeof ATTENDANCE__TOGGLE_IS_ALL_SELECTED;
}

export interface ToggleIsAbsentSelectedAction {
  type: typeof ATTENDANCE__TOGGLE_IS_ABSENT_SELECTED;
}

export interface ToggleIsPresentSelectedAction {
  type: typeof ATTENDANCE__TOGGLE_IS_PRESENT_SELECTED;
}

interface GetCurrentSchoolDataAction {
  type: typeof RSRCS__GET_CURRENT_SCHOOL_DATA;
  currentSchoolData: ICurrentSchoolData;
}

export type TSetAttendanceView = (view: string) => SetAttendanceViewAction;

interface GetSectionStudentsSuccess {
  type: typeof EVENT__GET_SECTION_STUDENTS;
  students: IStudentAttendance[];
  attendanceByData: IAttendanceByData;
}

interface Counts {
  type: typeof EVENT__COUNTS;
  attendanceByData: IAttendanceByData;
}

interface GetAttendancesSuccess {
  type: typeof EVENT__GET_ATTENDANCES;
  sectionAttendances: IAttendance[];
  sectionAttendanceNextLink: string | undefined;
}

interface EventFailAction {
  error: string;
  type: typeof EVENT__ERROR;
}

interface LaunchEventSuccessAction {
  sections: ISection[];
  type: typeof EVENT__LAUNCH_SUCCESS;
}

interface SetCurrentSectionAction {
  sectionID: string;
  students: IStudent[];
  allSectionStudentsNextLink: string | undefined;
  type: typeof EVENT__SET_CURRENT_SECTION;
}

interface SetDateRangeAction {
  endDate: Date;
  startDate: Date;
  type: typeof EVENT__SET_DATE_RANGE;
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof EVENT__SET_IS_LOADING;
}

interface SetIsUpdatingAction {
  bool: boolean;
  type: typeof EVENT__SET_IS_UPDATING;
}

interface UpdateAttendancesAction {
  type: typeof EVENT__UPDATE_ATTENDANCES;
}

export type EventActionTypes =
  | GetAttendancesSuccess
  | EventFailAction
  | LaunchEventSuccessAction
  | SetCurrentSectionAction
  | SetDateRangeAction
  | SetIsLoadingAction
  | SetIsUpdatingAction
  | UpdateAttendancesAction
  | SetAttendanceViewAction
  | GetSectionStudentsSuccess
  | GetCurrentSchoolDataAction
  | ToggleIsAbsentSelectedAction
  | ToggleIsPresentSelectedAction
  | ToggleIsAllSelectedAction
  | SetCheckedStudentsCheckInAction
  | SetCheckedStudentsCheckOutAction
  | SetMissedStudentsAction
  | Counts;

export type TSetMissedStudents = (
  missedStudents: string[],
) => SetMissedStudentsAction;

export type TSetCheckedStudentsCheckIn = (
  checkedStudentsCheckIn: IStudentAttendance[],
) => SetCheckedStudentsCheckInAction;

export type TSetCheckedStudentsCheckOut = (
  checkedStudentsCheckOut: IStudentAttendance[],
) => SetCheckedStudentsCheckOutAction;

export type TToggleIsAbsentSelected = () => ToggleIsAbsentSelectedAction;

export type TToggleIsPresentSelected = () => ToggleIsPresentSelectedAction;

export type TToggleIsAllSelected = () => ToggleIsAllSelectedAction;

// Action handlers
export type TGetSectionStudents = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, EventActionTypes>;

export type TGetSectionStudentsNext = () => ThunkAction<
  void,
  RootState,
  unknown,
  EventActionTypes
>;

export type TGetCurrentSectionAttendances = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, EventActionTypes>;

export type TLaunchEvent = (args: {
  activeSchoolID: string;
}) => ThunkAction<void, RootState, unknown, EventActionTypes>;

export type TLoadSectionAttendances = () => ThunkAction<
  void,
  RootState,
  unknown,
  EventActionTypes
>;

export type TSetCurrentSection = (args: {
  sectionID: string;
}) => ThunkAction<void, RootState, unknown, EventActionTypes>;

export type TSetDateRange = (args: {
  endDate: Date;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, EventActionTypes>;

export type TSetIsEventLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetIsUpdatingEvent = (bool: boolean) => SetIsUpdatingAction;

export type TUpdateAttendances = (args: {
  updates: { [attendanceID: string]: PartialAttendance };
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  EventActionTypes
>;
