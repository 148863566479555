import { OptionsType } from 'react-select';
import { GenericOption } from '../SelectDropdown';

const handleOnChange = ({
  newValue,
  action,
  callback,
}: {
  newValue: GenericOption | OptionsType<GenericOption> | null;
  action: unknown;
  callback: (
    newValues: GenericOption | OptionsType<GenericOption> | null,
  ) => void;
}): void => {
  switch (action) {
    case 'select-option': {
      callback(newValue);
      return;
    }

    case 'deselect-option': {
      callback(newValue);
      return;
    }

    case 'set-value': {
      callback(newValue);
      return;
    }

    default: {
      callback(newValue);
      return;
    }
  }
};

export default handleOnChange;
