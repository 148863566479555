import styled, { keyframes } from 'styled-components';

interface ICircle {
  borderSize: number;
  size: number;
}

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

export const Loader = styled.span`
  display: block;
  text-align: center;
`;

export const Circle = styled.div.attrs(({ size }: { size: number }) => ({
  borderSize: size * 0.2,
}))<ICircle>`
  border: ${({ borderSize }) => borderSize}px solid
    ${(props) => props.theme.secondaryGrayLight};
  border-radius: 50%;
  border-top: ${({ borderSize }) => borderSize}px solid
    ${(props) => props.theme.primaryBlueLight};
  margin: auto;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: ${spin} 1.5s linear infinite;
`;
