import { IFiltersChart } from 'data/adapters/dashboard/getAdaChartData';
import { ITeacher } from 'data/adapters/dashboard/getAttendanceSubmitted';
import { IFilters } from 'data/adapters/dashboard/getReportSummary';
import { IFiltersStudents } from 'data/adapters/dashboard/getStudents';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IDistrict } from 'types/district.types';
import { IGrade, ISchool } from 'types/school.types';
import { IUser } from 'types/user.types';

export interface IChronicAbsenteeismStudent {
  absent: {
    count: number;
    excused: number;
    unexcused: number;
  };
  ada: number;
  grade: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  present: number;
  tardy: number;
  total_attendance: number;
  total_days: number;
  user_account: string;
  school: string | undefined;
}

// Actions
export const NEW_DASHBOARD__SET_IS_LOADING = 'NEW_DASHBOARD__SET_IS_LOADING';
export const NEW_DASHBOARD__LAUNCH_SUCCESS = 'NEW_DASHBOARD__LAUNCH_SUCCESS';
export const NEW_DASHBOARD__ERROR = 'NEW_DASHBOARD__ERROR';
export const NEW_DASHBOARD__SET_VIEW = 'NEW_DASHBOARD__SET_VIEW';
export const NEW_DASHBOARD_SET_SUMMARY = 'NEW_DASHBOARD_SET_SUMMARY';
export const NEW_DASHBOARD_SET_SUBMITTED_ATTENDANCE =
  'NEW_DASHBOARD_SET_SUBMITTED_ATTENDANCE';
export const NEW_DASHBOARD__HANDLE_DATE_RANGE_CHANGE =
  'NEW_DASHBOARD__HANDLE_DATE_RANGE_CHANGE';
export const NEW_DASHBOARD_SET_CHART_DATA = 'NEW_DASHBOARD_SET_CHART_DATA';
export const NEW_DASHBOARD_GET_FILTERS_DATA = 'NEW_DASHBOARD_GET_FILTERS_DATA';
export const NEW_DASHBOARD__LAUNCH_CHRONIC_ABSENTEEISM =
  'NEW_DASHBOARD__LAUNCH_CHRONIC_ABSENTEEISM';
export const NEW_DASHBOARD__FETCH_NEXT_CHRONIC_ABSENTEEISM =
  'NEW_DASHBOARD__FETCH_NEXT_CHRONIC_ABSENTEEISM';
export const NEW_DASHBOARD__SET_IS_FETCHING_NEW_STUDENTS =
  'NEW_DASHBOARD__SET_IS_FETCHING_NEW_STUDENTS';
export const NEW_DASHBOARD__SET_IS_LOADING_OVERVIEW =
  'NEW_DASHBOARD__SET_IS_LOADING_OVERVIEW';

export const NEW_DASHBOARD__LAUNCH_SUBMITTED =
  'NEW_DASHBOARD__LAUNCH_SUBMITTED';
export const NEW_DASHBOARD__FETCH_NEXT_SUBMITTED =
  'NEW_DASHBOARD__FETCH_NEXT_SUBMITTED';
export const NEW_DASHBOARD__SET_IS_FETCHING_NEW_SUBMITTED =
  'NEW_DASHBOARD__SET_IS_FETCHING_NEW_SUBMITTED';
export const NEW_DASHBOARD__SET_IS_LOADING_SUBMITTED =
  'NEW_DASHBOARD__SET_IS_LOADING_SUBMITTED';
export const NEW_DASHBOARD__SET_TEACHERS_AND_GRADES =
  'NEW_DASHBOARD__SET_TEACHERS_AND_GRADES';

interface SetIsLoadingOverviewAction {
  bool: boolean;
  type: typeof NEW_DASHBOARD__SET_IS_LOADING_OVERVIEW;
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof NEW_DASHBOARD__SET_IS_LOADING;
}

interface SetIsFetchingNewStudentsAction {
  bool: boolean;
  type: typeof NEW_DASHBOARD__SET_IS_FETCHING_NEW_STUDENTS;
}

export interface LaunchSuccessAction {
  type: typeof NEW_DASHBOARD__LAUNCH_SUCCESS;
  schools: ISchool[];
  grades: IGrade[];
  districtDetails: IDistrict;
  teachers: IUser[];
  schoolYear: number;
}

export interface NewDashboardErrorAction {
  type: typeof NEW_DASHBOARD__ERROR;
  error: string;
}

export interface SetNewDashboardViewAction {
  type: typeof NEW_DASHBOARD__SET_VIEW;
  view: string;
}

export interface SetSummaryAction {
  type: typeof NEW_DASHBOARD_SET_SUMMARY;
  presentCount: number;
  absentCount: number;
  tardyCount: number;
  ada: number;
}

export interface SetSubmittedAttendanceAction {
  type: typeof NEW_DASHBOARD_SET_SUBMITTED_ATTENDANCE;
  submittedAttendance: number;
}

export interface SetChartDataAction {
  type: typeof NEW_DASHBOARD_SET_CHART_DATA;
  chartData: any;
}

// export interface HandleDateRangeChangeAction {
//   type: typeof NEW_DASHBOARD__HANDLE_DATE_RANGE_CHANGE;
//   startDate: string;
//   endDate: string;
// }

export interface GetFiltersDataAction {
  type: typeof NEW_DASHBOARD_GET_FILTERS_DATA;
  chartData: any;
  submittedAttendance: number;
  presentCount: number;
  absentCount: number;
  tardyCount: number;
  ada: number;
}

export interface LaunchChronicAbsenteeismAction {
  type: typeof NEW_DASHBOARD__LAUNCH_CHRONIC_ABSENTEEISM;
  students: IChronicAbsenteeismStudent[];
  nextStudentsUri: string | undefined;
}

export interface FetchNextChronicAbsenteeismAction {
  type: typeof NEW_DASHBOARD__FETCH_NEXT_CHRONIC_ABSENTEEISM;
  students: IChronicAbsenteeismStudent[];
  nextStudentsUri: string | undefined;
}

export interface LaunchSubmittedAction {
  type: typeof NEW_DASHBOARD__LAUNCH_SUBMITTED;
  teachersSubmitted: ITeacher[];
  nextTeachersUri: string | undefined;
}

export interface FetchNextSubmittedAction {
  type: typeof NEW_DASHBOARD__FETCH_NEXT_SUBMITTED;
  teachersSubmitted: ITeacher[];
  nextTeachersUri: string | undefined;
}

export interface SetIsFetchingNewSubmittedAction {
  type: typeof NEW_DASHBOARD__SET_IS_FETCHING_NEW_SUBMITTED;
  bool: boolean;
}

export interface SetIsLoadingSubmittedAction {
  type: typeof NEW_DASHBOARD__SET_IS_LOADING_SUBMITTED;
  bool: boolean;
}

export interface SetTeachersAndGradesAction {
  type: typeof NEW_DASHBOARD__SET_TEACHERS_AND_GRADES;
  teachers: IUser[];
  grades: IGrade[];
}

export type NewDashboardActionTypes =
  | SetIsLoadingOverviewAction
  | SetIsLoadingAction
  | SetNewDashboardViewAction
  | NewDashboardErrorAction
  | LaunchSuccessAction
  | SetSummaryAction
  | SetSubmittedAttendanceAction
  | SetChartDataAction
  // | HandleDateRangeChangeAction
  | GetFiltersDataAction
  | LaunchChronicAbsenteeismAction
  | FetchNextChronicAbsenteeismAction
  | SetIsFetchingNewStudentsAction
  | LaunchSubmittedAction
  | FetchNextSubmittedAction
  | SetIsFetchingNewSubmittedAction
  | SetIsLoadingSubmittedAction
  | SetTeachersAndGradesAction;

export type TSetIsLoadingOverview = (
  bool: boolean,
) => SetIsLoadingOverviewAction;

export type TSetNewDashboardView = (view: string) => SetNewDashboardViewAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetIsFetchingNewStudents = (
  bool: boolean,
) => SetIsFetchingNewStudentsAction;

export type TLaunchNewDashboard = (
  districtId: string,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TSetSummary = (
  filters: IFilters,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TSetSubmittedAttendance = (
  filters: IFilters,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

// export type THandleDateRangeChange = (args: {
//   startDate: string;
//   endDate: string;
// }) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TGetFiltersData = (args: {
  summaryFilters: IFilters;
  chartFilters: IFiltersChart;
}) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TLaunchChronicAbsenteeism = (
  filters: IFiltersStudents,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TFetchNextChronicAbsenteeism = (
  filters: IFiltersStudents,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TLaunchSubmitted = (
  filters: IFilters,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TFetchNextSubmitted = (
  filters: IFilters,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;

export type TSetIsFetchingNewSubmitted = (
  bool: boolean,
) => SetIsFetchingNewSubmittedAction;

export type TSetIsLoadingSubmitted = (
  bool: boolean,
) => SetIsLoadingSubmittedAction;

export type TSetTeachersAndGrades = (
  grades: IGrade[],
  schoolId: string,
) => ThunkAction<void, RootState, unknown, NewDashboardActionTypes>;
