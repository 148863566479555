import styled from 'styled-components';

interface IButton {
  isDisabled?: boolean;
  custom?: {
    backgroundColor?: string;
    width?: string;
    color?: string;
  };
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1170px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const HeaderButton = styled.button<IButton>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  z-index: 1;
  user-select: none;
  width: fit-content;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.fontBold};
  font-size: ${({ theme }) => theme.fontSizeExtraLarge};
  font-weight: ${({ theme }) => theme.weightBold};

  background: ${({ theme, custom }) =>
    custom?.backgroundColor === 'transparent'
      ? custom?.backgroundColor
      : `linear-gradient(
      224.33deg,
      rgba(0, 0, 0, 0.2) 5.75%,
      rgba(255, 255, 255, 2e-5) 71.65%
    ),
    ${custom?.backgroundColor ?? theme.primaryYellow}`};
  background-blend-mode: ${({ custom }) =>
    custom?.backgroundColor === 'transparent' ? '' : 'overlay, normal'};

  color: ${({ theme, custom }) => custom?.color ?? theme.secondaryBlueDark};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  box-shadow: 0px 10px 20px 0px
    ${({ theme, custom }) =>
      custom?.backgroundColor
        ? custom.backgroundColor + '40'
        : theme.secondaryYellowLightTrans};

  &:hover:not([disabled]) {
    opacity: 0.9;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
    opacity: 0.3 !important;
    cursor: not-allowed;
    `}

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownContainer = styled.div<{ gap: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => (gap ? '1rem' : '0')};

  @media (max-width: 1170px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
