import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/buttons';
import { Modal } from '..';

import WarnIcon from './assets/warn-icon.svg';

import {
  ContentContainer,
  H2,
  Img,
  Inner,
  P,
  Prompt,
} from './ConfirmDeleteModalStyles';

interface Props {
  closeModal: (e: React.MouseEvent) => void;
  details?: React.ReactNode;
  isLoading?: boolean;
  onConfirm: (e: React.MouseEvent) => void;
  shouldShow: boolean;
  messageDescription?: string;
  messageTitle?: string;
}

const ConfirmDeleteModal: React.FC<Props> = ({
  closeModal,
  details,
  isLoading = false,
  onConfirm,
  shouldShow,
  messageDescription,
  messageTitle,
}: Props) => {
  const { t } = useTranslation();

  const tHeader = t('Confirm Deletion');
  const tMessage = t('Do you really want to delete this data?');
  const tConfirm = t('Confirm');

  return (
    <Modal handleClose={closeModal} shouldShow={shouldShow} width="520px">
      <Inner>
        <Img src={WarnIcon} />
        <Prompt>
          <H2>{messageTitle ? messageTitle : tHeader}</H2>
          <P>{messageDescription ? messageDescription : tMessage}</P>
        </Prompt>

        {details && <ContentContainer>{details}</ContentContainer>}

        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          large
          onClick={onConfirm}
        >
          {tConfirm}
        </Button>
      </Inner>
    </Modal>
  );
};

export default ConfirmDeleteModal;
