import API from 'data/axios';
import { reversePlanStudentMapper } from 'data/mappers/schoolBus.mappers';
import { IBusRoutePlanStudent } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../../../types/api.types';

interface IEditPlanStudentArgs {
  data: Partial<IBusRoutePlanStudent>;
}

export default async ({ data }: IEditPlanStudentArgs): Promise<Adapter> => {
  const newData = reversePlanStudentMapper(data);
  if (!newData.id) throw 'Invalid bus route plan data';

  return API.patch(`/bus/route_plans/students/${newData.id}`, newData)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
