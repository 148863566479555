import styled from 'styled-components';
import { Link } from 'react-router-dom';

// make it take props
export const Container = styled.div<{
  isOnLoginPage?: boolean;
  isOnForgotPassword?: boolean;
}>`
  z-index: 2;
  max-width: 1420px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.pageTopMarginDesktop};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    ${({ isOnLoginPage, isOnForgotPassword }) =>
      isOnLoginPage || isOnForgotPassword
        ? `
    padding-left: 40px;
    padding-right: 40px;
  `
        : ''}
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    ${({ isOnLoginPage, isOnForgotPassword }) =>
      isOnLoginPage || isOnForgotPassword
        ? `
    padding-left: 113px;
    padding-right: 113px;
  `
        : ''}
  }

  @media only screen and (max-width: 360px) {
    gap: 8px;
  }
`;

export const TopNavText = styled.div<{
  isActive?: boolean;
}>`
  font-family: ${({ theme }) => theme.fontPro};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 17px;
  line-height: 22px;
  color: #07081f;
  letter-spacing: -0.408px;
  opacity: ${({ isActive }) => (isActive ? '0.5' : '1')};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  z-index: 1;

  &.desktop {
    @media (max-width: ${({ theme }) => theme.widthTablet}) {
      display: none;
    }
  }

  @media screen and (max-width: 360px) {
    font-size: 14px;
  }

  &.login {
    font-weight: 600 !important;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const LogoText = styled.div`
  font-family: ${({ theme }) => theme.font};
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme }) => theme.secondaryBlueMedium};
  font-size: 18px;
  line-height: 22px;
  margin: 0px 0px 0px 9px;
`;

export const LogoSpanDark = styled.span`
  color: ${({ theme }) => theme.primaryBlueDark};
  margin: 0px 0px 0px 4px;
`;

export const StyledLink = styled(Link)`
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  margin: 0px;
  text-decoration: none;
  z-index: 2;
`;

export const MiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const MobileContainer = styled.div<{
  isOpen: boolean;
  isMobile: boolean;
}>`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 60px;
  height: ${({ isOpen }) => (isOpen ? undefined : '0')};
  margin-top: ${({ isOpen }) => (isOpen ? '46px' : '0')};
  transition: ${({ isOpen }) =>
    isOpen ? 'transform 0.5s ease-in-out' : 'none'};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  z-index: 100;

  /* 
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
   */
`;

export const MobileMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 38px;
`;

export const MobileLastContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 22px;
`;

export const LastContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  z-index: 100;
`;

export const BookTimeToMeetButton = styled.a`
  text-decoration: none;
  cursor: pointer;
  background: #f6c000;
  border-radius: 14px;
  padding: 14px 20px;

  font-family: ${({ theme }) => theme.fontPro};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-style: normal;
  font-size: 17px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: 'case' on;
  color: #000000;

  :hover {
    background: ${({ theme }) => theme.secondaryYellowLight};
  }

  @media (max-width: 1300px) {
    font-size: 15px;
    padding: 8px 16px;
  }

  @media (max-width: 390px) {
    font-size: 8px;
    padding: 4px 12px;
  }

  @media (max-width: 310px) {
    display: none;
  }
`;

export const MobileDownloadContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.primaryWhite};
  @media only screen and (max-width: 1310px) {
    display: flex;
  }
`;

export const DownloadAppText = styled.h2`
  font-family: 'SF Pro Display';
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 369px) {
    font-size: 25px;
  }
`;
