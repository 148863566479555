import React from 'react';
import { Svg } from './ExitIconStyles';

type Props = {
  intent?: 'primary' | 'danger';
};

const ExitIcon: React.FC<Props> = ({ intent = 'primary' }: Props) => {
  return (
    <Svg
      intent={intent}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.38007 16.907C5.58295 16.97 5.78601 17 6.00006 17C6.73901 17 7.38542 16.5974 7.73165 16H9.25006C10.7671 16 11.9999 14.767 11.9999 13.2499V12.7499C11.9999 12.3359 11.6639 11.9999 11.2499 11.9999C10.8359 11.9999 10.4999 12.3359 10.4999 12.7499V13.2499C10.4999 13.939 9.93909 14.5 9.25006 14.5H7.99994V3.99994C7.99994 3.14703 7.45703 2.38403 6.638 2.09802L5.43959 1.5H9.25006C9.93909 1.5 10.4999 2.06104 10.4999 2.75006V4.25006C10.4999 4.66406 10.8359 5.00006 11.2499 5.00006C11.6639 5.00006 11.9999 4.66406 11.9999 4.25006V2.75006C11.9999 1.23303 10.7671 0 9.25006 0H2.00006C0.897034 0 0 0.897034 0 2.00006V14.0001C0 14.853 0.543091 15.616 1.36194 15.902L5.38007 16.907ZM14.4699 12.2801C14.1769 11.9869 14.1769 11.512 14.4699 11.219L16.439 9.25H10.7499C10.3359 9.25 9.99994 8.914 9.99994 8.5C9.99994 8.086 10.3359 7.75 10.7499 7.75H16.4409L14.4699 5.77899C14.1769 5.48602 14.1769 5.01105 14.4699 4.71808C14.7631 4.42493 15.238 4.42493 15.531 4.71808L18.781 7.96808C19.0367 8.22376 19.0692 8.61807 18.8786 8.90904C18.8458 8.95932 18.8072 9.00543 18.7636 9.0464L15.531 12.279C15.384 12.4269 15.1921 12.5 15 12.5C14.8079 12.5 14.616 12.4269 14.4699 12.2801Z"
      />
    </Svg>
  );
};

export default ExitIcon;
