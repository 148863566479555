import API from 'data/axios';

import { AttendanceEventTypeMapper } from '../../mappers/attendace.mappers';
import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiAttendanceEventType } from '../../types/attendanceApi.types';
import { IAttendanceEventType } from 'types/attendance.types';

// TODO: why is tracking_methods (trackingMethod) called event types?
export default async (): Promise<Adapter<IAttendanceEventType[]>> =>
  API.get<ArrayResponse<IApiAttendanceEventType>>(
    `/attendances/tracking_methods`,
  )
    .then(({ data: response }) => {
      const eventTypes = response.data.map(({ data }) =>
        AttendanceEventTypeMapper(data),
      );

      return { success: true, data: eventTypes };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
