import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IAttendance,
  IAttendanceStatus,
  INewAttendance,
} from 'types/attendance.types';

export const OFFICE__ADD_ATTENDANCE = 'OFFICE__ADD_ATTENDANCE';
export const OFFICE__ERROR = 'OFFICE__ERROR';
export const OFFICE__GET_NEXT_SUCCESS = 'OFFICE__GET_NEXT_SUCCESS';
export const OFFICE__LAUNCH_FRONT_OFFICE = 'OFFICE__LAUNCH_FRONT_OFFICE';
export const OFFICE__LOAD_ATTENDANCES_LIST = 'OFFICE__LOAD_ATTENDANCES_LIST';
export const OFFICE__SET_DATE_RANGE = 'OFFICE__SET_DATE_RANGE';
export const OFFICE__SET_DEFAULT_STATUS = 'OFFICE__SET_DEFAULT_STATUS';
export const OFFICE__SET_IS_LOADING = 'OFFICE__SET_IS_LOADING';
export const OFFICE__SET_IS_PRINTING_ON = 'OFFICE__SET_IS_PRINTING_ON';
export const OFFICE__DELETE_ATTENDANCE = 'OFFICE__DELETE_ATTENDANCE';
export const OFFICE__SET_DEFAULT_TRACKING_METHOD =
  'OFFICE__SET_DEFAULT_TRACKING_METHOD';
export const OFFICE__SET_DEFAULT_REASON = 'OFFICE__SET_DEFAULT_REASON';
export const OFFICE__SET_IS_UPDATING = 'OFFICE__SET_IS_UPDATING';
export const OFFICE__SET_PAGE_SIZE = 'OFFICE__SET_PAGE_SIZE';
export const OFFICE__UPDATE_ATTENDANCE = 'OFFICE__UPDATE_ATTENDANCE';
export const OFFICE__TOGGLE_ROW_SELECTION = 'OFFICE__TOGGLE_ROW_SELECTION';
export const OFFICE__TOGGLE_SELECTION = 'OFFICE__TOGGLE_SELECTION';
export const OFFICE__RESET = 'OFFICE__RESET';
export const OFFICE_CHANGE_FRONT_OFFICE_FILTER =
  'OFFICE_CHANGE_FRONT_OFFICE_FILTER';

interface AddAttendanceAction {
  type: typeof OFFICE__ADD_ATTENDANCE;
  newRecord: IAttendance;
}
interface FrontOfficeErrorAction {
  type: typeof OFFICE__ERROR;
  errorMessage: string;
}

interface LaunchFrontOfficeAction {
  type: typeof OFFICE__LAUNCH_FRONT_OFFICE;
}

interface ResetAction {
  type: typeof OFFICE__RESET;
}

interface LoadAttendancesListAction {
  type: typeof OFFICE__LOAD_ATTENDANCES_LIST;
  attendances: IAttendance[];
  next?: string;
}

export interface GetNextBatchSuccess {
  data: IAttendance[];
  next?: string;
  type: typeof OFFICE__GET_NEXT_SUCCESS;
}

interface SetDefaultStatusAction {
  type: typeof OFFICE__SET_DEFAULT_STATUS;
  status: IAttendanceStatus | null;
}

interface SetIsLoadingAction {
  type: typeof OFFICE__SET_IS_LOADING;
  bool: boolean;
}

interface SetIsPrintingOnAction {
  type: typeof OFFICE__SET_IS_PRINTING_ON;
  bool: boolean;
}

interface DeleteAttendanceAction {
  type: typeof OFFICE__DELETE_ATTENDANCE;
  attendanceID: string;
}

interface SetDefaultTrackingMethodAction {
  type: typeof OFFICE__SET_DEFAULT_TRACKING_METHOD;
  data: 'check_in' | 'check_out' | 'section';
}

interface SetDefaultReasonAction {
  type: typeof OFFICE__SET_DEFAULT_REASON;
  data: string;
}

interface SetIsUpdatingAction {
  type: typeof OFFICE__SET_IS_UPDATING;
  ids?: string[];
}

interface SetPageSizeAction {
  type: typeof OFFICE__SET_PAGE_SIZE;
  size: number;
}

interface SetSelectedDateRangeAction {
  type: typeof OFFICE__SET_DATE_RANGE;
  startDate: Date;
  endDate: Date;
}

interface UpdateAttendanceTypeAction {
  type: typeof OFFICE__UPDATE_ATTENDANCE;
  attendances: IAttendance[];
}

interface ToggleRowSelectionAction {
  type: typeof OFFICE__TOGGLE_ROW_SELECTION;
  recordID: string;
}

interface ToggleSelectionAction {
  type: typeof OFFICE__TOGGLE_SELECTION;
}

export interface HandleFrontOfficeChangeFilterAction {
  type: typeof OFFICE_CHANGE_FRONT_OFFICE_FILTER;
  fetchingType?: TFrontOfficeFetchingTypes;
  status?: string;
}

export type FrontOfficeActionTypes =
  | AddAttendanceAction
  | FrontOfficeErrorAction
  | GetNextBatchSuccess
  | LaunchFrontOfficeAction
  | LoadAttendancesListAction
  | SetDefaultStatusAction
  | SetIsLoadingAction
  | SetIsPrintingOnAction
  | SetDefaultTrackingMethodAction
  | SetDefaultReasonAction
  | SetIsUpdatingAction
  | SetPageSizeAction
  | SetSelectedDateRangeAction
  | ToggleRowSelectionAction
  | ToggleSelectionAction
  | ResetAction
  | DeleteAttendanceAction
  | UpdateAttendanceTypeAction
  | HandleFrontOfficeChangeFilterAction;

export type TAddFrontOfficeAttendance = (args: {
  data: INewAttendance | INewAttendance[];
}) => ThunkAction<
  Promise<{ isSuccessful: boolean; data?: IAttendance[]; error?: string }>,
  RootState,
  unknown,
  FrontOfficeActionTypes
>;

export type TLaunchFrontOffice = () => ThunkAction<
  void,
  RootState,
  unknown,
  FrontOfficeActionTypes
>;

export type loadOfficeAttendances = (args?: {
  startDate?: Date;
  endDate?: Date;
}) => ThunkAction<void, RootState, unknown, FrontOfficeActionTypes>;

export type TGetNextPage = (
  newPageSize?: number,
) => ThunkAction<Promise<void>, RootState, unknown, FrontOfficeActionTypes>;

export type TUpdateAttendance = (args: {
  ids: string | string[];
  comment?: string;
  statusID?: string;
  trackingMethod?: string;
  reasonCode?: string | null;
  eventDate?: Date;
  contact?: string;
}) => ThunkAction<
  Promise<{ isSuccessful: boolean }>,
  RootState,
  unknown,
  FrontOfficeActionTypes
>;

export type TUpdateEventComment = (args: {
  id: string;
  comment: string;
}) => ThunkAction<Promise<void>, RootState, unknown, FrontOfficeActionTypes>;

export type TSelectDate = (args: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, FrontOfficeActionTypes>;

export type TSetDefaultStatus = (
  status: IAttendanceStatus | null,
) => SetDefaultStatusAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TReset = () => ResetAction;

export type TSetIsPrintingOn = (bool: boolean) => SetIsPrintingOnAction;

export type TDeleteAttendance = (args: {
  attendanceID: string;
}) => ThunkAction<
  Promise<{ isSuccessful: boolean; error?: string }>,
  RootState,
  unknown,
  FrontOfficeActionTypes
>;

export type TSetDefaultTrackingMethod = (
  data: 'check_in' | 'check_out' | 'section',
) => ThunkAction<void, RootState, unknown, FrontOfficeActionTypes>;

export type TSetDefaultReason = (data: string) => SetDefaultReasonAction;

export type TSetIsUpdatingType = (ids?: string[]) => SetIsUpdatingAction;

export type TSetPageSize = (
  size: number,
) => ThunkAction<void, RootState, unknown, FrontOfficeActionTypes>;

export type TToggleRowSelection = (
  recordID: string,
) => ToggleRowSelectionAction;

export type TToggleSelection = () => ToggleSelectionAction;

export type THandleFrontOfficeFilterChange = (args: {
  fetchingType?: TFrontOfficeFetchingTypes;
  status?: string;
}) => ThunkAction<void, RootState, unknown, FrontOfficeActionTypes>;

export type TFrontOfficeFetchingTypes = 'front-office-type' | 'classroom-type';
