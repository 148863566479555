import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { handler } from './types';

import Theme from 'assets/styles/Theme';
import { MoreIcon } from 'components/icons';
import { EditIcon, TrashIcon, ViewIcon } from './components';

import {
  Container,
  IconContainer,
  MoreContainer,
  ToolTipItem,
  ToolTipItems,
  TOOL_TIP_PADDING,
  ToolTipText,
} from './OptionsToolTipStyles';

const deleteText = 'Delete';
const editText = 'Edit';
const viewText = 'View';

interface Props {
  hasWriteAccess?: boolean;
  id: number | string;
  isDisabled?: boolean;
  menuOptions?: { label: string; icon?: JSX.Element; onClick: handler }[];
  onDelete?: handler;
  onEdit?: handler;
  onView?: handler;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any;
}

export const OptionsToolTip: React.FC<Props> = ({
  hasWriteAccess = true,
  id,
  isDisabled = false,
  menuOptions = [],
  onDelete,
  onEdit,
  onView,
  row,
}: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [toolTipWidth, setToolTipWidth] = useState<number>(0);

  const toolTipInner = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    toolTipInner.current &&
      setToolTipWidth(toolTipInner.current.clientWidth || 0);
  }, [toolTipInner.current]);

  const handleAfterHide = () => setIsOpen(false);
  const handleAfterShow = () => setIsOpen(true);
  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const tDeleteText = t(deleteText);
  const tEditText = t(editText);
  const tViewText = t(viewText);

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MoreContainer
        isDisabled={isDisabled}
        data-event="click"
        data-for={'options' + id}
        data-tip
      >
        <MoreIcon isDisabled={isDisabled} isActive={isOpen || isHovering} />
      </MoreContainer>

      <ReactTooltip
        afterHide={handleAfterHide}
        afterShow={handleAfterShow}
        backgroundColor={Theme.primaryWhite}
        className="tooltip"
        clickable={true}
        disable={isDisabled}
        effect="solid"
        globalEventOff="click"
        id={'options' + id}
        offset={{ left: toolTipWidth / 2 + TOOL_TIP_PADDING }}
        place="bottom"
        scrollHide={true}
      >
        <ToolTipItems ref={toolTipInner}>
          {hasWriteAccess ? (
            <>
              {onEdit && (
                <ToolTipItem onClick={(e) => onEdit(row, e)}>
                  <IconContainer>
                    <EditIcon />
                  </IconContainer>
                  <ToolTipText>{tEditText}</ToolTipText>
                </ToolTipItem>
              )}

              {onDelete && (
                <ToolTipItem onClick={(e) => onDelete(row, e)}>
                  <IconContainer>
                    <TrashIcon />
                  </IconContainer>
                  <ToolTipText isDelete={true}>{tDeleteText}</ToolTipText>
                </ToolTipItem>
              )}
            </>
          ) : (
            onView && (
              <ToolTipItem onClick={(e) => onView(row, e)}>
                <IconContainer>
                  <ViewIcon />
                </IconContainer>
                <ToolTipText>{tViewText}</ToolTipText>
              </ToolTipItem>
            )
          )}
          {!!menuOptions.length &&
            menuOptions.map(({ label, icon, onClick }) => (
              <ToolTipItem
                onClick={(e) => onClick(row, e)}
                key={`option-${label}`}
              >
                {icon && <IconContainer>{icon}</IconContainer>}
                <ToolTipText>{t(label)}</ToolTipText>
              </ToolTipItem>
            ))}
        </ToolTipItems>
      </ReactTooltip>
    </Container>
  );
};
