import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { IRace } from 'types/school.types';
import { Adapter, ArrayResponse } from '../../types/api.types';

export default async (): Promise<Adapter<IRace[]>> =>
  API.get<ArrayResponse<IRace>>(`/resources/races`)
    .then(({ data: response }) => {
      const races = response.data.map(({ data }) => {
        return {
          id: data.id,
          name: data.name,
          order: data.order,
        };
      });

      return { success: true, data: races };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
