import React from 'react';
import { Circle, Svg } from './MoreStyles';

interface Props {
  isActive?: boolean;
  isDisabled?: boolean;
  small?: boolean;
}

const MoreIcon: React.FC<Props> = ({
  isActive = false,
  isDisabled = false,
  small = false,
}: Props) => {
  return (
    <Svg
      viewBox="0 0 6 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      small={small}
    >
      <Circle isDisabled={isDisabled} isActive={isActive} cx="3" cy="3" r="3" />
      <Circle
        isDisabled={isDisabled}
        isActive={isActive}
        cx="3"
        cy="25"
        r="3"
      />
      <Circle
        isDisabled={isDisabled}
        isActive={isActive}
        cx="3"
        cy="14"
        r="3"
      />
    </Svg>
  );
};

export default MoreIcon;
