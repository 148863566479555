import styled from 'styled-components';

interface IToolTipText {
  intent?: 'primary' | 'danger' | 'success';
}

export const ButtonContainer = styled.div``;

export const IconContainer = styled.div`
  max-width: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.secondaryFontDark};
  font-size: 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ToolTipItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.slightGrayBackground};
  }

  &:first-of-type {
    border-radius: 10px 10px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 10px 10px;
  }
`;

export const ToolTipItems = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const ToolTipText = styled.label<IToolTipText>`
  font-family: ${({ theme }) => theme.fontNormal};
  font-weight: ${({ theme }) => theme.weightMedium};
  font-size: 13px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ intent, theme }) =>
    intent === 'danger'
      ? theme.colorDanger
      : intent === 'success'
      ? theme.secondaryGreenDark
      : theme.secondaryFontDark};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: fit-content;

  .tooltip {
    padding: 0px;
    background: ${({ theme }) => theme.primaryWhite};
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: ${({ theme }) => theme.z10};

    &.__react_component_tooltip.show {
      opacity: 1 !important;
    }

    /* positioning of the tooltip arrow */
    &::before,
    &::after {
      left: 80% !important;
    }
  }

  .__react_component_tooltip {
    opacity: 1 !important;
  }
`;
