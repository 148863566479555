import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { schoolMapper } from '../../mappers/school.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiSchool } from '../../types/schoolApi.types';
import { ISchool } from 'types/school.types';

interface IGetUserSchoolsArgs {
  userID: string;
}

export default async ({
  userID,
}: IGetUserSchoolsArgs): Promise<Adapter<ISchool[]>> => {
  return API.get<ArrayResponse<IApiSchool>>(`/users/${userID}/schools`)
    .then(({ data: response }) => {
      const schools = response.data.map(({ data }) => schoolMapper(data));

      return { success: true, data: schools };
    })
    .catch((err) => {
      const stringError = parseError(err, "Failed to retrieve user's schools");

      return { error: stringError, success: false };
    });
};
