import React, { useRef } from 'react';
import { Row as RowObject } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';

import { Spinner } from 'components/spinner';
// import NoDataBubble from 'assets/images/NoData.svg';

import { handler } from 'components/options-tootip';
import {
  Data,
  EmptyTable,
  Message,
  // QuestionBubble,
  SubMessage,
  TableRow,
} from './RowStyles';

type RowProps = {
  checkboxCol?: boolean;
  columnsSpans?: string;
  loading?: boolean;
  emptyMessage?: string;
  onClick?: handler;
  optionsMenu?: boolean;
  placeholder?: string;
  row?: RowObject;
  useOnScreen?: (ref: React.RefObject<HTMLElement>) => void;
};

export const Row: React.FC<RowProps> = ({
  checkboxCol,
  columnsSpans,
  loading,
  onClick,
  optionsMenu,
  placeholder,
  row,
  emptyMessage,
  useOnScreen,
}: RowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  useOnScreen && useOnScreen(ref);

  const { t } = useTranslation();

  const tNoDataText = t(emptyMessage || 'No data yet');

  if (loading || !row)
    return (
      <TableRow ref={ref} empty>
        <Data full={true}>
          {loading ? (
            <Spinner size={30} />
          ) : (
            <EmptyTable>
              {/* <QuestionBubble src={NoDataBubble} /> */}
              <Message>{tNoDataText}</Message>
              {placeholder && <SubMessage>{t(placeholder)}</SubMessage>}
            </EmptyTable>
          )}
        </Data>
      </TableRow>
    );

  const isRowHasCellIgnoreEllipsis = row?.cells.find(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (cell) => cell.column.ignoreEllipsis,
  );

  return (
    <TableRow
      {...row.getRowProps()}
      autoHeight={Boolean(isRowHasCellIgnoreEllipsis)}
      clickable={!!onClick}
      columnsSpans={columnsSpans}
      length={row.cells.length}
      onClick={onClick ? () => onClick(row.original) : undefined}
      optionsMenu={optionsMenu}
      checkboxCol={checkboxCol}
    >
      {row.cells.map((cell, index) => (
        <Data
          {...cell.getCellProps()}
          key={`data-${index}`}
          optionsMenu={optionsMenu}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ignoreEllipsis={cell.column.ignoreEllipsis}
        >
          <Trans>{cell.value ? cell.render('Cell') : 'N/A'}</Trans>
        </Data>
      ))}
    </TableRow>
  );
};
