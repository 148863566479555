import { Reducer } from 'redux';

import { ICheckpointLocation } from 'types/checkpoint.types';
import * as Actions from './LocationManagementActions.types';

export interface State {
  isLoading: boolean;
  locations: ICheckpointLocation[];
  next?: string;
  pageSize: number;
  error: string;
}

const initialState: State = {
  isLoading: false,
  locations: [],
  pageSize: 50,
  error: '',
};

const LocationManagementReducer: Reducer<
  State,
  Actions.LocationsManageActionTypes
> = (
  state: State = initialState,
  action: Actions.LocationsManageActionTypes,
) => {
  switch (action.type) {
    case Actions.LOCATIONS_MANAGE__LAUNCH_LOCATIONS: {
      return {
        ...state,
        next: action.next,
        locations: action.locations,
      };
    }

    case Actions.LOCATIONS_MANAGE__GET_NEXT_SUCCESS: {
      return {
        ...state,
        locations: [...state.locations, ...action.data],
        next: action.next,
      };
    }

    case Actions.LOCATIONS_MANAGE__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.LOCATIONS_MANAGE__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.LOCATIONS_MANAGE__ADD_LOCATION: {
      return {
        ...state,
        locations: [action.location, ...state.locations],
      };
    }

    case Actions.LOCATIONS_MANAGE__EDIT_LOCATION: {
      return {
        ...state,
        locations: state.locations.map((location) => {
          if (location.id === action.location.id) {
            return action.location;
          }
          return location;
        }),
      };
    }

    case Actions.LOCATIONS_MANAGE__DELETE_LOCATION: {
      return {
        ...state,
        locations: state.locations.filter(
          (location) => location.id !== action.id,
        ),
      };
    }

    case Actions.LOCATIONS_MANAGE__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default LocationManagementReducer;
