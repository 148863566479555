import { IDateRange } from 'components/date-picker/datePicker.types';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { ISchoolCases } from 'types/reports.types';

// Actions
export const COVID__FETCH_CASES_FAIL = 'COVID__FETCH_CASES_FAIL';
export const COVID__FETCH_CASES_SUCCESS = 'COVID__FETCH_CASES_SUCCESS';
export const COVID__HANDLE_DATE_RANGE_CHANGE =
  'COVID__HANDLE_DATE_RANGE_CHANGE';
export const COVID__SET_IS_LOADING = 'COVID__SET_IS_LOADING';

// Action types
interface FetchCovidCasesFailAction {
  type: typeof COVID__FETCH_CASES_FAIL;
}

interface FetchCovidCasesSuccessAction {
  cases: ISchoolCases[];
  type: typeof COVID__FETCH_CASES_SUCCESS;
}

export interface HandleDateRangeChangeAction {
  endDate: Date;
  startDate: Date;
  type: typeof COVID__HANDLE_DATE_RANGE_CHANGE;
}

interface SetIsLoadingAction {
  isLoading: boolean;
  type: typeof COVID__SET_IS_LOADING;
}

export type CovidCasesReportsActionTypes =
  | FetchCovidCasesFailAction
  | FetchCovidCasesSuccessAction
  | HandleDateRangeChangeAction
  | SetIsLoadingAction;

// Action handlers
export type TFetchCovidCases = (
  range: IDateRange,
) => ThunkAction<void, RootState, unknown, CovidCasesReportsActionTypes>;

export type THandleDateRangeChange = (
  range: IDateRange,
) => CovidCasesReportsActionTypes;

export type TSetIsLoading = (
  isLoading: boolean,
) => CovidCasesReportsActionTypes;
