import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../types/api.types';
import { IDistrict } from 'types/district.types';
import { IApiDistrict } from 'data/types/districtsApi.types';
import { districtMapper } from 'data/mappers/district.mappers';

interface IGetDistrictArgs {
  id: string;
}

export default async ({ id }: IGetDistrictArgs): Promise<Adapter<IDistrict>> =>
  API.get<Response<IApiDistrict>>(`/districts/${id}`)
    .then(({ data: { data } }) => {
      const district = districtMapper(data);

      return { success: true, data: district };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
