import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { HealthCasesViewsEnum, IHealthCase } from 'types/medical.types';
import { IDateRange } from 'components/date-picker/datePicker.types';
import { IPerson } from 'types/user-old.types';

// Actions
export const HEALTH_CASES__ADD_HEALTH_CASE_FAIL =
  'HEALTH_CASES__ADD_HEALTH_CASE_FAIL';
export const HEALTH_CASES__ADD_HEALTH_CASE_SUCCESS =
  'HEALTH_CASES__ADD_HEALTH_CASE_SUCCESS';
export const HEALTH_CASES__CLEAR_SEARCH_TEXT_HEALTH_CASES =
  'HEALTH_CASES__CLEAR_SEARCH_TEXT_HEALTH_CASES';
export const HEALTH_CASES__GENERIC_FAIL = 'HEALTH_CASES__GENERIC_FAIL';
export const HEALTH_CASES__GET_HEALTH_CASE_FAIL =
  'HEALTH_CASES__GET_HEALTH_CASE_FAIL';
export const HEALTH_CASES__GET_HEALTH_CASE_SUCCESS =
  'HEALTH_CASES__GET_HEALTH_CASE_SUCCESS';
export const HEALTH_CASES__GET_PERSONS = 'HEALTH_CASES__GET_PERSONS';
export const HEALTH_CASES__HANDLE_HEALTH_CASES_DATE_RANGE_CHANGE =
  'HEALTH_CASES__HANDLE_HEALTH_CASES_DATE_RANGE_CHANGE';
export const HEALTH_CASES__HANDLE_SEARCH_INPUT_CHANGE_HEALTH_CASES =
  'HEALTH_CASES__HANDLE_SEARCH_INPUT_CHANGE_HEALTH_CASES';
export const HEALTH_CASES__ON_HEALTH_CASE_SEARCH_BAR_CHANGE =
  'HEALTH_CASES__ON_HEALTH_CASE_SEARCH_BAR_CHANGE';
export const HEALTH_CASES__SELECT_SEARCH_RESULT_HEALTH_CASES =
  'HEALTH_CASES__SELECT_SEARCH_RESULT_HEALTH_CASES';
export const HEALTH_CASES__SET_HAS_WRITE_ACCESS =
  'HEALTH_CASES__SET_HAS_WRITE_ACCESS';
export const HEALTH_CASES__SET_HEALTH_CASE_FORM =
  'HEALTH_CASES__SET_HEALTH_CASE_FORM';
export const HEALTH_CASES__SET_HEALTH_CASES_VIEW =
  'HEALTH_CASES__SET_HEALTH_CASES_VIEW';
export const HEALTH_CASES__SET_IS_LOADING = 'HEALTH_CASES__SET_IS_LOADING';
export const HEALTH_CASES__SET_SHOW_SAVE_MODAL_HEALTH_CASE =
  'HEALTH_CASES__SET_SHOW_SAVE_MODAL_HEALTH_CASE';
export const HEALTH_CASES__TOGGLE_IS_TODAY_SELECTED_HEALTH_CASES =
  'HEALTH_CASES__TOGGLE_IS_TODAY_SELECTED_HEALTH_CASES';
export const HEALTH_CASES__TOGGLE_IS_TOTAL_SELECTED_HEALTH_CASES =
  'HEALTH_CASES__TOGGLE_IS_TOTAL_SELECTED_HEALTH_CASES';

// Action types
export interface AddHealthCaseFailAction {
  type: typeof HEALTH_CASES__ADD_HEALTH_CASE_FAIL;
}

export interface AddHealthCaseSuccessAction {
  type: typeof HEALTH_CASES__ADD_HEALTH_CASE_SUCCESS;
}

export interface ClearSearchTextAction {
  type: typeof HEALTH_CASES__CLEAR_SEARCH_TEXT_HEALTH_CASES;
}

export interface GenericFailAction {
  type: typeof HEALTH_CASES__GENERIC_FAIL;
}

export interface GetHealthCaseFailAction {
  type: typeof HEALTH_CASES__GET_HEALTH_CASE_FAIL;
}

export interface GetHealthCaseSuccessAction {
  type: typeof HEALTH_CASES__GET_HEALTH_CASE_SUCCESS;
  healthCases: Partial<IHealthCase>[];
}

export interface GetPersonsAction {
  type: typeof HEALTH_CASES__GET_PERSONS;
  persons: IPerson[];
}

export interface HandleDateRangeChangeAction {
  type: typeof HEALTH_CASES__HANDLE_HEALTH_CASES_DATE_RANGE_CHANGE;
  startDate: Date;
  endDate: Date;
}

export interface HandleSearchInputChangeAction {
  type: typeof HEALTH_CASES__HANDLE_SEARCH_INPUT_CHANGE_HEALTH_CASES;
  e: React.ChangeEvent<HTMLInputElement>;
}

export interface OnHealthCaseSearchBarChangeAction {
  type: typeof HEALTH_CASES__ON_HEALTH_CASE_SEARCH_BAR_CHANGE;
  searchText: string;
}

export interface SelectSearchResultAction {
  type: typeof HEALTH_CASES__SELECT_SEARCH_RESULT_HEALTH_CASES;
  newCurrentPersonId: string | null;
}

export interface SetHasWriteAccessAction {
  hasWriteAccess: boolean;
  type: typeof HEALTH_CASES__SET_HAS_WRITE_ACCESS;
}

export interface SetHealthCaseFormAction {
  type: typeof HEALTH_CASES__SET_HEALTH_CASE_FORM;
  healthCase: IHealthCase;
}

export interface SetHealthCasesViewAction {
  type: typeof HEALTH_CASES__SET_HEALTH_CASES_VIEW;
  view: HealthCasesViewsEnum;
}

export interface SetIsLoading {
  type: typeof HEALTH_CASES__SET_IS_LOADING;
  isLoading: boolean;
}

export interface SetShowSaveModalAction {
  type: typeof HEALTH_CASES__SET_SHOW_SAVE_MODAL_HEALTH_CASE;
  bool: boolean;
}

export interface ToggleIsTodaySelectedAction {
  type: typeof HEALTH_CASES__TOGGLE_IS_TODAY_SELECTED_HEALTH_CASES;
}

export interface ToggleIsTotalSelectedAction {
  type: typeof HEALTH_CASES__TOGGLE_IS_TOTAL_SELECTED_HEALTH_CASES;
}

export type HealthCasesActionTypes =
  | AddHealthCaseFailAction
  | AddHealthCaseSuccessAction
  | ClearSearchTextAction
  | GenericFailAction
  | GetHealthCaseFailAction
  | GetHealthCaseSuccessAction
  | GetPersonsAction
  | HandleDateRangeChangeAction
  | HandleSearchInputChangeAction
  | OnHealthCaseSearchBarChangeAction
  | SelectSearchResultAction
  | SetHasWriteAccessAction
  | SetHealthCaseFormAction
  | SetHealthCasesViewAction
  | SetIsLoading
  | SetShowSaveModalAction
  | ToggleIsTodaySelectedAction
  | ToggleIsTotalSelectedAction;

// Action Handlers

export type TAddHealthCase = () => ThunkAction<
  void,
  RootState,
  unknown,
  HealthCasesActionTypes
>;

export type TClearSearchText = () => ClearSearchTextAction;

export type TGetHealthCases = (args: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, HealthCasesActionTypes>;

export type TGetPersons = () => ThunkAction<
  void,
  RootState,
  unknown,
  HealthCasesActionTypes
>;

export type THandleDateRangeChange = (
  args: IDateRange,
) => ThunkAction<void, RootState, unknown, HealthCasesActionTypes>;

export type THandleSearchInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
) => HandleSearchInputChangeAction;

export type TOnHealthCaseSearchBarChange = (
  searchText: string,
) => OnHealthCaseSearchBarChangeAction;

export type TSave = (args: {
  onSuccess?: (() => void) | undefined;
  healthCase: IHealthCase;
}) => ThunkAction<void, RootState, unknown, HealthCasesActionTypes>;

export type TSelectSearchResult = (
  newCurrentPersonId: string | null,
) => SelectSearchResultAction;

export type TSetHasWriteAccess = (
  hasWriteAccess: boolean,
) => SetHasWriteAccessAction;

export type TSetHealthCaseForm = (
  healthCase: IHealthCase,
) => SetHealthCaseFormAction;

export type TSetHealthCasesView = (
  view: HealthCasesViewsEnum,
) => SetHealthCasesViewAction;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoading;

export type TSetShowSaveModal = (bool: boolean) => SetShowSaveModalAction;

export type TToggleIsTodaySelected = () => ToggleIsTodaySelectedAction;

export type TToggleIsTotalSelected = () => ToggleIsTotalSelectedAction;
