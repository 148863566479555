import { StrictSchema } from 'morphism';
import { sectionPeriodMapper } from '../section.mappers';

import {
  IApiCurrentSchoolData,
  IApiState,
  IApiTransportationType,
} from 'data/types/resourcesApi.types';
import {
  ICurrentSchoolData,
  IState,
  ITransportationType,
} from 'types/resources.types';

export const IStateSchema: StrictSchema<IState, IApiState> = {
  abbr: 'abbr',
  id: 'id',
  name: 'name',
};

export const ITransportationTypeSchema: StrictSchema<
  ITransportationType,
  IApiTransportationType
> = {
  id: 'id',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  name: 'name',
  key: 'key',
  order: 'order',
};

export const ICurrentSchoolDataSchema: StrictSchema<
  ICurrentSchoolData,
  IApiCurrentSchoolData
> = {
  schoolYear: 'school_year',
  date: 'date',
  day: 'day',
  currentSection: (iteratee) => {
    if (!iteratee.current_section) return undefined;

    return {
      id: iteratee.current_section.id,
      name: iteratee.current_section.name,
      type: iteratee.current_section.type,
      period: iteratee.current_section.period
        ? sectionPeriodMapper(iteratee.current_section.period)
        : undefined,
    };
  },
};
