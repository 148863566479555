import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';

// Actions
export const CHECK_IN__DUPLICATE_FACE_SCAN = 'CHECK_IN__DUPLICATE_FACE_SCAN';
export const CHECK_IN__DUPLICATE_QR_SCAN = 'CHECK_IN__DUPLICATE_QR_SCAN';
export const CHECK_IN__FACE_SCAN_FAIL = 'CHECK_IN__FACE_SCAN_FAIL';
export const CHECK_IN__FACE_SCAN_SUCCESS = 'CHECK_IN__FACE_SCAN_SUCCESS';
export const CHECK_IN__QR_SCAN_FAIL = 'CHECK_IN__QR_SCAN_FAIL';
export const CHECK_IN__SET_IS_LOADING = 'CHECK_IN__SET_IS_LOADING';
export const CHECK_IN__SET_NOTIFICATION = 'CHECK_IN__SET_NOTIFICATION';
export const CHECK_IN__SET_SHOULD_SHOW_STATUS =
  'CHECK_IN__SET_SHOULD_SHOW_STATUS';

// Action types
export enum NotificationType {
  error = 'error',
  success = 'success',
}

export interface Person {
  checkInDate: Date;
  id: string;
  name: string;
}

interface DuplicateFaceScanAction {
  type: typeof CHECK_IN__DUPLICATE_FACE_SCAN;
  person: Person;
  name: string;
  checkOut: boolean;
}

interface DuplicateQrScanAction {
  type: typeof CHECK_IN__DUPLICATE_QR_SCAN;
  person: Person;
  name: string;
  checkOut: boolean;
}

interface FaceScanFailAction {
  type: typeof CHECK_IN__FACE_SCAN_FAIL;
}

interface FaceScanSuccessAction {
  type: typeof CHECK_IN__FACE_SCAN_SUCCESS;
  person: Person;
}

interface QrScanFailAction {
  type: typeof CHECK_IN__QR_SCAN_FAIL;
}

interface SetIsLoadingAction {
  type: typeof CHECK_IN__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetNotificationAction {
  type: typeof CHECK_IN__SET_NOTIFICATION;
  notification: {
    text: string;
    type: NotificationType;
  };
}

interface SetShouldShowStatusAction {
  type: typeof CHECK_IN__SET_SHOULD_SHOW_STATUS;
  shouldShowStatus?: boolean;
}

export type AttendanceCheckInActionTypes =
  | DuplicateFaceScanAction
  | DuplicateQrScanAction
  | FaceScanFailAction
  | FaceScanSuccessAction
  | QrScanFailAction
  | SetIsLoadingAction
  | SetNotificationAction
  | SetShouldShowStatusAction;

// Action handlers
export type TDuplicateFaceScan = (
  person: Person,
  name: string,
  checkOut: boolean,
) => AttendanceCheckInActionTypes;

export type TDuplicateQrScan = (
  person: Person,
  name: string,
  checkOut: boolean,
) => AttendanceCheckInActionTypes;

export type TScanFace = () => ThunkAction<
  void,
  RootState,
  unknown,
  AttendanceCheckInActionTypes
>;

export type TFailQR = () => QrScanFailAction;

export type TFailFaceScan = () => FaceScanFailAction;

export type TSetIsLoading = (
  isLoading: boolean,
) => AttendanceCheckInActionTypes;

export type TSetShouldShowStatus = (
  shouldShowStatus: boolean,
) => AttendanceCheckInActionTypes;
