import styled from 'styled-components';

interface ITableHeader {
  canFilter: boolean;
  canSort: boolean;
  optionsMenu?: boolean;
}

export const ArrowContainer = styled.span`
  margin-left: 5px;
`;

export const CheckboxContainer = styled.span`
  padding-right: 20px;
  width: 100%;
`;

export const FilterContainer = styled.span`
  display: flex;
  margin-left: 6px;
`;

export const Container = styled.thead`
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryFontDark}1A`};
  color: ${({ theme }) => theme.secondaryFontDarkTrans};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  font-weight: ${({ theme }) => theme.weightSemibold};
  line-height: ${({ theme }) => theme.lineHeightSmall};
`;

export const HeaderCell = styled.th<ITableHeader>`
  align-items: center;
  color: ${({ theme }) => theme.secondaryFontDarkTrans};
  cursor: ${({ canFilter, canSort }) => (canFilter || canSort) && 'pointer'};
  display: flex;
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  font-weight: ${({ theme }) => theme.weightSemibold};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  padding: 20px 0;
  position: relative;
  text-transform: none;
  user-select: none;
  flex: 1;
  width: 100%;

  ${({ optionsMenu }) =>
    optionsMenu &&
    `&:last-of-type {
      flex: unset;
    }`}

  ${FilterContainer} {
    margin: auto;
  }
`;

export const TextContainer = styled.div``;
