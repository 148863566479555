import styled from 'styled-components';

export const Path = styled.path`
  fill: ${({ theme }) => theme.colorDanger};
`;

export const Svg = styled.svg`
  width: 14px;
  height: 13px;
`;
