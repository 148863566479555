import API from 'data/axios';
import { reverseBusRoutePlanLocationMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBus } from 'data/types/schoolBusApi.types';
import { IBusRoutePlanLocation } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../../../types/api.types';

interface ICreateNewPlanLocationArgs {
  data: Partial<IBusRoutePlanLocation>;
}

export default async ({
  data,
}: ICreateNewPlanLocationArgs): Promise<Adapter> => {
  const newLocation = reverseBusRoutePlanLocationMapper(data);

  return API.post<Response<IApiBus>>(`/bus/route_plans/locations`, newLocation)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
