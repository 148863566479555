import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BookText,
  BookTimeToMeetButton as Button,
} from './BookTimeToMeetButtonStyles';
import { CalendarIcon } from './CalendarIcon';

const bookTimeToMeetText = 'Book Time to Meet';

export const BookTimeToMeetButton = ({
  setCalendlyModalOpen,
}: {
  setCalendlyModalOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  const tBookTimeToMeetText = t(bookTimeToMeetText);
  return (
    <Button
      onClick={() => {
        setCalendlyModalOpen(true);
      }}
    >
      <CalendarIcon />
      <BookText>{tBookTimeToMeetText}</BookText>
    </Button>
  );
};
