import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '..';
import {
  MainActionButton,
  LightActionButton,
  ButtonContainer,
  Inner,
} from './ConfirmDenyModalStyles';
import { Theme } from 'assets/styles';

interface Props {
  cancelLabel?: string;
  closeModal: (e: React.MouseEvent) => void;
  confirmLabel?: string;
  isLoading?: boolean;
  onConfirm: (e: React.MouseEvent) => void;
  onDeny?: (e: React.MouseEvent) => void;
  prompt: string;
  shouldShow: boolean;
  children?: React.ReactNode;
  width?: string;
}

const ConfirmDenyModal: React.FC<Props> = ({
  cancelLabel = 'No',
  closeModal,
  confirmLabel = 'Yes',
  isLoading = false,
  onConfirm,
  onDeny,
  prompt,
  shouldShow,
  children,
  width = '430px',
}: Props) => {
  const { t } = useTranslation();
  const tQuestion = t(prompt);
  const tYes = t(confirmLabel);
  const tNo = t(cancelLabel);

  return (
    <Modal
      handleClose={closeModal}
      isLoading={isLoading}
      shouldShow={shouldShow}
      width={width}
    >
      <Inner>
        <h4 style={{ fontWeight: Theme.fontBold, color: 'black' }}>
          {tQuestion}
        </h4>
        {children}
        <ButtonContainer>
          <MainActionButton onClick={onConfirm}>{tYes}</MainActionButton>
          {onDeny && (
            <LightActionButton onClick={onDeny}>{tNo}</LightActionButton>
          )}
        </ButtonContainer>
      </Inner>
    </Modal>
  );
};

export default ConfirmDenyModal;
