import { Reducer } from 'redux';
import { IStudent } from 'types/student.types';
import * as Actions from './studentsActions.types';
import { IParsedContact } from 'domains/people/students/view-student/components/contact-info/Contacts';

export interface State {
  currentStudent: IStudent | null;
  currentContact: IParsedContact | null;
  isLoading: boolean;
  isShowingResults: boolean;
  next?: string;
  nextSearchLink?: string;
  pageSize: number;
  searchResults: IStudent[];
  searchText: string;
  students: IStudent[];
}

export const initialState: State = {
  currentStudent: null,
  currentContact: null,
  isLoading: false,
  isShowingResults: false,
  pageSize: 20,
  searchResults: [],
  searchText: '',
  students: [],
};

const StudentsReducer: Reducer<State, Actions.StudentsActions> = (
  state: State = initialState,
  action: Actions.StudentsActions,
): State => {
  // TODO: alphabetic order for action cases
  switch (action.type) {
    case Actions.STDNTS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.STDNTS__GET_NEXT_PAGE: {
      return {
        ...state,
        students: [...state.students, ...action.data],
        next: action.next,
      };
    }

    case Actions.STDNTS__GET_NEXT_RESULTS_PAGE: {
      return {
        ...state,
        searchResults: [...state.searchResults, ...action.data],
        nextSearchLink: action.next,
      };
    }

    case Actions.STDNTS__LAUNCH_STUDENT_SUCCESS: {
      return {
        ...state,
        currentStudent: action.data,
      };
    }

    case Actions.STDNTS__LAUNCH_SUCCESS: {
      return {
        ...state,
        isShowingResults: false,
        next: action.next,
        nextSearchLink: undefined,
        pageSize: action.pageSize,
        searchResults: [],
        searchText: '',
        students: action.data,
      };
    }

    case Actions.STDNTS__CLEAR_SEARCH_TEXT: {
      return {
        ...state,
        isShowingResults: false,
        nextSearchLink: undefined,
        searchResults: [],
        searchText: '',
      };
    }

    case Actions.STDNTS__HANDLE_SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        isShowingResults: true,
        nextSearchLink: action.next,
        searchResults: action.data,
        searchText: action.searchText,
      };
    }

    case Actions.STDNTS__CLEAR_CURRENT_STUDENT: {
      return {
        ...state,
        currentStudent: null,
      };
    }

    case Actions.STDNTS__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.STDNTS__SET_CURRENT_CONTACT: {
      return {
        ...state,
        currentContact: action.contact,
      };
    }

    case Actions.STDNTS__UPDATE_STUDENT: {
      return {
        ...state,
        students: state.students.map((student) => {
          if (student.id == action.student.id) {
            return action.student;
          }
          return student;
        }),
      };
    }

    default:
      return state;
  }
};

export default StudentsReducer;
