import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPersonInitials } from 'utils/person.utils';
import {
  Container,
  AvatarCircleContainer,
  AvatarsWrapper,
  Initials,
  Label,
} from './SelectedStudentsAvatarsStyles';

type TAttendanceStudent = {
  firstName: string;
  id: string;
  lastName: string;
};

interface Props {
  selectedAttendances: TAttendanceStudent[];
}

export const SelectedStudentsAvatars: React.FC<Props> = ({
  selectedAttendances,
}: Props) => {
  const { t } = useTranslation();
  const topThreeSelected = [...selectedAttendances].slice(0, 3);

  return (
    <Container>
      <AvatarsWrapper count={topThreeSelected.length}>
        {topThreeSelected.map((student, index) => (
          <AvatarCircleContainer key={`${student.id}-${index}`} order={index}>
            <Initials>{student && getPersonInitials(student)}</Initials>
          </AvatarCircleContainer>
        ))}
      </AvatarsWrapper>
      <Label>{`${selectedAttendances.length} ${t('students selected')}`}</Label>
    </Container>
  );
};
