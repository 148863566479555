import styled from 'styled-components';

interface Props {
  isDisabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const TransparentButton = styled.div<Props>`
  background-color: transparent;
  color: ${({ theme }) => theme.primaryBlueLight};

  cursor: ${({ isDisabled }) => (isDisabled ? `not-allowed` : `pointer`)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 40px;
  z-index: 1;
  user-select: none;
  width: fit-content;

  font-family: ${({ theme }) => theme.fontBold};
  font-size: ${({ theme }) => theme.fontSizeExtraLarge};
  font-weight: ${({ theme }) => theme.weightBold};

  opacity: 0.7;

  ${({ isDisabled }) => isDisabled && `opacity: 0.3;`}
`;

export const ActiveButtonContainer = styled.div<Props>`
  position: relative;
  overflow: visible;
  border-radius: 10px;
  animation: ${({ isDisabled }) =>
    isDisabled ? 'none' : `borderPulse 1500ms infinite ease-in-out`};

  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px
          ${({ theme }) => theme.secondaryGreenDark + '40'},
        0px 0px 0px 0px ${({ theme }) => theme.secondaryGreenDark};
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 0, 0.2),
        0px 0px 0px 10px ${({ theme }) => theme.secondaryGreenDark + '00'};
    }
  }
`;

export const ActiveBadge = styled.div`
  position: absolute;
  top: -7px;
  left: -7px;
  background-color: ${({ theme }) => theme.secondaryGreenDark};
  color: white;
  padding: 5px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
`;
