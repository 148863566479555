import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionsDropdown, BackButton } from 'components/buttons';
import { Spinner } from 'components/spinner';
import { Title } from '..';

import {
  HeaderButton,
  DropdownContainer,
  HeaderContainer,
  TitleContainer,
} from './HeaderStyles';

interface HeaderProps {
  actions?: {
    disabled?: boolean;
    icon?: JSX.Element | undefined;
    intent?: 'danger' | 'success' | 'primary' | undefined;
    isLoading?: boolean;
    label: string;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    style?: {
      backgroundColor?: string;
      width?: string;
      color?: string;
    };
  }[];
  actionsLabel?: string;
  title: string;
  back?: boolean;
  dropDowns?: React.ReactNode[];
}

export const Header: React.FC<HeaderProps> = ({
  back,
  actions,
  actionsLabel = 'Actions',
  title,
  dropDowns,
}: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tTitle = t(title);
  const tActionsLabel = t(actionsLabel);

  return (
    <HeaderContainer>
      <TitleContainer>
        {back && <BackButton onClick={() => navigate(-1)} />}
        <Title>{tTitle}</Title>
      </TitleContainer>
      {actions &&
        !!actions.length &&
        (actions.length === 1 ? (
          <HeaderButton
            isDisabled={actions[0].disabled}
            disabled={actions[0].disabled}
            onClick={actions[0].onClick as () => void}
            custom={actions[0].style}
          >
            {actions[0].icon && actions[0].icon}
            {actions[0].isLoading ? <Spinner size={18} /> : actions[0].label}
          </HeaderButton>
        ) : (
          <ActionsDropdown actions={actions} id="layout-actions">
            {tActionsLabel}
          </ActionsDropdown>
        ))}
      {dropDowns && (
        <DropdownContainer gap={dropDowns.length > 1}>
          {dropDowns.map((dropDown, index) => (
            <div
              key={index}
              style={{
                width: 'fit-content',
                minWidth: '240px',
              }}
            >
              {dropDown}
            </div>
          ))}
        </DropdownContainer>
      )}
    </HeaderContainer>
  );
};
