import { StrictSchema } from 'morphism';

import {
  IApiCheckpointEvent,
  IApiCheckpointLocation,
} from 'data/types/checkpointApi.types';
import { ICheckpointEvent, ICheckpointLocation } from 'types/checkpoint.types';

export const ICheckpointEventSchema: StrictSchema<
  ICheckpointEvent,
  IApiCheckpointEvent
> = {
  comment: ({ comment }) => comment ?? undefined,
  eventDate: (iteratee) => new Date(iteratee.event_date),
  id: 'id',
  locationID: 'location',
  student: {
    firstName: 'student.first_name',
    lastName: 'student.last_name',
    userAccountId: 'student.user_account_id',
    photoUrl: 'student.photo_url',
  },
  type: ({ type }) => type ?? undefined,
};

export const ICheckpointLocationSchema: StrictSchema<
  ICheckpointLocation,
  IApiCheckpointLocation
> = {
  description: 'description',
  id: 'id',
  name: 'name',
  schoolID: 'school',
  type: ({ type }) => type ?? undefined,
};
