import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IDistrict, IOauthDistrict } from 'types/district.types';
import { IUser } from 'types/user.types';

// Actions
export const AUTH__CLEAR_ERROR = 'AUTH__CLEAR_ERROR';
export const AUTH__FAIL = 'AUTH__FAIL';
export const AUTH__FORGOT_PASSWORD_SUCCESS = 'AUTH__FORGOT_PASSWORD_SUCCESS';
export const AUTH__GET_USER_AUTH_SUCCESS = 'AUTH__GET_USER_AUTH_SUCCESS';
export const AUTH__LOGIN_FAIL = ' AUTH__LOGIN_FAIL';
export const AUTH__LOGIN_SUCCESS = 'AUTH__LOGIN_SUCCESS';
export const AUTH__LOGOUT_SUCCESS = 'AUTH__LOGOUT_SUCCESS';
export const AUTH__SET_ACTIVE_SCHOOL = 'AUTH__SET_ACTIVE_SCHOOL';
export const AUTH__SET_DISTRICT_SEARCH_RESULTS =
  'AUTH__SET_DISTRICT_SEARCH_RESULTS';
export const AUTH__SET_IS_LOADING = 'AUTH__SET_IS_LOADING';
export const AUTH__SET_IS_LOADING_FORGOT_PASSWORD =
  'AUTH__SET_IS_LOADING_FORGOT_PASSWORD';
export const AUTH__SET_IS_LOADING_USER = 'AUTH__SET_IS_LOADING_USER';

// Action Types
interface ClearErrorAction {
  type: typeof AUTH__CLEAR_ERROR;
}

interface FailAction {
  error: string;
  type: typeof AUTH__FAIL;
}

interface ForgotPasswordSucessAction {
  type: typeof AUTH__FORGOT_PASSWORD_SUCCESS;
}

interface GetUserAuthSuccessAction {
  user: IUser;
  district: IDistrict;
  activeSchoolID?: string;
  type: typeof AUTH__GET_USER_AUTH_SUCCESS;
}

interface LoginFailAction {
  error: string;
  type: typeof AUTH__LOGIN_FAIL;
}

interface LoginSuccessAction {
  type: typeof AUTH__LOGIN_SUCCESS;
}

interface LogoutSuccessAction {
  type: typeof AUTH__LOGOUT_SUCCESS;
}

interface SetCurrentActiveOrganizationAction {
  activeSchoolID: string;
  type: typeof AUTH__SET_ACTIVE_SCHOOL;
}

interface SetDistrictSearchResultsAction {
  type: typeof AUTH__SET_DISTRICT_SEARCH_RESULTS;
  districts: IOauthDistrict[];
}

interface SetIsLoadingAction {
  type: typeof AUTH__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetIsLoadingForgotPasswordAction {
  type: typeof AUTH__SET_IS_LOADING_FORGOT_PASSWORD;
  isLoadingForgotPassword: boolean;
}

interface SetIsLoadingUserAction {
  type: typeof AUTH__SET_IS_LOADING_USER;
  isLoadingUser: boolean;
}

export type AuthActionTypes =
  | ClearErrorAction
  | FailAction
  | ForgotPasswordSucessAction
  | GetUserAuthSuccessAction
  | LoginFailAction
  | LoginSuccessAction
  | LogoutSuccessAction
  | SetCurrentActiveOrganizationAction
  | SetDistrictSearchResultsAction
  | SetIsLoadingAction
  | SetIsLoadingForgotPasswordAction
  | SetIsLoadingUserAction;

// Action Handlers
export type TClearError = () => AuthActionTypes;

export type TForgotPassword = (args: {
  email: string;
}) => ThunkAction<
  Promise<{
    success: boolean;
  }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TGetDistrictSearchResults = (args: {
  provider: string;
  searchKeyword: string;
}) => ThunkAction<Promise<void>, RootState, unknown, AuthActionTypes>;

export type TGetUserAuth = () => ThunkAction<
  Promise<{ success: boolean; error?: string }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TLogin = (args: {
  email: string;
  password: string;
}) => ThunkAction<void, RootState, unknown, AuthActionTypes>;

export type TLogout = () => ThunkAction<
  void,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TOAuthLogin = (args: {
  code: string;
  provider: string;
  district: string;
}) => ThunkAction<void, RootState, unknown, AuthActionTypes>;

export type TResetPassword = (args: {
  oobCode: string;
  newPassword: string;
}) => ThunkAction<
  Promise<{
    success: boolean;
  }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TSetActiveOrganization = (args: {
  schoolID: string;
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TSetIsLoading = (isLoading: boolean) => AuthActionTypes;

export type TSetIsLoadingForgotPassword = (
  isLoadingForgotPassword: boolean,
) => AuthActionTypes;

export type TSetIsLoadingUser = (isLoadingUser: boolean) => AuthActionTypes;
