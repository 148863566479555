import styled from 'styled-components';

export const Container = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.secondaryGrayDark};
`;

export const Button = styled.button<{ disabled?: boolean; next?: boolean }>`
  border: 1px solid ${({ theme }) => theme.tableRowBorderGrayLight};
  padding: 3px 10px;
  border-radius: ${({ next }) =>
    next ? '0px 5px 5px 0px' : '5px 0px 0px 5px'};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.secondaryGrayLight : 'transparent'};
  opacity: 0.7;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const PageNumber = styled.strong``;

export const RowContainer = styled.td`
  justify-self: flex-end;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
`;

export const RowWrapper = styled.tr``;

export const SelectorContainer = styled.div`
  width: 100px;
`;
