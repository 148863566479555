import API from 'data/axios';
import { reverseBusRoutePlanLocationMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBus } from 'data/types/schoolBusApi.types';
import { IBusRoutePlanLocation } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../../../types/api.types';

interface IEditPlanLocationArgs {
  data: Partial<IBusRoutePlanLocation>;
}

export default async ({ data }: IEditPlanLocationArgs): Promise<Adapter> => {
  const newLocation = reverseBusRoutePlanLocationMapper(data);
  if (!newLocation.id) throw 'Invalid location data';

  return API.patch<Response<IApiBus>>(
    `/bus/route_plans/locations/${newLocation.id}`,
    newLocation,
  )
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
