import React from 'react';
import { useTranslation } from 'react-i18next';
import { AfterSchoolAttendance } from './components/after-school-attendance/AfterSchoolAttendance';
import { InSchoolAttendance } from './components/in-school-attendance/InSchoolAttendance';
// import { TIA } from './components/tia/TIA';

import { Container, SecondTitle, Title } from './HelpStyles';
import { Section3 } from './components/section3/Section3';

const letUsText = 'Let us';
const helpYouText = 'help you';

export const HelpAcademics = ({
  setCalendlyModalOpen,
}: {
  setCalendlyModalOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>
        {t(letUsText)}
        &nbsp;
        <SecondTitle>{t(helpYouText)}</SecondTitle>
      </Title>
      <InSchoolAttendance setCalendlyModalOpen={setCalendlyModalOpen} />
      <AfterSchoolAttendance setCalendlyModalOpen={setCalendlyModalOpen} />
      <Section3 setCalendlyModalOpen={setCalendlyModalOpen} />
      {/* <TIA setCalendlyModalOpen={setCalendlyModalOpen} /> */}
    </Container>
  );
};
