export const AUDIT__SET_CURRENT_TYPE = 'AUDIT__SET_CURRENT_TYPE';
export const AUDIT__SET_CURSOR = 'AUDIT__SET_CURSOR';
export const AUDIT__SET_DATE_RANGE = 'AUDIT__SET_DATE_RANGE';

// Actions
export interface SetCurrentTypeAction {
  type: typeof AUDIT__SET_CURRENT_TYPE;
  data?: string;
}

export interface SetCursorAction {
  type: typeof AUDIT__SET_CURSOR;
  cursor?: string;
}

export interface SetDateRangeAction {
  type: typeof AUDIT__SET_DATE_RANGE;
  startDate: Date;
  endDate: Date;
}

// Action types
export type AuditLogActionTypes =
  | SetCurrentTypeAction
  | SetCursorAction
  | SetDateRangeAction;

// Action handlers
export type TSetCurrentType = (type?: string) => SetCurrentTypeAction;

export type TSetCursor = (cursor?: string) => SetCursorAction;

export type TSetDateRange = (args: {
  startDate: Date;
  endDate: Date;
}) => SetDateRangeAction;
