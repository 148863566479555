import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'state/hooks';

import { Spinner } from 'components/spinner';
import getStudentContacts from 'data/adapters/users/getStudentContacts';
import { IUser } from 'types/user.types';
import Table from 'components/table';
import getColumns from './columns';

const ContactTable: React.FC = () => {
  const [contacts, setContacts] = useState<IUser[]>([]);
  const [contactsLoading, setContactsLoading] = useState(true);
  const { currentStudent, isLoading } = useAppSelector(
    ({ students }) => students,
  );
  const columns = useMemo(() => getColumns(currentStudent), [
    currentStudent,
    contacts,
  ]);

  const getStudentDetails = async () => {
    const { data: contactsData } = await getStudentContacts({
      studentID: currentStudent?.id ?? '',
    });
    const parsedContacts =
      contactsData?.filter((contact) => {
        const matchedStudent = contact.roles.contact?.students?.find(
          (student) => student.id === currentStudent?.id,
        );
        if (matchedStudent?.is_check_in_out) return true;
      }) ?? [];
    parsedContacts.sort((a, b) => {
      const sequenceA =
        a.roles.contact?.students?.find(
          (student) => student.id === currentStudent?.id,
        )?.sequence ?? 0;
      const sequenceB =
        b.roles.contact?.students?.find(
          (student) => student.id === currentStudent?.id,
        )?.sequence ?? 0;
      return sequenceA - sequenceB;
    });
    setContacts(parsedContacts ?? []);
  };

  useEffect(() => {
    getStudentDetails();
    setContactsLoading(false);
  }, []);

  if (isLoading || contactsLoading) {
    return <Spinner></Spinner>;
  }

  if (!currentStudent) {
    return <p>failed to load details!</p>;
  }

  return <Table columns={columns} data={contacts} isLoading={isLoading} />;
};

export default ContactTable;
