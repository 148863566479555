import { morphism, StrictSchema } from 'morphism';

import {
  IApiCheckpointEvent,
  IApiCheckpointLocation,
} from 'data/types/checkpointApi.types';
import {
  ICheckpointEventSchema,
  ICheckpointLocationSchema,
} from './schemas/checkpointApi.schemas';
import { ICheckpointEvent, ICheckpointLocation } from 'types/checkpoint.types';

export const CheckpointEventMapper = (event: IApiCheckpointEvent) =>
  morphism<StrictSchema<ICheckpointEvent, IApiCheckpointEvent>>(
    ICheckpointEventSchema,
    event,
  );

export const locationMapper = (location: IApiCheckpointLocation) =>
  morphism<StrictSchema<ICheckpointLocation, IApiCheckpointLocation>>(
    ICheckpointLocationSchema,
    location,
  );
