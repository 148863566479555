import styled from 'styled-components';

export const RootContainer = styled.div`
  max-width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    margin-left: ${({ theme }) => theme.homepageLeftMarginTablet};
    margin-right: ${({ theme }) => theme.homepageLeftMarginTablet};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media only screen and (max-width: 1130px) {
    width: auto;
    gap: 16px;
  }
`;

export const Title = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.35px;
  color: #07081f;
  /* margin-bottom: 43px; */

  @media only screen and (max-width: 1100px) {
    font-size: 18px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;
