import useDimensions from 'hooks/useDimensions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'routes';
import { Link, useNavigate } from 'react-router-dom';
import { DownloadButton } from './components/download-btn/DownloadButton';

import {
  BottomNavText,
  Container,
  DownloadContainer,
  InnerContainer,
  LeftContainer,
  RightContainer,
  RightsReservedText,
  TextContainer,
} from './FooterStyles';

import { FooterButton } from '../footer/FooterStyles';

import { AppStoreIcon, Logo, PlayStoreIcon } from './icons';

// const tiaText = 'TIA.AI';
const partnersText = 'Partners';
const privacyText = 'Privacy Policy';
const termsOfService = 'Terms Of Service';
// const testimonialsText = 'Testimonials';

const rightsReservedText = '2023. All rights reserved';

export const Footer = () => {
  const { t } = useTranslation();

  // const tTiaText = t(tiaText);
  const tPartnersText = t(partnersText);
  const tPrivacyText = t(privacyText);
  const tTermsOfService = t(termsOfService);
  // const tTestimonialsText = t(testimonialsText);
  const navigate = useNavigate();

  const tRightsReservedText = t(rightsReservedText);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  };

  const { ref, dimensions } = useDimensions();

  const isXS = useMemo(() => {
    return dimensions.width ? dimensions.width <= 324 : false;
  }, [dimensions]);

  return (
    <Container ref={ref}>
      <InnerContainer>
        <LeftContainer>
          <Logo />
          <TextContainer>
            <BottomNavText
              onClick={() => {
                scrollTo('partners');
              }}
            >
              {tPartnersText}
            </BottomNavText>
            <BottomNavText
              onClick={() => {
                if (location.pathname == '/') {
                  scrollTo('in-school');
                } else {
                  navigate('/');
                }
              }}
            >
              Attendance
            </BottomNavText>
            <BottomNavText
              onClick={() => {
                navigate(PATHS.academics);
              }}
            >
              Academics
            </BottomNavText>
            {/* <BottomNavText
              onClick={() => {
                scrollTo('tia');
              }}
            >
              {tTiaText}
            </BottomNavText> */}

            {/* <BottomNavText
              onClick={() => {
                scrollTo('testimonials');
              }}
            >
              {tTestimonialsText}
            </BottomNavText> */}
          </TextContainer>
        </LeftContainer>
        <RightContainer>
          <DownloadContainer>
            <DownloadButton
              link="https://play.google.com/store/apps/details?id=com.safermgmt.district"
              title="get it on"
              text="Google Play"
              icon={
                <PlayStoreIcon
                  width={isXS ? '22' : undefined}
                  height={isXS ? '22' : undefined}
                />
              }
            />
            <DownloadButton
              link="https://apps.apple.com/us/app/schoolday-ai/id1625011643"
              title="download on the"
              text="App Store"
              icon={
                <AppStoreIcon
                  width={isXS ? '24' : undefined}
                  height={isXS ? '25' : undefined}
                />
              }
            />
          </DownloadContainer>

          <RightsReservedText>{tRightsReservedText}</RightsReservedText>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Link
              to={PATHS.privacyPolicy}
              style={{
                textDecoration: 'none',
                paddingRight: '30px',
                fontSize: '12px',
              }}
            >
              <FooterButton>{tPrivacyText}</FooterButton>
            </Link>
            <Link
              to={PATHS.termsOfService}
              style={{ textDecoration: 'none', fontSize: '12px' }}
            >
              <FooterButton>{tTermsOfService}</FooterButton>
            </Link>
          </div>
        </RightContainer>
      </InnerContainer>
    </Container>
  );
};
