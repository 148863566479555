import React, { Suspense, useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { LoadScriptProps, useJsApiLoader } from '@react-google-maps/api';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { getUserAuth } from 'state/auth/AuthActions';

import { AppRoutes } from 'routes';
import { GOOGLE_API_KEY } from 'data/google';
import LoadingPage from 'domains/pages/loading-page/LoadingPage';

import { GlobalStyles, Theme } from './assets/styles';
import { Inner, Page } from './AppStyles';

import './i18n';
import { ScanningProvider } from 'contexts/ScanningContext';

const googleAPILibraries: LoadScriptProps['libraries'] = ['places'];

export const AppContext = React.createContext<{
  googleLoader: {
    isLoaded: boolean;
    loadError?: Error;
  };
}>({ googleLoader: { isLoaded: false } });

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDispatched = useRef(false);
  const ENV = process.env.NODE_ENV;

  const isLoadingUser = useAppSelector(({ auth }) => auth.isLoadingUser);
  const queryClient = new QueryClient();
  const googleLoader = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: googleAPILibraries,
  });

  useEffect(() => {
    if (!isDispatched.current) {
      isDispatched.current = true;
      dispatch(getUserAuth());
    }
  }, [history]);

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={Theme}>
        <GlobalStyles />

        <AppContext.Provider value={{ googleLoader }}>
          <ScanningProvider>
            <QueryClientProvider client={queryClient}>
              <Page>
                <LoadingPage isVisible={isLoadingUser} />

                <Inner isVisible={!isLoadingUser}>{<AppRoutes />}</Inner>
              </Page>

              {ENV === 'development' && <ReactQueryDevtools />}
            </QueryClientProvider>
          </ScanningProvider>
        </AppContext.Provider>
        <Toaster />
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
