import { Reducer } from 'redux';
import { endOfDay, sub, startOfDay } from 'date-fns';
import { getPersonText } from 'utils/person.utils';
import { HealthCasesViewsEnum, IHealthCase } from 'types/medical.types';
import { IPerson, IPersonalDetail } from 'types/user-old.types';
import * as Actions from './HealthCasesActions.types';

interface State {
  currentPersonId: string | null;
  endDate: Date;
  hasBeenChanged: boolean;
  hasWriteAccess: boolean;
  healthCase: Partial<IHealthCase & IPersonalDetail>;
  healthCases: Partial<IHealthCase>[];
  healthCaseUnderEditId: string | null;
  isLoading: boolean;
  isSaved: boolean;
  isShowingResults: boolean;
  isTodaySelected: boolean;
  isTotalSelected: boolean;
  persons: IPerson[];
  resultHasBeenSelected: boolean;
  searchText: string;
  showSaveErrorModal: boolean;
  showSaveModal: boolean;
  startDate: Date;
  view: HealthCasesViewsEnum;
}

const initialState = {
  currentPersonId: null,
  endDate: endOfDay(new Date()),
  hasBeenChanged: false,
  hasWriteAccess: false,
  healthCase: {},
  healthCases: [],
  healthCaseUnderEditId: null,
  isLoading: false,
  isSaved: true,
  isShowingResults: false,
  isTodaySelected: false,
  isTotalSelected: true,
  persons: [],
  resultHasBeenSelected: false,
  searchText: '',
  showSaveErrorModal: false,
  showSaveModal: false,
  startDate: startOfDay(sub(new Date(), { days: 7 })),
  view: HealthCasesViewsEnum.students,
};

const HealthCasesReducer: Reducer<State, Actions.HealthCasesActionTypes> = (
  state: State = initialState,
  action: Actions.HealthCasesActionTypes,
): State => {
  switch (action.type) {
    case Actions.HEALTH_CASES__ADD_HEALTH_CASE_SUCCESS:
      return {
        ...state,
        isSaved: true,
        hasBeenChanged: false,
      };

    case Actions.HEALTH_CASES__CLEAR_SEARCH_TEXT_HEALTH_CASES: {
      return {
        ...state,
        searchText: '',
        isShowingResults: false,
      };
    }

    case Actions.HEALTH_CASES__GENERIC_FAIL: {
      return state;
    }

    case Actions.HEALTH_CASES__GET_HEALTH_CASE_SUCCESS:
      return {
        ...state,
        healthCases: action.healthCases,
      };

    case Actions.HEALTH_CASES__GET_PERSONS:
      return {
        ...state,
        persons: action.persons,
      };

    case Actions.HEALTH_CASES__HANDLE_HEALTH_CASES_DATE_RANGE_CHANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    case Actions.HEALTH_CASES__HANDLE_SEARCH_INPUT_CHANGE_HEALTH_CASES: {
      return {
        ...state,
        searchText: action.e.target.value,
        isShowingResults: !!action.e.target.value,
        resultHasBeenSelected: false,
      };
    }

    case Actions.HEALTH_CASES__ON_HEALTH_CASE_SEARCH_BAR_CHANGE: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }

    case Actions.HEALTH_CASES__SELECT_SEARCH_RESULT_HEALTH_CASES: {
      const { persons } = state;
      const currentPerson = persons.find(
        (person) => person.id === action.newCurrentPersonId,
      );

      const currentPersonString = currentPerson
        ? getPersonText(currentPerson)
        : '';

      return {
        ...state,
        currentPersonId: action.newCurrentPersonId,
        isShowingResults: false,
        resultHasBeenSelected: true,
        searchText: currentPersonString,
      };
    }

    case Actions.HEALTH_CASES__SET_HAS_WRITE_ACCESS: {
      return {
        ...state,
        hasWriteAccess: action.hasWriteAccess,
      };
    }

    case Actions.HEALTH_CASES__SET_HEALTH_CASE_FORM: {
      return {
        ...state,
        isSaved: false,
        hasBeenChanged: true,
        healthCase: action.healthCase,
      };
    }

    case Actions.HEALTH_CASES__SET_HEALTH_CASES_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }

    case Actions.HEALTH_CASES__SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case Actions.HEALTH_CASES__SET_SHOW_SAVE_MODAL_HEALTH_CASE:
      return {
        ...state,
        showSaveModal: action.bool,
      };

    case Actions.HEALTH_CASES__TOGGLE_IS_TODAY_SELECTED_HEALTH_CASES: {
      return {
        ...state,
        isTodaySelected: true,
        isTotalSelected: false,
      };
    }

    case Actions.HEALTH_CASES__TOGGLE_IS_TOTAL_SELECTED_HEALTH_CASES: {
      return {
        ...state,
        isTotalSelected: true,
        isTodaySelected: false,
      };
    }

    default:
      return state;
  }
};

export default HealthCasesReducer;
