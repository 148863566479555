import { parseError } from 'utils/errors.utils';

import getAttendanceEventTypes from 'data/adapters/attendance/getAttendanceEventTypes';
import getAttendanceStatuses from 'data/adapters/attendance/getAttendanceStatuses';
import getCurrentSchoolData from 'data/adapters/resources/getCurrentSchoolData';
import getGrades from 'data/adapters/resources/getGrades';
import getRouteLabelsAdapter from 'data/adapters/school-bus/getRouteLabels';
import getSchoolCalendarDates from 'data/adapters/school/getSchoolCalendarDates';
import getStatesAdapter from 'data/adapters/resources/getStates';

import {
  getBusMessageDeliveryMethods,
  getBusMessageTypes,
} from 'data/adapters/school-bus/messages';
import getTransportationTypes from 'data/adapters/resources/getTransportationTypes';

import * as Actions from './ResourcesActions.types';
import getAttendanceReasons from 'data/adapters/attendance/getAttendanceReasons';
import getRaces from 'data/adapters/resources/getRaces';
import getGenders from 'data/adapters/resources/getGenders';

export const getAttendanceResources: Actions.TGetAttendanceResourcesAction = (
  args,
) => async (dispatch) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const results = await Promise.allSettled([
      dispatch(fetchCurrentSchool()),
      dispatch(fetchEventTypes()),
      dispatch(fetchReasons()),
      dispatch(fetchGrades()),
      dispatch(fetchRaces()),
      dispatch(fetchGenders()),
      dispatch(fetchTransportationTypes()),
      dispatch(fetchStatuses(args)),
    ]);

    const aggregatedResult: Partial<Actions.TGetAttendanceResourcesAction> = results.reduce(
      (acc, result, index) => {
        if (result.status === 'fulfilled') {
          return { ...acc, ...result.value };
        }
        return acc;
      },
      {},
    );

    const hasErrors = results.some((result) => result.status === 'rejected');

    if (hasErrors) {
      dispatch({
        type: Actions.RSRCS__ERROR,
        error: 'Some resources failed to load.',
      });
      return { ...aggregatedResult, isSuccessful: false };
    }

    return { ...aggregatedResult, isSuccessful: true };
  } catch (error) {
    const stringError = parseError(error);
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
    return { isSuccessful: false };
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const fetchCurrentSchool = () => async (dispatch: any) => {
  try {
    const currentSchool = await getCurrentSchoolData();
    const { data: currentSchoolData, success } = currentSchool;
    if (success && currentSchoolData) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        currentSchoolData,
      });
      return { currentSchoolData };
    }
    throw new Error('Failed to load current school data');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load current school data',
    });
    throw error;
  }
};

export const fetchEventTypes = () => async (dispatch: any) => {
  try {
    const eventTypes = await getAttendanceEventTypes();
    const { data: attendancesEventTypes = [], success } = eventTypes;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        eventTypes: attendancesEventTypes,
      });
      return { eventTypes: attendancesEventTypes };
    }
    throw new Error('Failed to load event types');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load event types',
    });
    throw error;
  }
};

export const fetchReasons = () => async (dispatch: any) => {
  try {
    const reasons = await getAttendanceReasons();
    const { data: attendancesReasons = [], success } = reasons;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        reasons: attendancesReasons,
      });
      return { reasons: attendancesReasons };
    }
    throw new Error('Failed to load reasons');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load reasons',
    });
    throw error;
  }
};

export const fetchGrades = () => async (dispatch: any) => {
  try {
    const grades = await getGrades();
    const { data: gradesData = [], success } = grades;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        grades: gradesData,
      });
      return { grades: gradesData };
    }
    throw new Error('Failed to load grades');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load grades',
    });
    throw error;
  }
};

export const fetchRaces = () => async (dispatch: any) => {
  try {
    const races = await getRaces();
    const { data: racesData = [], success } = races;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        races: racesData,
      });
      return { races: racesData };
    }
    throw new Error('Failed to load races');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load races',
    });
    throw error;
  }
};

export const fetchGenders = () => async (dispatch: any) => {
  try {
    const genders = await getGenders();
    const { data: gendersData = [], success } = genders;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        genders: gendersData,
      });
      return { genders: gendersData };
    }
    throw new Error('Failed to load genders');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load genders',
    });
    throw error;
  }
};

export const fetchTransportationTypes = () => async (dispatch: any) => {
  try {
    const transportationTypes = await getTransportationTypes();
    const {
      data: attendanceTransportationTypes = [],
      success,
    } = transportationTypes;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        transportationTypes: attendanceTransportationTypes,
      });
      return { transportationTypes: attendanceTransportationTypes };
    }
    throw new Error('Failed to load transportation types');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load transportation types',
    });
    throw error;
  }
};

export const fetchStatuses = (args: any) => async (dispatch: any) => {
  try {
    const statuses = await getAttendanceStatuses({
      schoolID: args?.activeSchoolID,
      type: args?.type,
      all: args?.allStatuses || false,
    });
    const { data: attendancesStatuses = [], success } = statuses;
    if (success) {
      dispatch({
        type: Actions.RSRCS__GET_ATTENDANCE_RESOURCES,
        statuses: attendancesStatuses,
      });
      return { statuses: attendancesStatuses };
    }
    throw new Error('Failed to load statuses');
  } catch (error) {
    dispatch({
      type: Actions.RSRCS__ERROR,
      error: 'Failed to load statuses',
    });
    throw error;
  }
};

export const getCurrentSchoolDetails: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data, success, error } = await getCurrentSchoolData();

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_CURR_SCHOOL_DATA,
      currentSchoolData: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getBusMessagesResources: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const {
      data: messageTypes = [],
      success: typesSuccess,
      error: typesError,
    } = await getBusMessageTypes();

    if (!typesSuccess) {
      throw typesError;
    }

    const {
      data: deliveryMethods = [],
      success: deliveryMethodsSuccess,
      error: deliveryMethodsError,
    } = await getBusMessageDeliveryMethods();

    if (!deliveryMethodsSuccess) {
      throw deliveryMethodsError;
    }

    dispatch({
      type: Actions.RSRCS__GET_BUS_MESSAGES_RSRCS,
      busMessageTypes: messageTypes,
      busMessageDeliveryMethods: deliveryMethods,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getCalendarDates: Actions.TGetCalendarDates = () => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const state = getState();
    const activeSchoolID = state.auth.activeSchool?.id;

    if (!activeSchoolID) {
      throw 'Could not retrieve school data';
    }

    const { data = [], success, error } = await getSchoolCalendarDates({
      id: activeSchoolID,
    });

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_CALEDAR_DATES,
      calendarDates: data,
    });

    return { data, success, error };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });

    return { success: false, error: stringError };
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getRouteLabels: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data = [], success, error } = await getRouteLabelsAdapter();

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_ROUTE_LABELS,
      labels: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const getStates: Actions.TResourcesThunkAction = () => async (
  dispatch,
) => {
  try {
    dispatch(SetIsResourcesLoading(true));

    const { data = [], success, error } = await getStatesAdapter();

    if (!success) {
      throw error;
    }

    dispatch({
      type: Actions.RSRCS__GET_STATES,
      states: data,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.RSRCS__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(SetIsResourcesLoading(false));
  }
};

export const SetIsResourcesLoading: Actions.TSetIsAttendanceLoading = (
  bool,
) => ({
  type: Actions.RSRCS__SET_IS_LOADING,
  bool,
});
