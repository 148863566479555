import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { removeAuthToken } from 'data/utils/authToken.utils';

import { Adapter } from '../../types/api.types';

const logoutSuccess = () => {
  removeAuthToken();
  window.localStorage.clear();
  return { success: true };
};

export default async (): Promise<Adapter> =>
  API.get('/logout')
    .then(logoutSuccess)
    .catch((err) => {
      if (
        err.response?.status === 401 ||
        err.response?.status === 403 ||
        err.message.includes('status code 401') ||
        err.message.includes('status code 403')
      ) {
        return logoutSuccess();
      }

      const errorMessage = parseError(
        err,
        'Something went wrong with logging out of your account',
      );

      return { error: errorMessage, success: false };
    });
