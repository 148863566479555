import API from 'data/axios';
import { busRouteLabelMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBusRouteLabel } from 'data/types/schoolBusApi.types';
import { IBusRouteLabel } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';

export default async (): Promise<Adapter<IBusRouteLabel[]>> =>
  API.get<ArrayResponse<IApiBusRouteLabel>>(`/bus/route_labels`)
    .then(({ data: response }) => {
      const label = response.data.map(({ data }) => busRouteLabelMapper(data));

      return { success: true, data: label };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
