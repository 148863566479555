import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { AttendanceMapper } from 'data/mappers/attendace.mappers';

import { Adapter, Response } from '../../types/api.types';
import { IApiAttendance } from 'data/types/attendanceApi.types';
import { IAttendance } from 'types/attendance.types';

interface IArgs {
  id: string;
}

export default async ({ id }: IArgs): Promise<Adapter<IAttendance>> =>
  API.get<Response<IApiAttendance>>(`/attendances/${id}?expansions=student`)
    .then(({ data: { data } }) => {
      const attendance = AttendanceMapper(data);

      return { success: true, data: attendance };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
