import { Reducer } from 'redux';
import { IMedicalAlert } from 'types/medical.types';
import { TQuestion } from 'types/surveys.types';
import * as Actions from './MedicalAlertsActions.types';

export interface State {
  error: string;
  alerts: {
    alertData: IMedicalAlert[];
    surveyQuestions: TQuestion[];
  };
  isLoading: boolean;
}

export const initialState = {
  error: '',
  alerts: {
    alertData: [],
    surveyQuestions: [],
  },
  isLoading: true,
};

const medicalAlertsReducer: Reducer<State, Actions.MedicalAlertsActionTypes> = (
  state: State = initialState,
  action: Actions.MedicalAlertsActionTypes,
) => {
  switch (action.type) {
    case Actions.MED_ALERTS__GET_ALERTS_FAIL: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }

    case Actions.MED_ALERTS__GET_ALERTS_SUCCESS: {
      return {
        ...state,
        alerts: action.data,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default medicalAlertsReducer;
