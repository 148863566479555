import DropdownStyles from 'components/select-dropdown/SelectDropdownStyles';
import { StylesConfig, OptionTypeBase } from 'react-select';
import styled from 'styled-components';

interface IBorder {
  error?: string;
}

interface IContainer {
  fillWidth?: boolean;
}

export const Border = styled.div<IBorder>`
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colorDanger : 'transparent')};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  max-width: ${({ fillWidth }) => (fillWidth ? '100%' : '320px')};
`;

const globalDropDownStyles = DropdownStyles(false);

const pageSizeDropdownStyles: StylesConfig<OptionTypeBase, boolean> = {
  ...globalDropDownStyles,

  container: (provided, props) => ({
    ...(globalDropDownStyles.container &&
      globalDropDownStyles.container(provided, props)),
    maxWidth: '100px',
  }),

  control: (provided, props) => ({
    ...(globalDropDownStyles.control &&
      globalDropDownStyles.control(provided, props)),
    minHeight: 0,
    padding: '0px 0px 0px 10px',
  }),
};

export default pageSizeDropdownStyles;
