import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { sectionMapper } from '../../mappers/section.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiSection } from '../../types/sectionsApi.types';
import { ISection } from 'types/section.types';

interface IGetUserSectionsArgs {
  userID: string;
  schoolYear: string;
  date: string;
}

export default async ({
  userID,
  schoolYear,
}: IGetUserSectionsArgs): Promise<Adapter<ISection[]>> => {
  return API.get<ArrayResponse<IApiSection>>(
    `/users/${userID}/sections?type=event&school_year=${schoolYear}&expansions=teacher`,
  )

    .then(({ data: response }) => {
      const sections = response.data.map(({ data }) => sectionMapper(data));
      return { success: true, data: sections };
    })
    .catch((err) => {
      const stringError = parseError(err, "Failed to retrieve user's sections");

      return { error: stringError, success: false };
    });
};
