import styled from 'styled-components';

export const ActionContainer = styled.div`
  flex-direction: row;
  align-self: center;
`;

export const InputContainer = styled.div`
  display: flex;
  min-width: 600px;
  gap: 10px;
`;

export const CardTitle = styled.h3`
  text-align: left;
  color: ${({ theme }) => theme.primaryBlueDark};
  font-family: ${({ theme }) => theme.fontBold};
  line-height: ${({ theme }) => theme.lineHeightSmall};
`;

export const CardSubtitle = styled.h5`
  color: ${({ theme }) => theme.primaryBlueDark};
  font-family: ${({ theme }) => theme.fontBold};
  line-height: ${({ theme }) => theme.lineHeightSmall};
`;

export const AlertContainer = styled.div`
  background-color: ${({ theme }) => theme.colorDanger};
  border-radius: 10px;
  padding: 10px;
`;

export const AlertText = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.fontBold};
  font-size: 16px;
`;

export const ParentName = styled.p`
  font-family: ${({ theme }) => theme.fontBold};
  font-size: 16px;
`;

export const Avatar = styled.img`
  width: ${({ theme }) => theme.profileAvatarSize};
  height: ${({ theme }) => theme.profileAvatarSize};
  border-radius: ${({ theme }) => theme.borderRadiusHalf};
  border: 3px solid ${({ theme }) => theme.primaryWhite};
  filter: drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.1));

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    width: 110px;
    height: 110px;
  }

  object-fit: cover;
`;

export const Badge = styled.h5<{ type: string }>`
  background-color: ${({ theme, type }) =>
    type === 'check_out' ? theme.primaryBlueLight : theme.secondaryGreenDark};
  font-family: ${({ theme }) => theme.fontNormal};
  padding: 5px;
  text-align: center;
  color: white;
  border-radius: 12px;
`;
