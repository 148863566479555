import React from 'react';

export const CalendarIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27_617)">
        <path
          d="M19.8333 3.50001H24.5C24.8094 3.50001 25.1062 3.62292 25.325 3.84171C25.5437 4.06051 25.6667 4.35725 25.6667 4.66667V23.3333C25.6667 23.6428 25.5437 23.9395 25.325 24.1583C25.1062 24.3771 24.8094 24.5 24.5 24.5H3.49999C3.19058 24.5 2.89383 24.3771 2.67504 24.1583C2.45624 23.9395 2.33333 23.6428 2.33333 23.3333V4.66667C2.33333 4.35725 2.45624 4.06051 2.67504 3.84171C2.89383 3.62292 3.19058 3.50001 3.49999 3.50001H8.16666V1.16667H10.5V3.50001H17.5V1.16667H19.8333V3.50001ZM23.3333 10.5V5.83334H19.8333V8.16667H17.5V5.83334H10.5V8.16667H8.16666V5.83334H4.66666V10.5H23.3333ZM23.3333 12.8333H4.66666V22.1667H23.3333V12.8333ZM7 15.1667H12.8333V19.8333H7V15.1667Z"
          fill="#07081F"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_617">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
