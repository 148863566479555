import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  setDefaultReason,
  setDefaultStatus,
  setDefaultTrackingMethod,
  setIsPrintingOn,
  toggleSelection,
  updateFrontOfficeAttendance,
} from 'state/front-office/FrontOfficeActions';
import { IScanType, SCANNING_TYPES } from '../OptionsBar';
import { FailToast, SuccessToast } from 'components/toasts';
import { findTrackingMethod } from 'utils/attendance.utils';
import { getStatusOptions } from 'utils/status.utils';

type TDefaultStatusOption = { value: string | null; label: string };
type TStatusOption = { value: string; label: string };

export default () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    isPrintingOn,
    defaultStatus,
    defaultTrackingMethod,
    defaultReason,
    selectedRecords,
    attendances,
    UpdatingIDs,
    trackingMethodsOptions,
    fetchingType,
  } = useAppSelector(({ frontOffice }) => frontOffice);
  const { statuses, isLoading: isResourcesLoading, reasons } = useAppSelector(
    ({ resources }) => resources,
  );

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isInitialRenderReasons, setIsInitialRenderReasons] = useState(true);
  const [isPrinting, setIsPrinting] = useState(isPrintingOn);
  const [selectedDefaultReason, setSelectedDefaultReason] = useState<
    { label: string; value: string } | undefined
  >(undefined);
  const [defaultTrackMethod, setDefaultTrackMethod] = useState<
    IScanType | undefined
  >(
    findTrackingMethod(defaultTrackingMethod, fetchingType) as
      | IScanType
      | undefined,
  );

  const [groupStatus, setGroupStatus] = useState<TStatusOption>();
  const [isAlreadyUpdated, setIsAlreadyUpdated] = useState(false);

  const selectedStatus = defaultStatus
    ? {
        label: defaultStatus?.name,
        value: defaultStatus?.id,
      }
    : undefined;

  const autoStatus = { value: null, label: t('Automatic') };
  const statusOptions = getStatusOptions(
    statuses,
    defaultTrackingMethod,
    fetchingType,
  ) as TDefaultStatusOption[];
  const customizedStatuses =
    fetchingType === 'front-office-type' && defaultTrackingMethod === 'section'
      ? statusOptions
      : [autoStatus, ...statusOptions];

  const setSelectedDefaultStatus = ({ value }: TDefaultStatusOption) => {
    if (!value) {
      return dispatch(setDefaultStatus(null));
    }
    const status = statuses.find(({ id }) => id === value);
    status && dispatch(setDefaultStatus(status));
  };

  const togglePrinting = (bool: boolean) => dispatch(setIsPrintingOn(bool));

  const selectedStudents = [...selectedRecords].slice(0, 3).reduce(
    (list, recordID) => {
      const attendance = attendances.find(({ id }) => id === recordID);
      if (attendance?.student) return [...list, attendance.student];

      return list;
    },
    [] as {
      id: string;
      firstName: string;
      lastName: string;
    }[],
  );

  const updateGroupStatus = async () => {
    if (!groupStatus || isAlreadyUpdated) return;

    await dispatch(
      updateFrontOfficeAttendance({
        ids: selectedRecords,
        statusID: groupStatus?.value,
      }),
    ).then(({ isSuccessful }) => {
      if (isSuccessful) {
        setIsAlreadyUpdated(true);
        dispatch(toggleSelection());
        SuccessToast('Attendance status has been updated');
      } else FailToast('Failed to update status');
    });
  };

  useEffect(() => {
    setIsAlreadyUpdated(false);
  }, [selectedRecords.length, groupStatus]);

  useEffect(() => {
    if (isInitialRender) {
      const valueInStorage = window.localStorage.getItem('is_printing_on');
      setIsPrinting(valueInStorage === '1' ? true : false);
    } else {
      togglePrinting(isPrinting);
    }

    setIsInitialRender(false);
  }, [isPrinting]);

  useEffect(() => {
    setDefaultTrackMethod(
      findTrackingMethod(defaultTrackingMethod, fetchingType) as IScanType,
    );
  }, [defaultTrackingMethod]);

  useEffect(() => {
    if (isInitialRender) {
      const valueInStorage = window.localStorage.getItem(
        'default_tracking_method',
      );
      if (!valueInStorage || valueInStorage === 'undefined') {
        setDefaultTrackMethod(
          findTrackingMethod('check_in', fetchingType) as IScanType,
        );
      } else {
        setDefaultTrackMethod(
          findTrackingMethod(valueInStorage, fetchingType) as IScanType,
        );
      }
    } else {
      if (defaultTrackMethod?.value) {
        dispatch(setDefaultTrackingMethod(defaultTrackMethod?.value));
        if (
          defaultTrackMethod?.value === 'section' &&
          fetchingType === 'front-office-type'
        ) {
          dispatch(
            setDefaultStatus(
              statuses.find((status) => status.abbr === 'T') ??
                defaultStatus ??
                null,
            ),
          );
        }
      }
    }

    setIsInitialRender(false);
  }, [defaultTrackMethod, statuses]);

  useEffect(() => {
    if (reasons?.length) {
      const unKnownReason = reasons.find((reason) => reason.code === 'Unk');
      if (isInitialRenderReasons) {
        const valueInStorage = window.localStorage.getItem('default_reason');
        if (!valueInStorage) {
          setSelectedDefaultReason({
            label: unKnownReason?.description ?? '',
            value: unKnownReason?.id ?? '',
          });
        } else {
          setSelectedDefaultReason({
            label:
              reasons.find((reason) => reason.id === valueInStorage)
                ?.description ??
              unKnownReason?.description ??
              '',
            value: valueInStorage ?? unKnownReason?.id ?? '',
          });
        }
      } else {
        dispatch(setDefaultReason(selectedDefaultReason?.value ?? ''));
      }

      setIsInitialRenderReasons(false);
    }
  }, [selectedDefaultReason, reasons]);

  return {
    autoStatus,
    customizedStatuses,
    isPrinting,
    defaultTrackMethod,
    setDefaultTrackMethod,
    isResourcesLoading,
    selectedStatus,
    groupStatus,
    setIsPrinting,
    setSelectedDefaultStatus,
    setGroupStatus,
    statusOptions,
    selectedDefaultReason,
    setSelectedDefaultReason,
    isUpdating: !!UpdatingIDs.length,
    selectedRecords,
    updateGroupStatus,
    selectedStudents,
    canGroupUpdate: !isAlreadyUpdated,
    trackingMethodsOptions,
    fetchingType,
  };
};
