import React from 'react';

import { InfoIcon, InfoText } from './InfoButtonStyles';
import ReactTooltip from 'react-tooltip';
import { splitStringIntoLines } from 'utils/string.utils';

interface Props {
  text: string;
  id: string;
  icon?: React.ReactNode;
  paddingLeft?: number;
}

const InfoButton: React.FC<Props> = ({
  text,
  id,
  icon,
  paddingLeft = 10,
}: Props) => {
  return (
    <>
      <ReactTooltip place="top" id={id} aria-haspopup={id}>
        {text.split(`\n`).map((textItem, index) => {
          return <InfoText key={index}>{textItem}</InfoText>;
        })}
      </ReactTooltip>
      <a style={{ paddingLeft: paddingLeft }} data-tip data-for={id}>
        {!icon && <InfoIcon className="fas fa-info-circle" />}
        {icon && icon}
      </a>
    </>
  );
};

export default InfoButton;
