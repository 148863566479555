import { Reducer } from 'redux';
import { ClassRoomAttendanceRecord, ISection } from 'types/section.types';
import { IStudent } from 'types/student.types';
import { AttendanceViewsEnum } from 'types/event.types';

import * as Actions from './ClassroomActions.types';
import { IUser } from 'types/user.types';

export interface IAttendanceByData {
  TotalPresent: number;
  TotalAbsent: number;
  TotalStudents: number;
}

export interface State {
  attendanceByData: IAttendanceByData;
  classRoster: ClassRoomAttendanceRecord[];
  next?: string | undefined;
  checkedRecords: ClassRoomAttendanceRecord[];
  currentSectionID?: string | undefined;
  error: string;
  inSchoolView: string;
  isAbsentSelected: boolean;
  isAllSelected: boolean;
  isLoading: boolean;
  isPresentSelected: boolean;
  isUpdating: boolean;
  pageSize: number;
  sections: ISection[];
  selectedDate: Date;
  students: IStudent[];
  isSelectTeacherFlow: boolean;
  searchText: string;
  searchResults: IUser[];
  selectedTeacher: string;
  selectedTeacherLabel: string;
  sectionsLoading: boolean;
}

const today = new Date();
const initialState: State = {
  attendanceByData: {
    TotalPresent: 0,
    TotalAbsent: 0,
    TotalStudents: 0,
  },
  next: undefined,
  checkedRecords: [],
  currentSectionID: undefined,
  error: '',
  inSchoolView: AttendanceViewsEnum.checkIn,
  isAbsentSelected: false,
  isAllSelected: false,
  isLoading: false,
  isPresentSelected: false,
  isUpdating: false,
  pageSize: 50,
  classRoster: [],
  sections: [],
  selectedDate: today,
  students: [],
  isSelectTeacherFlow: false,
  searchText: '',
  searchResults: [],
  selectedTeacher: '',
  selectedTeacherLabel: '',
  sectionsLoading: false,
};

const classroomReducer: Reducer<State, Actions.InSchoolActionTypes> = (
  state: State = initialState,
  action: Actions.InSchoolActionTypes,
) => {
  switch (action.type) {
    case Actions.INSCHOOL__SET_CHECKED_STUDENTS: {
      return {
        ...state,
        checkedRecords: action.checkedRecords,
      };
    }

    case Actions.INSCHOOL__SET_VIEW: {
      return {
        ...state,
        inSchoolView: action.inSchoolView,
      };
    }

    case Actions.INSCHOOL__TOGGLE_IS_ABSENT_SELECTED: {
      const isPresentSelected = false;
      const isAbsentSelected = true;
      const isAllSelected = false;
      return {
        ...state,
        isAbsentSelected,
        isPresentSelected,
        isAllSelected,
      };
    }

    case Actions.INSCHOOL__TOGGLE_IS_PRESENT_SELECTED: {
      const isPresentSelected = true;
      const isAbsentSelected = false;
      const isAllSelected = false;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        isAllSelected,
      };
    }

    case Actions.INSCHOOL__TOGGLE_IS_ALL_SELECTED: {
      const isPresentSelected = false;
      const isAbsentSelected = false;
      const isAllSelected = true;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        isAllSelected,
      };
    }

    case Actions.INSCHOOL__LOAD_ATTENDANCES: {
      return {
        ...state,
        error: '',
        classRoster: action.classRoster,
        attendanceByData: action.attendanceByData,
        next: action.next,
      };
    }

    case Actions.INSCHOOL__UPDATE_ATTENDANCES: {
      return {
        ...state,
        error: '',
        classRoster: action.newList,
      };
    }

    case Actions.INSCHOOL__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.INSCHOOL__LAUNCH_SUCCESS: {
      return {
        ...state,
        classRoster: [],
        checkedRecords: [],
        students: [],
        next: undefined,
        currentSectionID: undefined,
        sections: action.sections,
        error: '',
      };
    }

    case Actions.INSCHOOL__SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSectionID: action.currentSectionID,
        error: '',
        students: action.students,
        next: action.attendancesNext,
      };
    }

    case Actions.INSCHOOL__SET_DATE_RANGE: {
      return {
        ...state,
        selectedDate: action.date,
        next: undefined,
        currentSectionID: undefined,
        classRoster: [],
        students: [],
        attendanceByData: {
          TotalPresent: 0,
          TotalAbsent: 0,
          TotalStudents: 0,
        },
      };
    }

    case Actions.INSCHOOL__SET_SECTIONS: {
      return {
        ...state,
        sections: action.sections,
      };
    }

    case Actions.INSCHOOL__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.INSCHOOL__SET_CLASSROOM_SECTIONS_LOADING: {
      return {
        ...state,
        sectionsLoading: action.bool,
      };
    }

    case Actions.INSCHOOL__SET_IS_UPDATING: {
      return {
        ...state,
        isUpdating: action.bool,
      };
    }

    case Actions.INSCHOOL__SET_IS_SELECT_TEACHER_FLOW: {
      return {
        ...state,
        isSelectTeacherFlow: action.isSelectTeacherFlow,
      };
    }

    case Actions.INSCHOOL__HANDLE_TEACHER_SEARCH: {
      return {
        ...state,
        isShowingResults: true,
        nextSearchLink: action.next,
        searchResults: action.data,
        searchText: action.searchText,
      };
    }

    case Actions.INSCHOOL__SET_SELECTED_TEACHER: {
      const teacher = state.searchResults?.find(
        (teacher) => teacher.id === action.selectedTeacher,
      );
      return {
        ...state,
        selectedTeacher: action.selectedTeacher,
        selectedTeacherLabel: teacher
          ? `${teacher.firstName} ${teacher.lastName}`
          : '',
      };
    }

    case Actions.INSCHOOL__CLEAR_SEARCH_TEXT: {
      return {
        ...state,
        isShowingResults: false,
        nextSearchLink: undefined,
        searchResults: [],
        selectedTeacher: '',
        searchText: '',
        sections: [],
      };
    }
    default:
      return state;
  }
};

export default classroomReducer;
