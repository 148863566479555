import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Hide, Show } from './components/icons';

import {
  Container,
  ErrorMessage,
  InnerTopContainer,
  Input,
  InputContainer,
  InputIcon,
  Label,
  TextArea,
  TopContainer,
} from './InputStyles';

interface Props {
  disabled?: boolean;
  error?: string;
  label: string;
  maxLength?: number;
  name: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  placeholder?: string;
  shouldFillHeight?: boolean;
  type:
    | 'email'
    | 'number'
    | 'password'
    | 'tel'
    | 'text'
    | 'textarea'
    | 'datetime-local';
  value?: number | string;
  rows?: number;
  resize?: 'none' | 'both' | 'vertical' | 'horizontal';
  light?: boolean;
  minInputHeight?: string;
}

const InputComponent: React.FC<Props> = ({
  error,
  disabled = false,
  label,
  light = false,
  maxLength = undefined,
  name,
  onChange,
  placeholder = '...',
  shouldFillHeight = false,
  type,
  value,
  rows,
  resize = 'none',
  minInputHeight = '50px',
}: Props) => {
  const { t } = useTranslation();

  const [isInFocus, setIsInFocus] = useState<boolean>(false);
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);

  // https://tomduffytech.com/how-to-format-phone-number-in-react/
  const formatPhoneNumber = (value: string) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFieldValue(e.target.value);

    if (onChange) {
      if (e.target.type === 'tel') {
        e.target.value = formatPhoneNumber(e.target.value);
      }

      onChange(e);
    }
  };

  const handleTogglePassword = () => {
    setShouldShowPassword(!shouldShowPassword);
  };

  const tError = error && t(error);
  const tLabel = t(label);
  const tPlaceholder = t(placeholder);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <Container shouldFillHeight={shouldFillHeight}>
      {(label || error) && (
        <TopContainer>
          <Label htmlFor={name}>{tLabel}</Label>

          {error && (
            <InnerTopContainer>
              <ErrorMessage>{tError}</ErrorMessage>
            </InnerTopContainer>
          )}
        </TopContainer>
      )}

      <InputContainer shouldFillHeight={shouldFillHeight}>
        {type === 'textarea' ? (
          <Input
            as={TextArea}
            disabled={disabled}
            error={tError}
            isInFocus={isInFocus}
            maxLength={maxLength}
            name={name}
            onBlur={() => setIsInFocus(false)}
            onChange={handleChange}
            onFocus={() => setIsInFocus(true)}
            placeholder={tPlaceholder}
            type={type}
            value={fieldValue}
            styles={{ with: 100 }}
            rows={rows}
            resize={resize}
            light={light}
          />
        ) : (
          <>
            <Input
              minInputHeight={minInputHeight}
              disabled={disabled}
              error={tError}
              hasIcon={type === 'password'}
              isInFocus={isInFocus}
              maxLength={maxLength}
              name={name}
              onBlur={() => setIsInFocus(false)}
              onChange={handleChange}
              onFocus={() => setIsInFocus(true)}
              placeholder={tPlaceholder}
              type={type === 'password' && shouldShowPassword ? 'text' : type}
              value={
                type === 'tel'
                  ? formatPhoneNumber((fieldValue || '').toString())
                  : fieldValue
              }
              light={light}
            />

            {type === 'password' && (
              <InputIcon onClick={handleTogglePassword}>
                {shouldShowPassword ? <Show /> : <Hide />}
              </InputIcon>
            )}
          </>
        )}
      </InputContainer>
    </Container>
  );
};

export default InputComponent;
