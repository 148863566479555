import API from 'data/axios';

import { getAuthToken } from 'data/utils/authToken.utils';
import { parseError } from 'utils/errors.utils';
import { userMapper } from '../../mappers/user.mappers';
import getAllSchoolsInDistrict from '../school/getAllSchoolsInDistrict';
import getUserSchools from '../users/getUserSchools';

import { Adapter, Response } from '../../types/api.types';
import { IApiUser } from '../../types/userApi.types';
import { IUser } from 'types/user.types';

const ERR_TEXT = 'Something went wrong with getting the user';

export default async (): Promise<Adapter<IUser>> => {
  if (!getAuthToken()) return { error: ERR_TEXT, success: false };

  return API.get<Response<IApiUser>>('/me')
    .then(async ({ data: { data } }) => {
      const user = userMapper(data);

      if (!user.isActive) {
        throw 'User account has been deactivated';
      }

      if (
        !user.roles ||
        !Object.keys(user.roles).length ||
        (Object.keys(user.roles).length === 1 &&
          Object.keys(user.roles).includes('student'))
      ) {
        throw 'Unauthorized account';
      }

      if ('districtAdmin' in user.roles) {
        const { data: userSchools = [] } = await getAllSchoolsInDistrict();
        user.schools = userSchools;
      } else {
        const { data: userSchools = [] } = await getUserSchools({
          userID: user.id,
        });
        user.schools = userSchools;
      }

      return { success: true, data: user };
    })
    .catch((err) => {
      const stringError = parseError(err, ERR_TEXT);

      return { error: stringError, success: false };
    });
};
