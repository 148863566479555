import styled from 'styled-components';

interface ICircle {
  isActive: boolean;
  isDisabled?: boolean;
}

interface ISvg {
  small?: boolean;
}

export const Circle = styled.circle<ICircle>`
  fill: ${({ isActive, isDisabled, theme }) =>
    !isDisabled && isActive
      ? theme.secondaryFontDark
      : `${theme.secondaryFontDark}33`};
`;

export const Svg = styled.svg<ISvg>`
  width: 6px;
  height: ${({ small }) => (small ? '20px' : '28px')};
`;
