import { Reducer } from 'redux';
import { endOfDay, startOfDay } from 'date-fns';

import { IBus, IBusMessage } from 'types/schoolBus.types';
import * as Actions from './BusActions.types';

/* Buses & Bus Alerts */

export interface State {
  busAlerts: IBusMessage[];
  buses: IBus[];
  endDate: Date;
  isLoading: boolean;
  startDate: Date;
}

const today = new Date();
const initialState: State = {
  busAlerts: [],
  buses: [],
  endDate: startOfDay(today),
  isLoading: false,
  startDate: endOfDay(today),
};

const BusReducer: Reducer<State, Actions.BusActionTypes> = (
  state: State = initialState,
  action: Actions.BusActionTypes,
) => {
  switch (action.type) {
    case Actions.BUS__GET_ALERTS: {
      return {
        ...state,
        busAlerts: action.alerts,
      };
    }

    case Actions.BUS__LAUNCH_BUSES: {
      return {
        ...state,
        buses: action.buses,
      };
    }

    case Actions.BUS__RESET_DATE_RANGE: {
      return {
        ...state,
        endDate: startOfDay(today),
        startDate: endOfDay(today),
      };
    }

    case Actions.BUS__SET_DATE_RANGE: {
      return {
        ...state,
        endDate: action.endDate,
        startDate: action.startDate,
      };
    }

    case Actions.BUS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    default:
      return state;
  }
};

export default BusReducer;
