import { Reducer } from 'redux';
import * as Actions from './AuthActions.types';
import { IUser } from 'types/user.types';
import { IDistrict, IOauthDistrict } from 'types/district.types';
import { ISchool } from 'types/school.types';

export interface State {
  activeSchool?: ISchool;
  district: IDistrict | null;
  districtsResult: IOauthDistrict[];
  error: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingForgotPassword: boolean;
  isLoadingUser: boolean;
  user: IUser | null;
}

export const initialState = {
  districtsResult: [],
  district: null,
  error: '',
  isAuthenticated: false,
  isLoading: false,
  isLoadingForgotPassword: false,
  isLoadingUser: true,
  user: null,
};

const authReducer: Reducer<State> = (
  state: State = initialState,
  action: Actions.AuthActionTypes,
): State => {
  switch (action.type) {
    case Actions.AUTH__CLEAR_ERROR: {
      return {
        ...state,
        error: '',
      };
    }

    case Actions.AUTH__FAIL: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.AUTH__FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: '',
      };
    }

    case Actions.AUTH__GET_USER_AUTH_SUCCESS: {
      return {
        ...state,
        activeSchool: action.user.schools?.find(
          ({ id }) => id === action.activeSchoolID,
        ),
        district: action.district,
        error: '',
        isAuthenticated: true,
        user: action.user,
      };
    }

    case Actions.AUTH__LOGIN_FAIL: {
      return {
        ...state,
        error: action.error,
        isAuthenticated: false,
        user: initialState.user,
      };
    }

    case Actions.AUTH__LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case Actions.AUTH__LOGOUT_SUCCESS: {
      return {
        ...initialState,
        isLoadingUser: false,
      };
    }

    case Actions.AUTH__SET_ACTIVE_SCHOOL: {
      return {
        ...state,
        activeSchool: state.user?.schools?.find(
          ({ id }) => id === action.activeSchoolID,
        ),
      };
    }

    case Actions.AUTH__SET_DISTRICT_SEARCH_RESULTS: {
      return {
        ...state,
        districtsResult: action.districts,
      };
    }

    case Actions.AUTH__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.AUTH__SET_IS_LOADING_FORGOT_PASSWORD: {
      return {
        ...state,
        isLoadingForgotPassword: action.isLoadingForgotPassword,
      };
    }

    case Actions.AUTH__SET_IS_LOADING_USER: {
      return {
        ...state,
        isLoadingUser: action.isLoadingUser,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
