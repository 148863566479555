import styled from 'styled-components';

interface IContainer {
  shouldFillHeight: boolean;
}

interface IInput {
  error?: string;
  hasIcon: boolean;
  isInFocus: boolean;
  light?: boolean;
  minInputHeight?: string;
}

interface IInputContainer {
  shouldFillHeight: boolean;
}

interface ITextarea {
  resize?: 'none' | 'both' | 'vertical' | 'horizontal';
}

export const Container = styled.div<IContainer>`
  height: ${({ shouldFillHeight }) => shouldFillHeight && '100%'};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: 14px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ theme }) => theme.colorDanger};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  font-weight: ${({ theme }) => theme.weightSemibold};
  color: ${({ theme }) => theme.secondaryGrayDark};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const InnerTopContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const Input = styled.input<IInput>`
  min-height: ${({ minInputHeight }) => minInputHeight ?? '50px'};
  padding: ${({ theme }) => theme.inputPadding};
  padding-right: ${({ hasIcon, theme }) =>
    hasIcon && `calc(${theme.inputPadding} * 2 + ${theme.inputIconSize})`};
  width: 100%;
  border: 1px solid
    ${({ error, isInFocus, theme, light }) =>
      (error && theme.colorDanger) ||
      (isInFocus && theme.colorInputBorderActive) ||
      (light && theme.primaryWhite) ||
      theme.colorInputBorderInactive};
  background-color: ${({ isInFocus, theme, light }) =>
    light
      ? 'transparent'
      : isInFocus
      ? theme.primaryWhite
      : theme.slightGrayBackground};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: 16px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ theme, light }) =>
    light ? theme.primaryWhite : theme.secondaryFontDark};
  outline: none;
  transition: background-color 0.2s, border 0.2s;

  &:hover:not([disabled]) {
    border: 1px solid
      ${({ error, theme, light }) =>
        error
          ? theme.colorDanger
          : light
          ? theme.primaryWhite
          : theme.secondaryGrayDark};
  }

  &:focus {
    border: 1px solid
      ${({ error, theme, light }) =>
        error
          ? theme.colorDanger
          : light
          ? theme.primaryWhite
          : theme.secondaryGrayDark};
    background-color: ${({ theme, light }) =>
      light ? 'transparent' : theme.primaryWhite};
  }

  &::placeholder {
    color: ${({ theme, light }) =>
      light ? theme.primaryWhite : theme.secondaryFontDarkTrans};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.secondaryGrayLight};
    cursor: not-allowed;
  }

  /* autofill styles */
  &:-webkit-autofill::first-line {
  }
`;

export const InputContainer = styled.div<IInputContainer>`
  position: relative;
  width: 100%;
  height: ${({ shouldFillHeight }) => shouldFillHeight && '100%'};
  display: flex;
  align-items: center;
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  right: ${({ theme }) => theme.inputPadding};
  transform: translateY(-50%);
  cursor: pointer;
`;

export const TextArea = styled.textarea<ITextarea>`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weightRegular};
  height: 100%;
  line-height: 1.2;
  resize: ${({ resize }) => resize};
  overflow: auto;
  padding: 9px;
  &::-webkit-scrollbar {
    display: unset;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
