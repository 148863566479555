import { morphism, StrictSchema } from 'morphism';
import {
  busRouteLabelMapper,
  busMapper,
  reversePlanStudentMapper,
  planStudentMapper,
} from '../schoolBus.mappers';
import { userMapper } from '../user.mappers';
import {
  IApiBus,
  IApiBusAttendance,
  IApiBusMessage,
  IApiBusRoute,
  IApiBusRouteLabel,
  IApiBusRouteLocation,
  IApiBusRoutePlan,
  IApiBusRoutePlanLocation,
  IApiBusRoutePlanStudent,
  IApiMapLocation,
} from 'data/types/schoolBusApi.types';
import {
  BusRoueStatusEnum,
  IBus,
  IBusAttendance,
  IBusMessage,
  IBusRoute,
  IBusRouteLabel,
  IBusRouteLocation,
  IBusRoutePlan,
  IBusRoutePlanLocation,
  IBusRoutePlanStudent,
  IMapLocation,
  INewBusMessageInstance,
} from 'types/schoolBus.types';
import { IStudent } from 'types/student.types';

export const IBusAttendanceSchema: StrictSchema<
  IBusAttendance,
  IApiBusAttendance
> = {
  eventDate: (iteratee) => new Date(iteratee.event_date),
  eventType: {
    id: 'event_type.id',
    key: 'event_type.key',
    name: 'event_type.name',
  },
  id: 'id',
  location: ({ location }) =>
    location && location.id
      ? morphism(IBusRouteLocationSchema, location)
      : undefined,
  routeID: 'route',
  userAccountID: 'user_account',
};

export const IBusSchema: StrictSchema<IBus, IApiBus> = {
  busNumber: 'number',
  createdAt: (iteratee) => new Date(iteratee.created_at),
  districtID: 'district',
  id: 'id',
  isActive: 'is_active',
  isOutOfService: 'is_out_of_service',
  notes: 'notes',
  updatedAt: (iteratee) => new Date(iteratee.updated_at),
};

export const IReverseBusSchema: StrictSchema<
  Partial<IApiBus>,
  Partial<IBus>
> = {
  district: 'districtID',
  id: 'id',
  is_active: 'isActive',
  is_out_of_service: 'isOutOfService',
  notes: 'notes',
  number: 'busNumber',
};

export const IReverseBusMessageSchema: StrictSchema<
  Partial<IApiBusMessage>,
  INewBusMessageInstance
> = {
  date: (iteratee) => iteratee.date?.toISOString() || undefined,
  delivery_methods: 'deliveryMethodIDs',
  id: 'id',
  route: 'routeID',
  text: 'text',
  type: 'typeID',
};

export const IBusRouteLabelSchema: StrictSchema<
  IBusRouteLabel,
  IApiBusRouteLabel
> = {
  districtID: 'district',
  id: 'id',
  key: 'key',
  name: 'name',
};

export const IBusMessageSchema: StrictSchema<IBusMessage, IApiBusMessage> = {
  date: (iteratee) => new Date(iteratee.date),
  deliveryMethods: 'delivery_methods',
  id: 'id',
  routeID: 'route',
  sendToBusAdmin: 'send_to_bus_admin',
  sendToPrimaryContact: 'send_to_primary_contact',
  text: 'text',
  type: 'type',
};

export const IMapLocationSchema: StrictSchema<IMapLocation, IApiMapLocation> = {
  city: 'city',
  id: 'id',
  lat: 'latitude',
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  lng: 'longitude',
  state: ({ state }) => (state ? state : undefined),
  zipCode: 'zip_code',
};

export const IBusRouteLocationSchema: StrictSchema<
  IBusRouteLocation,
  IApiBusRouteLocation
> = {
  address: '',
  date: (iteratee) => new Date(iteratee.date),
  id: 'id',
  isStop: 'is_stop',
  lat: (iteratee) => parseFloat(iteratee.latitude),
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  lng: (iteratee) => parseFloat(iteratee.longitude),
  routeID: 'route',
};

export const IBusRouteSchema: StrictSchema<IBusRoute, IApiBusRoute> = {
  busDriver: {
    driverID: 'bus_driver.roles.bus_driver.id',
    driverNumber: 'bus_driver.roles.bus_driver.driver_number',
    firstName: 'bus_driver.first_name',
    lastName: 'bus_driver.last_name',
    sisID: 'bus_driver.sis_id',
    userID: 'bus_driver.id',
  },
  bus: (iteratee) => busMapper(iteratee.bus),
  comment: 'comment',
  createdAt: (iteratee) => new Date(iteratee.created_at),
  distance: 'distance',
  districtID: 'district',
  endLocation: (iteratee) => {
    if (!iteratee.end_location) return undefined;
    return morphism(IBusRouteLocationSchema, iteratee.end_location);
  },
  endTime: (iteratee) =>
    iteratee.end_time ? new Date(iteratee.end_time) : undefined,
  id: 'id',
  startLocation: (iteratee) =>
    morphism(IBusRouteLocationSchema, iteratee.start_location),
  startTime: (iteratee) => new Date(iteratee.start_time),
  status: ({ is_emergency, is_complete }) =>
    is_emergency
      ? BusRoueStatusEnum.emergency
      : is_complete
      ? BusRoueStatusEnum.completed
      : BusRoueStatusEnum.inProgress,
  stops: (iteratee) =>
    iteratee.stops.map((stop) => morphism(IBusRouteLocationSchema, stop)),
  students: (iteratee) =>
    iteratee.students.map((user) => userMapper(user) as IStudent),
  updatedAt: (iteratee) => new Date(iteratee.updated_at),
};

export const IBusRoutePlanLocationSchema: StrictSchema<
  IBusRoutePlanLocation,
  IApiBusRoutePlanLocation
> = {
  city: 'city',
  country: 'country',
  id: 'id',
  isStop: 'is_stop',
  lat: ({ latitude = '0' }) => parseFloat(latitude),
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  lng: ({ longitude = '0' }) => parseFloat(longitude),
  order: 'order',
  routePlanID: 'route_plan',
  state: 'state',
  zipCode: 'zip_code',
};

export const IBusRoutePlanSchema: StrictSchema<
  IBusRoutePlan,
  IApiBusRoutePlan
> = {
  busDriverID: ({ bus_driver }) => bus_driver || undefined,
  description: ({ description }) => description || undefined,
  districtID: 'district',
  id: 'id',
  label: ({ label }) => (label ? busRouteLabelMapper(label) : undefined),
  locations: ({ locations = [] }) =>
    locations.map((location) =>
      morphism(IBusRoutePlanLocationSchema, location),
    ),
  name: 'name',
  students: ({ students = [] }) => students.map(planStudentMapper),
};

export const IReverseBusRoutePlanSchema: StrictSchema<
  IApiBusRoutePlan,
  Partial<IBusRoutePlan>
> = {
  bus_driver: 'busDriverID',
  description: 'description',
  district: 'districtID',
  id: 'id',
  label: 'label.id',
  name: 'name',
  students: ({ students = [] }) => students.map(reversePlanStudentMapper),
};

export const IReverseBusRoutePlanLocationSchema: StrictSchema<
  Partial<IApiBusRoutePlanLocation>,
  Partial<IBusRoutePlanLocation>
> = {
  city: 'city',
  country: 'country',
  id: 'id',
  is_stop: 'isStop',
  latitude: 'lat',
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  longitude: 'lng',
  order: 'order',
  route_plan: 'routePlanID',
  state: 'state',
  zip_code: 'zipCode',
};

export const IBusPlanStudentSchema: StrictSchema<
  IBusRoutePlanStudent,
  Partial<IApiBusRoutePlanStudent>
> = {
  busStopID: 'bus_stop',
  id: 'id',
  routePlanID: 'route_plan',
  studentID: 'student',
};

export const IReversePlanStudentSchema: StrictSchema<
  IApiBusRoutePlanStudent,
  Partial<IBusRoutePlanStudent>
> = {
  bus_stop: 'busStopID',
  id: 'id',
  route_plan: 'routePlanID',
  student: 'studentID',
};
