import { createGlobalStyle } from 'styled-components';
import Reset from './Reset';
import TextElement from './TextElementReset';

// do not use @font-face in creatGlobalStyle, it sometimes causes
// fonts to get requested frequently resulting in a 'font-flicker'

const GlobalStyles = createGlobalStyle`
  ${Reset}
  ${TextElement}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  body {
    position: relative;
    background-color: ${(props) => props.theme.primaryWhite};
    color: ${(props) => props.theme.secondaryBlack};
    font-family: ${(props) => props.theme.font};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .react-pdf__Document,
  .react-pdf__Page,
  .react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    width: inherit !important;
    height: inherit !important;
    border-radius: inherit !important;

    .annotationLayer {
      display: none !important;
    }
  }

  .react-pdf__Document {
    position: relative;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .react-pdf__Page__canvas {
      object-fit: cover;
      border-radius: 20px !important;
    }
  }

  div[data-rmiz-modal-content] {
    img {
      border-radius: 0 !important;
    }
    .react-pdf__Document .react-pdf__Page__canvas {
      object-fit: contain;
      border-radius: 0 !important;
    }
  }
`;

export const HighlightStyles = createGlobalStyle`
    .generator-result-highlight {
        background-color: transparent;
        font-weight: 700;
    }
`;

export default GlobalStyles;
