import { ThunkAction } from 'redux-thunk';
import { IAttendance } from 'types/attendance.types';
import { RootState } from 'state/store';

export const ATTENDANCE_LOGS__LOAD_ATTENDANCES =
  'ATTENDANCE_LOGS__LOAD_ATTENDANCES';
export const ATTENDANCE_LOGS__SET_IS_LOADING =
  'ATTENDANCE_LOGS__SET_IS_LOADING';
export const ATTENDANCE_LOGS__ERROR = 'ATTENDANCE_LOGS__ERROR';
export const ATTENDANCE_LOGS__HANDLE_DATE_RANGE_CHANGE =
  'ATTENDANCE_LOGS__HANDLE_DATE_RANGE_CHANGE';
export const ATTENDANCE_LOGS__HANDLE_FILTERS_UPDATE =
  'ATTENDANCE_LOGS__HANDLE_FILTERS_UPDATE';
export const ATTENDANCE_LOGS__GET_NEXT_PAGE = 'ATTENDANCE_LOGS__GET_NEXT_PAGE';

export type IAttendanceLogsTrackingMethod =
  | 'section'
  | 'check_in'
  | 'check_out';

interface LoadAttendancesSuccessAction {
  type: typeof ATTENDANCE_LOGS__LOAD_ATTENDANCES;
  attendances: IAttendance[];
  search_text?: string;
  next?: string;
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof ATTENDANCE_LOGS__SET_IS_LOADING;
}

interface AttendanceLogsFailAction {
  error: string;
  type: typeof ATTENDANCE_LOGS__ERROR;
}

export interface HandleDateRangeChangeAction {
  type: typeof ATTENDANCE_LOGS__HANDLE_DATE_RANGE_CHANGE;
  startDate: Date;
  endDate: Date;
}

export interface HandleAttendanceLogsFiltersUpdate {
  type: typeof ATTENDANCE_LOGS__HANDLE_FILTERS_UPDATE;
  trackingMethod?: IAttendanceLogsTrackingMethod;
  status?: string;
}

interface getNextBatchOfAttendanceLogsAction {
  data: IAttendance[];
  next?: string;
  type: typeof ATTENDANCE_LOGS__GET_NEXT_PAGE;
}

export type AttendanceLogsActionTypes =
  | LoadAttendancesSuccessAction
  | SetIsLoadingAction
  | AttendanceLogsFailAction
  | getNextBatchOfAttendanceLogsAction
  | HandleDateRangeChangeAction
  | HandleAttendanceLogsFiltersUpdate;

export type TLoadAttendances = (args: {
  startDate?: Date;
  endDate?: Date;
  pageSize?: number;
  search_text?: string;
}) => ThunkAction<Promise<void>, RootState, unknown, AttendanceLogsActionTypes>;

export type TSetAttendanceLogsLoading = (bool: boolean) => SetIsLoadingAction;

export type TGetAttendancesNext = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AttendanceLogsActionTypes
>;
export type THandleDateRangeChange = (args: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, AttendanceLogsActionTypes>;

export type THandleAttendanceLogsFiltersUpdate = (args: {
  trackingMethod?: IAttendanceLogsTrackingMethod;
  status?: string;
}) => ThunkAction<void, RootState, unknown, AttendanceLogsActionTypes>;
