import { format } from 'date-fns';
import API from 'data/axios';

import { AttendanceMapper } from '../../mappers/attendace.mappers';
import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiAttendance } from '../../types/attendanceApi.types';
import { AttendanceTagEnum, IAttendance } from 'types/attendance.types';

export type TTrackingMethod = 'section' | 'daily' | 'check_in' | 'check_out';

interface IGetSchoolAttendancesArgs {
  endDate?: Date;
  schoolID: string;
  startDate?: Date;
  pageSize?: number;
  search_text?: string;
  trackingMethod?: TTrackingMethod | TTrackingMethod[];
  tags?: AttendanceTagEnum;
  status?: string;
}

export default async ({
  endDate,
  schoolID,
  startDate,
  pageSize,
  search_text,
  trackingMethod,
  tags,
  status,
}: IGetSchoolAttendancesArgs): Promise<Adapter<IAttendance[]>> => {
  const methods = trackingMethod
    ? Array.isArray(trackingMethod)
      ? trackingMethod
      : [trackingMethod]
    : undefined;

  let url = `/schools/${schoolID}/attendances`;

  const queries: string[] = ['expansions=student,bell_schedule_item'];
  if (startDate) {
    queries.push(`calendar_date_start=${format(startDate, 'yyyy-MM-dd')}`);
  }
  if (endDate) {
    queries.push(`calendar_date_end=${format(endDate, 'yyyy-MM-dd')}`);
  }
  if (pageSize) queries.push(`page_size=${pageSize}`);
  if (search_text) queries.push(`search_text=${search_text}`);
  if (tags) queries.push(`tags=${tags}`);
  if (status) queries.push(`status=${status}`);
  if (methods) queries.push(`tracking_method=${methods.join(',')}`);

  if (queries) url += `?${queries.join('&')}`;

  return API.get<ArrayResponse<IApiAttendance>>(url)
    .then(({ data: response }) => {
      const attendances = response.data.map(({ data }) =>
        AttendanceMapper(data),
      );
      const next = response.links.find(({ rel }) => rel === 'next')?.uri;
      return { success: true, data: attendances, next: next };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
