import API from 'data/axios';

import { userMapper } from '../../../mappers/user.mappers';

import { Response } from '../../../types/api.types';
import { IApiUser } from '../../../types/userApi.types';
import { IUser } from 'types/user.types';

interface IGetUserDataArgs {
  userID: string;
}

export default async ({ userID }: IGetUserDataArgs): Promise<IUser> =>
  API.get<Response<IApiUser>>(`/users/${userID}`).then(({ data: response }) => {
    const user = userMapper(response.data);

    return user;
  });
