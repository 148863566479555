import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ClassPass from '../class-pass/ClassPass';

import { IPassDetails } from '../classpass.types';

const PrintIDBadge = (data: IPassDetails) => {
  const printWindow = window.open('', '_blank');

  return new Promise((resolve) => {
    if (printWindow) {
      printWindow.document.write(
        `<html><head><title>${data.studentName}_ID_Badge</title></head><body>`,
      );
      //to enable background and graphics for print by default
      printWindow.document.write('<style>');
      printWindow.document.write(`@media print {  * {
        -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
        color-adjust: exact !important;  /*Firefox*/
      }}`);
      printWindow.document.write('</style>');
      printWindow.document.write(
        ReactDOMServer.renderToString(<ClassPass {...data} />),
      );
      printWindow.document.write('</body></html>');
      setTimeout(() => {
        //if no timeout -> images will not rendered correctly
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 1000);

      resolve({ success: true });
    } else {
      throw new Error('Failed to open print window');
    }
  }).catch((err) => ({ success: false, error: err }));
};

export default PrintIDBadge;
