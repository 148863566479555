import { IApiDistrict, IApiOauthDistrict } from 'data/types/districtsApi.types';
import { StrictSchema } from 'morphism';
import { IDistrict, IOauthDistrict } from 'types/district.types';

export const IDistrictSchema: StrictSchema<IDistrict, IApiDistrict> = {
  createdAt: (iteratee) => new Date(iteratee.created_at),
  id: 'id',
  lastSync: 'last_sync',
  launchDate: 'launch_date',
  logoUrl: (iteratee) =>
    iteratee.logo_url === null ? undefined : iteratee.logo_url,
  name: 'name',
  pauseEnd: 'pause_end',
  pauseStart: 'pause_start',
  portalUrl: 'portal_url',
  sisID: 'sis_id',
  sisType: 'sis_type',
  identifier: 'identifier',
  state: 'state',
  updatedAt: (iteratee) => new Date(iteratee.updated_at),
};

export const IOauthDistrictSchema: StrictSchema<
  IOauthDistrict,
  IApiOauthDistrict
> = {
  id: 'id',
  provider: 'provider',
  url: 'url',
  client: 'client',
  district: {
    id: 'district.id',
    name: 'district.name',
  },
};
