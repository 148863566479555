import styled from 'styled-components';

interface IAvatar {
  isDistrictAdmin?: boolean;
}

export const Avatar = styled.img<IAvatar>`
  width: ${({ theme }) => theme.profileAvatarSize};
  height: ${({ theme }) => theme.profileAvatarSize};
  border-radius: ${({ theme }) => theme.borderRadiusHalf};
  border: 3px solid
    ${({ theme, isDistrictAdmin }) =>
      isDistrictAdmin ? theme.secondaryGreenDark : theme.primaryWhite};
  filter: drop-shadow(0px 8px 15px rgba(0, 0, 0, 0.1));
  position: absolute;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    width: 110px;
    height: 110px;
  }

  object-fit: cover;
`;

export const AvatarContainer = styled.div`
  min-height: 130px;
  min-width: 130px;
`;

export const Badge = styled.div`
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
  background-color: ${({ theme }) => theme.secondaryGreenDark};
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const Block = styled.div``;

export const DeactivatedBadge = styled.div`
  background-color: ${({ theme }) => theme.colorDanger};
  border-radius: 4px;
  color: ${({ theme }) => theme.primaryWhite};
  font-family: ${({ theme }) => theme.fontBold};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.weightBoldX};
  padding: 4px;
  width: fit-content;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const Info = styled.div`
  padding-left: 150px; /*130 avatar size + padding*/
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 25px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.widthMobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const InfoGrid = styled.div`
  margin-top: 30px;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    max-width: 100%;
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.secondaryGrayDark};
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  font-weight: ${({ theme }) => theme.weightSemibold};
  line-height: ${({ theme }) => theme.lineHeightSmall};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const Name = styled.h4`
  color: ${({ theme }) => theme.primaryBlueDark};
`;

export const School = styled.span`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.font};
  font-weight: ${({ theme }) => theme.weightSemibold};
  font-size: 22px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ theme }) => theme.primaryBlueDark};
  text-align: left;

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 18px;
  }
`;

export const SubInfo = styled.span`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fontNormal};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 15px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ theme }) => theme.primaryBlueDark};

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 12px;
  }
`;

export const Unknown = styled.div`
  ::before {
    color: ${({ theme }) => theme.primaryBlueDark};
    content: 'N/A';
    font-family: ${({ theme }) => theme.fontNormal};
    font-size: ${({ theme }) => theme.fontSizeMedium};
    line-height: ${({ theme }) => theme.lineHeightSmall};
    opacity: 0.5;
  }
`;

export const Value = styled.div`
  color: ${({ theme }) => theme.primaryBlueDark};
  font-family: ${({ theme }) => theme.font};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weightSemibold};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  margin-top: 10px;

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 18px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  min-height: 130px;
  min-width: 130px;
`;
