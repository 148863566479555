import React from 'react';
import { Path, Svg } from './ViewIconStyles';

const ViewIcon: React.FC = () => {
  return (
    <Svg viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg">
      <Path
        opacity="0.504702"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1501 0.350006C5.67732 0.350006 1.85763 3.13032 0.310059 7.05501C1.85763 10.9797 5.67732 13.76 10.1501 13.76C14.6272 13.76 18.4425 10.9797 19.9901 7.05501C18.4425 3.13032 14.6272 0.350006 10.1501 0.350006ZM10.1501 11.525C7.68112 11.525 5.67732 9.5224 5.67732 7.05496C5.67732 4.58752 7.68112 2.58499 10.1501 2.58499C12.619 2.58499 14.6228 4.58757 14.6228 7.05501C14.6228 9.52245 12.619 11.525 10.1501 11.525ZM7.46643 7.05501C7.46643 5.57542 8.66957 4.37301 10.1501 4.37301C11.6305 4.37301 12.8337 5.57542 12.8337 7.05501C12.8337 8.53459 11.6305 9.73701 10.1501 9.73701C8.66957 9.73701 7.46643 8.53459 7.46643 7.05501Z"
      />
    </Svg>
  );
};

export default ViewIcon;
