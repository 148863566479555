import API from 'data/axios';

import { AttendanceMapper } from 'data/mappers/attendace.mappers';
import {
  CheckpointEventMapper,
  locationMapper,
} from 'data/mappers/checkpoint.mappers';
import { schoolMapper } from 'data/mappers/school.mappers';
import { userMapper } from 'data/mappers/user.mappers';

import { ApiQuery, ArrayResponse } from '../../types/api.types';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface IGetNextArgs {
  path: string;
  type?:
    | 'user'
    | 'school'
    | 'checkpoint_event'
    | 'attendance'
    | 'checkpoint_location';
}

export default async <T>({
  path,
  type,
}: IGetNextArgs): Promise<ApiQuery<T[]>> =>
  API.get<ArrayResponse<any>>(path, { baseURL: '' }).then(
    ({ data: response }) => {
      let results = response.data.map(({ data }) => data);
      const next = response.links.find(({ rel }) => rel === 'next')?.uri;

      switch (type) {
        case 'attendance': {
          results = results.map(AttendanceMapper);
          break;
        }

        case 'checkpoint_event': {
          results = results.map(CheckpointEventMapper);
          break;
        }

        case 'school': {
          results = results.map(schoolMapper);
          break;
        }

        case 'user': {
          results = results.map(userMapper);
          break;
        }

        case 'checkpoint_location': {
          results = results.map(locationMapper);
          break;
        }
      }

      return { data: results, next };
    },
  );
