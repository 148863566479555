import React from 'react';
import { Svg } from './QuestionBubbleIconStyles';

const QuestionBubbleIcon: React.FC = () => {
  return (
    <Svg viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.503751"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.5C0 10.093 10.093 0 22.5 0C34.907 0 45 10.093 45 22.5C45 33.1881 37.5099 42.159 27.5028 44.4395C26.7419 44.6129 25.9664 45.8653 25.1786 47.1376C24.3001 48.5564 23.4062 50 22.5 50C21.5946 50 20.7016 48.5567 19.8239 47.1382C19.0367 45.8659 18.2618 44.6136 17.5015 44.4405C7.49224 42.1615 0 33.1896 0 22.5ZM25.468 23.7037C24.8044 24.0092 24.3749 24.6787 24.3749 25.4083V26.2501C24.3749 27.2849 23.5368 28.125 22.5 28.125C21.4632 28.125 20.6251 27.2849 20.6251 26.2501V25.4083C20.6251 23.2199 21.9112 21.2139 23.897 20.2969C25.8076 19.4176 27.1874 17.0831 27.1874 15.9374C27.1874 13.3539 25.0856 11.25 22.5 11.25C19.9144 11.25 17.8126 13.3539 17.8126 15.9374C17.8126 16.9725 16.9742 17.8126 15.9374 17.8126C14.9006 17.8126 14.0625 16.9725 14.0625 15.9374C14.0625 11.2857 17.848 7.49989 22.5 7.49989C27.152 7.49989 30.9375 11.2857 30.9375 15.9374C30.9375 18.4708 28.7399 22.1944 25.468 23.7037ZM24.3749 33.75C24.3749 34.7855 23.5355 35.6249 22.5 35.6249C21.4645 35.6249 20.6251 34.7855 20.6251 33.75C20.6251 32.7145 21.4645 31.8751 22.5 31.8751C23.5355 31.8751 24.3749 32.7145 24.3749 33.75Z"
      />
    </Svg>
  );
};

export default QuestionBubbleIcon;
