import Geocode from 'data/google';

import { IMapLocation } from 'types/schoolBus.types';
import { IState } from 'types/resources.types';
import { IBusRoutePlan, IBusRoutePlanLocation } from 'types/schoolBus.types';

interface IMapLocationObject extends IMapLocation {
  address?: string;
  country?: string;
}

export const getLocationDetails = async (
  location: Partial<IBusRoutePlanLocation>,
  states: IState[],
  plan?: IBusRoutePlan,
) => {
  let newLocation: Omit<IBusRoutePlanLocation, 'id'> & { id?: string } = {
    id: location.id || undefined,
    isStop: !!location.isStop,
    lat: parseFloat((location.lat + '').substring(0, 9)),
    line1: location.line1 || '',
    line2: location.line2 || '',
    line3: location.line3 || '',
    lng: parseFloat((location.lng + '').substring(0, 10)),
    order:
      location.order !== undefined
        ? location.order
        : (plan?.locations || []).length || 0,
    routePlanID: location.routePlanID || plan?.id || '',
  };

  const geoData = await Geocode.fromLatLng(
    `${location.lat}`,
    `${location.lng}`,
  );

  if (geoData && geoData.results && geoData.results.length) {
    const addressComponents: {
      long_name: string;
      short_name: string;
      types: string[];
    }[] = geoData.results[0].address_components;

    const country = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('country');
    })?.short_name;

    const stateCode = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('administrative_area_level_1');
    })?.short_name;
    const state = states.find(({ abbr }) => abbr === stateCode)?.id;

    const city = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('locality');
    })?.short_name;

    const zipCode = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('postal_code');
    })?.short_name;

    const streetNumber = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('street_number');
    })?.short_name;

    const street = addressComponents.find((component) => {
      if (!component.types) return undefined;
      return component.types.includes('route');
    })?.short_name;

    const line1 = [streetNumber, street].filter((l) => !!l).join(' ');

    newLocation = { ...newLocation, line1, zipCode, city, state, country };
  }

  return newLocation;
};

export const parseLocationAddress = (location?: IMapLocationObject) => {
  if (!location) return '';

  const { line1, city, state, country, zipCode, lat, lng } = location;

  const stateTxt = [state, zipCode].filter((info) => !!info).join(' ');
  let address = [line1, city, stateTxt, country]
    .filter((info) => !!info)
    .join(', ');

  if (!address && location.address) {
    address = location.address;
  } else if (!address) {
    address = `[${lat}, ${lng}]`;
  }

  return address;
};
