import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { CalendarDateMapper } from '../../mappers/school.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiCalendarDate } from '../../types/schoolApi.types';
import { ICalendarDate } from 'types/school.types';
import { format } from 'date-fns';

interface IGetCalendarDatesArgs {
  id: string;
  startDate?: Date;
  endDate?: Date;
}

export default async ({
  id,
  startDate,
  endDate,
}: IGetCalendarDatesArgs): Promise<Adapter<ICalendarDate[]>> => {
  let url = `/schools/${id}/calendar?page_size=365`;

  if (startDate && endDate) {
    const start = format(startDate, 'yyyy-MM-dd');
    const end = format(endDate, 'yyyy-MM-dd');

    url = `/schools/${id}/calendar?page_size=365&start_date=${start}&end_date=${end}`;
  }

  return API.get<ArrayResponse<IApiCalendarDate>>(url)
    .then(({ data: response }) => {
      const dates = response.data.map(({ data }) => CalendarDateMapper(data));

      return { success: true, data: dates };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
