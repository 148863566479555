import { ThunkAction } from 'redux-thunk';
import { Row } from 'react-table';
import { RootState } from 'state/store';
import { IDateRange } from 'components/date-picker/datePicker.types';
import { ISurveyResponse, SurveyReportViewsEnum } from 'types/reports.types';

// Actions
export const SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_FAIL =
  'SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_FAIL';
export const SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_SUCCESS =
  'SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_SUCCESS';
export const SRVY_REPORT__HANDLE_DATE_RANGE_CHANGE =
  'SRVY_REPORT__HANDLE_DATE_RANGE_CHANGE';
export const SRVY_REPORT__LAUNCH_FAIL = 'SRVY_REPORT__LAUNCH_FAIL';
export const SRVY_REPORT__LAUNCH_SUCCESS = 'SRVY_REPORT__LAUNCH_SUCCESS';
export const SRVY_REPORT__SET_FILTERED_ROWS = 'SRVY_REPORT__SET_FILTERED_ROWS';
export const SRVY_REPORT__SET_IS_LOADING = 'SRVY_REPORT__SET_IS_LOADING';
export const SRVY_REPORT__SET_VIEW = 'SRVY_REPORT__SET_VIEW';
export const SRVY_REPORT__TOGGLE_IS_FILTER_ACTIVE =
  'SRVY_REPORT__TOGGLE_IS_FILTER_ACTIVE';
export const SRVY_REPORT__TOGGLE_IS_TOTAL_COMPLETED_SELECTED =
  'SRVY_REPORT__TOGGLE_IS_TOTAL_COMPLETED_SELECTED';
export const SRVY_REPORT__TOGGLE_IS_TOTAL_INCOMPLETED_SELECTED =
  'SRVY_REPORT__TOGGLE_IS_TOTAL_INCOMPLETED_SELECTED';

// Action types
export interface GetSurveyReportsInDateRangeFailAction {
  type: typeof SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_FAIL;
}

export interface GetSurveyReportsInDateRangeSuccessAction {
  type: typeof SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_SUCCESS;
  startDate: Date;
  endDate: Date;
}

export interface HandleDateRangeChangeAction {
  type: typeof SRVY_REPORT__HANDLE_DATE_RANGE_CHANGE;
  startDate: Date;
  endDate: Date;
}

export interface LaunchSurveyReportsFailAction {
  type: typeof SRVY_REPORT__LAUNCH_FAIL;
}

export interface LaunchSurveyReportsSuccessAction {
  type: typeof SRVY_REPORT__LAUNCH_SUCCESS;
  data: ISurveyResponse[];
}

export interface SetFilteredRowsAction {
  type: typeof SRVY_REPORT__SET_FILTERED_ROWS;
  filteredRows: Row<Record<string, unknown>>[];
}

export interface SetIsLoadingAction {
  type: typeof SRVY_REPORT__SET_IS_LOADING;
  bool: boolean;
}

export interface SetSurveyViewAction {
  type: typeof SRVY_REPORT__SET_VIEW;
  view: SurveyReportViewsEnum;
}

export interface ToggleIsFilterActiveAction {
  type: typeof SRVY_REPORT__TOGGLE_IS_FILTER_ACTIVE;
}

export interface ToggleIsTotalCompletedSelectedAction {
  type: typeof SRVY_REPORT__TOGGLE_IS_TOTAL_COMPLETED_SELECTED;
}

export interface ToggleIsTotalIncompletedSelectedAction {
  type: typeof SRVY_REPORT__TOGGLE_IS_TOTAL_INCOMPLETED_SELECTED;
}

export type SurveyReportsActionTypes =
  | GetSurveyReportsInDateRangeFailAction
  | GetSurveyReportsInDateRangeSuccessAction
  | HandleDateRangeChangeAction
  | LaunchSurveyReportsFailAction
  | LaunchSurveyReportsSuccessAction
  | SetFilteredRowsAction
  | SetIsLoadingAction
  | SetSurveyViewAction
  | ToggleIsFilterActiveAction
  | ToggleIsTotalCompletedSelectedAction
  | ToggleIsTotalIncompletedSelectedAction;

// Action handlers

export type TGetSurveyReportsInDateRange = (
  range: IDateRange,
) => ThunkAction<void, RootState, unknown, SurveyReportsActionTypes>;

export type THandleDateRangeChange = (
  range: IDateRange,
) => HandleDateRangeChangeAction;

export type TLaunchSurveyReports = () => ThunkAction<
  void,
  RootState,
  unknown,
  SurveyReportsActionTypes
>;

export type TSetFilteredRows = (
  filteredRows: Row<Record<string, unknown>>[],
) => SetFilteredRowsAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetSurveyView = (
  view: SurveyReportViewsEnum,
) => SetSurveyViewAction;

export type TToggleIsFilterActive = () => ToggleIsFilterActiveAction;

export type TToggleIsTotalCompletedSelected = () => ToggleIsTotalCompletedSelectedAction;

export type TToggleIsTotalIncompletedSelected = () => ToggleIsTotalIncompletedSelectedAction;
