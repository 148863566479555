import firebase from 'data/firebase';
import { IPerson } from './user-old.types';

// Alerts
export interface IAlertData {
  eventDate: firebase.firestore.Timestamp;
  eventID: string;
  eventType: string;
  organizationID: string;
  personID: string;
  personType?: 'staff' | 'student';
  surveyID: string;
  surveyResponses: { [questionID: string]: boolean };
  temperature?: { recordedValue: number };
}

export interface IMedicalAlert {
  alertData: IAlertData;
  userInfo: {
    type: string;
    user: IPerson;
  } | null;
}

// Health Cases
export interface IHealthCase {
  createdAt?: Date;
  dateReported: Date;
  firstName?: string;
  healthCaseIssueID: string;
  identifier: string;
  isQuarantined: boolean;
  isRecovered: boolean;
  lastName?: string;
  notes: string;
  organizationID?: string;
  personID?: string;
  personType?: string;
  quarantineEndDate: Date;
  quarantineStartDate: Date;
  recoveredDate?: Date | null;
  temperature: string;
  timeInQuarantine?: number;
  updatedAt?: Date;
  zip?: string;
}

export interface IHealthIssue {
  id: string;
  isActive: boolean;
  name: string;
}

export enum HealthCasesViewsEnum {
  students = 'students',
  overview = 'overview',
}
