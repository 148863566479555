import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';

const hostname = window.location.hostname;

export const IS_STAGE = {
  development: process.env.NODE_ENV === 'development',
  local: hostname.includes('localhost'),
  dev: hostname.includes('dev'),
  staging: hostname.includes('stage'),
  demo: hostname.includes('demo'),
  production:
    !hostname.includes('dev') &&
    !hostname.includes('stage') &&
    !hostname.includes('demo') &&
    (hostname.includes('safermgmt') || hostname.includes('schoolday')),
};

const firebaseConfigSandbox = {
  apiKey: 'AIzaSyDnFLy02K8m69O3ySJB98qgdu10f_7Dn7M',
  authDomain: 'safr-sandbox.firebaseapp.com',
  databaseURL: 'https://safr-sandbox.firebaseio.com',
  projectId: 'safr-sandbox',
  storageBucket: 'safr-sandbox.appspot.com',
  messagingSenderId: '364839686605',
  appId: '1:364839686605:web:bfddb80c3a43a50ff33484',
  measurementId: 'G-2MYT85ECDL',
};

const firebaseConfigProduction = {
  apiKey: 'AIzaSyDz3t8m0Pijiq0c77Bt9THsKtdx8mbDAjc',
  authDomain: 'safr-dcbff.firebaseapp.com',
  databaseURL: 'https://safr-dcbff.firebaseio.com',
  projectId: 'safr-dcbff',
  storageBucket: 'safr-dcbff.appspot.com',
  messagingSenderId: '302491434231',
  appId: '1:302491434231:web:12f75864c1c37d9b1327f2',
  measurementId: 'G-H8Y0RZG09Q',
};

export const HOSTS = {
  development: firebaseConfigSandbox,
  local: firebaseConfigSandbox,
  dev: firebaseConfigSandbox,
  staging: firebaseConfigProduction,
  demo: firebaseConfigSandbox,
  production: firebaseConfigProduction,
};

const hostIndex = Math.min(
  Object.values(IS_STAGE).findIndex((isStage) => isStage),
);
export const ENVIRONMENT_NAME = Object.keys(HOSTS)[hostIndex];
export const HOST = Object.values(HOSTS)[hostIndex];

const initFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(HOST);
  }
};

initFirebase();

const auth = firebase.auth();
const firestore = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
const analytics = firebase.analytics();

// active host's storage bucket will be used to fetch files from Storage, since we store only the file path for sensitive files and don't want to expose a public URL using getDownloadURL()
const storageBucket = Object.values(HOSTS)[hostIndex].storageBucket;

// ignoreUndefinedProperties tells Firestore to ignore undefined properties sending data, rather than have the request fail
firestore.settings({ ignoreUndefinedProperties: true, merge: true });

export {
  auth,
  firebase as default,
  firestore,
  functions,
  storage,
  storageBucket,
  analytics,
};
