import { Reducer } from 'redux';

import { IAttendance, IStudentAttendance } from 'types/attendance.types';
import { ISection, ISectionType } from 'types/section.types';
import { IStudent } from 'types/student.types';
import { SectionViewsEnum } from 'types/event.types';
import { IUser } from 'types/user.types';
import { IStaff } from 'types/staff.types';

import * as Actions from './SectionsActions.types';

export interface State {
  currentSectionID?: string;
  currentSection?: ISection;
  currentSectionPrimaryTeacher?: IUser;
  error: string;
  isLoading: boolean;
  sectionAttendances: IAttendance[];
  sections: ISection[];
  students: IStudent[];
  sectionView: string;
  sectionStudents: IStudentAttendance[];
  sectionsTeachers: IUser[];
  searchResults: IStaff[];
  searchText: string;
  sectionsTypes: ISectionType[];
  allUserStudents: {
    data: IUser[];
    links: {
      rel: string;
      uri: string;
    }[];
  };
}

const initialState: State = {
  sectionView: SectionViewsEnum.info,
  currentSectionID: undefined,
  currentSection: undefined,
  currentSectionPrimaryTeacher: undefined,
  error: '',
  isLoading: false,
  sectionAttendances: [],
  sections: [],
  students: [],
  sectionStudents: [],
  sectionsTeachers: [],
  searchResults: [],
  searchText: '',
  sectionsTypes: [],
  allUserStudents: {
    data: [],
    links: [],
  },
};

const SectionsReducer: Reducer<State, Actions.SectionsActionTypes> = (
  state: State = initialState,
  action: Actions.SectionsActionTypes,
) => {
  switch (action.type) {
    case Actions.SECTIONS__SET_ALL_USER_STUDENTS: {
      return {
        ...state,
        allUserStudents: action.allUserStudents,
      };
    }

    case Actions.SECTIONS__GET_ALL_USER_STUDENTS: {
      return {
        ...state,
        allUserStudents: action.allUserStudents,
      };
    }

    case Actions.SECTIONS__SET_SECTION_STUDENTS: {
      return {
        ...state,
        sectionStudents: action.students,
      };
    }

    case Actions.SECTIONS__UPDATE_CURRENT_SECTION: {
      return {
        ...state,
      };
    }

    case Actions.SECTIONS__SET_CURRENT_SECTION_PRIMARY_TEACHER: {
      return {
        ...state,
        currentSectionPrimaryTeacher: action.currentSectionPrimaryTeacher,
      };
    }

    case Actions.SECTIONS__SET_CURRENT_SECTION_OBJECT: {
      return {
        ...state,
        currentSection: action.currentSection,
      };
    }

    case Actions.RSRCS__GET_SECTIONS_TYPES: {
      return {
        ...state,
        sectionsTypes: action.sectionsTypes,
      };
    }

    case Actions.STAFF__CLEAR_SEARCH_TEXT: {
      return {
        ...state,
        searchResults: [],
        searchText: '',
      };
    }

    case Actions.STAFF__HANDLE_SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchResults: action.data,
        searchText: action.searchText,
      };
    }

    case Actions.SECTION__SET_VIEW: {
      return {
        ...state,
        sectionView: action.sectionView,
      };
    }

    case Actions.SECTIONS_GET_TEACHERS: {
      return {
        ...state,
        sectionsTeachers: action.sectionsTeachers,
      };
    }

    case Actions.SECTIONS__GET_SECTION_STUDENTS: {
      return {
        ...state,
        error: '',
        sectionStudents: action.students,
      };
    }

    case Actions.SECTIONS__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.SECTIONS__LAUNCH_SUCCESS: {
      return {
        ...state,
        currentSectionID: undefined,
        error: '',
        sectionAttendances: [],
        sections: action.sections,
      };
    }

    case Actions.SECTIONS__SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSectionID: action.sectionID,
        error: '',
        students: action.students,
      };
    }

    case Actions.SECTIONS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.SECTIONS__SET_IS_UPDATING: {
      return {
        ...state,
        isUpdating: action.bool,
      };
    }

    default:
      return state;
  }
};

export default SectionsReducer;
