import React from 'react';
import { Path, Svg } from './TrashIconStyles';

const TrashIcon: React.FC = () => {
  return (
    <Svg viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18148 0.65868H9.02563C9.73182 0.65868 10.3063 1.23319 10.3063 1.93934V2.76057C10.3063 2.90743 10.1872 3.02651 10.0404 3.02651H0.265986C0.119057 3.02651 0 2.90743 0 2.76057V1.93934C0 1.23317 0.57453 0.65868 1.28069 0.65868H3.12483V0.498752C3.12483 0.223736 3.34857 0 3.62359 0H6.68279C6.95778 0 7.18148 0.223736 7.18148 0.498752V0.65868ZM0.856622 4.0981H9.44979C9.68361 4.0981 9.87308 4.28765 9.87305 4.52142V10.9614C9.87305 12.0855 8.95851 13 7.83446 13H2.47181C1.3478 13 0.433258 12.0855 0.433258 10.9614V4.52142C0.433258 4.28763 0.622807 4.0981 0.856622 4.0981ZM3.3987 10.9703C3.68598 10.9703 3.91883 10.7375 3.91883 10.4503V6.47449C3.91883 6.18726 3.68596 5.95441 3.3987 5.95441C3.11149 5.95441 2.87858 6.18724 2.87858 6.47449V10.4503C2.87858 10.7375 3.11153 10.9703 3.3987 10.9703ZM7.42775 10.4503C7.42775 10.7375 7.1949 10.9703 6.90763 10.9703C6.62046 10.9703 6.38751 10.7375 6.38751 10.4503V6.47449C6.38751 6.18724 6.62041 5.95441 6.90763 5.95441C7.19488 5.95441 7.42775 6.18726 7.42775 6.47449V10.4503Z"
      />
    </Svg>
  );
};

export default TrashIcon;
