import React from 'react';

import { Container, Button, PageNumber } from './PaginationStyles';

export interface IPaginationProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  fetchNextPage?: () => void;
  gotoPage: ((updater: number) => void) | ((pageIndex: number) => number);
  loading?: boolean;
  pageCount: number;
  pageIndex: number;
  previousPage: () => void;
}

export const Pagination: React.FC<IPaginationProps> = ({
  canNextPage = false,
  canPreviousPage,
  fetchNextPage,
  gotoPage,
  loading = false,
  pageCount,
  pageIndex,
  previousPage,
}: IPaginationProps) => {
  const handleNext = async () => {
    if (fetchNextPage && pageCount === pageIndex + 1) await fetchNextPage();

    gotoPage(pageIndex + 1);
  };

  const handlePrevious = () => {
    if (previousPage) {
      previousPage();
      return;
    }
    gotoPage(pageIndex - 1);
  };

  const canGoNext = !loading && canNextPage;
  const canGoBack = !loading && canPreviousPage;
  return (
    <Container>
      <Button
        onClick={canGoBack ? handlePrevious : undefined}
        disabled={!canGoBack}
      >
        <i className="fas fa-caret-left" />
      </Button>
      <PageNumber>{pageIndex + 1}</PageNumber>
      <Button
        next
        onClick={canGoNext ? handleNext : undefined}
        disabled={!canGoNext}
      >
        <i className="fas fa-caret-right" />
      </Button>
    </Container>
  );
};
