import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IStaff } from 'types/staff.types';
import { IUser } from 'types/user.types';

// Actions
export const STAFF__CLEAR_CURRENT_STAFF = 'STAFF__CLEAR_CURRENT_STAFF';
export const STAFF__CLEAR_SEARCH_TEXT = 'STAFF__CLEAR_SEARCH_TEXT';
export const STAFF__EDIT_SUCCESS = 'STAFF__EDIT_SUCCESS';
export const STAFF__FAIL = 'STAFF__FAIL';
export const STAFF__GET_NEXT_BATCH_SUCCESS = 'STAFF__GET_NEXT_BATCH_SUCCESS';
export const STAFF__GET_NEXT_RESULTS_PAGE = 'STAFF__GET_NEXT_RESULTS_PAGE';
export const STAFF__HANDLE_SEARCH_TEXT_CHANGE =
  'STAFF__HANDLE_SEARCH_TEXT_CHANGE';
export const STAFF__LAUNCH_STAFF_VIEW = 'STAFF__LAUNCH_STAFF_VIEW';
export const STAFF__LAUNCH_STAFF_VIEW_FAIL = 'STAFF__LAUNCH_STAFF_VIEW_FAIL';
export const STAFF__LAUNCH_SUCCESS = 'STAFF__LAUNCH_SUCCESS';
export const STAFF__SET_IS_LOADING = 'STAFF__SET_IS_LOADING';
export const STAFF__SET_PAGE_SIZE = 'STAFF__SET_PAGE_SIZE';

// Actions types
export interface ClearCurrentStaffMemberAction {
  type: typeof STAFF__CLEAR_CURRENT_STAFF;
}

export interface ClearSearchTextAction {
  type: typeof STAFF__CLEAR_SEARCH_TEXT;
}

export interface EditStaffAction {
  type: typeof STAFF__EDIT_SUCCESS;
}

export interface GetNextMembersBatchSuccess {
  data: IStaff[];
  next?: string;
  type: typeof STAFF__GET_NEXT_BATCH_SUCCESS;
}

export interface GetNextResultsPageSuccess {
  data: IStaff[];
  next?: string;
  type: typeof STAFF__GET_NEXT_RESULTS_PAGE;
}

export interface HandleSearchInputChangeAction {
  data: IStaff[];
  next?: string;
  searchText: string;
  type: typeof STAFF__HANDLE_SEARCH_TEXT_CHANGE;
}

export interface LaunchStaffMemberFailAction {
  type: typeof STAFF__LAUNCH_STAFF_VIEW_FAIL;
}

export interface LaunchStaffMemberSuccessAction {
  data: IStaff | null;
  type: typeof STAFF__LAUNCH_STAFF_VIEW;
}

export interface LaunchStaffSelectionSuccessAction {
  data: IStaff[];
  next?: string;
  pageSize: number;
  type: typeof STAFF__LAUNCH_SUCCESS;
}

export interface SetIsLoadingAction {
  isLoading: boolean;
  type: typeof STAFF__SET_IS_LOADING;
}

interface SetPageSizeAction {
  type: typeof STAFF__SET_PAGE_SIZE;
  size: number;
}

export interface StaffSelectionFailAction {
  type: typeof STAFF__FAIL;
}

export type StaffActions =
  | ClearCurrentStaffMemberAction
  | ClearSearchTextAction
  | EditStaffAction
  | GetNextMembersBatchSuccess
  | GetNextResultsPageSuccess
  | HandleSearchInputChangeAction
  | LaunchStaffMemberFailAction
  | LaunchStaffMemberSuccessAction
  | LaunchStaffSelectionSuccessAction
  | SetIsLoadingAction
  | SetPageSizeAction
  | StaffSelectionFailAction;

// Action handlers
export type TCheckForMoreMembers = (
  lastRetrievedName: string,
) => ThunkAction<void, RootState, unknown, StaffActions>;

export type TClearCurrentStaffMember = () => ClearCurrentStaffMemberAction;

export type TClearSearchText = () => ClearSearchTextAction;

export type TEditStaff = (
  staff: Partial<IStaff> & { id: string },
) => ThunkAction<
  Promise<{ success: boolean; data?: IUser }>,
  RootState,
  unknown,
  StaffActions
>;

export type TGetNextPage = (
  newPageSize?: number,
) => ThunkAction<void, RootState, unknown, StaffActions>;

export type THandleSearchInputChange = (
  searchText: string,
) => ThunkAction<void, RootState, unknown, StaffActions>;

export type TLaunchStaffMember = (args: {
  id: string;
}) => ThunkAction<void, RootState, unknown, StaffActions>;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TSetPageSize = (
  size: number,
) => ThunkAction<void, RootState, unknown, StaffActions>;

export type TStaffThunkAction = () => ThunkAction<
  void,
  RootState,
  unknown,
  StaffActions
>;
