import API from 'data/axios';
import { Adapter } from 'data/types/api.types';
import { parseError } from 'utils/errors.utils';

interface IDeleteAttendanceArgs {
  attendanceID: string;
}

export default async ({
  attendanceID,
}: IDeleteAttendanceArgs): Promise<Adapter> =>
  API.delete(`/attendances/${attendanceID}`)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
