import axios from 'axios';
import { setAuthToken } from 'data/utils/authToken.utils';

import { parseError } from 'utils/errors.utils';

import { Adapter, ErrorResponse, Response } from '../../types/api.types';
import { auth } from 'data/firebase';

interface ILoginUserArgs {
  email: string;
  password: string;
}

const BASE_URL = process.env.REACT_APP_API_HOST;

export default async ({ email, password }: ILoginUserArgs): Promise<Adapter> =>
  axios
    .post<Response<{ auth_token: string }> | ErrorResponse>(
      '/auth-token',
      { email, password },
      { baseURL: `${BASE_URL}` },
    )
    .then(({ data: response }) => {
      const { error } = response as ErrorResponse;
      const { data } = response as Response<{ auth_token: string }>;

      if (error || !data) {
        throw error;
      }

      setAuthToken(data.auth_token);

      return { success: true };
    })
    .catch((error) => {
      const stringError = parseError(
        error,
        'An error occured. Please try again.',
      );

      return { success: false, error: stringError };
    });
