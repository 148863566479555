import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IconEnum,
  IScreeningConfig,
  ISurvey,
  SurveyRolesEnum,
  SurveyViewsEnum,
  TQuestion,
} from 'types/surveys.types';

// Actions
export const SURVEYS__ADD_QUESTION = 'SURVEYS__ADD_QUESTION';
export const SURVEYS__ADD_SURVEY_FAIL = 'SURVEYS__ADD_SURVEY_FAIL';
export const SURVEYS__ADD_SURVEY_SUCCESS = 'SURVEYS__ADD_SURVEY_SUCCESS';
export const SURVEYS__DELETE_QUESTION = 'SURVEYS__DELETE_QUESTION';
export const SURVEYS__DELETE_SURVEY_FAIL = 'SURVEYS__DELETE_SURVEY_FAIL';
export const SURVEYS__DELETE_SURVEY_SUCCESS = 'SURVEYS__DELETE_SURVEY_SUCCESS';
export const SURVEYS__GET_ALL_SURVEYS_SUCCESS =
  'SURVEYS__GET_ALL_SURVEYS_SUCCESS';
export const SURVEYS__GET_SURVEY_QUESTIONS = 'SURVEYS__GET_SURVEY_QUESTIONS';
export const SURVEYS__GET_SURVEY_SUCCESS = 'SURVEYS__GET_SURVEY_SUCCESS';
export const SURVEYS__GET_SURVEYS_FAIL = 'SURVEYS__GET_SURVEYS_FAIL';
export const SURVEYS__HANDLE_HEADER_TEXT_INPUT_CHANGE =
  'SURVEYS__HANDLE_HEADER_TEXT_INPUT_CHANGE';
export const SURVEYS__HANDLE_NAME_INPUT_CHANGE =
  'SURVEYS__HANDLE_NAME_INPUT_CHANGE';
export const SURVEYS__HANDLE_NEEDS_UNIQUE_NAME =
  'SURVEYS__HANDLE_NEEDS_UNIQUE_NAME';
export const SURVEYS__LAUNCH_NEW_SURVEY_FAIL =
  'SURVEYS__LAUNCH_NEW_SURVEY_FAIL';
export const SURVEYS__LAUNCH_NEW_SURVEY_SUCCESS =
  'SURVEYS__LAUNCH_NEW_SURVEY_SUCCESS';
export const SURVEYS__LAUNCH_SURVEY_SELECTION =
  'SURVEYS__LAUNCH_SURVEY_SELECTION';
export const SURVEYS__ON_QUESTION_CHANGE = 'SURVEYS__ON_QUESTION_CHANGE';
export const SURVEYS__SAVE = 'SURVEYS__SAVE';
export const SURVEYS__SELECT_QUESTION = 'SURVEYS__SELECT_QUESTION';
export const SURVEYS__SET_ACTIVE_SURVEY_ID = 'SURVEYS__SET_ACTIVE_SURVEY_ID';
export const SURVEYS__SET_CURRENT_QUESTION_SEQUENCE =
  'SURVEYS__SET_CURRENT_QUESTION_SEQUENCE';
export const SURVEYS__SET_HAS_WRITE_ACCESS = 'SURVEYS__SET_HAS_WRITE_ACCESS';
export const SURVEYS__SET_IS_LOADING = 'SURVEYS__SET_IS_LOADING';
export const SURVEYS__SET_QUESTION_UNDER_EDIT =
  'SURVEYS__SET_QUESTION_UNDER_EDIT';
export const SURVEYS__SET_SHOW_CONFIRM_DELETE_SURVEY_MODAL =
  'SURVEYS__SET_SHOW_CONFIRM_DELETE_SURVEY_MODAL';
export const SURVEYS__SET_SHOW_NEEDS_UNIQUE_NAME_MODAL =
  'SURVEYS__SET_SHOW_NEEDS_UNIQUE_NAME_MODAL';
export const SURVEYS__SET_SHOW_SAVE_ERROR_MODAL =
  'SURVEYS__SET_SHOW_SAVE_ERROR_MODAL';
export const SURVEYS__SET_SHOW_SAVE_MODAL = 'SURVEYS__SET_SHOW_SAVE_MODAL';
export const SURVEYS__SET_SHOW_TITLE_REQUIRED_MODAL =
  'SURVEYS__SET_SHOW_TITLE_REQUIRED_MODAL';
export const SURVEYS__SET_SURVEY_ID_UNDER_EDIT =
  'SURVEYS__SET_SURVEY_ID_UNDER_EDIT';
export const SURVEYS__SET_SURVEY_VIEW = 'SURVEYS__SET_SURVEY_VIEW';
export const SURVEYS__TOGGLE_QUESTION_VISIBILITY =
  'SURVEYS__TOGGLE_QUESTION_VISIBILITY';
export const SURVEYS__TOGGLE_SURVEY_VISIBILITY_FAIL =
  'SURVEYS__TOGGLE_SURVEY_VISIBILITY_FAIL';
export const SURVEYS__TOGGLE_SURVEY_VISIBILITY_SUCCESS =
  'SURVEYS__TOGGLE_SURVEY_VISIBILITY_SUCCESS';
export const SURVEYS__TOGGLE_VISIBILITY = 'SURVEYS__TOGGLE_VISIBILITY';
export const SURVEYS__UPDATE_QUESTION = 'SURVEYS__UPDATE_QUESTION';
export const SURVEYS__UPDATE_SCREENING_CONFIG =
  'SURVEYS__UPDATE_SCREENING_CONFIG';
export const SURVEYS__UPDATE_SURVEY_FAIL = 'SURVEYS__UPDATE_SURVEY_FAIL';
export const SURVEYS__UPDATE_SURVEY_SUCCESS = 'SURVEYS__UPDATE_SURVEY_SUCCESS';

// Action Types
export interface AddQuestionAction {
  type: typeof SURVEYS__ADD_QUESTION;
  questionIndex: number;
}

export interface AddSurveyFailAction {
  type: typeof SURVEYS__ADD_SURVEY_FAIL;
  errorMessage: string;
}

export interface AddSurveySuccessAction {
  type: typeof SURVEYS__ADD_SURVEY_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export interface DeleteQuestionAction {
  type: typeof SURVEYS__DELETE_QUESTION;
  questionSequence: number;
}

export interface DeleteSurveyFailAction {
  type: typeof SURVEYS__DELETE_SURVEY_FAIL;
  errorMessage: string;
}

export interface DeleteSurveySuccessAction {
  type: typeof SURVEYS__DELETE_SURVEY_SUCCESS;
  id: string;
}

export interface GetAllSurveysSuccessAction {
  type: typeof SURVEYS__GET_ALL_SURVEYS_SUCCESS;
  data: ISurvey[];
}

export interface GetSurveyQuestionsFailAction {
  type: typeof SURVEYS__GET_SURVEY_QUESTIONS;
  errorMessage: string;
}

export interface GetSurveyQuestionsSuccessAction {
  type: typeof SURVEYS__GET_SURVEY_SUCCESS;
  data: ISurvey;
}

export interface GetSurveysFailAction {
  type: typeof SURVEYS__GET_SURVEYS_FAIL;
  errorMessage: string;
}

export interface HandleHeaderTextInputChangeAction {
  type: typeof SURVEYS__HANDLE_HEADER_TEXT_INPUT_CHANGE;
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

export interface HandleNameInputChangeAction {
  type: typeof SURVEYS__HANDLE_NAME_INPUT_CHANGE;
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

export interface HandleNeedsUniqueNameAction {
  type: typeof SURVEYS__HANDLE_NEEDS_UNIQUE_NAME;
}

export interface IdAction {
  type:
    | typeof SURVEYS__SET_SURVEY_ID_UNDER_EDIT
    | typeof SURVEYS__TOGGLE_VISIBILITY;
  id: string;
}

export interface LaunchNewSurveyFailAction {
  type: typeof SURVEYS__LAUNCH_NEW_SURVEY_FAIL;
}

export interface LaunchNewSurveySuccessAction {
  type: typeof SURVEYS__LAUNCH_NEW_SURVEY_SUCCESS;
}

export interface LaunchSurveySelectionAction {
  type: typeof SURVEYS__LAUNCH_SURVEY_SELECTION;
}

export interface OnQuestionChangeAction {
  type: typeof SURVEYS__ON_QUESTION_CHANGE;
  e: React.ChangeEvent<HTMLInputElement>;
}

export interface SaveAction {
  type: typeof SURVEYS__SAVE;
}

export interface SelectQuestionAction {
  type: typeof SURVEYS__SELECT_QUESTION;
  questionSequence: number | null;
  questionText: string;
}

export interface SetActiveSurveyId {
  type: typeof SURVEYS__SET_ACTIVE_SURVEY_ID;
  activeSurveyId: string | null;
}

export interface SetCurrentQuestionSequenceAction {
  type: typeof SURVEYS__SET_CURRENT_QUESTION_SEQUENCE;
  questionIndex: number | null;
}

export interface SetHasWriteAccessAction {
  hasWriteAccess: boolean;
  type: typeof SURVEYS__SET_HAS_WRITE_ACCESS;
}

export interface SetIsLoadingAction {
  type: typeof SURVEYS__SET_IS_LOADING;
  bool: boolean;
}

export interface SetQuestionUnderEditAction {
  type: typeof SURVEYS__SET_QUESTION_UNDER_EDIT;
  value: string;
}

export interface SetShowConfirmDeleteSurveyModal {
  type: typeof SURVEYS__SET_SHOW_CONFIRM_DELETE_SURVEY_MODAL;
  bool: boolean;
}

export interface SetShowNeedsUniqueNameModalAction {
  type: typeof SURVEYS__SET_SHOW_NEEDS_UNIQUE_NAME_MODAL;
  bool: boolean;
}

export interface SetShowSaveErrorModalAction {
  type: typeof SURVEYS__SET_SHOW_SAVE_ERROR_MODAL;
  bool: boolean;
}

export interface SetShowSaveModalAction {
  type: typeof SURVEYS__SET_SHOW_SAVE_MODAL;
  bool: boolean;
}

export interface SetShowTitleRequiredModalAction {
  type: typeof SURVEYS__SET_SHOW_TITLE_REQUIRED_MODAL;
  bool: boolean;
}

export interface SetSurveyViewAction {
  type: typeof SURVEYS__SET_SURVEY_VIEW;
  view: SurveyViewsEnum;
}

export interface ToggleQuestionVisibilityAction {
  type: typeof SURVEYS__TOGGLE_QUESTION_VISIBILITY;
  questionSequence: number;
}

export interface ToggleSurveyVisibilityFailAction {
  type: typeof SURVEYS__TOGGLE_SURVEY_VISIBILITY_FAIL;
  errorMessage: string;
}

export interface ToggleSurveyVisibilitySuccessAction {
  type: typeof SURVEYS__TOGGLE_SURVEY_VISIBILITY_SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export interface UpdateQuestionAction {
  type: typeof SURVEYS__UPDATE_QUESTION;
}

export interface UpdateScreeningConfig {
  configType: string;
  updateType: IconEnum | SurveyRolesEnum;
  type: typeof SURVEYS__UPDATE_SCREENING_CONFIG;
}

export interface UpdateSurveyFailAction {
  type: typeof SURVEYS__UPDATE_SURVEY_FAIL;
  errorMessage: string;
}

export interface UpdateSurveySuccessAction {
  type: typeof SURVEYS__UPDATE_SURVEY_SUCCESS;
}

export type SurveysActionTypes =
  | AddQuestionAction
  | AddSurveyFailAction
  | AddSurveySuccessAction
  | DeleteQuestionAction
  | DeleteSurveyFailAction
  | DeleteSurveySuccessAction
  | GetAllSurveysSuccessAction
  | GetSurveyQuestionsFailAction
  | GetSurveyQuestionsSuccessAction
  | GetSurveysFailAction
  | HandleHeaderTextInputChangeAction
  | HandleNameInputChangeAction
  | HandleNeedsUniqueNameAction
  | IdAction
  | LaunchNewSurveyFailAction
  | LaunchNewSurveySuccessAction
  | LaunchSurveySelectionAction
  | OnQuestionChangeAction
  | SaveAction
  | SelectQuestionAction
  | SetActiveSurveyId
  | SetCurrentQuestionSequenceAction
  | SetHasWriteAccessAction
  | SetIsLoadingAction
  | SetQuestionUnderEditAction
  | SetShowConfirmDeleteSurveyModal
  | SetShowNeedsUniqueNameModalAction
  | SetShowSaveErrorModalAction
  | SetShowSaveModalAction
  | SetShowTitleRequiredModalAction
  | SetSurveyViewAction
  | ToggleQuestionVisibilityAction
  | ToggleSurveyVisibilityFailAction
  | ToggleSurveyVisibilitySuccessAction
  | UpdateQuestionAction
  | UpdateScreeningConfig
  | UpdateSurveyFailAction
  | UpdateSurveySuccessAction;

// Action Handlers
export type TAddNewSurvey = (args: {
  organizationID: string;
  name: string;
  header: string;
  questions: TQuestion[];
  active: boolean;
  checkInConfig: IScreeningConfig;
  checkOutConfig: IScreeningConfig;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TAddQuestion = (questionIndex: number) => AddQuestionAction;

export type TDeleteQuestion = (
  questionSequence: number,
) => DeleteQuestionAction;

export type TDeleteSurvey = (args: {
  id: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TGetAllSurveys = (args: {
  organizationID: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TGetSurvey = (args: {
  surveyId: string;
  organizationID: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type THandleNeedsUniqueName = () => HandleNeedsUniqueNameAction;

export type TLaunchNewSurvey = (args: {
  organizationID: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TLaunchSurveySelection = (args: {
  organizationID: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TOnHeaderTextInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => HandleHeaderTextInputChangeAction;

export type TOnNameInputChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => HandleNameInputChangeAction;

export type TOnQuestionChange = (
  e: React.ChangeEvent<HTMLInputElement>,
) => OnQuestionChangeAction;

export type TSaveSurveyUpdates = (
  onSuccess?: (() => void) | undefined,
) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TSelectQuestion = (
  questionSequence: number | null,
  questionText: string,
) => SelectQuestionAction;

export type TSetActiveSurveyId = (
  activeSurveyId: string | null,
) => SetActiveSurveyId;

export type TSetCurrentQuestionSequence = (
  questionIndex: number | null,
) => SetCurrentQuestionSequenceAction;

export type TSetHasWriteAccess = (
  hasWriteAccess: boolean,
) => SetHasWriteAccessAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetQuestionUnderEdit = (
  value: string,
) => SetQuestionUnderEditAction;

export type TSetShowConfirmDeleteSurveyModal = (
  bool: boolean,
) => SetShowConfirmDeleteSurveyModal;

export type TSetShowNeedsUniqueNameModal = (
  bool: boolean,
) => SetShowNeedsUniqueNameModalAction;

export type TSetShowSaveErrorModal = (
  bool: boolean,
) => SetShowSaveErrorModalAction;

export type TSetShowSaveModal = (bool: boolean) => SetShowSaveModalAction;

export type TSetShowTitleRequiredModal = (
  bool: boolean,
) => SetShowTitleRequiredModalAction;

export type TSetSurveyIdUnderEdit = (id: string) => SurveysActionTypes;

export type TSetSurveyView = (view: SurveyViewsEnum) => SetSurveyViewAction;

export type TToggleQuestionVisibility = (
  questionSequence: number,
) => ToggleQuestionVisibilityAction;

export type TToggleSurveyVisibility = (
  id: string,
) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;

export type TUpdateQuestion = () => UpdateQuestionAction;

export type TUpdateScreeningConfig = (
  configType: string,
  updateType: IconEnum | SurveyRolesEnum,
) => UpdateScreeningConfig;

export type TUpdateSurvey = (args: {
  survey: ISurvey;
  organizationID: string;
}) => ThunkAction<void, RootState, unknown, SurveysActionTypes>;
