import API from 'data/axios';
import { oauthDistrictMapper } from '../../mappers/district.mappers';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiOauthDistrict } from 'data/types/districtsApi.types';
import { IOauthDistrict } from 'types/district.types';

type GetOauthDistrictArgs = {
  searchKeyword?: string;
  provider: string;
};

export default async ({
  searchKeyword,
  provider,
}: GetOauthDistrictArgs): Promise<Adapter<IOauthDistrict[]>> =>
  API.get<ArrayResponse<IApiOauthDistrict>>(`/oauth/districts`, {
    params: {
      name_filter: searchKeyword,
      provider,
    },
  })
    .then(({ data: response }) => {
      const districts = response.data.map(({ data }) =>
        oauthDistrictMapper(data),
      );
      return { success: true, data: districts };
    })
    .catch((err) => {
      const stringError = parseError(
        err,
        'Something went wrong with getting districts',
      );
      return { error: stringError, success: false };
    });
