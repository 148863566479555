import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  ICardConfig,
  IGenerateCardData,
  IFailedUser,
  ICard,
} from 'types/idCards.types';
import { IUser, UserRolesEnum } from 'types/user.types';

export interface IAddress {
  line1: string;
  line2: string;
}

// Actions
export const IDS__CLEAR_FAILED_USERS = 'IDS__CLEAR_FAILED_USERS';
export const IDS__DESELECT_ALL = 'IDS__DESELECT_ALL';
export const IDS__GENERATION_FAIL = 'IDS__GENERATION_FAIL';
export const IDS__GENERATION_SUCCESS = 'IDS__GENERATION_SUCCESS';
export const IDS__LAUNCH_SUCCESS = 'IDS__LAUNCH_SUCCESS';
export const IDS__RESET = 'IDS__RESET';
export const IDS__SELECT_SEARCH_RESULT = 'IDS__SELECT_SEARCH_RESULT';
export const IDS__SET_CURSOR = 'IDS__SET_CURSOR';
export const IDS__SET_HAS_RECENTLY_FAILED = 'IDS__SET_HAS_RECENTLY_FAILED';
export const IDS__SET_HAS_RECENTLY_SUCCEEDED =
  'IDS__SET_HAS_RECENTLY_SUCCEEDED';
export const IDS__SET_IS_LOADING = 'IDS__SET_IS_LOADING';
export const IDS__SET_IS_PERFORMING_ACTION = 'IDS__SET_IS_PERFORMING_ACTION';
export const IDS__SET_ROLE_FILTER = 'IDS__SET_ROLE_FILTER';
export const IDS__SET_SEARCH_TEXT = 'IDS__SET_SEARCH_TEXT';
export const IDS__TOGGLE_PERSON_RESULT_SELECTION =
  'IDS__TOGGLE_PERSON_RESULT_SELECTION';
export const IDS__TOGGLE_SELECTION = 'IDS__TOGGLE_SELECTION';
export const IDS__UPDATE_CONFIG = 'IDS__UPDATE_CONFIG';

// Actions types
export interface ClearFailedUsersAction {
  type: typeof IDS__CLEAR_FAILED_USERS;
}

export interface DeselectAllAction {
  type: typeof IDS__DESELECT_ALL;
}

export interface GenerateDigitalCardsFailAction {
  digitalIds?: IGenerateCardData[];
  failedUsers?: IFailedUser[];
  type: typeof IDS__GENERATION_FAIL;
}

export interface GenerateDigitalIdCardsSuccessAction {
  digitalIds: IGenerateCardData[];
  type: typeof IDS__GENERATION_SUCCESS;
}

export interface LaunchIdCardsSuccessAction {
  config: ICardConfig[];
  type: typeof IDS__LAUNCH_SUCCESS;
  schoolYear: string | undefined;
  schoolAddress: IAddress;
}

export interface ResetAllAction {
  type: typeof IDS__RESET;
}

export interface SelectSearchResultAction {
  newCurrentPerson: IUser | null;
  type: typeof IDS__SELECT_SEARCH_RESULT;
}

export interface SetCursorAction {
  cursor?: string;
  type: typeof IDS__SET_CURSOR;
}

export interface SetHasRecentlyFailedAction {
  bool: boolean;
  type: typeof IDS__SET_HAS_RECENTLY_FAILED;
}

export interface SetHasRecentlySuceededAction {
  bool: boolean;
  type: typeof IDS__SET_HAS_RECENTLY_SUCCEEDED;
}

export interface SetIsLoadingAction {
  type: typeof IDS__SET_IS_LOADING;
  bool: boolean;
}

export interface SetIsPerformingAction {
  isPerformingAction: boolean;
  type: typeof IDS__SET_IS_PERFORMING_ACTION;
}

export interface SetRoleFilterAction {
  role: UserRolesEnum;
  type: typeof IDS__SET_ROLE_FILTER;
}

export interface SetSearchTextAction {
  text: string;
  type: typeof IDS__SET_SEARCH_TEXT;
}

export interface ToggleResultSelectionAction {
  id: string;
  type: typeof IDS__TOGGLE_PERSON_RESULT_SELECTION;
}

interface ToggleSelectionAction {
  type: typeof IDS__TOGGLE_SELECTION;
  AllUsers: IUser[];
}

interface UpdateConfigAction {
  config: ICardConfig[];
  type: typeof IDS__UPDATE_CONFIG;
}

export type IdCardsActionTypes =
  | ClearFailedUsersAction
  | DeselectAllAction
  | GenerateDigitalCardsFailAction
  | GenerateDigitalIdCardsSuccessAction
  | LaunchIdCardsSuccessAction
  | ResetAllAction
  | SelectSearchResultAction
  | SetCursorAction
  | SetHasRecentlyFailedAction
  | SetHasRecentlySuceededAction
  | SetIsLoadingAction
  | SetIsPerformingAction
  | SetRoleFilterAction
  | SetSearchTextAction
  | ToggleResultSelectionAction
  | ToggleSelectionAction
  | UpdateConfigAction;

// Action handlers

export type TClearFailedUsers = () => ClearFailedUsersAction;

export type TDeselectAll = () => DeselectAllAction;

export type TGenerateCards = (args: {
  isStudent: boolean;
  people: IUser[];
  printable?: boolean;
  schoolID: string;
}) => Promise<{
  error?: string;
  failedUsersList?: IFailedUser[];
  idCards?: IGenerateCardData[];
  success: boolean;
}>;

export type TGenerateDigitalCards = (args: {
  people: IUser[];
}) => ThunkAction<void, RootState, unknown, IdCardsActionTypes>;

export type TGeneratePrintableCards = (args: {
  people: IUser[];
}) => ThunkAction<
  Promise<{ idCards: ICard[] } | undefined>,
  RootState,
  unknown,
  IdCardsActionTypes
>;

export type THandleSearchInputChange = (args: {
  searchText: string;
}) => ThunkAction<void, RootState, unknown, IdCardsActionTypes>;

export type TIdCardsThunkAction = () => ThunkAction<
  void,
  RootState,
  unknown,
  IdCardsActionTypes
>;

export type TResetAll = () => ResetAllAction;

export type TSelectSearchResult = (
  newCurrentPerson: IUser | null,
) => SelectSearchResultAction;

export type TSetCursor = (cursor?: string) => SetCursorAction;

export type TSetHasRecentlyFailed = (
  bool: boolean,
) => SetHasRecentlyFailedAction;

export type TSetHasRecentlySucceeded = (
  bool: boolean,
) => SetHasRecentlySuceededAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetIsPerformingAction = (
  isPerformingAction: boolean,
) => SetIsPerformingAction;

export type TSetRoleFilter = (role: UserRolesEnum) => SetRoleFilterAction;

export type TSetSearchText = (txt: string) => SetSearchTextAction;

export type TTogglePersonResultSelection = (
  id: string,
) => ToggleResultSelectionAction;

export type TToggleSelection = (AllUsers: IUser[]) => ToggleSelectionAction;

export type TUpdateCardConfig = (args: {
  config: FormData;
}) => ThunkAction<void, RootState, unknown, IdCardsActionTypes>;
