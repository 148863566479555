import API from 'data/axios';

import { AttendanceStatusMapper } from '../../mappers/attendace.mappers';
import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiAttendanceStatus } from '../../types/attendanceApi.types';
import { IAttendanceStatus } from 'types/attendance.types';

interface Args {
  schoolID?: string;
  type?: string;
  all?: boolean;
}

export default async ({
  schoolID,
  type,
  all = false,
}: Args): Promise<Adapter<IAttendanceStatus[]>> => {
  const args = [];
  if (schoolID) args.push(`school_id=${schoolID}`);
  if (type) args.push(`type=${type}`);
  if (all) args.push(`all=1`);

  const queries = args.length ? `?${args.join('&')}` : '';

  return API.get<ArrayResponse<IApiAttendanceStatus>>(
    `/attendances/statuses${queries}`,
  )
    .then(({ data: response }) => {
      const statuses = response.data.map(({ data }) =>
        AttendanceStatusMapper(data),
      );

      return { success: true, data: statuses };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
