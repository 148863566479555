import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IVideoTutorial } from 'types/videoTutorial.types';
import firebase from 'firebase/app';

// Action Types
export const VIDEOS__FETCH_SUCCESS = 'VIDEOS__FETCH_SUCCESS';
export const VIDEOS__SET_WATCHED = 'VIDEOS__SET_WATCHED';
export const VIDEOS__SET_IS_LOADING = 'VIDEOS__SET_IS_LOADING';
export const VIDEOS__SET_IS_LOADING_MORE = 'VIDEOS__SET_IS_LOADING_MORE';
export const VIDEOS__SET_SELECTED_VIDEO = 'VIDEOS__SET_SELECTED_VIDEO';
export const VIDEOS__RESET = 'VIDEOS__RESET';

interface LaunchVideosSuccessAction {
  data: IVideoTutorial[];
  nextPage?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
  allFetched?: boolean;
  appendData?: boolean;
  type: typeof VIDEOS__FETCH_SUCCESS;
}

interface SetVideoWatchedAction {
  video: IVideoTutorial;
  type: typeof VIDEOS__SET_WATCHED;
}

interface SetIsLoadingAction {
  type: typeof VIDEOS__SET_IS_LOADING;
  bool: boolean;
}

interface setSelectedVideoAction {
  type: typeof VIDEOS__SET_SELECTED_VIDEO;
  video: IVideoTutorial | null;
}

interface SetIsLoadingMoreAction {
  type: typeof VIDEOS__SET_IS_LOADING_MORE;
  bool: boolean;
}

interface ResetAction {
  type: typeof VIDEOS__RESET;
}

export type VideosActions =
  | LaunchVideosSuccessAction
  | SetIsLoadingAction
  | SetIsLoadingMoreAction
  | setSelectedVideoAction
  | SetVideoWatchedAction
  | ResetAction;

export type TFetchVideos = (args: {
  from?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
  userRole: string;
  appendData?: boolean;
}) => ThunkAction<void, RootState, unknown, VideosActions>;

export type TSetVideoAsWatched = (args: {
  video: IVideoTutorial;
}) => ThunkAction<void, RootState, unknown, VideosActions>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetSelectedVideo = (
  video: IVideoTutorial | null,
) => setSelectedVideoAction;

export type TSetIsLoadingMore = (bool: boolean) => SetIsLoadingMoreAction;

export type TReset = () => ResetAction;
