import { Reducer } from 'redux';

import { ICheckpointEvent, ICheckpointLocation } from 'types/checkpoint.types';
import * as Actions from './CheckpointActions.types';

/* Buses & Bus Alerts */

export interface State {
  events: ICheckpointEvent[];
  isLoading: boolean;
  isPrintingOn: boolean;
  locations: ICheckpointLocation[];
  next?: string;
  pageSize: number;
  selectedDate: Date;
  selectedLocationID?: string;
}

const today = new Date();
const initialState: State = {
  events: [],
  isLoading: false,
  isPrintingOn: true,
  locations: [],
  pageSize: 50,
  selectedDate: today,
};

const CheckpointReducer: Reducer<State, Actions.CheckpointActionTypes> = (
  state: State = initialState,
  action: Actions.CheckpointActionTypes,
) => {
  switch (action.type) {
    case Actions.CHCKPNT__LAUNCH_LOCATIONS: {
      return {
        ...state,
        locations: action.locations,
      };
    }

    case Actions.CHCKPNT__GET_NEXT_SUCCESS: {
      return {
        ...state,
        events: [...state.events, ...action.data],
        next: action.next,
      };
    }

    case Actions.CHCKPNT__SELECT_LOCATION: {
      return {
        ...state,
        events: action.events,
        selectedLocationID: action.id,
        next: action.next,
      };
    }

    case Actions.CHCKPNT__SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.date,
      };
    }

    case Actions.CHCKPNT__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.CHCKPNT__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.CHCKPNT__ADD_ATTENDANCE: {
      return {
        ...state,
        events: [action.newRecord, ...state.events],
      };
    }

    case Actions.CHCKPNT__SET_IS_PRINTING_ON: {
      return {
        ...state,
        isPrintingOn: action.isPrintingOn,
      };
    }

    case Actions.CHCKPNT__RESET: {
      return initialState;
    }

    default:
      return state;
  }
};

export default CheckpointReducer;
