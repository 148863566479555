import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../../types/api.types';
import { IBusResource } from 'types/schoolBus.types';
import { IApiBusResource } from 'data/types/schoolBusApi.types';

export default async (): Promise<Adapter<IBusResource[]>> =>
  API.get<ArrayResponse<IApiBusResource>>(`/bus/route_instances/message_types`)
    .then(({ data: response }) => {
      const types = response.data.map(({ data }) => ({
        name: data.name,
        id: data.id,
        key: data.key,
      }));

      return { success: true, data: types };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
