import React from 'react';
import Triangle from './components/triangle/Triangle';
import { IndicatorProps, OptionTypeBase } from 'react-select';

interface Props extends IndicatorProps<OptionTypeBase, boolean> {
  fill?: string;
}

const DropdownIndicator: React.FC<Props> = ({ fill, ...props }: Props) => {
  const { selectProps } = props || {};
  const { isMenuOpen } = selectProps || {};
  return <Triangle down={isMenuOpen} isActive={isMenuOpen} color={fill} />;
};

export default DropdownIndicator;
