import { IUpdateSectionArgs } from 'data/adapters/sections/updateSection';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IStudentAttendance, PartialAttendance } from 'types/attendance.types';
import { ICurrentSchoolData } from 'types/resources.types';
import { ISection, ISectionType } from 'types/section.types';
import { IStaff } from 'types/staff.types';
import { IStudent } from 'types/student.types';
import { IUser } from 'types/user.types';

// Actions
export const SECTIONS__ERROR = 'SECTIONS__ERROR';
export const SECTIONS__GET_SECTION_STUDENTS = 'SECTIONS__GET_SECTION_STUDENTS';
export const RSRCS__GET_CURRENT_SCHOOL_DATA = 'RSRCS__GET_CURRENT_SCHOOL_DATA';
export const SECTIONS__LAUNCH_SUCCESS = 'SECTIONS__LAUNCH_SUCCESS';
export const SECTIONS__SET_CURRENT_SECTION = 'SECTIONS__SET_CURRENT_SECTION';
export const SECTIONS__SET_CURRENT_SECTION_OBJECT =
  'SECTIONS__SET_CURRENT_SECTION_OBJECT';
export const SECTIONS__SET_IS_LOADING = 'SECTIONS__SET_IS_LOADING';
export const SECTIONS__SET_IS_UPDATING = 'SECTIONS__SET_IS_UPDATING';
export const SECTIONS_GET_TEACHERS = 'SECTIONS_GET_TEACHERS';
export const SECTION__SET_VIEW = 'SECTION__SET_VIEW';
export const STAFF__HANDLE_SEARCH_TEXT_CHANGE =
  'STAFF__HANDLE_SEARCH_TEXT_CHANGE';
export const STAFF__FAIL = 'STAFF__FAIL';
export const STAFF__CLEAR_SEARCH_TEXT = 'STAFF__CLEAR_SEARCH_TEXT';
export const RSRCS__GET_SECTIONS_TYPES = 'RSRCS__GET_SECTIONS_TYPES';
export const SECTIONS__SET_CURRENT_SECTION_PRIMARY_TEACHER =
  'SECTIONS__SET_CURRENT_SECTION_PRIMARY_TEACHER';
export const SECTIONS__UPDATE_CURRENT_SECTION =
  'SECTIONS__UPDATE_CURRENT_SECTION';
export const SECTIONS__SET_SECTION_STUDENTS = 'SECTIONS__SET_SECTION_STUDENTS';
export const SECTIONS__GET_ALL_USER_STUDENTS =
  'SECTIONS__GET_ALL_USER_STUDENTS';
export const SECTIONS__SET_ALL_USER_STUDENTS =
  'SECTIONS__SET_ALL_USER_STUDENTS';

// Action types
export interface SetAllUserStudentsAction {
  type: typeof SECTIONS__SET_ALL_USER_STUDENTS;
  allUserStudents: {
    data: IUser[];
    links: {
      rel: string;
      uri: string;
    }[];
  };
}

export interface GetAllUserStudents {
  type: typeof SECTIONS__GET_ALL_USER_STUDENTS;
  allUserStudents: {
    data: IUser[];
    links: {
      rel: string;
      uri: string;
    }[];
  };
}

export interface SetSectionViewAction {
  type: typeof SECTION__SET_VIEW;
  sectionView: string;
}

export interface SetSectionStudentsAction {
  type: typeof SECTIONS__SET_SECTION_STUDENTS;
  students: IStudentAttendance[];
}

export interface UpdateCurrentSection {
  type: typeof SECTIONS__UPDATE_CURRENT_SECTION;
}

export interface SetCurrentSectionPrimaryTeacherAction {
  type: typeof SECTIONS__SET_CURRENT_SECTION_PRIMARY_TEACHER;
  currentSectionPrimaryTeacher: IUser;
}

export interface SetCurrentSectionObjectAction {
  type: typeof SECTIONS__SET_CURRENT_SECTION_OBJECT;
  currentSection: ISection;
}

export interface GetSectionsTypes {
  type: typeof RSRCS__GET_SECTIONS_TYPES;
  sectionsTypes: ISectionType[];
}

export interface HandleSearchInputChangeAction {
  data: IStaff[];
  searchText: string;
  type: typeof STAFF__HANDLE_SEARCH_TEXT_CHANGE;
}

interface GetTeachersSuccess {
  type: typeof SECTIONS_GET_TEACHERS;
  sectionsTeachers: IUser[];
}

interface GetCurrentSchoolDataAction {
  type: typeof RSRCS__GET_CURRENT_SCHOOL_DATA;
  currentSchoolData: ICurrentSchoolData;
}

export type TSetSectionView = (sectionView: string) => SetSectionViewAction;

interface GetSectionStudentsSuccess {
  type: typeof SECTIONS__GET_SECTION_STUDENTS;
  students: IStudentAttendance[];
}

interface SectionsFailAction {
  error: string;
  type: typeof SECTIONS__ERROR;
}

interface LaunchSectionsSuccessAction {
  sections: ISection[];
  type: typeof SECTIONS__LAUNCH_SUCCESS;
}

interface SetCurrentSectionAction {
  sectionID: string;
  students: IStudent[];
  type: typeof SECTIONS__SET_CURRENT_SECTION;
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof SECTIONS__SET_IS_LOADING;
}

interface SetIsUpdatingAction {
  bool: boolean;
  type: typeof SECTIONS__SET_IS_UPDATING;
}

export interface StaffSelectionFailAction {
  type: typeof STAFF__FAIL;
}

export interface ClearSearchTextAction {
  type: typeof STAFF__CLEAR_SEARCH_TEXT;
}

export type SectionsActionTypes =
  | SectionsFailAction
  | LaunchSectionsSuccessAction
  | SetCurrentSectionAction
  | SetIsLoadingAction
  | SetIsUpdatingAction
  | GetSectionStudentsSuccess
  | GetCurrentSchoolDataAction
  | GetTeachersSuccess
  | HandleSearchInputChangeAction
  | StaffSelectionFailAction
  | ClearSearchTextAction
  | GetSectionsTypes
  | SetCurrentSectionObjectAction
  | SetCurrentSectionPrimaryTeacherAction
  | UpdateCurrentSection
  | SetSectionStudentsAction
  | GetAllUserStudents
  | SetSectionViewAction
  | SetAllUserStudentsAction;

// Action handlers

export type TClearSearchText = () => ClearSearchTextAction;

export type TUpdateCurrentSection = (
  sectionID: string,
  body: IUpdateSectionArgs,
) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  SectionsActionTypes
>;

export type TSetAllUserStudents = (allUserStudents: {
  data: IUser[];
  links: {
    rel: string;
    uri: string;
  }[];
}) => SetAllUserStudentsAction;

export type TSetSectionStudents = (
  sectionStudents: IStudentAttendance[],
) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;

export type TGetSectionStudents = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;

export type TGetCurrentSectionAttendances = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;

export type TLaunchSections = () => ThunkAction<
  void,
  RootState,
  unknown,
  SectionsActionTypes
>;

export type TSetCurrentSectionObject = (args: {
  sectionID: string;
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  SectionsActionTypes
>;

export type TLoadSectionAttendances = () => ThunkAction<
  void,
  RootState,
  unknown,
  SectionsActionTypes
>;

export type TSetCurrentSection = (args: {
  sectionID: string;
}) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;

export type TSetDateRange = (args: {
  endDate: Date;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;

export type TSetIsSectionsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetIsUpdatingSections = (bool: boolean) => SetIsUpdatingAction;

export type TUpdateAttendances = (args: {
  updates: { [attendanceID: string]: PartialAttendance };
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  SectionsActionTypes
>;

export type THandleSearchInputChange = (
  searchText: string,
) => ThunkAction<void, RootState, unknown, SectionsActionTypes>;
