import styled from 'styled-components';

interface IContainer {
  isOn: boolean;
  isDisabled?: boolean;
  light?: boolean;
}

interface ICircle {
  isOn: boolean;
  light?: boolean;
}

interface ILabel {
  light?: boolean;
  labelColor?: string;
}

const transitionTime = 0.3;

export const Container = styled.div<IContainer>`
  width: 56px;
  height: 30px;
  min-width: 56px;
  min-height: 30px;
  position: relative;
  display: flex;
  background-color: ${({ isOn, light, theme }) =>
    isOn
      ? light
        ? theme.secondaryGreenDark
        : theme.primaryBlueLight
      : theme.secondaryGrayMedium};
  border-radius: 9999px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: background-color ${transitionTime}s ease-in-out;
`;

export const Circle = styled.div<ICircle>`
  margin: 2px;
  width: 26px;
  height: 26px;
  position: absolute;
  left: ${({ isOn }) => (isOn ? '26px' : '0')};
  background-color: ${({ theme }) => theme.primaryWhite};
  border-radius: 13px;
  transition: left ${transitionTime}s ease-in-out;
`;

export const Label = styled.label<ILabel>`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  font-weight: ${({ theme }) => theme.weightSemibold};
  color: ${({ theme, light, labelColor }) =>
    labelColor ? labelColor : light ? 'white' : theme.secondaryGrayDark};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
