import { ISchool } from './school.types';

// Roles
export interface IRoleBusDriver {
  driverNumber: string;
  id: string;
}

export interface IRoleContact {
  createdAt: Date;
  id: string;
  sisID: string;
  students?: {
    id: string;
    relationship: string;
    is_check_in_out: boolean;
    is_emergency_contact: boolean;
    sequence: number;
  }[];
  updatedAt: Date;
}

export interface IRoleDistrictAdmin {
  createdAt: Date;
  id: string;
  title?: string;
  updatedAt: Date;
}

export interface IRoleStaff {
  createdAt: Date;
  department: string;
  id: string;
  title: string;
  updatedAt: Date;
  roles?: {
    id: string;
    name: string;
    key: string;
    school: string;
  }[];
}

export interface IRoleStudent {
  dateOfBirth?: Date;
  gender: string;
  gradeID?: string;
  grade?: {
    id: string;
    name: string;
    classification: string;
    order: number;
  };
  raceID?: string;
  genderID?: string;
  graduationYear: string;
  id: string;
  race: string;
  schoolID: string;
  stateID?: string;
  studentCode?: string;
}

export interface IRoleTeacher {
  createdAt: Date;
  id: string;
  schoolID: string;
  sisID: string;
  stateID: string;
  teacherNumber?: string;
  title: string;
  updatedAt: Date;
}

export type TRole =
  | IRoleBusDriver
  | IRoleContact
  | IRoleDistrictAdmin
  | IRoleStaff
  | IRoleStudent
  | IRoleTeacher;

// user types
export interface IUser {
  canCustomizeIdCards?: boolean;
  districtID: string;
  email: string;
  firstName: string;
  id: string;
  gender?: string | null;
  race?: string | null;
  isABCTrackerEnabled?: boolean;
  isActive: boolean;
  isADAEnabled?: boolean;
  isAdmin?: boolean;
  isAssistantEnabled?: boolean;
  isAttendanceEnabled?: boolean;
  isInSchoolUser?: boolean;
  isOutOfSchoolUser?: boolean;
  isPupilAccounting?: boolean;
  isAssignmentsEnabled?: boolean;
  isAttendanceRecordsEnabled?: boolean;
  isClassScheduleEnabled?: boolean;
  isContactInfoEnabled?: boolean;
  isDisciplineDataEnabled?: boolean;
  isReportCardsEnabled?: boolean;
  isMaapScoresEnabled?: boolean;
  isNineWeeksTestScoresEnabled?: boolean;
  isNineWeeksTestScoresAnalysisEnabled?: boolean;
  isReportEnabled?: boolean;
  lastName: string;
  phone?: string;
  photoUrl?: string | null | undefined;
  roles: {
    busDriver?: IRoleBusDriver;
    contact?: IRoleContact;
    districtAdmin?: IRoleDistrictAdmin;
    staff?: IRoleStaff;
    student?: IRoleStudent;
    teacher?: IRoleTeacher;
  };
  schools?: ISchool[];
  sisID: string;
  userHMAC?: string;
}

export enum UserRolesEnum {
  busDriver = 'bus_driver',
  contact = 'contact',
  districtAdmin = 'district_admin',
  staff = 'staff',
  student = 'student',
  teacher = 'teacher',
}
