import { Row } from 'react-table';

// Actions
export const REPORTS__CLEAR_SEARCH_TEXT = 'REPORTS__CLEAR_SEARCH_TEXT';
export const REPORTS__ON_SEARCH_TEXT_CHANGE = 'REPORTS__ON_SEARCH_TEXT_CHANGE';
export const REPORTS__SET_FILTERED_ROWS = 'REPORTS__SET_FILTERED_ROWS';
export const REPORTS__SET_IS_LOADING = 'REPORTS__SET_IS_LOADING';

// Action types
interface ClearSearchTextAction {
  type: typeof REPORTS__CLEAR_SEARCH_TEXT;
}

interface OnSearchInputChangeAction {
  searchText: string;
  type: typeof REPORTS__ON_SEARCH_TEXT_CHANGE;
}

interface SetFilteredRowsAction {
  filteredRows: Row<Record<string, unknown>>[];
  type: typeof REPORTS__SET_FILTERED_ROWS;
}

interface SetIsLoadingAction {
  type: typeof REPORTS__SET_IS_LOADING;
  data: boolean;
}

export type MedicalReportsActionTypes =
  | ClearSearchTextAction
  | OnSearchInputChangeAction
  | SetFilteredRowsAction
  | SetIsLoadingAction;

// Action handlers
export type TClearSearchText = () => ClearSearchTextAction;

export type THandleSearchInputChange = (
  searchText: string,
) => OnSearchInputChangeAction;

export type TSetFilteredRows = (
  filteredRows: Row<Record<string, unknown>>[],
) => MedicalReportsActionTypes;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;
