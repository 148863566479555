import { morphism, StrictSchema } from 'morphism';

import {
  IApiCurrentSchoolData,
  IApiState,
  IApiTransportationType,
} from 'data/types/resourcesApi.types';
import {
  ICurrentSchoolData,
  IState,
  ITransportationType,
} from 'types/resources.types';
import {
  ICurrentSchoolDataSchema,
  IStateSchema,
  ITransportationTypeSchema,
} from './schemas/resourcesApi.schemas';

export const stateMapper = (state: IApiState) =>
  morphism<StrictSchema<IState, IApiState>>(IStateSchema, state);

export const transportationTypeMapper = (
  transportationType: IApiTransportationType,
) =>
  morphism<StrictSchema<ITransportationType, IApiTransportationType>>(
    ITransportationTypeSchema,
    transportationType,
  );

export const currentSchoolDataMapper = (
  currentSchoolData: IApiCurrentSchoolData,
) =>
  morphism<StrictSchema<ICurrentSchoolData, IApiCurrentSchoolData>>(
    ICurrentSchoolDataSchema,
    currentSchoolData,
  );
