import styled from 'styled-components';

interface IToolTipText {
  isDelete?: boolean;
}

interface IMoreContainer {
  isDisabled?: boolean;
}

export const TOOL_TIP_PADDING = 0;

export const Container = styled.div`
  .tooltip {
    padding: ${TOOL_TIP_PADDING}px;
    background: ${({ theme }) => theme.primaryWhite};
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: ${({ theme }) => theme.z10};

    &.__react_component_tooltip.show {
      opacity: 1 !important;
    }

    /* positioning of the tooltip arrow */
    &::before,
    &::after {
      left: 80% !important;
    }
  }

  .__react_component_tooltip {
    opacity: 1 !important;
  }
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  color: ${({ theme }) => theme.secondaryFontDark};
`;

export const MoreContainer = styled.button<IMoreContainer>`
  margin-right: -4px;
  padding: 4px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const ToolTipItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.slightGrayBackground};
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const ToolTipItems = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const ToolTipText = styled.label<IToolTipText>`
  font-family: ${({ theme }) => theme.fontNormal};
  font-weight: ${({ theme }) => theme.weightMedium};
  font-size: 13px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ isDelete, theme }) =>
    isDelete ? theme.colorDanger : theme.secondaryFontDark};
  cursor: pointer;
`;
