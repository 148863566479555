import React from 'react';
import { Svg } from './CheckStyles';

const Check: React.FC = () => {
  return (
    <Svg viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7198 0.304268C17.3369 -0.0916434 16.705 -0.102622 16.3085 0.279742C16.3002 0.287742 16.292 0.295917 16.2839 0.304268L5.97472 10.5944L1.69156 6.31943C1.29503 5.93706 0.663159 5.94804 0.280201 6.34395C-0.0934002 6.73017 -0.0934002 7.34243 0.280201 7.72865L5.26904 12.7082C5.65884 13.0973 6.29066 13.0973 6.6804 12.7082L17.6953 1.71349C18.0918 1.33107 18.1028 0.700179 17.7198 0.304268Z" />
    </Svg>
  );
};

export default Check;
