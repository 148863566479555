import { Theme } from 'assets/styles';
import { StylesConfig, OptionTypeBase } from 'react-select';
import styled, { CSSProperties } from 'styled-components';

interface IBorder {
  error?: string;
}

interface IContainer {
  fillWidth?: boolean;
}

export const Border = styled.div<IBorder>`
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colorDanger : 'transparent')};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  max-width: ${({ fillWidth }) => (fillWidth ? '100%' : '320px')};
`;

const DropdownStyles = (
  isDisabled: boolean,
  custom?: {
    backgroundColor?: string;
    color?: string;
    height?: string;
    padding?: string;
    fontSize?: string;
    valueContainer?: CSSProperties;
  },
): StylesConfig<OptionTypeBase, boolean> => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
    zIndex: 2,
    minWidth: 0,
    ...(isDisabled && { opacity: 0.5 }),
  }),

  control: (provided) => ({
    ...provided,
    border: `1px solid ${custom?.color || Theme.colorInputBorderInactive}`,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${custom?.color || Theme.colorInputBorderActive}`,
    },
    borderRadius: Theme.borderRadiusLarge,
    backgroundColor: custom?.backgroundColor || Theme.secondaryGrayLight,
    cursor: 'pointer',
    minHeight: custom?.height || 50,
    padding: custom?.padding || '4px 10px',
    fontSize: custom?.fontSize || 'unset',
  }),

  indicatorSeparator: () => ({
    display: 'none',
    fill: '#fff',
  }),

  multiValue: () => ({
    display: 'none',
  }),

  clearIndicator: () => ({
    display: 'none',
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: Theme.borderRadiusLarge,
    zIndex: 9999,
  }),

  menuList: (provided) => ({
    ...provided,
    padding: '0px 0px 0px 0px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    width: '100%',
    padding: 0,
    color: custom?.color,
    ...custom?.valueContainer,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: custom?.color,
  }),
  input: () => ({
    display: 'none',
  }),

  singleValue: () => ({
    display: 'none',
  }),

  option: (_, state) => {
    const { data, options } = state;

    const isDistrictOption = !!data.schools;

    const index = options?.findIndex(
      (val: OptionTypeBase) => val.id === data.id,
    );

    const isFirst = index === 0;

    const showTopBorder = !isFirst && isDistrictOption;

    return {
      borderTop: showTopBorder
        ? `3px solid ${Theme.secondaryGrayMedium}`
        : 'none',
      cursor: 'pointer',
      display: 'flex',
      padding: '0px 16px 0px 16px',
      '&:hover': {
        backgroundColor: `${Theme.secondaryGrayLight}`,
      },
      '&:first-of-type': {
        borderTopLeftRadius: Theme.borderRadiusLarge,
        borderTopRightRadius: Theme.borderRadiusLarge,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: Theme.borderRadiusLarge,
        borderBottomRightRadius: Theme.borderRadiusLarge,
      },
    };
  },
});

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: 14px;
  line-height: ${({ theme }) => theme.lineHeightSmall};
  color: ${({ theme }) => theme.colorDanger};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontNormal};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  font-weight: ${({ theme }) => theme.weightSemibold};
  color: ${({ theme }) => theme.secondaryGrayDark};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const InnerTopContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TopContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export default DropdownStyles;
