import { Reducer } from 'redux';
import * as Actions from './AuditLogActions.types';

export type State = {
  currentType?: string;
  endDate: Date;
  pageCursor?: string;
  pageSize: number;
  startDate: Date;
};

const today = new Date();
const initialState: State = {
  endDate: today,
  pageSize: 10,
  startDate: today,
};

const auditLogReducer: Reducer<State, Actions.AuditLogActionTypes> = (
  state: State = initialState,
  action: Actions.AuditLogActionTypes,
) => {
  switch (action.type) {
    case Actions.AUDIT__SET_CURRENT_TYPE: {
      return {
        ...state,
        currentType: action.data,
      };
    }

    case Actions.AUDIT__SET_CURSOR: {
      return {
        ...state,
        pageCursor: action.cursor,
      };
    }

    case Actions.AUDIT__SET_DATE_RANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    default:
      return state;
  }
};

export default auditLogReducer;
