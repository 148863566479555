import React from 'react';
import {
  CheckIcon,
  IconContainer,
  Input,
  Label,
  MinusIcon,
} from './CheckboxStyles';

interface Props {
  handleCheckClick: React.ChangeEventHandler<HTMLInputElement>;
  icon?: 'check' | 'minus';
  isDisabled?: boolean;
  isSelected: boolean;
  value?: string;
}

const Checkbox: React.FC<Props> = ({
  handleCheckClick,
  icon = 'check',
  isDisabled = false,
  isSelected,
  value,
}: Props) => {
  let iconComponent: JSX.Element | undefined;
  if (icon === 'check') iconComponent = <CheckIcon />;
  if (icon === 'minus') iconComponent = <MinusIcon />;

  return (
    <Label isDisabled={isDisabled} checked={isSelected}>
      <Input
        checked={isSelected}
        disabled={isDisabled}
        onChange={handleCheckClick}
        type="checkbox"
        value={value}
      />

      <div className="result-check-mark">
        {isSelected && <IconContainer>{iconComponent}</IconContainer>}
      </div>
    </Label>
  );
};

export default Checkbox;
