import API from 'data/axios';
import { reverseBusMessageMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBusMessage } from 'data/types/schoolBusApi.types';
import { INewBusMessageInstance } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../../types/api.types';

interface IEditBusMsgArgs {
  data: INewBusMessageInstance;
}

export default async ({ data }: IEditBusMsgArgs): Promise<Adapter> => {
  const newMsg = reverseBusMessageMapper(data);
  if (!newMsg.id) throw 'Invalid message data';

  return API.patch<Response<IApiBusMessage>>(
    `/bus/route_instances/messages/${newMsg.id}`,
    newMsg,
  )
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
