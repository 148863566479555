import styled from 'styled-components';

export const BookTimeToMeetButton = styled.a`
  text-decoration: none;
  cursor: pointer;
  background: #f7d000;
  border-radius: 10px;
  display: flex;
  gap: 32px;
  padding: 24px 17px;
  align-items: center;
  width: 80%;
  margin-bottom: 36px;

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }

  @media only screen and (max-width: 1300px) {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 9px;
    padding-bottom: 11px;
    gap: 16px;
  }

  @media only screen and (max-width: 275px) {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 9px;
    padding-bottom: 11px;
    gap: 8px;
  }
`;

export const BookText = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: 'case' on;
  color: #07081f;

  @media only screen and (max-width: 1300px) {
    font-size: 18px;
  }
`;
