import API from 'data/axios';
import { busMessageMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBusMessage } from 'data/types/schoolBusApi.types';
import { addDays, format } from 'date-fns';
import { IBusMessage } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../../types/api.types';

interface IGetMessagesArgs {
  startDate?: Date;
  endDate?: Date;
}

export default async ({
  startDate,
  endDate,
}: IGetMessagesArgs): Promise<Adapter<IBusMessage[]>> => {
  const start_date = startDate ? format(startDate, 'yyyy-MM-dd') : '';
  const end_date = endDate ? format(addDays(endDate, 1), 'yyyy-MM-dd') : '';

  return API.get<ArrayResponse<IApiBusMessage>>(
    `/bus/route_instances/messages?start_date=${start_date}&end_date=${end_date}`,
  )
    .then(({ data: response }) => {
      const messages = response.data.map(({ data }) => busMessageMapper(data));

      return { success: true, data: messages };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
