import API from 'data/axios';
import { reverseBusMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBus } from 'data/types/schoolBusApi.types';
import { IBus } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../../types/api.types';

interface ICreateNewBusArgs {
  data: Partial<IBus>;
}

export default async ({ data }: ICreateNewBusArgs): Promise<Adapter> => {
  const newBus = reverseBusMapper(data);

  return API.post<Response<IApiBus>>(`/bus/buses`, newBus)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
