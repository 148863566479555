import getNext from 'data/api/queries/getNext';

import { parseError } from 'utils/errors.utils';
import { Adapter } from '../types/api.types';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface IGetNextArgs {
  path: string;
  type?:
    | 'user'
    | 'school'
    | 'checkpoint_event'
    | 'attendance'
    | 'checkpoint_location';
}

export default async <T>({ path, type }: IGetNextArgs): Promise<Adapter<T[]>> =>
  getNext<T>({ path, type })
    .then(({ data, next }) => ({ success: true, data, next }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
