import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
`;

export const Content = styled.table`
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const Body = styled.tbody`
  flex: 1;
  overflow: auto;
`;

export const PaginationContainer = styled.tfoot`
  border-top: 1px solid ${({ theme }) => theme.tableRowBorderGrayLight};
  display: grid;
  grid-template-columns: 1fr;
  justify-self: flex-end;
  padding: 15px 20px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
  flex-shrink: 0;
`;
