import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { transportationTypeMapper } from 'data/mappers/resources.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { ITransportationType } from 'types/resources.types';
import { IApiTransportationType } from 'data/types/resourcesApi.types';

export default async (): Promise<Adapter<ITransportationType[]>> =>
  API.get<ArrayResponse<IApiTransportationType>>(
    `/resources/transportation_types`,
  )
    .then(({ data: response }) => {
      const transportationTypes = response.data.map((transportationType) =>
        transportationTypeMapper(transportationType.data),
      );

      return { success: true, data: transportationTypes };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
