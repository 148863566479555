import styled from 'styled-components';

interface IPath1 {
  fill?: string;
}

interface ISvg {
  location?: 'filter' | 'table';
}

export const Path1 = styled.path<IPath1>`
  fill: ${({ fill, theme }) => fill || theme.primaryWhite};
`;

export const Svg = styled.svg<ISvg>`
  height: ${({ location, theme }) =>
    (location === 'filter' && theme.filterIconSize) ||
    (location === 'table' && theme.tableIconSize) ||
    '19px'};
  width: ${({ location, theme }) =>
    (location === 'filter' && theme.filterIconSize) ||
    (location === 'table' && theme.tableIconSize) ||
    '19px'};
`;
