import React from 'react';

import useAppTable from './hooks/useAppTable';
import { TableHeader } from './components/header';

import { ITableProps } from './types';
import {
  Content,
  Body,
  Container,
  PaginationContainer,
  Wrapper,
} from './TableStyles';

export const Table: React.FC<ITableProps> = ({
  isFilterActive = false,
  setIsFilterActive,
  wrapperStyle,
  ...tableProps
}: ITableProps) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    PaginationRowComponent,
    renderRows,
    usePagination,
  } = useAppTable(tableProps);

  const canSelectAll =
    !tableProps.selectProps?.onSelect ||
    tableProps.selectProps?.canSelectAll === false
      ? false
      : true;

  return (
    <Container>
      <Wrapper style={wrapperStyle}>
        <Content {...getTableProps()}>
          <TableHeader
            {...(tableProps.selectProps || {})}
            canSelectAll={canSelectAll}
            columnsSpans={tableProps.columnsSpans}
            headerGroups={headerGroups}
            isFilterActive={isFilterActive}
            optionsMenu={tableProps.showOptions}
            rowsCount={(tableProps.data || []).length}
            setIsFilterActive={setIsFilterActive}
          />

          <Body {...getTableBodyProps()}>{renderRows()}</Body>

          {usePagination && (
            <PaginationContainer>
              <PaginationRowComponent />
            </PaginationContainer>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};
