export interface IAlertConfig {
  questions: {
    [id: string]: boolean;
  };
}

export enum IconEnum {
  questions = 'questions',
  temperature = 'temperature',
  signature = 'signature',
}

export interface IScreeningConfig {
  questions: {
    active: boolean;
  };
  temperature: {
    active: boolean;
    required?: boolean;
  };
  signature: {
    active: boolean;
    type: null | SurveyRolesEnum;
  };
}

export interface ISurvey {
  name: string;
  active: boolean;
  id: string;
  questions: TQuestion[];
  header: string;
  checkInConfig: IScreeningConfig;
  checkOutConfig: IScreeningConfig;
  alertConfig?: IAlertConfig;
}

export enum SurveyRolesEnum {
  staff = 'staff',
  guardian = 'guardian',
}

export enum SurveyViewsEnum {
  questions = 'questions',
  general = 'general',
  screening = 'screening',
}

export type TQuestion = {
  active: boolean;
  id?: string;
  sequence: number;
  surveyId: string;
  text: string;
};
