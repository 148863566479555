import { Analytics } from 'data/firebase/analytics';
import API from 'data/axios';
import isEqual from 'lodash/isEqual';

import { getAttendanceResources } from '../../resources/ResourcesActions';
import getAllUsersPaginated from 'data/adapters/users/getAllUsersPaginated';
import getSectionAttendances from 'data/adapters/sections/getSectionAttendances';
import getUserSectionsEvent from 'data/adapters/users/getUserSectionsEvent';
import UpdateAttendance from 'data/adapters/attendance/UpdateAttendance';

import { getStudentAttendanceTemplate } from 'utils/attendance.utils';
import { isStatusAbsent, isStatusPresent } from 'utils/status.utils';
import { parseError } from 'utils/errors.utils';

import { AttendanceMapper } from 'data/mappers/attendace.mappers';
import { userMapper } from 'data/mappers/user.mappers';

import { IAttendance, IStudentAttendance } from 'types/attendance.types';
import { ArrayResponse } from 'data/types/api.types';
import { IApiAttendance } from 'data/types/attendanceApi.types';
import { IApiUser } from 'data/types/userApi.types';
import { IStudent } from 'types/student.types';
import { IUser } from 'types/user.types';

import { AnalyticsEventsEnum } from 'data/firebase/analytics/types';
import * as Actions from './AttendanceActions.types';
import getCurrentSchoolData from 'data/adapters/resources/getCurrentSchoolData';

export const SetCheckedStudentsCheckIn: Actions.TSetCheckedStudentsCheckIn = (
  checkedStudentsCheckIn,
) => ({
  type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN,
  checkedStudentsCheckIn,
});

export const SetCheckedStudentsCheckOut: Actions.TSetCheckedStudentsCheckOut = (
  checkedStudentsCheckOut,
) => ({
  type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT,
  checkedStudentsCheckOut,
});

export const setAttendanceView: Actions.TSetAttendanceView = (view) => ({
  type: Actions.ATTENDANCE__SET_VIEW,
  view,
});

export const getSectionStudents: Actions.TGetSectionStudents = ({
  endDate,
  sectionID,
  startDate,
}) => async (dispatch, getState) => {
  dispatch(setIsEventLoading(true));
  try {
    const state = getState();
    const data = state.eventAttendance.students;
    const eventTypes = state.resources.eventTypes;

    const checkInType = eventTypes.find(({ key }) => key === 'section');

    if (!data) {
      throw 'Failed to retrieve section data.';
    }

    const {
      data: attendance = [],
      success: attendanceSuccess,
      error: attendanceError,
      next: nextLink,
    } = await getSectionAttendances({
      endDate,
      sectionID,
      startDate,
    });

    if (!attendanceSuccess) {
      throw attendanceError;
    }

    dispatch({
      sectionAttendances: attendance,
      sectionAttendanceNextLink: nextLink,
      type: Actions.EVENT__GET_ATTENDANCES,
    });

    //sort the attendance by latest date
    const sorted = await attendance
      .sort((a, b) => {
        const dateA = new Date(a.eventDate || a.createdAt);
        const dateB = new Date(b.eventDate || b.createdAt);
        return dateB.getTime() - dateA.getTime();
      })
      .filter(
        (attendanceRecord) =>
          attendanceRecord.trackingMethod?.id === checkInType?.id,
      );

    const newStudents = data.map((student: IUser) => {
      const attendanceStudent = sorted.find(
        (attendanceStudent) => attendanceStudent.studentID === student.id,
      );
      const newStudent: IStudentAttendance = getStudentAttendanceTemplate(
        student,
        attendanceStudent,
        { sectionID: sectionID || '' },
      );

      return newStudent;
    });

    // get count of present and absent students
    const presentStudents = newStudents.filter((student) =>
      isStatusPresent(student.status),
    ).length;
    const absentStudents = newStudents.filter((student) =>
      isStatusAbsent(student.status),
    ).length;

    dispatch({
      students: newStudents,
      attendanceByData: {
        TotalPresent: presentStudents,
        TotalAbsent: absentStudents,
        TotalStudents: newStudents.length,
      },
      type: Actions.EVENT__GET_SECTION_STUDENTS,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.EVENT__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(setIsEventLoading(false));
  }
};

export const launchEventAttendance: Actions.TLaunchEvent = ({
  activeSchoolID,
}) => async (dispatch, getState) => {
  dispatch(setIsEventLoading(true));

  const {
    data: currentSchoolData,
    success: isSuccessSchoolData,
  } = await getCurrentSchoolData();

  if (!isSuccessSchoolData || !currentSchoolData) {
    throw new Error('Failed to load calendar dates.');
  }

  dispatch({
    type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN,
    checkedStudentsCheckIn: [] as IStudentAttendance[],
  });

  dispatch({
    type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT,
    checkedStudentsCheckOut: [] as IStudentAttendance[],
  });

  try {
    const state = getState();
    const userID = state.auth.user?.id;

    if (!userID) throw 'Unable to get current user ID.';

    dispatch({
      type: Actions.RSRCS__GET_CURRENT_SCHOOL_DATA,
      currentSchoolData,
    });

    const { data: sections = [], success, error } = await getUserSectionsEvent({
      userID,
      schoolYear: currentSchoolData.schoolYear,
      date: currentSchoolData.date,
    });

    if (!success || !sections) {
      throw error;
    }

    dispatch({
      type: Actions.EVENT__LAUNCH_SUCCESS,
      sections,
    });

    dispatch(getAttendanceResources({ activeSchoolID, type: 'event' }));
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.EVENT__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(setIsEventLoading(false));
  }
};

export const loadSectionAttendances: Actions.TLoadSectionAttendances = () => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { currentSectionID } = state.eventAttendance;

  if (currentSectionID) {
    dispatch(
      setCurrentSection({
        sectionID: currentSectionID,
      }),
    );
  }
};

export const setCurrentSection: Actions.TSetCurrentSection = ({
  sectionID,
}) => async (dispatch, getState) => {
  dispatch(setIsEventLoading(true));

  dispatch({
    type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN,
    checkedStudentsCheckIn: [] as IStudentAttendance[],
  });

  dispatch({
    type: Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT,
    checkedStudentsCheckOut: [] as IStudentAttendance[],
  });

  try {
    const state = getState();
    const { startDate, endDate } = state.eventAttendance;
    const section = state.eventAttendance.sections.find(
      (s) => s.id === sectionID,
    );

    if (!section) {
      throw 'Failed to retrieve section data.';
    }

    const {
      data: allSectionStudentsObject,
      success: studentsSuccess,
      error: studentsError,
    } = await getAllUsersPaginated({
      usersIds: section.studentIDs.join(','),
    });

    if (!studentsSuccess) {
      throw studentsError;
    }

    const allSectionStudents = allSectionStudentsObject?.users;

    dispatch({
      sectionID,
      students: allSectionStudents as IStudent[],
      allSectionStudentsNextLink: allSectionStudentsObject?.nextLink,
      type: Actions.EVENT__SET_CURRENT_SECTION,
    });

    dispatch(getSectionStudents({ startDate, sectionID, endDate }));
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.EVENT__ERROR,
      error: stringError,
    });
  }
};

export const setDateRange: Actions.TSetDateRange = ({
  startDate,
  endDate,
}) => async (dispatch, getState) => {
  const state = getState();
  const { currentSectionID } = state.eventAttendance;

  dispatch({
    endDate,
    startDate,
    type: Actions.EVENT__SET_DATE_RANGE,
  });

  currentSectionID &&
    dispatch(
      setCurrentSection({
        sectionID: currentSectionID,
      }),
    );
};

export const setIsEventLoading: Actions.TSetIsEventLoading = (bool) => ({
  type: Actions.EVENT__SET_IS_LOADING,
  bool,
});

export const setIsUpdatingEvent: Actions.TSetIsUpdatingEvent = (bool) => ({
  type: Actions.EVENT__SET_IS_UPDATING,
  bool,
});

export const toggleIsPresentSelected: Actions.TToggleIsPresentSelected = () => ({
  type: Actions.ATTENDANCE__TOGGLE_IS_PRESENT_SELECTED,
});

export const toggleIsAbsentSelected: Actions.TToggleIsAbsentSelected = () => ({
  type: Actions.ATTENDANCE__TOGGLE_IS_ABSENT_SELECTED,
});

export const toggleIsAllSelected: Actions.TToggleIsAllSelected = () => ({
  type: Actions.ATTENDANCE__TOGGLE_IS_ALL_SELECTED,
});

export const setMissedStudents: Actions.TSetMissedStudents = (
  missedStudents,
) => ({
  type: Actions.EVENT__ATTENDANCE__SET_MISSED_STUDENTS,
  missedStudents,
});

export const updateEventAttendances: Actions.TUpdateAttendances = ({
  updates,
}) => async (dispatch, getState) => {
  dispatch(setIsUpdatingEvent(true));

  try {
    const state = getState();
    const userID = state.auth.user?.id;
    const sectionID = state.eventAttendance.currentSectionID;
    const statuses = state.resources.statuses || [];
    const sectionAttendance = state.eventAttendance.sectionAttendances;
    const sectionAttendanceNextLink =
      state.eventAttendance.sectionAttendanceNextLink;
    const data = state.eventAttendance.students;
    const eventTypes = state.resources.eventTypes;

    const checkInType = eventTypes.find(({ key }) => key === 'section');

    if (!sectionID) {
      throw 'Unable to get current section ID.';
    }

    const updatedRecords = Object.entries(updates).map(([id, data]) => ({
      id,
      ...data,
      sectionID,
      schoolID: data.schoolID || '',
      userAccountID: data.studentID || '',
      status:
        statuses.find((status) => status.abbr === data.status?.abbr)?.id || '',
      trackingMethod: data.trackingMethod?.id || '',
      comment: data.comment ?? undefined,
      reasonCode: data.reasonCode?.id ?? undefined,
    }));

    const {
      data: newStudentAttendance,
      success,
      error,
    } = await UpdateAttendance({
      updated: updatedRecords,
    });

    if (!success || !newStudentAttendance) {
      throw error;
    }

    const allSectionAttendance = await [
      ...sectionAttendance,
      ...newStudentAttendance,
    ]
      .sort((a, b) => {
        const dateA = new Date(a.eventDate || a.createdAt);
        const dateB = new Date(b.eventDate || b.createdAt);
        return dateB.getTime() - dateA.getTime();
      })
      .filter(
        (attendanceRecord) =>
          attendanceRecord.trackingMethod?.id === checkInType?.id,
      );

    const newStudents = data.map((student: IUser) => {
      const attendanceStudent = allSectionAttendance.find(
        (attendanceStudent) => attendanceStudent.studentID === student.id,
      );
      const oldStudentObject = sectionAttendance.find(
        (attendanceStudent) => attendanceStudent.studentID === student.id,
      );
      if (!isEqual(attendanceStudent?.status, oldStudentObject?.status)) {
        Analytics.logEvent(AnalyticsEventsEnum.UPDATE_ATTENDANCE_STATUS, {
          district_id: student.districtID ?? '',
          school_id: attendanceStudent?.schoolID ?? '',
          auditUser_id: userID ?? '',
        });
      }
      const newStudent: IStudentAttendance = getStudentAttendanceTemplate(
        student,
        attendanceStudent,
        { sectionID: sectionID || '' },
      );

      return newStudent;
    });

    // get count of present and absent students
    const presentStudents = newStudents.filter((student) =>
      isStatusPresent(student.status),
    ).length;
    const absentStudents = newStudents.filter((student) =>
      isStatusAbsent(student.status),
    ).length;

    dispatch({
      students: newStudents,
      attendanceByData: {
        TotalPresent: presentStudents,
        TotalAbsent: absentStudents,
        TotalStudents: newStudents.length,
      },
      type: Actions.EVENT__GET_SECTION_STUDENTS,
    });

    dispatch({
      sectionAttendances: [...sectionAttendance, ...newStudentAttendance],
      sectionAttendanceNextLink: sectionAttendanceNextLink,
      type: Actions.EVENT__GET_ATTENDANCES,
    });

    // dispatch(setIsEventLoading(true));
    // await dispatch(loadSectionAttendances());

    dispatch({
      type: Actions.EVENT__UPDATE_ATTENDANCES,
    });

    return { isSuccessful: true };
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.EVENT__ERROR,
      error: stringError,
    });
    return { isSuccessful: false };
  } finally {
    dispatch(setIsUpdatingEvent(false));
  }
};

export const getSectionStudentsNext: Actions.TGetSectionStudentsNext = () => async (
  dispatch,
  getState,
) => {
  dispatch(setIsEventLoading(true));
  try {
    const state = getState();
    const sectionID = state.eventAttendance.currentSectionID;
    const currSectionStudents = state.eventAttendance.students;
    const currSectionStudentsAttendance =
      state.eventAttendance.sectionAttendances;

    const missedStudents = state.eventAttendance.missedStudents;

    const eventTypes = state.resources.eventTypes;

    const checkInType = eventTypes.find(({ key }) => key === 'section');

    let nextSectionStudents: IUser[] = [];
    let allSectionStudentsNextLink = '';

    if (state.eventAttendance.allSectionStudentsNextLink) {
      await API.get<ArrayResponse<IApiUser>>(
        state.eventAttendance.allSectionStudentsNextLink,
      ).then(({ data: response }) => {
        const users = response.data.map(({ data }) => userMapper(data));
        nextSectionStudents = users;
        const nextLink = response.links.filter(({ rel }) => rel === 'next')[0]
          ?.uri;
        allSectionStudentsNextLink = nextLink;
      });
    }

    const allSectionStudents = await currSectionStudents.filter(
      (student) => !missedStudents.includes(student.id),
    );

    dispatch({
      sectionID: sectionID || '',
      students: [...allSectionStudents, ...nextSectionStudents] as IStudent[],
      allSectionStudentsNextLink: allSectionStudentsNextLink,
      type: Actions.EVENT__SET_CURRENT_SECTION,
    });

    let nextSectionStudentsAttendance: IAttendance[] = [];
    let sectionAttendanceNextLink = '';

    if (state.eventAttendance.sectionAttendanceNextLink) {
      await API.get<ArrayResponse<IApiAttendance>>(
        state.eventAttendance.sectionAttendanceNextLink,
      ).then(({ data: response }) => {
        const attendances = response.data.map(({ data }) =>
          AttendanceMapper(data),
        );
        nextSectionStudentsAttendance = attendances;
        const nextLink = response.links.filter(({ rel }) => rel === 'next')[0]
          .uri;
        sectionAttendanceNextLink = nextLink;
      });
    }

    dispatch({
      sectionAttendances: [
        ...currSectionStudentsAttendance,
        ...nextSectionStudentsAttendance,
      ],
      sectionAttendanceNextLink: sectionAttendanceNextLink,
      type: Actions.EVENT__GET_ATTENDANCES,
    });

    //sort the attendance by latest date
    const allSectionAttendance = await [
      ...currSectionStudentsAttendance,
      ...nextSectionStudentsAttendance,
    ]
      .sort((a, b) => {
        const dateA = new Date(a.eventDate || a.createdAt);
        const dateB = new Date(b.eventDate || b.createdAt);
        return dateB.getTime() - dateA.getTime();
      })
      .filter(
        (attendanceRecord) =>
          attendanceRecord.trackingMethod?.id === checkInType?.id,
      );

    const newStudents = [...allSectionStudents, ...nextSectionStudents].map(
      (student: IUser) => {
        const attendanceStudent = allSectionAttendance.find(
          (attendanceStudent) => attendanceStudent.studentID === student.id,
        );
        const newStudent: IStudentAttendance = getStudentAttendanceTemplate(
          student,
          attendanceStudent,
          { sectionID: sectionID || '' },
        );
        return newStudent;
      },
    );

    // get count of present and absent students
    const presentStudents = newStudents.filter((student) =>
      isStatusPresent(student.status),
    ).length;
    const absentStudents = newStudents.filter((student) =>
      isStatusAbsent(student.status),
    ).length;

    dispatch({
      students: newStudents,
      attendanceByData: {
        TotalPresent: presentStudents,
        TotalAbsent: absentStudents,
        TotalStudents: newStudents.length,
      },
      type: Actions.EVENT__GET_SECTION_STUDENTS,
    });
  } catch (error) {
    const stringError = parseError(error);

    dispatch({
      type: Actions.EVENT__ERROR,
      error: stringError,
    });
  } finally {
    dispatch(setIsEventLoading(false));
  }
};
