import { ITutorialLog } from 'types/videoTutorial.types';
import { firestore } from 'data/firebase';
import { PAGINATION_LIMIT } from './utils';

interface IGetAuditLogsArgs {
  videoIds: string[];
  schoolID: string;
  userID: string;
}

interface IGetAuditLogsResponse {
  data: string[]; //list of video id's
  error?: string;
}

export default async ({
  videoIds,
  schoolID,
  userID,
}: IGetAuditLogsArgs): Promise<IGetAuditLogsResponse> => {
  try {
    const query = firestore
      .collection('trainingVideoAudits')
      .where('schoolID', '==', schoolID)
      .where('userID', '==', userID)
      .where('videoID', 'in', videoIds);

    const snapshot = await query.limit(PAGINATION_LIMIT).get();

    const logs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as ITutorialLog[];

    let parsedResult: string[] = [];
    if (snapshot.docs.length > 0) {
      parsedResult = logs
        ?.filter((logItem) => logItem.watchedComplete === true)
        ?.map((item) => item.videoID);
    }

    if (snapshot.docs.length > 0) {
      return {
        data: parsedResult,
      };
    } else {
      return { data: parsedResult };
    }
  } catch (error) {
    console.error('Error When fetching logs:', error);
    return { data: [], error: error as string };
  }
};
