import { IOrganization } from './organization.types';

export interface IPerson {
  cellPhone?: string;
  dateOfBirth?: string;
  driverNumber?: string | null;
  email: string;
  emailVerified?: boolean;
  firstName: string;
  gradeLevel?: string;
  hasBeenSent?: boolean;
  id?: string;
  isSelected?: boolean;
  lastName: string;
  middleName?: string;
  organizations?: IOrganization[];
  personType?: IPersonType;
  photoUrl?: string | null | undefined;
  userID?: string;
}

export interface IPersonalDetail {
  nameOrMSID: string;
  zipCode: string;
  personId: string;
  personType: string;
}

export enum IPersonType {
  GUARDIAN = 'GUARDIAN',
  STAFF = 'STAFF',
  STUDENT = 'STUDENT',
}

export enum UserRolesEnum {
  parent = 'Parent',
  teacher = 'Teacher',
  student = 'Student',
  staff = 'Staff',
}
