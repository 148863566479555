import API from 'data/axios';

import { schoolMapper } from 'data/mappers/school.mappers';

import { ArrayResponse } from '../../../types/api.types';
import { IApiSchool } from 'data/types/schoolApi.types';
import { ISchool } from 'types/school.types';

interface IGetSchoolsArgs {
  pageSize?: number;
}

export default async (args?: IGetSchoolsArgs): Promise<ISchool[]> =>
  API.get<ArrayResponse<IApiSchool>>(
    `/schools${args?.pageSize ? '?page_size=' + args.pageSize : ''}`,
  ).then(({ data: response }) => {
    const schools = response.data.map(({ data }) => schoolMapper(data));

    return schools;
  });
