import { IStudent } from './student.types';
import { IRoleBusDriver, IUser, TRole } from './user.types';

export interface IBus {
  busNumber: string;
  createdAt: Date;
  districtID: string;
  id: string;
  isActive: boolean;
  isOutOfService: boolean;
  notes?: string;
  updatedAt: Date;
}

export interface IBusAttendance {
  eventDate: Date;
  eventType: IBusResource;
  id: string;
  location?: IBusRouteLocation;
  routeID: string;
  userAccountID: string;
}

export interface IBusMessage {
  date: Date;
  deliveryMethods: IBusResource[];
  id: string;
  routeID: string;
  sendToBusAdmin: boolean;
  sendToPrimaryContact: boolean;
  text: string;
  type: IBusResource;
}

export interface INewBusMessageInstance extends Partial<IBusMessage> {
  date?: Date;
  deliveryMethodIDs: string[];
  routeID: string;
  text: string;
  typeID?: string;
}

export interface IBusResource {
  id: string;
  key: string;
  name: string;
}

export interface IBusDriver extends IUser {
  roles: {
    busDriver: IRoleBusDriver;
    [roleName: string]: TRole;
  };
}

export interface IBusRouteLabel {
  districtID: string;
  id: string;
  key: string;
  name: string;
}

export interface IMapLocation extends google.maps.LatLngLiteral {
  city?: string;
  id: string;
  line1: string;
  line2: string;
  line3: string;
  state?: string;
  zipCode?: string;
}

export interface IBusRouteLocation extends IMapLocation {
  address: string;
  date: Date;
  isStop: boolean;
  routeID: string;
}

export interface IBusRoute {
  busDriver: {
    driverNumber: string;
    firstName: string;
    lastName: string;
    sisID: string;
    userID: string;
    driverID: string;
  };
  bus: IBus;
  comment: string;
  createdAt: Date;
  distance: number;
  districtID: string;
  endLocation?: IBusRouteLocation;
  endTime?: Date;
  id: string;
  startLocation?: IBusRouteLocation;
  startTime: Date;
  status: BusRoueStatusEnum;
  stops?: IBusRouteLocation[];
  students: IStudent[];
  updatedAt: Date;
}

export interface IBusRoutePlan {
  busDriverID?: string;
  description?: string;
  districtID: string;
  id: string;
  label?: IBusRouteLabel;
  locations: IBusRoutePlanLocation[];
  name: string;
  students: IBusRoutePlanStudent[];
}

export interface IBusRoutePlanLocation extends IMapLocation {
  country?: string;
  isStop: boolean;
  order: number;
  routePlanID: string;
  state?: string;
}

export interface IBusRoutePlanStudent {
  id: string;
  routePlanID: string;
  studentID: string;
  busStopID: string;
}

export enum BusRoueStatusEnum {
  completed = 'completed',
  emergency = 'emergency',
  inProgress = 'inProgress',
}

export interface IEmergencyLocation {
  coordinates: google.maps.LatLngLiteral;
  locationAddress: string;
}

export interface INewRoute {
  distance: number;
  driverName: string;
  id: string;
  isActive: boolean;
  studentCount: number;
  totalStops: number;
}

export enum ManageRoutesViewsEnum {
  info = 'info',
  stops = 'stops',
  students = 'students',
}
