import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  width: 1420px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 100px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    margin-left: ${({ theme }) => theme.homepageLeftMarginTablet};
    margin-right: ${({ theme }) => theme.homepageLeftMarginTablet};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media only screen and (max-width: 1300px) {
    gap: 15px;
  }
`;

export const Title = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  color: #07081f;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 470px) {
    font-size: 22px;
  }
`;

export const SecondTitle = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 110%;
  color: #1a8ddc;

  @media only screen and (max-width: 470px) {
    font-size: 22px;
  }
`;
