import React from 'react';
import { Path, Svg } from './ExclamationCircleStyles';

export interface Props {
  fill?: string;
  location?: 'filter' | 'notification' | 'table';
}

const ExclamationCircle: React.FC<Props> = ({ fill, location }: Props) => {
  return (
    <Svg
      location={location}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        clipRule="evenodd"
        d="M0 9.5C0 4.2619 4.2619 0 9.5 0C14.7381 0 19 4.2619 19 9.5C19 14.7381 14.7381 19 9.5 19C4.2619 19 0 14.7381 0 9.5ZM10.6067 10.1334C10.6067 10.7449 10.111 11.2405 9.49955 11.2405C8.92632 11.2405 8.45485 10.8049 8.39815 10.2466L8.39244 10.1334V5.3657C8.39244 4.75425 8.88811 4.25858 9.49955 4.25858C10.0728 4.25858 10.5443 4.69423 10.601 5.2525L10.6067 5.3657V10.1334ZM8.71489 12.8449C9.14837 12.4114 9.85118 12.4114 10.2847 12.8449C10.7181 13.2784 10.7181 13.9812 10.2847 14.4147C9.85118 14.8481 9.14837 14.8481 8.71489 14.4147C8.28141 13.9812 8.28141 13.2784 8.71489 12.8449Z"
        fill={fill}
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M0 9.5C0 4.2619 4.2619 0 9.5 0C14.7381 0 19 4.2619 19 9.5C19 14.7381 14.7381 19 9.5 19C4.2619 19 0 14.7381 0 9.5ZM10.6067 10.1334C10.6067 10.7449 10.111 11.2405 9.49955 11.2405C8.92632 11.2405 8.45485 10.8049 8.39815 10.2466L8.39244 10.1334V5.3657C8.39244 4.75425 8.88811 4.25858 9.49955 4.25858C10.0728 4.25858 10.5443 4.69423 10.601 5.2525L10.6067 5.3657V10.1334ZM8.71489 12.8449C9.14837 12.4114 9.85118 12.4114 10.2847 12.8449C10.7181 13.2784 10.7181 13.9812 10.2847 14.4147C9.85118 14.8481 9.14837 14.8481 8.71489 14.4147C8.28141 13.9812 8.28141 13.2784 8.71489 12.8449Z"
        fill={fill}
        fillRule="evenodd"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="9.5"
          y1="-9.5"
          x2="-9.5"
          y2="9.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.000290329" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default ExclamationCircle;
