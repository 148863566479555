import API from '../../axios';
import { setAuthToken } from 'data/utils/authToken.utils';
import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../types/api.types';

interface IOauthLoginUserArgs {
  code: string;
  provider: string;
  district: string;
}

export default async ({
  code,
  provider,
  district,
}: IOauthLoginUserArgs): Promise<Adapter> => {
  return API.get(`/oauth/${provider}`, {
    params: { code, district },
  })
    .then((res) => {
      setAuthToken(res.data.data.auth_token);
      return { success: true };
    })
    .catch((err) => ({ success: false, error: parseError(err) }));
};
