import API from 'data/axios';

import { userMapper } from '../../../mappers/user.mappers';

import { ApiQuery, ArrayResponse } from '../../../types/api.types';
import { IApiUser } from '../../../types/userApi.types';
import { IUser, UserRolesEnum } from 'types/user.types';

interface IGetSchoolUsersArgs {
  cursor?: string;
  pageSize?: number;
  role?: UserRolesEnum;
  schoolID: string;
  searchText?: string;
}

export default async ({
  cursor = '',
  pageSize,
  role,
  schoolID,
  searchText = '',
}: IGetSchoolUsersArgs): Promise<ApiQuery<IUser[]>> => {
  const args = [];
  if (role) args.push(`role=${role}`);
  if (pageSize) args.push(`page_size=${pageSize}`);
  if (searchText) args.push(`search=${searchText}`);
  if (cursor) args.push(`cursor=${cursor}`);

  const queries = args.length ? `?${args.join('&')}` : '';

  const path = `/schools/${schoolID}/users${queries}`;

  return API.get<ArrayResponse<IApiUser>>(path).then(({ data: response }) => {
    const students = response.data.map(({ data }) => userMapper(data));
    const next = response.links.find(({ rel }) => rel === 'next')?.uri;

    return { data: students, next };
  });
};
