import styled, { css } from 'styled-components';

interface ITableData {
  full?: boolean;
  optionsMenu?: boolean;
  ignoreEllipsis?: boolean;
}

interface ITableRow {
  autoHeight?: boolean;
  checkboxCol?: boolean;
  clickable?: boolean;
  columnsSpans?: string;
  empty?: boolean;
  length?: number;
  optionsMenu?: boolean;
}

export const Data = styled.td<ITableData>`
  color: ${({ theme }) => theme.secondaryFontDark};
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizeLarge};
  font-weight: ${({ theme }) => theme.weightRegular};
  line-height: ${({ theme }) => theme.lineHeightSmall};
  padding: 18px 20px 18px 0;
  width: 100%;
  ${({ ignoreEllipsis }) =>
    !ignoreEllipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}

  ${({ optionsMenu }) =>
    optionsMenu &&
    `&:last-of-type {
      flex: unset;
      text-align: right;
    }`}

  ${({ full }) =>
    full &&
    css`
      grid-column: 1/-1;
      justify-self: center;
      text-align: center;
    `}

  &:last-of-type {
    padding-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    &:nth-of-type(3) {
      justify-self: center;
    }
  }
`;

export const EmptyTable = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.secondaryFontDarkTrans};
  font-family: ${({ theme }) => theme.fontBold};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weightSemibold};
`;

export const QuestionBubble = styled.img`
  margin: 15px;
  max-width: 50px;
`;

export const SubMessage = styled.div`
  color: ${({ theme }) => theme.secondaryFontDarkTrans};
  font-size: 16px;
`;

const getColSpans = ({ optionsMenu, checkboxCol, length = 1 }: ITableRow) => {
  const originalSpans = new Array(length).fill(0).map(() => '1fr');

  if (optionsMenu) originalSpans[length - 1] = '0.1fr';
  if (checkboxCol) originalSpans[0] = '60px';

  return originalSpans.join(' ');
};

export const TableRow = styled.tr<ITableRow>`
  align-items: center;
  height: ${({ empty }) => (empty ? '100%' : '50px')};
  ${({ autoHeight }) =>
    autoHeight &&
    css`
      height: unset;
      minheight: '50px';
    `}
  background-color: transparent;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryFontDark}1A`};
  justify-items: start;
  width: 100%;
  display: grid;
  grid-template-columns: ${({
    columnsSpans,
    length = 1,
    checkboxCol,
    optionsMenu,
  }) =>
    columnsSpans
      ? columnsSpans
      : getColSpans({ length, checkboxCol, optionsMenu })};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};

  &:last-of-type {
    border-bottom: none;
  }

  ${({ clickable, theme }) =>
    clickable &&
    `
  &:hover {
    background-color: ${theme.slightGrayBackground}
  }
  
  `};

  // TODO: this doesn't belong here. This should be a generic component.
  // @media (max-width: ${({ theme }) => theme.widthMobile}) {
  //   grid-template-columns: \${({ isCheckOutTable }) =>
  //     isCheckOutTable
  //       ? '160px 180px 180px 180px 180px 180px'
  //       : '160px 180px 180px 180px 180px'};
  // }
`;
