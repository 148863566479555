import { parseError } from 'utils/errors.utils';
import { auth } from '../../firebase';

interface IResetUserPasswordDataArgs {
  code: string;
  newPassword: string;
}

interface ForgottUserPasswordData {
  success: boolean;
  error?: string;
}

export default async ({
  code,
  newPassword,
}: IResetUserPasswordDataArgs): Promise<ForgottUserPasswordData> => {
  try {
    await auth.confirmPasswordReset(code, newPassword);

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(
      err,
      'Something went wrong with trying to reset your password',
    );
    return { success: false, error: errorMessage };
  }
};
