import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';

import { ClassRoomAttendanceRecord, ISection } from 'types/section.types';
import { IAttendance, INewAttendance } from 'types/attendance.types';
import { IAttendanceByData } from './ClassroomReducer';
import { IStudent } from 'types/student.types';
import { IUser } from 'types/user.types';

// Actions
export const INSCHOOL__ERROR = 'INSCHOOL__ERROR';
export const INSCHOOL__LOAD_ATTENDANCES = 'INSCHOOL__LOAD_ATTENDANCES';
export const INSCHOOL__LAUNCH_SUCCESS = 'INSCHOOL__LAUNCH_SUCCESS';
export const INSCHOOL__SET_CURRENT_SECTION = 'INSCHOOL__SET_CURRENT_SECTION';
export const INSCHOOL__SET_DATE_RANGE = 'INSCHOOL__SET_DATE_RANGE';
export const INSCHOOL__SET_SECTIONS = 'INSCHOOL__SET_SECTIONS';
export const INSCHOOL__SET_IS_LOADING = 'INSCHOOL__SET_IS_LOADING';
export const INSCHOOL__SET_IS_UPDATING = 'INSCHOOL__SET_IS_UPDATING';
export const INSCHOOL__SET_VIEW = 'INSCHOOL__SET_VIEW';
export const INSCHOOL__UPDATE_ATTENDANCES = 'INSCHOOL__UPDATE_ATTENDANCES';
export const INSCHOOL__TOGGLE_IS_ABSENT_SELECTED =
  'INSCHOOL__TOGGLE_IS_ABSENT_SELECTED';
export const INSCHOOL__TOGGLE_IS_PRESENT_SELECTED =
  'INSCHOOL__TOGGLE_IS_PRESENT_SELECTED';
export const INSCHOOL__TOGGLE_IS_ALL_SELECTED =
  'INSCHOOL__TOGGLE_IS_ALL_SELECTED';
export const INSCHOOL__SET_CHECKED_STUDENTS = 'INSCHOOL__SET_CHECKED_STUDENTS';
export const INSCHOOL__SET_IS_SELECT_TEACHER_FLOW =
  'INSCHOOL__SET_IS_SELECT_TEACHER_FLOW';
export const INSCHOOL__HANDLE_TEACHER_SEARCH =
  'INSCHOOL__HANDLE_TEACHER_SEARCH';
export const INSCHOOL__CLEAR_SEARCH_TEXT = 'INSCHOOL__CLEAR_SEARCH_TEXT';
export const INSCHOOL__SET_SELECTED_TEACHER = 'INSCHOOL__SET_SELECTED_TEACHER';
export const INSCHOOL__SET_CLASSROOM_SECTIONS_LOADING =
  'INSCHOOL__SET_CLASSROOM_SECTIONS_LOADING';
// Action types

export interface SetCheckedStudentsAction {
  type: typeof INSCHOOL__SET_CHECKED_STUDENTS;
  checkedRecords: ClassRoomAttendanceRecord[];
}
export interface SetAttendanceViewAction {
  type: typeof INSCHOOL__SET_VIEW;
  inSchoolView: string;
}

export interface ToggleIsAllSelectedAction {
  type: typeof INSCHOOL__TOGGLE_IS_ALL_SELECTED;
}

export interface ToggleIsAbsentSelectedAction {
  type: typeof INSCHOOL__TOGGLE_IS_ABSENT_SELECTED;
}

export interface ToggleIsPresentSelectedAction {
  type: typeof INSCHOOL__TOGGLE_IS_PRESENT_SELECTED;
}

export type TSetAttendanceView = (view: string) => SetAttendanceViewAction;

interface loadAttendancesSuccess {
  type: typeof INSCHOOL__LOAD_ATTENDANCES;
  classRoster: ClassRoomAttendanceRecord[];
  attendanceByData: IAttendanceByData;
  next?: string;
}

interface InSchoolFailAction {
  error: string;
  type: typeof INSCHOOL__ERROR;
}

interface LaunchInSchoolSuccessAction {
  sections: ISection[];
  type: typeof INSCHOOL__LAUNCH_SUCCESS;
}

interface SetCurrentSectionAction {
  currentSectionID: string | undefined;
  students: IStudent[];
  type: typeof INSCHOOL__SET_CURRENT_SECTION;
  attendancesNext: string | undefined;
}

export interface HandleTeacherSearchAction {
  data: IUser[];
  next?: string;
  searchText: string;
  type: typeof INSCHOOL__HANDLE_TEACHER_SEARCH;
}

export interface HandleSelectTeacherAction {
  selectedTeacher: string;
  type: typeof INSCHOOL__SET_SELECTED_TEACHER;
}
interface SetDateRangeAction {
  type: typeof INSCHOOL__SET_DATE_RANGE;
  date: Date;
}

interface SetSectionsAction {
  type: typeof INSCHOOL__SET_SECTIONS;
  sections: ISection[];
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof INSCHOOL__SET_IS_LOADING;
}

interface SetClassRoomSectionsLoading {
  bool: boolean;
  type: typeof INSCHOOL__SET_CLASSROOM_SECTIONS_LOADING;
}

interface SetIsUpdatingAction {
  bool: boolean;
  type: typeof INSCHOOL__SET_IS_UPDATING;
}

interface SetIsSelectTeacherFlowAction {
  isSelectTeacherFlow: boolean;
  type: typeof INSCHOOL__SET_IS_SELECT_TEACHER_FLOW;
}

interface UpdateAttendancesAction {
  type: typeof INSCHOOL__UPDATE_ATTENDANCES;
  newList: ClassRoomAttendanceRecord[];
}

export type InSchoolActionTypes =
  | loadAttendancesSuccess
  | InSchoolFailAction
  | LaunchInSchoolSuccessAction
  | SetAttendanceViewAction
  | SetCheckedStudentsAction
  | SetCurrentSectionAction
  | SetDateRangeAction
  | SetIsLoadingAction
  | SetIsUpdatingAction
  | SetSectionsAction
  | ToggleIsAbsentSelectedAction
  | ToggleIsAllSelectedAction
  | ToggleIsPresentSelectedAction
  | SetIsSelectTeacherFlowAction
  | HandleTeacherSearchAction
  | ClearSearchTextAction
  | HandleSelectTeacherAction
  | UpdateAttendancesAction
  | SetClassRoomSectionsLoading;

export type TSetCheckedRecords = (args: {
  checkedRecords: ClassRoomAttendanceRecord[];
}) => SetCheckedStudentsAction;

export type TToggleIsAbsentSelected = () => ToggleIsAbsentSelectedAction;

export type TToggleIsPresentSelected = () => ToggleIsPresentSelectedAction;

export type TToggleIsAllSelected = () => ToggleIsAllSelectedAction;

// Action handlers
export type TLoadAttendances = (args?: {
  date?: Date;
  sectionID?: string;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TGetAttendancesNext = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type TGetCurrentSectionAttendances = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TLaunchInSchool = (args: {
  activeSchoolID: string;
  selectedDate?: Date;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TCheckTeacherFlow = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type TLoadSectionAttendances = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type TRefreshUserAttendances = (args?: {
  date: Date;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TLoadUserAttendances = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type TGetSectionStudents = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, InSchoolActionTypes>;

export type TSetCurrentSection = (args: {
  sectionID: string;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TSetSelectedDate = (args: {
  date: Date;
  schoolYear?: string;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TFetchSections = (args: {
  userID: string;
}) => ThunkAction<Promise<void>, RootState, unknown, InSchoolActionTypes>;

export type TSetIsInSchoolLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetClassRoomSectionsLoading = (
  bool: boolean,
) => SetClassRoomSectionsLoading;

export type TSetIsUpdatingInSchool = (bool: boolean) => SetIsUpdatingAction;

export type TAddNewAttendanceRecord = (args: {
  data: Partial<INewAttendance>;
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
    data?: IAttendance[];
    error?: string;
  }>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type TUpdateAttendances = (args: {
  updates: Partial<ClassRoomAttendanceRecord>[];
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
    successfulRecords: ClassRoomAttendanceRecord[];
    error?: string;
  }>,
  RootState,
  unknown,
  InSchoolActionTypes
>;

export type THandleTeacherSearch = (
  searchText: string,
) => ThunkAction<void, RootState, unknown, InSchoolActionTypes>;

export type THandleSyncPendingData = (
  newRecords: IAttendance[] | undefined,
) => ThunkAction<void, RootState, unknown, InSchoolActionTypes>;

export type THandleSetSelectedTeacher = (
  selectedTeacher: string,
) => ThunkAction<void, RootState, unknown, InSchoolActionTypes>;

export interface ClearSearchTextAction {
  type: typeof INSCHOOL__CLEAR_SEARCH_TEXT;
}

export type TClearSearchText = () => ClearSearchTextAction;
