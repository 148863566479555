/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
import placeholderAvatar from 'assets/images/placeholder-avatar.jpg';
import { SyntheticEvent } from 'react';

export async function asyncForEach<T extends unknown>(
  array: T[],
  callback: (element: T, index?: number, array?: T[]) => any,
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const getUrlParam = (urlPath: string, param: string) => {
  if (!urlPath) return undefined;

  const url = new URL(urlPath);
  const searchParams = new URLSearchParams(url.search);
  return searchParams.get(param) || undefined;
};

export const onAvatarError = (
  event: SyntheticEvent<HTMLImageElement, Event>,
) => {
  const target = event.target as HTMLImageElement;
  target.onerror = null;
  target.src = placeholderAvatar;
};
