import { morphism, StrictSchema } from 'morphism';
import { IApiSection, IApiSectionPeriod } from '../types/sectionsApi.types';
import { ISection, ISectionPeriod } from 'types/section.types';
import {
  ISectionPeriodSchema,
  ISectionSchema,
} from './schemas/sectionsApi.schemas';

export const sectionMapper = (section: IApiSection) =>
  morphism<StrictSchema<ISection, IApiSection>>(ISectionSchema, section);

export const sectionPeriodMapper = (sectionPeriod: IApiSectionPeriod) =>
  morphism<StrictSchema<ISectionPeriod, IApiSectionPeriod>>(
    ISectionPeriodSchema,
    sectionPeriod,
  );
