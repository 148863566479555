import styled from 'styled-components';

export const Container = styled.div`
  background: #07081f;
  border-radius: 20px;
  max-width: 100%;
  width: 1420px;
  margin-top: 100px;

  height: 412px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    margin-left: ${({ theme }) => theme.homepageLeftMarginTablet};
    margin-right: ${({ theme }) => theme.homepageLeftMarginTablet};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media only screen and (max-width: 1131px) {
    flex-direction: column;
    gap: 20px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  padding-top: 80px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;

  @media only screen and (max-width: 1131px) {
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    padding-top: 24px;
    padding-left: 26px;
    padding-right: 26px;
    align-items: center;
  }

  @media only screen and (max-width: 500px) {
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
  }

  @media only screen and (max-width: 280px) {
    justify-content: flex-start;
    width: 100%;
    padding-left: 15px;
  }
`;

export const LeftText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  color: #ffffff;
  width: 100%;

  @media only screen and (max-width: 1131px) {
    width: auto;
    font-size: 22px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 340px) {
    font-size: 14px;
  }
`;

export const BePresentText = styled.span`
  color: #1a8ddc;
`;

export const RightContainer = styled.div`
  padding-top: 89px;
  padding-right: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 89px;
  gap: 24px;

  @media only screen and (max-width: 1131px) {
    gap: 30px;
    padding-top: 0px;
    padding-right: 26px;
    width: auto;
    padding-bottom: 30px;
    padding-left: 26px;
  }
`;

export const InnerRightTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;

  @media only screen and (max-width: 500px) {
    gap: 10px;
  }
`;

export const RightTextContainer = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1900px;
`;

export const RightText = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120%;
  color: #e9e9eb;

  @media only screen and (max-width: 500px) {
    font-size: 15px;
  }

  @media only screen and (max-width: 380px) {
    font-size: 13px;
  }
`;

export const BoldText = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 120%;
  color: #e9e9eb;

  @media only screen and (max-width: 380px) {
    font-size: 14px;
  }
`;

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const AuthorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AuthorName = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;

  @media only screen and (max-width: 380px) {
    font-size: 10px;
  }
`;

export const AuthorWork = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;

  @media only screen and (max-width: 380px) {
    font-size: 10px;
  }
`;
