import { Reducer } from 'redux';
import { sub, startOfDay, endOfDay } from 'date-fns';
import { ISchoolCases } from 'types/reports.types';
import * as Actions from './CovidReportActions.types';

export interface State {
  cases: ISchoolCases[];
  endDate: Date;
  isLoading: boolean;
  startDate: Date;
}

export const initialState: State = {
  cases: [],
  endDate: endOfDay(new Date()),
  isLoading: true,
  startDate: startOfDay(sub(new Date(), { days: 7 })),
};

const covidReportReducer: Reducer<
  State,
  Actions.CovidCasesReportsActionTypes
> = (
  state: State = initialState,
  action: Actions.CovidCasesReportsActionTypes,
): State => {
  switch (action.type) {
    // TODO: case Actions.COVID__FETCH_CASES_FAIL

    case Actions.COVID__FETCH_CASES_SUCCESS: {
      return {
        ...state,
        cases: action.cases,
      };
    }

    case Actions.COVID__HANDLE_DATE_RANGE_CHANGE: {
      return {
        ...state,
        endDate: action.endDate,
        startDate: action.startDate,
      };
    }

    case Actions.COVID__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    default:
      return state;
  }
};

export default covidReportReducer;
