import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';

import {
  IBus,
  IBusMessage,
  INewBusMessageInstance,
} from 'types/schoolBus.types';

export const BUS__ERROR = 'BUS__ERROR';
export const BUS__GET_ALERTS = 'BUS__GET_ALERTS';
export const BUS__LAUNCH_BUSES = 'BUS__LAUNCH_BUSES';
export const BUS__RESET_DATE_RANGE = 'BUS__RESET_DATE_RANGE';
export const BUS__SET_DATE_RANGE = 'BUS__SET_DATE_RANGE';
export const BUS__SET_IS_LOADING = 'BUS__SET_IS_LOADING';

interface BusErrorAction {
  type: typeof BUS__ERROR;
  errorMessage: string;
}

interface GetMessagesAction {
  type: typeof BUS__GET_ALERTS;
  alerts: IBusMessage[];
}

interface LaunchBusesAction {
  type: typeof BUS__LAUNCH_BUSES;
  buses: IBus[];
}

interface ResetDateRangeAction {
  type: typeof BUS__RESET_DATE_RANGE;
}

interface SetDateRangeAction {
  type: typeof BUS__SET_DATE_RANGE;
  endDate: Date;
  startDate: Date;
}

interface SetIsLoadingAction {
  type: typeof BUS__SET_IS_LOADING;
  bool: boolean;
}

export type BusActionTypes =
  | BusErrorAction
  | GetMessagesAction
  | LaunchBusesAction
  | ResetDateRangeAction
  | SetDateRangeAction
  | SetIsLoadingAction;

export type TAddNewBus = (args: {
  data: Partial<IBus>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusActionTypes
>;
export type TAddNewBusAlert = (args: {
  data: INewBusMessageInstance;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusActionTypes
>;

export type TDeleteBus = (args: {
  id: string;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusActionTypes
>;

export type TEditBusData = (args: {
  data: Partial<IBus>;
}) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  BusActionTypes
>;

export type TBusThunkAction = () => ThunkAction<
  void,
  RootState,
  unknown,
  BusActionTypes
>;

export type TLoadBusAlerts = (range: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, BusActionTypes>;

export type TResetDateRange = () => ResetDateRangeAction;

export type TSetDateRange = (range: {
  startDate: Date;
  endDate: Date;
}) => ThunkAction<void, RootState, unknown, BusActionTypes>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;
