import { Adapter } from 'data/types/api.types';
import { IApiAttendanceReasonCode } from 'data/types/attendanceApi.types';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import {
  IAttendanceEventType,
  IAttendanceStatus,
} from 'types/attendance.types';
import {
  ICurrentSchoolData,
  IState,
  ITransportationType,
} from 'types/resources.types';
import { ICalendarDate, IGender, IGrade, IRace } from 'types/school.types';
import { IBusResource, IBusRouteLabel } from 'types/schoolBus.types';

// Actions
export const RSRCS__ERROR = 'RSRCS__ERROR';
export const RSRCS__GET_ATTENDANCE_RESOURCES =
  'RSRCS__GET_ATTENDANCE_RESOURCES';
export const RSRCS__GET_BUS_MESSAGES_RSRCS = 'RSRCS__GET_BUS_MESSAGES_RSRCS';
export const RSRCS__GET_CALEDAR_DATES = 'RSRCS__GET_CALEDAR_DATES';
export const RSRCS__GET_CURR_SCHOOL_DATA = 'RSRCS__GET_CURR_SCHOOL_DATA';
export const RSRCS__GET_ROUTE_LABELS = 'RSRCS__GET_ROUTE_LABELS';
export const RSRCS__GET_STATES = 'RSRCS__GET_STATES';
export const RSRCS__SET_IS_LOADING = 'RSRCS__SET_IS_LOADING';

// Action types
interface GetAttendanceResourcesAction {
  type: typeof RSRCS__GET_ATTENDANCE_RESOURCES;
  currentSchoolData: ICurrentSchoolData;
  eventTypes: IAttendanceEventType[];
  reasons: IApiAttendanceReasonCode[];
  statuses: IAttendanceStatus[];
  transportationTypes: ITransportationType[];
  grades: IGrade[];
  races: IRace[];
  genders: IGender[];
}

interface GetBusMessagesResourcesAction {
  type: typeof RSRCS__GET_BUS_MESSAGES_RSRCS;
  busMessageTypes: IBusResource[];
  busMessageDeliveryMethods: IBusResource[];
}

interface GetCalendarDatesAction {
  type: typeof RSRCS__GET_CALEDAR_DATES;
  calendarDates: ICalendarDate[];
}

interface GetCurrentSchoolDetailsAction {
  type: typeof RSRCS__GET_CURR_SCHOOL_DATA;
  currentSchoolData: ICurrentSchoolData;
}

interface GetRouteLabelsAction {
  type: typeof RSRCS__GET_ROUTE_LABELS;
  labels: IBusRouteLabel[];
}

interface GetStatesAction {
  type: typeof RSRCS__GET_STATES;
  states: IState[];
}

interface SetErrorAction {
  error: string;
  type: typeof RSRCS__ERROR;
}

interface SetIsLoadingAction {
  type: typeof RSRCS__SET_IS_LOADING;
  bool: boolean;
}

export type AttendanceActionTypes =
  | GetAttendanceResourcesAction
  | GetBusMessagesResourcesAction
  | GetCalendarDatesAction
  | GetCurrentSchoolDetailsAction
  | GetRouteLabelsAction
  | GetStatesAction
  | SetErrorAction
  | SetIsLoadingAction;

// Action handlers
export type TResourcesThunkAction = (args?: {
  activeSchoolID: string;
  type: string;
}) => ThunkAction<Promise<void>, RootState, unknown, AttendanceActionTypes>;

export type TGetCalendarDates = (args?: {
  activeSchoolID: string;
  type: string;
}) => ThunkAction<
  Promise<Adapter<ICalendarDate[]>>,
  RootState,
  unknown,
  AttendanceActionTypes
>;

export type TGetAttendanceResourcesAction = (args?: {
  activeSchoolID?: string;
  type?: string;
  allStatuses?: boolean;
}) => ThunkAction<
  Promise<{
    currentSchoolData?: ICurrentSchoolData;
    eventTypes?: IAttendanceEventType[];
    grades?: IGrade[];
    races?: IRace[];
    genders?: IGender[];
    isSuccessful: boolean;
    statuses?: IAttendanceStatus[];
    transportationTypes?: ITransportationType[];
  }>,
  RootState,
  unknown,
  AttendanceActionTypes
>;

export type TSetIsAttendanceLoading = (bool: boolean) => SetIsLoadingAction;
