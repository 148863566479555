import API from 'data/axios';
import { reverseBusMessageMapper } from 'data/mappers/schoolBus.mappers';
import { IApiBus } from 'data/types/schoolBusApi.types';
import { INewBusMessageInstance } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter, Response } from '../../../types/api.types';

interface ICreateNewBusMessageArgs {
  data: INewBusMessageInstance;
}

export default async ({ data }: ICreateNewBusMessageArgs): Promise<Adapter> => {
  const newMsg = reverseBusMessageMapper(data);

  return API.post<Response<IApiBus>>(`/bus/route_instances/messages`, newMsg)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
