import axios from 'axios';
import { getAuthToken } from 'data/utils/authToken.utils';
import { MOCKING_STORAGE_KEY } from 'domains/pages/navigation/components/nav-options/components/mock-toggle/MockToggle';

/* eslint-disable @typescript-eslint/no-explicit-any */

const BASE_URL = process.env.REACT_APP_API_HOST;

const initialize = () => {
  const TOKEN = getAuthToken();

  const instance = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
      ...(TOKEN && { Authorization: `Bearer ${TOKEN}` }),
    },
  });
  return instance;
};

// intitializing axios instance
const API = initialize();

// update headers in token change
API.interceptors.request.use((config = {}) => {
  const TOKEN = getAuthToken();
  const isMocking = localStorage.getItem(MOCKING_STORAGE_KEY);

  if (TOKEN) {
    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${TOKEN}`,
      ...(isMocking ? { 'X-MASK-DATA': true } : {}),
    };
  }

  return config;
});

export { API as default };
