import { parseError } from 'utils/errors.utils';
import getSchoolUsers from 'data/api/queries/school/getSchoolUsers';

import { Adapter } from '../../types/api.types';
import { IUser, UserRolesEnum } from 'types/user.types';

interface IGetSchoolUsersArgs {
  pageSize?: number;
  role?: UserRolesEnum;
  schoolID: string;
  searchText?: string;
}

export default async ({
  pageSize,
  role,
  schoolID,
  searchText = '',
}: IGetSchoolUsersArgs): Promise<Adapter<IUser[]>> =>
  getSchoolUsers({
    pageSize,
    role,
    schoolID,
    searchText,
  })
    .then(({ data, next }) => ({ success: true, data, next }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
