import React from 'react';

import { Container } from './IconButtonStyles';

interface Props {
  disabled?: boolean;
  icon: string;
  onClick?: (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
  ) => void;
  type?: 'button' | 'submit' | 'reset';
  withShadow?: boolean;
}

const IconButton: React.FC<Props> = ({
  disabled = false,
  icon,
  onClick,
  withShadow = true,
  type = 'button',
}: Props) => {
  return (
    <Container
      withShadow={withShadow}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      <i className={icon} />
    </Container>
  );
};

export default IconButton;
