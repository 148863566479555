import { ISchool } from './school.types';
import { IUser } from './user.types';
import { TQuestion } from './surveys.types';
import { UserRolesEnum } from './user-old.types';

export interface IAudit {
  action: 'ADD' | 'CHANGE' | 'DELETE';
  id: string;
  newValue?: IAuditValue;
  oldValue?: IAuditValue;
  type: string;
  userAccountID: string;
  // TODO: the next fields should soon be expanded in the API response
  user?: IUser;
  student?: IUser;
  school?: ISchool;
}

export interface IAuditValue {
  calendarDate: {
    day: string;
    id: string;
    isStudentDay: boolean;
    isTeacherDay: boolean;
    schoolID: string;
  };
  comment?: string;
  eventDate?: Date;
  eventType: {
    id: string;
    key: string;
    name: string;
  };
  id: string;
  schoolID: string;
  sectionID?: string;
  status: {
    abbr: string;
    hexColor?: string;
    id: string;
    name: string;
    type: string;
  };
  studentID: string;
}

export interface ISurveyReport {
  isComplete: boolean;
  userRoles: UserRolesEnum[];
  userAccountId: string;
  name: string;
  date: Date;
}

export interface ISurveyResponse {
  updatedAt: string;
  answers: {
    id: string;
    value: boolean;
  }[];
  survey: {
    questions: TQuestion[];
  };
  userAccount: {
    name: {
      firstName: string;
      middleName: string;
      lastName: string;
    };
    id: string;
    roles: {
      student: { id: string } | null;
      staff: { id: string } | null;
      teacher: { id: string } | null;
    };
  };
}

export interface ISchoolCases {
  name: string;
  staffCases: number;
  studentCases: number;
  totalCases: number;
}

export enum SurveyReportViewsEnum {
  audit = 'audit',
  dailyParticipation = 'dailyParticipation',
}
