import React, { useEffect, useState } from 'react';

export const useOnScreen = (fetchNextPage: () => Promise<void>) => (
  ref: React.RefObject<HTMLElement>,
): void => {
  const [didSendRequest, setDidSendRequest] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && fetchNextPage && !didSendRequest) {
      setDidSendRequest(true);
      fetchNextPage().then(() => setDidSendRequest(false));
    }
  });

  useEffect(() => {
    ref.current && observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => observer.disconnect();
  }, []);
};
