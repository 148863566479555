import { Spinner } from 'components/spinner';
import React from 'react';

import { Container } from './LoadingPageStyles';

interface Props {
  isVisible?: boolean;
}

const LoadingPage: React.FC<Props> = ({ isVisible }: Props) => {
  return isVisible ? (
    <Container>
      <Spinner />
    </Container>
  ) : null;
};

export default LoadingPage;
