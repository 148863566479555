import { morphism, StrictSchema } from 'morphism';
import { IApiUser } from '../types/userApi.types';
import { IUser } from 'types/user.types';
import { IReverseUserSchema, IUserSchema } from './schemas/userApi.schemas';

export const userMapper = (user: IApiUser) =>
  morphism<StrictSchema<IUser, IApiUser>>(IUserSchema, user);

export const reverseUserMapper = (user: Partial<IUser>) =>
  morphism<StrictSchema<IApiUser, Partial<IUser>>>(IReverseUserSchema, user);
