import React, { useMemo } from 'react';

import {
  Body,
  BookTimeToMeetButton,
  Container,
  LeftContainer,
  Title,
} from './InSchoolAttendanceStyles';
import { Logo } from './icons';
import { ReactComponent as RightImage } from './academics1.svg';
import { useTranslation } from 'react-i18next';
import useDimensions from 'hooks/useDimensions';

const inSchoolText = 'In School';
const attendanceText = 'Attendance';

const body =
  'Attending school regularly ensures that students receive consistent instruction and have the opportunity to engage with their peers and teachers. In after-school activities, regular attendance helps students build skills, develop relationships with their coaches or mentors, and contribute to the success of their team or group.';

const bookTimeToMeetText = 'Book Time to Meet';

export const InSchoolAttendance = ({
  setCalendlyModalOpen,
}: {
  setCalendlyModalOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();

  const tTitle = t(inSchoolText);
  const tAttendance = t(attendanceText);
  const tBody = t(body);
  const tBookTimeToMeet = t(bookTimeToMeetText);

  const { ref, dimensions } = useDimensions();

  const isMobile = useMemo(() => dimensions.width && dimensions.width <= 1300, [
    dimensions.width,
  ]);

  const isXS = useMemo(() => dimensions.width && dimensions.width <= 470, [
    dimensions.width,
  ]);

  return (
    <Container id="in-school" ref={ref}>
      <LeftContainer>
        <Logo
          width={isXS ? '60' : undefined}
          height={isXS ? '60' : undefined}
        />
        <Title>
          {tTitle}
          {!isMobile && <br />}
          {tAttendance}
        </Title>
        <Body>{tBody}</Body>
        <BookTimeToMeetButton onClick={() => setCalendlyModalOpen(true)}>
          {tBookTimeToMeet}
        </BookTimeToMeetButton>
      </LeftContainer>
      <RightImage
        width={
          isMobile ? (dimensions.width ? dimensions.width * 0.9 : '50%') : '50%'
        }
        height={isMobile ? 'auto' : '100%'}
      />
    </Container>
  );
};
