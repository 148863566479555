import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from 'routes';
import { LogoFull, MobileLogo } from './components/logo-full/LogoFull';
import {
  BookTimeToMeetButton,
  Container,
  LastContainer,
  LogoContainer,
  MiddleContainer,
  MobileContainer,
  MobileDownloadContainer,
  MobileLastContainer,
  MobileMiddleContainer,
  StyledLink,
  TopNavText,
} from './HeaderNavStyles';
import useDimensions from 'hooks/useDimensions';
import { CloseIcon, HamburgerMenuIcon } from './assets/icons';
import { DownloadButton } from './components/download-btn/DownloadButton';
import {
  AppStoreIcon,
  PlayStoreIcon,
} from 'domains/pages/splash-page/components/new-hero/assets/icons';
import { useAppSelector } from 'state/hooks';

interface Props {
  shouldShowNav?: boolean;
  setCalendlyModalOpen?: (isOpen: boolean) => void;
}

const loginText = 'Log In';
const inSchoolAttendanceText = 'In School Attendance';
const outOfSchoolAttendanceText = 'Out of School Attendance';
// const tiaText = 'TIA.AI';
const partnersText = 'Partners';
// const testimonialsText = 'Testimonials';
const bookTimeToMeetText = 'Book Time to Meet';

const HeaderNav: React.FC<Props> = ({
  shouldShowNav = false,
  setCalendlyModalOpen,
}: Props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const loggedUser = useAppSelector(({ auth }) => auth.user);
  const userName = loggedUser?.firstName;
  const tLoginText = !userName ? t(loginText) : `Welcome, ${userName}`;
  const tInSchoolAttendanceText = t(inSchoolAttendanceText);
  const tOutOfSchoolAttendanceText = t(outOfSchoolAttendanceText);
  // const tTiaText = t(tiaText);
  const tPartnersText = t(partnersText);
  // const tTestimonialsText = t(testimonialsText);
  const tBookTimeToMeetText = t(bookTimeToMeetText);

  const isOnLoginPage = PATHS.login === location.pathname;
  const isOnForgotPassword = PATHS.forgotPassword === location.pathname;
  const navigate = useNavigate();

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  };

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  const { ref, dimensions } = useDimensions();
  const isMobile = useMemo(
    () => (dimensions?.width ? dimensions.width <= 1300 : false),
    [dimensions],
  );

  return (
    <>
      <Container
        ref={ref}
        isOnLoginPage={isOnLoginPage}
        isOnForgotPassword={isOnForgotPassword}
      >
        {/* Left Most Item */}
        <StyledLink replace to={PATHS.home}>
          <LogoContainer>
            {isMobile ? (
              <MobileLogo />
            ) : isOnForgotPassword || isOnLoginPage ? (
              <MobileLogo />
            ) : (
              <LogoFull />
            )}
          </LogoContainer>
        </StyledLink>

        {shouldShowNav && (
          <>
            {/* Middle Item(s) */}
            {isMobile ? (
              <BookTimeToMeetButton
                onClick={() => {
                  setCalendlyModalOpen && setCalendlyModalOpen(true);
                }}
              >
                {tBookTimeToMeetText}
              </BookTimeToMeetButton>
            ) : (
              <MiddleContainer>
                <TopNavText
                  onClick={() => {
                    scrollTo('partners');
                  }}
                >
                  {tPartnersText}
                </TopNavText>
                <TopNavText
                  onClick={() => {
                    if (location.pathname == '/') {
                      scrollTo('in-school');
                    } else {
                      navigate('/');
                    }
                  }}
                >
                  Attendance
                </TopNavText>
                <TopNavText
                  onClick={() => {
                    navigate(PATHS.academics);
                  }}
                >
                  Academics
                </TopNavText>
                {/* <TopNavText
                  onClick={() => {
                    scrollTo('tia');
                  }}
                >
                  {tTiaText}
                </TopNavText> */}

                {/* <TopNavText
                  onClick={() => {
                    scrollTo('testimonials');
                  }}
                >
                  {tTestimonialsText}
                </TopNavText> */}
              </MiddleContainer>
            )}

            {/* Right Most Item(s) */}
            {isMobile ? (
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
                }}
              >
                {isHamburgerMenuOpen ? <CloseIcon /> : <HamburgerMenuIcon />}
              </button>
            ) : (
              <LastContainer>
                <TopNavText
                  isActive={isOnLoginPage}
                  className={
                    isOnLoginPage || isOnForgotPassword
                      ? 'desktop'
                      : 'undefined'
                  }
                >
                  <StyledLink replace to={PATHS.login}>
                    {tLoginText}
                  </StyledLink>
                </TopNavText>

                {!isOnLoginPage && !isOnForgotPassword && (
                  <BookTimeToMeetButton
                    onClick={() => {
                      setCalendlyModalOpen && setCalendlyModalOpen(true);
                    }}
                  >
                    {tBookTimeToMeetText}
                  </BookTimeToMeetButton>
                )}
              </LastContainer>
            )}
          </>
        )}
      </Container>

      {/* Mobile Hamburger Menu */}
      {isMobile && (
        <MobileContainer isOpen={isHamburgerMenuOpen} isMobile={isMobile}>
          {/* Middle Container */}
          <MobileMiddleContainer>
            <TopNavText
              onClick={() => {
                scrollTo('partners');
              }}
            >
              {tPartnersText}
            </TopNavText>
            <TopNavText
              onClick={() => {
                if (location.pathname == '/') {
                  scrollTo('in-school');
                } else {
                  navigate('/');
                }
              }}
            >
              Attendance
            </TopNavText>
            <TopNavText
              onClick={() => {
                navigate(PATHS.academics);
              }}
            >
              Academics
            </TopNavText>
            {/* <TopNavText
              onClick={() => {
                scrollTo('tia');
              }}
            >
              {tTiaText}
            </TopNavText> */}

            {/* <TopNavText
              onClick={() => {
                scrollTo('testimonials');
              }}
            >
              {tTestimonialsText}
            </TopNavText> */}
          </MobileMiddleContainer>

          {/* Last Container */}
          <MobileLastContainer>
            <TopNavText isActive={isOnLoginPage} className="login">
              <StyledLink replace to={PATHS.login}>
                {tLoginText}
              </StyledLink>
            </TopNavText>
            <MobileDownloadContainer>
              <DownloadButton
                link="https://apps.apple.com/us/app/schoolday-ai/id1625011643"
                title="download on the"
                text="App Store"
                icon={<AppStoreIcon />}
              />
              <DownloadButton
                link="https://play.google.com/store/apps/details?id=com.safermgmt.district"
                title="get it on"
                text="Google Play"
                icon={<PlayStoreIcon />}
              />
            </MobileDownloadContainer>
          </MobileLastContainer>
        </MobileContainer>
      )}
    </>
  );
};

export default HeaderNav;
