import { Reducer } from 'redux';
import * as Actions from './videosTutorialActions.types';
import { IVideoTutorial } from 'types/videoTutorial.types';
import firebase from 'firebase/app';

export interface State {
  videos: IVideoTutorial[];
  isLoading: boolean;
  isLoadingMore: boolean;
  nextPage?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
  allFetched?: boolean;
  selectedVideo?: IVideoTutorial | null;
}

export const initialState: State = {
  isLoading: true,
  isLoadingMore: false,
  videos: [],
  nextPage: null,
  allFetched: false,
  selectedVideo: null,
};

const VideosTutorialReducer: Reducer<State, Actions.VideosActions> = (
  state: State = initialState,
  action: Actions.VideosActions,
): State => {
  switch (action.type) {
    case Actions.VIDEOS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.VIDEOS__SET_IS_LOADING_MORE: {
      return {
        ...state,
        isLoadingMore: action.bool,
      };
    }

    case Actions.VIDEOS__FETCH_SUCCESS: {
      return {
        ...state,
        videos: action.appendData
          ? [...state.videos, ...action.data]
          : action.data,
        nextPage: action.nextPage,
        allFetched: action.allFetched,
      };
    }

    case Actions.VIDEOS__RESET: {
      return initialState;
    }

    case Actions.VIDEOS__SET_SELECTED_VIDEO: {
      return {
        ...state,
        selectedVideo: action.video,
      };
    }

    case Actions.VIDEOS__SET_WATCHED: {
      return {
        ...state,
        videos: state.videos.map((video) => {
          if (video.id === action.video.id) {
            return {
              ...video,
              watched: true,
            };
          }
          return video;
        }),
      };
    }

    default:
      return state;
  }
};

export default VideosTutorialReducer;
