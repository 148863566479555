import { StrictSchema } from 'morphism';
import { IApiCourse } from '../../types/courseApi.types';
import { ICourse } from 'types/course.types';

export const ICourseSchema: StrictSchema<ICourse, IApiCourse> = {
  createdAt: (iteratee) => new Date(iteratee.created_at),
  districtID: 'district',
  id: 'id',
  name: 'name',
  number: (iteratee) => iteratee.number || undefined,
  shortName: 'short_name',
  sisID: 'sis_id',
  updatedAt: (iteratee) => new Date(iteratee.updated_at),
};
