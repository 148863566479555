import { isBefore, isAfter } from 'date-fns';
import groupBy from 'lodash/groupBy';

import { getAttendanceResources } from '../../resources/ResourcesActions';

import getSchoolAttendances from 'data/adapters/school/getSchoolAttendances';
import getSchoolUsers from 'data/adapters/school/getSchoolUsers';

import { UserRolesEnum } from 'types/user.types';
import {
  IAttendanceByData,
  IAttendanceCountByDayDatum,
  IAttendanceCountByStudentData,
  IAttendanceStatusByStudentData,
  StatusEnum,
} from 'types/attendance.types';
import * as Actions from './AttendanceDashboardAction.types';

export const togglePendingSelected: Actions.TTogglePendingSelected = () => ({
  type: Actions.DASHBOARD__TOGGLE_IS_PENDING_SELECTED,
});

export const setDashboardView: Actions.TSetDashboardView = (view) => ({
  type: Actions.DASHBOARD__SET_VIEW,
  view,
});

export const setIsDashboardLoading: Actions.TSetIsDashboardLoading = (
  bool,
) => ({
  type: Actions.DASHBOARD__SET_IS_LOADING,
  bool,
});

export const toggleIsPresentSelected: Actions.TToggleIsPresentSelected = () => ({
  type: Actions.DASHBOARD__TOGGLE_IS_PRESENT_SELECTED,
});

export const toggleIsAbsentSelected: Actions.TToggleIsAbsentSelected = () => ({
  type: Actions.DASHBOARD__TOGGLE_IS_ABSENT_SELECTED,
});

export const getAttendanceCounts: Actions.TGetAttendanceCounts = ({
  startDate,
  endDate,
}) => async (dispatch, getState) => {
  try {
    dispatch(setIsDashboardLoading(true));
    const state = getState();
    const schoolID = state.auth.activeSchool?.id;
    const currentSchool = (state.auth.user?.schools || []).find(
      (school) => school.id === schoolID,
    );

    if (!schoolID || !currentSchool) {
      throw 'Failed to get current organization';
    }

    const { data: records = [] } = await getSchoolAttendances({
      endDate,
      schoolID,
      startDate,
    });

    const { data: students = [] } = await getSchoolUsers({
      schoolID,
      role: UserRolesEnum.student,
    });

    const attendanceCountsByDayData: IAttendanceCountByDayDatum[] = [];
    const attendanceStatusByStudentData: IAttendanceStatusByStudentData[] = [];
    const attendanceCountsByStudentData: IAttendanceCountByStudentData[] = [];
    const attendanceByData: IAttendanceByData = {
      TotalPresent: 0,
      TotalAbsent: 0,
      TotalPending: 0,
      Total: 0,
    };

    for (const attendanceRecord of records) {
      const student = students.find(
        ({ id }) => id === attendanceRecord.studentID,
      );

      if (!student) continue;

      const {
        Total,
        TotalPending,
        TotalAbsent,
        TotalPresent,
      } = attendanceByData;
      const { eventDate, status } = attendanceRecord;
      const { firstName, lastName, roles } = student;

      if (!roles || !roles.student) continue;

      const gradeID = roles.student.grade?.id;

      const statusByStudent: IAttendanceStatusByStudentData = {
        eventDate,
        schoolName: currentSchool.name,
        grade: gradeID ?? '', // TODO: fetch grade ids
        abbr: status.abbr,
        studentID: student.id,
        status: status.name,
        name: `${firstName} ${lastName}`,
        location: currentSchool.location.id,
        isAbsent: false,
        isPending: false,
        isPresent: false,
      };

      switch (status.abbr) {
        case StatusEnum.UNK:
          attendanceByData.Total = Total + 1;
          attendanceByData.TotalPending = TotalPending + 1;
          statusByStudent.isPending = true;
          break;
        case StatusEnum.A:
          attendanceByData.Total = Total + 1;
          attendanceByData.TotalAbsent = TotalAbsent + 1;
          statusByStudent.isAbsent = true;
          break;
        case StatusEnum.U:
          attendanceByData.Total = Total + 1;
          attendanceByData.TotalAbsent = TotalAbsent + 1;
          statusByStudent.isAbsent = true;
          break;
        case StatusEnum.OCT:
        case StatusEnum.P:
        case StatusEnum.RAE:
          attendanceByData.Total = Total + 1;
          attendanceByData.TotalPresent = TotalPresent + 1;
          statusByStudent.isPresent = true;
      }
      attendanceStatusByStudentData.push(statusByStudent);
    }

    const groupedData = groupBy(records, 'eventDate');
    for (const key in groupedData) {
      const count = {
        ABSENT: 0,
        ON_CAMPUS_TARDY: 0,
        PENDING: 0,
        PRESENT: 0,
        REMOTE_ASYNCHRONOUS_ENGAGED: 0,
        UNEXCUSED_ABSENSE: 0,
      };
      for (const _data of groupedData[key]) {
        const abbr = _data.status.abbr;
        switch (abbr) {
          case StatusEnum.A:
            count.ABSENT++;
            break;
          case StatusEnum.OCT:
            count.ON_CAMPUS_TARDY++;
            break;
          case StatusEnum.UNK:
            count.PENDING++;
            break;
          case StatusEnum.P:
            count.PRESENT++;
            break;
          case StatusEnum.RAE:
            count.REMOTE_ASYNCHRONOUS_ENGAGED++;
            break;
          case StatusEnum.U:
            count.UNEXCUSED_ABSENSE++;
            break;
        }
      }

      attendanceCountsByDayData.push({
        count,
        date: key,
      });
    }

    const groupedStudentData = groupBy(
      attendanceStatusByStudentData,
      'studentID',
    );
    for (const studentID in groupedStudentData) {
      const studentData = groupedStudentData[studentID];
      const { name, location, schoolName, grade } = studentData[0];
      const abbrGroup = groupBy(studentData, 'abbr');
      const excused = abbrGroup[StatusEnum.A]?.length ?? 0;
      const unexcused = abbrGroup[StatusEnum.U]?.length ?? 0;
      attendanceCountsByStudentData.push({
        excused,
        grade,
        name,
        location,
        schoolName,
        studentID,
        total: excused + unexcused,
        unexcused,
      });
    }

    dispatch({
      attendanceByData,
      attendanceCountsByDayData,
      attendanceCountsByStudentData,
      attendanceStatusByStudentData,
      type: Actions.DASHBOARD__GET_ATTENDANCE_COUNTS_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: Actions.DASHBOARD__GET_ATTENDANCE_COUNTS_FAIL,
    });
  } finally {
    dispatch(setIsDashboardLoading(false));
  }
};

export const launchAttendanceDashboard: Actions.TLaunchAttendanceDashboard = () => async (
  dispatch,
  getState,
) => {
  const { startDate, endDate } = getState().attendanceDashboard;
  dispatch(getAttendanceCounts({ startDate, endDate }));
  dispatch(getAttendanceResources());
};

export const handleDateRangeChange: Actions.THandleDateRangeChange = ({
  startDate: newStartDate,
  endDate: newEndDate,
}) => async (dispatch, getState) => {
  try {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
    } = getState().attendanceDashboard;

    const isNewStartDateOutOfRange = isBefore(newStartDate, prevStartDate);
    const isNewEndDateOutOfRange = isAfter(newEndDate, prevEndDate);

    if (isNewStartDateOutOfRange || isNewEndDateOutOfRange) {
      dispatch(
        getAttendanceCounts({
          startDate: newStartDate,
          endDate: newEndDate,
        }),
      );
    }

    dispatch({
      type: Actions.DASHBOARD__HANDLE_DATE_RANGE_CHANGE,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  } catch {
    dispatch({
      type: Actions.DASHBOARD__GENERIC_FAIL,
    });
  }
};
