import { Reducer } from 'redux';
import * as Actions from './BusAttendanceActions.types';

/* Bus Attendances */

export type State = {
  currentRouteID?: string;
  endDate: Date;
  isLoading: boolean;
  startDate: Date;
};

const today = new Date();
const initialState = {
  startDate: today,
  endDate: today,
  isLoading: false,
};

const BusAttendenceReducer: Reducer<State, Actions.BusAttendenceActionTypes> = (
  state: State = initialState,
  action: Actions.BusAttendenceActionTypes,
) => {
  switch (action.type) {
    case Actions.BUS_ATDNC__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.BUS_ATDNC__SET_CURRENT_ROUTE: {
      return {
        ...state,
        currentRouteID: action.routeID,
      };
    }

    case Actions.BUS_ATDNC__DATE_RANGE_CHANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    default:
      return state;
  }
};

export default BusAttendenceReducer;
