import * as Actions from './BusAttendanceActions.types';

export const handleDateRangeChange: Actions.THandleDateRangeChange = ({
  startDate,
  endDate,
}) => ({
  type: Actions.BUS_ATDNC__DATE_RANGE_CHANGE,
  startDate,
  endDate,
});

export const setCurrentRouteID: Actions.TSetCurrentRouteID = ({ routeID }) => ({
  type: Actions.BUS_ATDNC__SET_CURRENT_ROUTE,
  routeID,
});

export const setIsBusAttendancesLoading: Actions.TSetIsLoading = (
  bool: boolean,
) => ({
  type: Actions.BUS_ATDNC__SET_IS_LOADING,
  bool,
});
