import styled from 'styled-components';

export const Container = styled.div`
  background: #1a8ddc;
  border-radius: 20px;
  width: 100%;
  max-width: 1420px;
  margin-top: 49px;

  padding-bottom: 50px;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 1300px) {
    display: flex;
    align-items: center;
    padding: 24px;
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-left: 60px;

  width: 37%;
`;

export const RightContainer = styled.div`
  padding-top: 60px;
  position: relative;
  display: inline-block;
  padding-left: 100px;

  @media screen and (max-width: 1300px) {
    padding-top: 0;
    padding-left: 0;
  }
  @media screen and (min-width: 1300px) {
    padding-top: 60px;
    padding-left: 0;
  }

  /* width: 60%; */
`;

export const ImageTextContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 140px;
`;

export const SmallText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 16px;

  @media screen and (max-width: 1300px) {
    font-size: 16px;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
    margin-bottom: 4px;
  }
`;

export const BigText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 36px;

  @media screen and (max-width: 1300px) {
    font-size: 60px;
    margin-bottom: 16px;
    max-width: 400px;
  }

  @media screen and (max-width: 600px) {
    font-size: 40px;
    margin-bottom: 16px;
    max-width: 300px;
  }
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 36px;
  border: 1px solid rgba(255, 255, 255, 0.3);
`;

export const DownloadText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 16px;

  @media screen and (max-width: 260px) {
    font-size: 16px;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  & > *:first-child {
    width: 90%;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;
    width: 100%;

    & > *:first-child {
      width: 100%;
    }
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
