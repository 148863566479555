import PrintIDBadge from 'components/attendance-update-tools/utils/PrintIDBadge';
import { format } from 'date-fns';
import AddAttendanceModal from 'domains/people/students/view-student/components/add-attendance-modal/AddAttendanceModal';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { addFrontOfficeAttendance } from 'state/front-office/FrontOfficeActions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { AttendanceTagEnum } from 'types/attendance.types';
import { IStudent } from 'types/student.types';
import { formatName } from 'utils/person.utils';

interface IAddAttendanceProps {
  barcodeScanValue?: string;
  id?: string;
  statusID?: string;
  comment: string;
  trackingMethod: string;
  reasonCode?: string | null;
  eventDate?: Date;
}

const ScanningContext = createContext<{
  isScanningEnabled: boolean;
  setIsScanningEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isScanningLocationEnabled: boolean;
  setIsScanningLocationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  scanningCheckOutVisible: boolean;
  setScanningCheckOutVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onAddAttendance: (data: IAddAttendanceProps) => void;
}>({
  isScanningEnabled: false,
  // eslint-disable-next-line
  setIsScanningEnabled: () => {},
  isScanningLocationEnabled: false,
  // eslint-disable-next-line
  setIsScanningLocationEnabled: () => {},
  scanningCheckOutVisible: false,
  // eslint-disable-next-line
  setScanningCheckOutVisible: () => {},
  // eslint-disable-next-line
  onAddAttendance: () => {},
});

export function useScanning() {
  return useContext(ScanningContext);
}

export function ScanningProvider({ children }: { children: React.ReactNode }) {
  const [isScanningEnabled, setIsScanningEnabled] = useState(true);
  const [printCurrentStudent, setPrintCurrentStudent] = useState(false);
  const [isScanningLocationEnabled, setIsScanningLocationEnabled] = useState(
    true,
  );
  const [scanningCheckOutVisible, setScanningCheckOutVisible] = useState(false);
  const { activeSchool, district } = useAppSelector(({ auth }) => auth);
  const { calendarDates, grades, eventTypes } = useAppSelector(
    ({ resources }) => resources,
  );
  const { currentStudent } = useAppSelector(({ students }) => students);
  const { isPrintingOn, fetchingType } = useAppSelector(
    ({ frontOffice }) => frontOffice,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (printCurrentStudent && currentStudent && isPrintingOn) {
      printBadgeDetails(currentStudent);
      setPrintCurrentStudent(false);
    }
  }, [printCurrentStudent, isPrintingOn]);

  const printBadgeDetails = async (student: IStudent) => {
    if (student) {
      const studentName = formatName(student);
      const schoolName = activeSchool?.name;
      const logoUrl = district?.logoUrl;
      const gradeID = student?.roles?.student?.grade?.id;
      const grade = grades.find(({ id }) => id === gradeID)?.name;

      await PrintIDBadge({ studentName, schoolName, logoUrl, grade });
    }
  };

  const onAddAttendance = (
    _data: IAddAttendanceProps,
  ): Promise<{ isSuccessful: boolean }> => {
    const currDate = new Date();
    const currentCalendarDate = calendarDates.find(({ day }) =>
      day.includes(format(currDate, 'yyyy-MM-dd')),
    );

    const data = {
      studentID: _data.barcodeScanValue
        ? undefined
        : _data.id ?? currentStudent?.id,
      barcodeScanValue: _data.barcodeScanValue,
      calendarDate: currentCalendarDate?.id,
      eventDate: _data.eventDate,
      schoolID: activeSchool?.id,
      status: _data.statusID,
      comment: _data.comment,
      trackingMethod: _data.trackingMethod,
      reasonCode: _data.reasonCode,
      tags:
        fetchingType === 'front-office-type'
          ? [AttendanceTagEnum.FRONT_OFFICE]
          : undefined,
    };

    return dispatch(addFrontOfficeAttendance({ data })).then(
      async ({ isSuccessful }) => {
        if (isSuccessful) {
          const isCheckOut =
            eventTypes.find((item) => item.id === _data.trackingMethod)?.key ===
            'check_out';
          if (!isCheckOut) {
            setPrintCurrentStudent(true);
          }
        }
        return { isSuccessful };
      },
    );
  };

  return (
    <ScanningContext.Provider
      value={{
        isScanningEnabled,
        setIsScanningEnabled,
        isScanningLocationEnabled,
        setIsScanningLocationEnabled,
        scanningCheckOutVisible,
        setScanningCheckOutVisible,
        onAddAttendance,
      }}
    >
      {children}
      <AddAttendanceModal
        onSave={onAddAttendance}
        visible={scanningCheckOutVisible}
        onClose={() => {
          setScanningCheckOutVisible(false);
        }}
      />
    </ScanningContext.Provider>
  );
}
