import API from 'data/axios';
import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../types/api.types';
import {
  AttendanceMapper,
  ReverseNewAttendanceMapper,
} from 'data/mappers/attendace.mappers';
import { IApiAttendance } from 'data/types/attendanceApi.types';
import { IAttendance, INewAttendance } from 'types/attendance.types';

interface IAddNewAttendanceArgs {
  data: Partial<INewAttendance> | Partial<INewAttendance>[];
}

export default async ({
  data: input,
}: IAddNewAttendanceArgs): Promise<Adapter<IAttendance[]>> => {
  const records = Array.isArray(input) ? input : [input];
  const data = records.map(ReverseNewAttendanceMapper);

  return API.post<IApiAttendance[]>(`/attendances`, {
    data,
  })
    .then(({ data }) => {
      const AddedRecords = data.map((rec) => AttendanceMapper(rec));
      return { success: true, data: AddedRecords };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
