import i18n from 'i18n';
import { Cell, Column } from 'react-table';
import { IStudent } from 'types/student.types';
import { IUser } from 'types/user.types';

const parseContactName = (contact: IUser) => {
  if (!contact?.firstName && !contact?.lastName) {
    return 'N/A';
  }
  return contact?.firstName + ' ' + contact?.lastName;
};

export default (selectedStudent: IStudent | null) =>
  ([
    {
      accessor: (row) => row,
      Cell: (props: Cell) => {
        return parseContactName(props.value);
      },
      disableFilters: true,
      disableSortBy: true,
      Header: i18n.t('Parent/Guardian Name'),
      id: 'name',
    },
    {
      accessor: (row) => row,
      Cell: (props: Cell) => {
        return (
          props.value.roles?.contact?.students?.find(
            (student: IUser) => student.id === selectedStudent?.id,
          )?.relationship ?? 'N/A'
        );
      },
      disableFilters: true,
      disableSortBy: true,
      Header: i18n.t('Relationship'),
      id: 'relationship',
    },
  ] as Column<IUser>[]) as Column[];
