import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  height: auto;
  max-height: 60vh;
  overflow-y: auto;
  margin: 40px 0px;
  font-size: 16px;
`;

export const Inner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

export const H2 = styled.h2`
  font-size: 25px;
  line-height: 25px;
`;

export const Img = styled.img`
  align-self: center;
`;

export const P = styled.p`
  color: ${({ theme }) => theme.primaryBlueDark};
  font: ${({ theme }) => theme.fontBold};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 25px;
  text-align: left;
`;

export const Prompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;
