import styled from 'styled-components';

interface IContainer {
  isExpanded?: boolean;
  isVisible?: boolean;
}

interface IBtn {
  isDisabled?: boolean;
}

export const OptionsBarContainer = styled.div<IContainer>`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryBlueLight};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  transition: height 500ms;

  ${({ isVisible = true, isExpanded = false }) =>
    isExpanded ? `height: 50px;` : isVisible ? 'height: 50px;' : 'height: 0px;'}
`;

export const LightButton = styled.div<IBtn>`
  margin-left: 10px;
  color: ${({ theme }) => theme.secondaryBlack};
  background-color: ${({ theme }) => theme.primaryYellow};
  border: 1px solid ${({ theme }) => theme.primaryYellow};
  border-radius: 10px;
  height: 30px;
  padding: 5px 10px;
  width: fit-content;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:hover {
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    background: ${({ theme }) => theme.secondaryYellowLight};
  }
`;
