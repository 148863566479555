import { Reducer } from 'redux';

import { IAttendance, IAttendanceStatus } from 'types/attendance.types';
import * as Actions from './FrontOfficeActions.types';
import {
  FRONT_OFFICE_OPTIONS,
  FRONT_OFFICE_TYPES_TRACKING_TYPES,
} from './FrontOfficeActions';

/* Buses & Bus Alerts */

export interface State {
  attendances: IAttendance[];
  defaultStatus?: IAttendanceStatus | null;
  endDate: Date;
  isLoading: boolean;
  isPrintingOn: boolean;
  defaultTrackingMethod: 'check_in' | 'check_out' | 'section';
  defaultReason: string;
  next?: string;
  pageSize: number;
  startDate: Date;
  UpdatingIDs: string[]; // The IDs of the attendances being updated
  selectedRecords: string[];
  fetchingType: Actions.TFrontOfficeFetchingTypes;
  filteredStatus: string | undefined;
  trackingMethodsOptions: { label: string; value: string }[];
}

const today = new Date();
const initialState: State = {
  attendances: [],
  endDate: today,
  isLoading: false,
  isPrintingOn: true,
  defaultReason: '',
  defaultTrackingMethod: 'check_in',
  pageSize: 50,
  startDate: today,
  UpdatingIDs: [],
  selectedRecords: [],
  fetchingType: 'front-office-type',
  filteredStatus: undefined,
  trackingMethodsOptions: FRONT_OFFICE_OPTIONS,
};

const FrontOfficeReducer: Reducer<State, Actions.FrontOfficeActionTypes> = (
  state: State = initialState,
  action: Actions.FrontOfficeActionTypes,
) => {
  switch (action.type) {
    case Actions.OFFICE__LAUNCH_FRONT_OFFICE: {
      return {
        ...state,
        selectedRecords: [],
      };
    }

    case Actions.OFFICE__LOAD_ATTENDANCES_LIST: {
      return {
        ...state,
        attendances: action.attendances,
        next: action.next,
      };
    }

    case Actions.OFFICE__GET_NEXT_SUCCESS: {
      return {
        ...state,
        attendances: [...state.attendances, ...action.data],
        next: action.next,
      };
    }

    case Actions.OFFICE__UPDATE_ATTENDANCE: {
      return {
        ...state,
        attendances: action.attendances,
      };
    }

    case Actions.OFFICE__ADD_ATTENDANCE: {
      return {
        ...state,
        attendances: [action.newRecord, ...state.attendances],
      };
    }

    case Actions.OFFICE__SET_DATE_RANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        selectedRecords: [],
      };
    }

    case Actions.OFFICE__SET_DEFAULT_STATUS: {
      return {
        ...state,
        defaultStatus: action.status,
      };
    }

    case Actions.OFFICE__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.OFFICE__SET_IS_PRINTING_ON: {
      return {
        ...state,
        isPrintingOn: action.bool,
      };
    }

    case Actions.OFFICE__SET_DEFAULT_TRACKING_METHOD: {
      return {
        ...state,
        defaultTrackingMethod: action.data,
      };
    }

    case Actions.OFFICE__SET_DEFAULT_REASON: {
      return {
        ...state,
        defaultReason: action.data,
      };
    }

    case Actions.OFFICE__SET_IS_UPDATING: {
      return {
        ...state,
        UpdatingIDs: action.ids || [],
      };
    }

    case Actions.OFFICE__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.OFFICE__DELETE_ATTENDANCE: {
      return {
        ...state,
        attendances: state.attendances.filter(
          (attendance) => attendance.id !== action.attendanceID,
        ),
      };
    }

    case Actions.OFFICE__TOGGLE_ROW_SELECTION: {
      const isSelected = state.selectedRecords.includes(action.recordID);

      return {
        ...state,
        selectedRecords: isSelected
          ? state.selectedRecords.filter((id) => id !== action.recordID)
          : [...state.selectedRecords, action.recordID],
      };
    }

    case Actions.OFFICE__TOGGLE_SELECTION: {
      return {
        ...state,
        selectedRecords: state.selectedRecords.length
          ? []
          : state.attendances.map(({ id }) => id),
      };
    }

    case Actions.OFFICE__RESET: {
      return initialState;
    }

    case Actions.OFFICE_CHANGE_FRONT_OFFICE_FILTER: {
      const targetFetchingType = action.fetchingType ?? state.fetchingType;
      let targetTrackingMethod = state.defaultTrackingMethod;
      if (
        targetFetchingType === 'front-office-type' &&
        !FRONT_OFFICE_TYPES_TRACKING_TYPES.includes(targetTrackingMethod)
      ) {
        targetTrackingMethod = 'check_in';
      }
      if (targetFetchingType === 'classroom-type') {
        targetTrackingMethod = 'section';
      }
      return {
        ...state,
        fetchingType: targetFetchingType,
        filteredStatus: action.status ?? state.filteredStatus,
        trackingMethodsOptions:
          targetFetchingType === 'front-office-type'
            ? FRONT_OFFICE_OPTIONS
            : [{ label: 'Section', value: 'section' }],
        defaultTrackingMethod: targetTrackingMethod,
      };
    }

    default:
      return state;
  }
};

export default FrontOfficeReducer;
