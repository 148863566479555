import React from 'react';
import { Theme } from 'assets/styles';
import { Path1, Svg } from './ClockStyles';

interface Props {
  fill?: string;
  light?: boolean;
  location?: 'filter' | 'notification' | 'table';
}

const ClockIcon: React.FC<Props> = ({
  fill = Theme.secondaryYellowMedium,
  light = false,
  location,
}: Props) => {
  return (
    <Svg
      fill="none"
      height="19"
      location={location}
      viewBox="0 0 250 250"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path1
        xmlns="http://www.w3.org/2000/svg"
        fill={light ? '#ffffff' : fill}
        fill-opacity="1"
        stroke="none"
        fill-rule="nonzero"
        opacity="1"
        d="M1.447202539303703,126.33541031433958 C1.447202539303703,57.30723495967706 57.36002457658037,1.39441292240042 126.38819993124287,1.39441292240042 C195.41637528590542,1.39441292240042 251.32919732318203,57.30723495967706 251.32919732318203,126.33541031433958 C251.32919732318203,195.36358566900213 195.41637528590542,251.27640770627875 126.38819993124287,251.27640770627875 C57.36002457658037,251.27640770627875 1.447202539303703,195.36358566900213 1.447202539303703,126.33541031433958 z"
      />
      <Path1
        xmlns="http://www.w3.org/2000/svg"
        fill={light ? fill : '#ffffff'}
        fill-opacity="1"
        stroke={light ? fill : '#ffffff'}
        fill-rule="evenodd"
        opacity="1"
        d="M119.5558832527422,40.52794997747833 L119.5558832527422,145.2670830406094 "
      />
      <Path1
        xmlns="http://www.w3.org/2000/svg"
        fill={light ? fill : '#ffffff'}
        fill-opacity="1"
        stroke={light ? fill : '#ffffff'}
        fill-rule="evenodd"
        opacity="1"
        d="M109.60559590119198,136.89441133666693 L190.19565858939882,136.89441133666693 "
      />
    </Svg>
  );
};

export default ClockIcon;
