import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 0px;
  position: relative;
  min-height: 30px;
  display: flex;
  align-items: center;
`;

export const Text = styled.div<{
  maxWidth?: number;
}>`
  position: absolute;
  min-width: 0px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ChildrenContainer = styled.div`
  max-width: 0px;
  max-height: 0px;
  overflow: hidden;
`;
