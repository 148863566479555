import { Reducer } from 'redux';

import * as Actions from './NewDashboardActions.types';
import { NewDashboardViewsEnum } from 'types/newDashboard.types';
import { IGrade, ISchool } from 'types/school.types';
import { IDistrict } from 'types/district.types';
import { IUser } from 'types/user.types';
import { ITeacher } from 'data/adapters/dashboard/getAttendanceSubmitted';

export interface State {
  isLoading: boolean;
  isLoadingOverview: boolean;
  view: string;
  teachers: IUser[];
  schools: ISchool[];
  grades: IGrade[];
  selectedTeachersIds: string[];
  selectedSchoolsIds: string[];
  selectedGradesIds: string[];
  districtDetails: IDistrict | null;
  schoolYear: number | undefined;
  presentCount: number;
  absentCount: number;
  tardyCount: number;
  ada: number;
  submittedAttendance: number;
  // startDate: string;
  // endDate: string;
  chartData: any;
  students: Actions.IChronicAbsenteeismStudent[];
  nextStudentsUri: string | undefined;
  isFetchingNewStudents: boolean;
  teachersSubmitted: ITeacher[];
  nextTeachersUri: string | undefined;
  isFetchingNewTeachers: boolean;
  isLoadingSubmitted: boolean;
}

// const today = new Date();
// const oneMonthAgo = new Date(today);
// oneMonthAgo.setDate(today.getDate() - 30);

const initialState = {
  isLoading: true,
  isLoadingOverview: true,
  view: NewDashboardViewsEnum.overview,
  teachers: [],
  schools: [],
  grades: [],
  selectedTeachersIds: [],
  selectedSchoolsIds: [],
  selectedGradesIds: [],
  districtDetails: null,
  schoolYear: undefined,
  presentCount: 0,
  absentCount: 0,
  tardyCount: 0,
  ada: 0,
  submittedAttendance: 0,
  // startDate: oneMonthAgo.toISOString().split('T')[0],
  // endDate: today.toISOString().split('T')[0],
  chartData: [],
  students: [],
  nextStudentsUri: undefined,
  isFetchingNewStudents: false,
  teachersSubmitted: [],
  nextTeachersUri: undefined,
  isFetchingNewTeachers: false,
  isLoadingSubmitted: true,
};

const NewDashboardReducer: Reducer<State, Actions.NewDashboardActionTypes> = (
  state: State = initialState,
  action: Actions.NewDashboardActionTypes,
) => {
  switch (action.type) {
    case Actions.NEW_DASHBOARD__SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.bool,
      };

    case Actions.NEW_DASHBOARD__LAUNCH_SUCCESS:
      return {
        ...state,
        schools: action.schools,
        grades: action.grades,
        districtDetails: action.districtDetails,
        teachers: action.teachers,
        schoolYear: action.schoolYear,
      };
    case Actions.NEW_DASHBOARD__ERROR:
      return {
        ...state,
        error: action.error,
      };
    case Actions.NEW_DASHBOARD__SET_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case Actions.NEW_DASHBOARD_SET_SUMMARY:
      return {
        ...state,
        presentCount: action.presentCount,
        absentCount: action.absentCount,
        tardyCount: action.tardyCount,
        ada: action.ada,
      };
    case Actions.NEW_DASHBOARD_SET_SUBMITTED_ATTENDANCE:
      return {
        ...state,
        submittedAttendance: action.submittedAttendance,
      };
    case Actions.NEW_DASHBOARD_SET_CHART_DATA:
      return {
        ...state,
        chartData: action.chartData,
      };
    // case Actions.NEW_DASHBOARD__HANDLE_DATE_RANGE_CHANGE:
    //   return {
    //     ...state,
    //     startDate: action.startDate,
    //     endDate: action.endDate,
    //   };
    case Actions.NEW_DASHBOARD_GET_FILTERS_DATA:
      return {
        ...state,
        chartData: action.chartData,
        submittedAttendance: action.submittedAttendance,
        presentCount: action.presentCount,
        absentCount: action.absentCount,
        tardyCount: action.tardyCount,
        ada: action.ada,
      };
    case Actions.NEW_DASHBOARD__LAUNCH_CHRONIC_ABSENTEEISM:
      return {
        ...state,
        students: action.students,
        nextStudentsUri: action.nextStudentsUri,
      };
    case Actions.NEW_DASHBOARD__FETCH_NEXT_CHRONIC_ABSENTEEISM:
      return {
        ...state,
        students: [...state.students, ...action.students],
        nextStudentsUri: action.nextStudentsUri,
      };

    case Actions.NEW_DASHBOARD__SET_IS_FETCHING_NEW_STUDENTS:
      return {
        ...state,
        isFetchingNewStudents: action.bool,
      };
    case Actions.NEW_DASHBOARD__SET_IS_LOADING_OVERVIEW:
      return {
        ...state,
        isLoadingOverview: action.bool,
      };
    case Actions.NEW_DASHBOARD__LAUNCH_SUBMITTED:
      return {
        ...state,
        teachersSubmitted: action.teachersSubmitted,
        nextTeachersUri: action.nextTeachersUri,
      };
    case Actions.NEW_DASHBOARD__FETCH_NEXT_SUBMITTED:
      return {
        ...state,
        teachersSubmitted: [
          ...state.teachersSubmitted,
          ...action.teachersSubmitted,
        ],
        nextTeachersUri: action.nextTeachersUri,
      };

    case Actions.NEW_DASHBOARD__SET_IS_FETCHING_NEW_SUBMITTED:
      return {
        ...state,
        isFetchingNewTeachers: action.bool,
      };
    case Actions.NEW_DASHBOARD__SET_IS_LOADING_SUBMITTED:
      return {
        ...state,
        isLoadingSubmitted: action.bool,
      };
    case Actions.NEW_DASHBOARD__SET_TEACHERS_AND_GRADES:
      return {
        ...state,
        teachers: action.teachers,
        grades: action.grades,
      };

    default:
      return state;
  }
};

export default NewDashboardReducer;
