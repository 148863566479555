import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';

export interface IDimensions {
  height?: number;
  width?: number;
  top?: number;
  left?: number;
}

type argsType = {
  initialDimension?: IDimensions;
};

type returnType = {
  ref: React.RefObject<HTMLDivElement>;
  dimensions: IDimensions;
  handleResize: _.DebouncedFunc<() => void>;
};

const INITIAL_DIMENSIONS = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};

const useDimensions = ({
  initialDimension = INITIAL_DIMENSIONS,
}: argsType = {}): returnType => {
  const [dimensions, setDimensions] = useState<IDimensions>(initialDimension);
  const ref = useRef<HTMLDivElement>(null);

  const reset = async () => {
    setDimensions(initialDimension);
  };

  const handleResize = _.throttle(() => {
    reset().then(() => {
      if (!ref || !ref.current) return;
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    });
  }, 20);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ref,
    dimensions,
    handleResize,
  };
};

export default useDimensions;
