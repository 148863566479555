import React, { useState } from 'react';

import { HeaderNav } from 'components/layout';
import { Footer } from './components/new-footer/Footer';

import { Container, FooterContainer } from './SplashStyles';
import { TrustedBy } from './components/trusted-by/TrustedBy';
import { StudentSuccess } from './components/student-success/StudentSuccess';
// import { Testimonials } from './components/newTestimonials/Testimonials';
// import { WhyUs } from './components/why-us/WhyUs';
import { PopupModal } from 'react-calendly';
import { HeroAcademics } from './components/hero-academics/HeroAcademics';
import { HelpAcademics } from './components/help-academics/HelpAcademics';

const Academics: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <PopupModal
        url="https://calendly.com/schooldayai/overview"
        open={isOpen}
        onModalClose={() => setIsOpen(false)}
        rootElement={document.getElementById('root') as HTMLElement}
      />
      <Container>
        <HeaderNav shouldShowNav setCalendlyModalOpen={setIsOpen} />
        <HeroAcademics setCalendlyModalOpen={setIsOpen} />
        <TrustedBy />
        <StudentSuccess />
        <HelpAcademics setCalendlyModalOpen={setIsOpen} />
        {/* <Testimonials />
        <WhyUs /> */}
      </Container>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};

export default Academics;
