import API from 'data/axios';
import { reverseBusRoutePlanMapper } from 'data/mappers/schoolBus.mappers';
import { IBusRoutePlan } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../../types/api.types';

interface IEditBusRoutePlanArgs {
  data: Partial<IBusRoutePlan>;
}

export default async ({ data }: IEditBusRoutePlanArgs): Promise<Adapter> => {
  const newPlan = reverseBusRoutePlanMapper(data);
  if (!newPlan.id) throw 'Invalid bus route plan data';

  return API.patch(`/bus/route_plans/${newPlan.id}`, newPlan)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
