import forgotUserPassword from '../../api/functions/forgotUserPassword';
import { parseError } from 'utils/errors.utils';
import { Adapter } from '../../types/api.types';

interface IForgotUserPasswordDataArgs {
  email: string;
}

export default async ({
  email,
}: IForgotUserPasswordDataArgs): Promise<Adapter> => {
  try {
    const { success, error } = await forgotUserPassword({
      email,
    });

    if (!success) {
      throw error;
    }

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);
    return {
      success: false,
      error: stringError,
    };
  }
};
