import API from 'data/axios';

import { parseError } from 'utils/errors.utils';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IApiAttendanceReasonCode } from '../../types/attendanceApi.types';

export default async (): Promise<Adapter<IApiAttendanceReasonCode[]>> =>
  API.get<ArrayResponse<IApiAttendanceReasonCode>>(`/attendances/reason_codes`)
    .then(({ data: response }) => {
      const reasons = response.data.map(({ data }) => data);

      return { success: true, data: reasons };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
