import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 24px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
`;

export const Title = styled.span`
  margin-top: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 110%;
  color: #07081f;

  @media only screen and (max-width: 1300px) {
    margin-top: 8px;
  }

  @media only screen and (max-width: 470px) {
    font-size: 22px;
  }
`;

export const Body = styled.span`
  margin-top: 24px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  color: rgba(7, 8, 31, 0.8);

  @media only screen and (max-width: 1300px) {
    margin-top: 8px;
  }

  @media only screen and (max-width: 470px) {
    font-size: 15px;
  }
`;

export const BookTimeToMeetButton = styled.a`
  text-decoration: none;
  cursor: pointer;
  margin-top: 60px;
  background: #f6c000;
  border-radius: 14px;
  padding: 14px 20px;
  max-width: 260px;
  padding: 16px 0px;

  font-family: ${({ theme }) => theme.fontPro};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-style: normal;
  font-size: 17px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.408px;
  font-feature-settings: 'case' on;
  color: #000000;

  // hover
  :hover {
    background: ${({ theme }) => theme.secondaryYellowLight};
  }

  @media only screen and (max-width: 1300px) {
    margin-top: 16px;
    margin-bottom: 17px;
    width: 100%;
    max-width: 100%;
    align-self: center;
  }

  @media only screen and (max-width: 470px) {
    font-size: 15px;
  }
`;
