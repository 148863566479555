import React from 'react';
import { Svg } from './HideStyles';

const Hide: React.FC = () => {
  return (
    <Svg viewBox="0 0 20 14" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.504702"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77514 0.570432C1.46014 0.88543 1.46014 1.39614 1.77514 1.71114L3.24478 3.18079L3.6519 3.58791C2.17559 4.74212 1.00795 6.27661 0.310059 8.05263C1.85797 11.9805 5.67848 14.7632 10.1522 14.7632C11.539 14.7632 12.8632 14.4947 14.0756 14.0071L14.4559 14.3874L16.4961 16.4311C16.8099 16.7454 17.319 16.7461 17.6336 16.4327C17.9489 16.1186 17.9495 15.6082 17.6348 15.2935L2.91593 0.570511C2.60094 0.255438 2.09017 0.255402 1.77514 0.570432ZM6.15271 6.08423L7.53508 7.4666C7.49482 7.65898 7.46797 7.85132 7.46797 8.05263C7.46797 9.53344 8.67138 10.7368 10.1522 10.7368C10.3535 10.7368 10.5459 10.71 10.7338 10.6697L12.1161 12.0521C11.5211 12.3474 10.8591 12.5263 10.1522 12.5263C7.68271 12.5263 5.67848 10.5221 5.67848 8.05263C5.67848 7.34581 5.85745 6.68369 6.15271 6.08423ZM10.1522 3.57897C12.6217 3.57897 14.6259 5.5832 14.6259 8.05268C14.6259 8.62978 14.5096 9.18005 14.3083 9.68556L16.9254 12.3027C18.2764 11.1753 19.3412 9.71689 19.9988 8.05268C18.4464 4.12479 14.6304 1.34215 10.1522 1.34215C8.89958 1.34215 7.70066 1.56582 6.58671 1.96845L8.51935 3.89659C9.02482 3.69976 9.57508 3.57897 10.1522 3.57897ZM10.0046 5.38184L12.823 8.20027L12.8364 8.05263C12.8364 6.57183 11.633 5.36842 10.1522 5.36842L10.0046 5.38184Z"
      />
    </Svg>
  );
};

export default Hide;
