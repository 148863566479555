import resetUserPassword from '../../api/functions/resetUserPassword';
import { parseError } from 'utils/errors.utils';
import { Adapter } from '../../types/api.types';

interface IResetUserPasswordDataArgs {
  code: string;
  newPassword: string;
}

export default async ({
  code,
  newPassword,
}: IResetUserPasswordDataArgs): Promise<Adapter> => {
  try {
    const { success, error } = await resetUserPassword({
      code,
      newPassword,
    });

    if (!success) {
      throw error;
    }

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);
    return {
      success: false,
      error: stringError,
    };
  }
};
