import { morphism, StrictSchema } from 'morphism';
import {
  IAttendance,
  IAttendanceEventType,
  IAttendanceStatus,
  INewAttendance,
} from 'types/attendance.types';
import {
  IApiAttendance,
  IApiAttendanceEventType,
  IApiAttendancePayload,
  IApiAttendanceStatus,
  TUpadtedAttendance,
} from '../types/attendanceApi.types';
import {
  IAttendanceEventSchema,
  IAttendanceSchema,
  IAttendanceStatusSchema,
  IReverseUpdatedAttendanceSchema,
} from './schemas/attendanceApi.schemas';

export const AttendanceMapper = (attendanceRecord: IApiAttendance) =>
  morphism<StrictSchema<IAttendance, IApiAttendance>>(
    IAttendanceSchema,
    attendanceRecord,
  );

export const AttendanceEventTypeMapper = (type: IApiAttendanceEventType) =>
  morphism<StrictSchema<IAttendanceEventType, IApiAttendanceEventType>>(
    IAttendanceEventSchema,
    type,
  );

export const AttendanceStatusMapper = (status: IApiAttendanceStatus) =>
  morphism<StrictSchema<IAttendanceStatus, IApiAttendanceStatus>>(
    IAttendanceStatusSchema,
    status,
  );

export const ReverseUpdatedAttendanceMapper = (
  attendanceRecord: TUpadtedAttendance,
) =>
  morphism<StrictSchema<IApiAttendancePayload, TUpadtedAttendance>>(
    IReverseUpdatedAttendanceSchema,
    attendanceRecord,
  );

export const ReverseNewAttendanceMapper = (
  attendanceRecord: Partial<INewAttendance>,
) =>
  morphism<StrictSchema<IApiAttendancePayload, Partial<INewAttendance>>>(
    {
      barcode_scan_value: (iteratee) => iteratee.barcodeScanValue || undefined,
      calendar_date: (iteratee) => iteratee.calendarDate || null,
      event_date: (iteratee) => iteratee.eventDate?.toISOString() || undefined,
      location: ({ locationID }) => locationID || undefined,
      school: ({ schoolID }) => schoolID || undefined,
      section: ({ sectionID }) => sectionID || undefined,
      status: ({ status }) => status || undefined,
      student: ({ studentID }) => studentID || undefined,
      comment: ({ comment }) => comment || undefined,
      tracking_method: ({ trackingMethod }) => trackingMethod || undefined,
      reason_code: ({ reasonCode }) => reasonCode || undefined,
      bell_schedule_item: ({ bellScheduleItem }) =>
        bellScheduleItem || undefined,
      tags: ({ tags }) => tags || undefined,
    },
    attendanceRecord,
  );
