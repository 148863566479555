import React, { useEffect, useState } from 'react';
import { Container } from './MockToggleStyles';
import { ToggleSwitch } from 'components/toggle-switch';

export const MOCKING_STORAGE_KEY = 'MOCKING_ENABLED';

const MockToggle: React.FC = () => {
  const [isMocking, setIsMocking] = useState<boolean>(
    localStorage.getItem(MOCKING_STORAGE_KEY)
      ? Boolean(localStorage.getItem(MOCKING_STORAGE_KEY))
      : false,
  );

  useEffect(() => {
    if (isMocking) {
      localStorage.setItem(MOCKING_STORAGE_KEY, 'true');
    } else {
      localStorage.removeItem(MOCKING_STORAGE_KEY);
    }
  }, [isMocking]);

  if (process.env.REACT_APP_TYPE !== 'DEVELOP') {
    return null;
  }

  return (
    <Container>
      <ToggleSwitch
        labelAlignment="left"
        labels={{
          on: 'Mocking On',
          off: 'Mocking Off',
        }}
        labelColor="black"
        isOn={isMocking}
        onChange={() => setIsMocking((v) => !v)}
      />
    </Container>
  );
};

export default MockToggle;
