import styled from 'styled-components';

interface ISvg {
  color?: string;
  dark?: boolean;
  down?: boolean;
  isActive?: boolean;
  left?: boolean;
  light?: boolean;
  right?: boolean;
  up?: boolean;
}

export const Svg = styled.svg<ISvg>`
  cursor: pointer;
  height: 8px;
  min-height: 7px;
  min-width: 9px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  transition: transform 150ms ease-in-out;

  fill: ${({ light, dark, color, theme }) => {
    if (dark || light) {
      if (light && !dark) {
        return theme.secondaryYellowMedium;
      } else if (dark && !light) {
        return theme.secondaryBlueDark;
      } else {
        return 'blue';
      }
    } else {
      return color || theme.primaryWhite;
    }
  }};


  ${({ up, down, left, right }) => {
    if (up) return 'transform: rotate(0deg);';
    if (right) return 'transform: rotate(90deg);';
    if (down) return 'transform: rotate(180deg);';
    if (left) return 'transform: rotate(270deg);';
    return 'transform: rotate(90deg);';
  }}}
`;
