import { Reducer } from 'redux';
import { IStaff } from 'types/staff.types';
import * as Actions from './StaffActions.types';

interface State {
  currentStaffMember: IStaff | null;
  hasWriteAccess: boolean;
  isLoading: boolean;
  isPerformingDeleteStaff: boolean;
  isShowingResults: boolean;
  next?: string;
  nextSearchLink?: string;
  pageSize: number;
  searchResults: IStaff[];
  searchText: string;
  shouldShowSaveErrorModal: boolean;
  shouldShowSaveModal: boolean;
  staff: IStaff[];
}

const initialState: State = {
  currentStaffMember: null,
  hasWriteAccess: false,
  isLoading: false,
  isPerformingDeleteStaff: false,
  isShowingResults: false,
  pageSize: 20,
  searchResults: [],
  searchText: '',
  shouldShowSaveErrorModal: false,
  shouldShowSaveModal: false,
  staff: [],
};

const StaffReducer: Reducer<State, Actions.StaffActions> = (
  state: State = initialState,
  action: Actions.StaffActions,
): State => {
  switch (action.type) {
    case Actions.STAFF__CLEAR_CURRENT_STAFF: {
      return {
        ...state,
        currentStaffMember: null,
      };
    }

    case Actions.STAFF__CLEAR_SEARCH_TEXT: {
      return {
        ...state,
        isShowingResults: false,
        nextSearchLink: undefined,
        searchResults: [],
        searchText: '',
      };
    }

    case Actions.STAFF__GET_NEXT_BATCH_SUCCESS: {
      return {
        ...state,
        staff: [...state.staff, ...action.data],
        next: action.next,
      };
    }

    case Actions.STAFF__GET_NEXT_RESULTS_PAGE: {
      return {
        ...state,
        searchResults: [...state.searchResults, ...action.data],
        nextSearchLink: action.next,
      };
    }

    case Actions.STAFF__HANDLE_SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        isShowingResults: true,
        nextSearchLink: action.next,
        searchResults: action.data,
        searchText: action.searchText,
      };
    }

    case Actions.STAFF__LAUNCH_STAFF_VIEW_FAIL: {
      return {
        ...state,
      };
    }

    case Actions.STAFF__LAUNCH_STAFF_VIEW: {
      return {
        ...state,
        currentStaffMember: action.data,
      };
    }

    case Actions.STAFF__LAUNCH_SUCCESS: {
      return {
        ...state,
        next: action.next,
        staff: action.data,
        pageSize: action.pageSize,
      };
    }

    case Actions.STAFF__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.STAFF__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    default:
      return state;
  }
};

export default StaffReducer;
