import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { ICheckpointLocation } from 'types/checkpoint.types';

export const LOCATIONS_MANAGE__ERROR = 'LOCATIONS_MANAGE__ERROR';
export const LOCATIONS_MANAGE__GET_NEXT_SUCCESS =
  'LOCATIONS_MANAGE__GET_NEXT_SUCCESS';
export const LOCATIONS_MANAGE__LAUNCH_LOCATIONS =
  'LOCATIONS_MANAGE__LAUNCH_LOCATIONS';
export const LOCATIONS_MANAGE__ADD_LOCATION = 'LOCATIONS_MANAGE__ADD_LOCATION';
export const LOCATIONS_MANAGE__EDIT_LOCATION =
  'LOCATIONS_MANAGE__EDIT_LOCATION';
export const LOCATIONS_MANAGE__DELETE_LOCATION =
  'LOCATIONS_MANAGE__DELETE_LOCATION';
export const LOCATIONS_MANAGE__SET_IS_LOADING =
  'LOCATIONS_MANAGE__SET_IS_LOADING';
export const LOCATIONS_MANAGE__SET_PAGE_SIZE =
  'LOCATIONS_MANAGE__SET_PAGE_SIZE';

interface LocationsManageErrorAction {
  type: typeof LOCATIONS_MANAGE__ERROR;
  error: string;
}

interface LaunchLocationsAction {
  type: typeof LOCATIONS_MANAGE__LAUNCH_LOCATIONS;
  locations: ICheckpointLocation[];
  next?: string;
}

interface AddNewLocationAction {
  type: typeof LOCATIONS_MANAGE__ADD_LOCATION;
  location: ICheckpointLocation;
}

interface EditLocationAction {
  type: typeof LOCATIONS_MANAGE__EDIT_LOCATION;
  location: ICheckpointLocation;
}

interface DeleteLocationAction {
  type: typeof LOCATIONS_MANAGE__DELETE_LOCATION;
  id: string;
}

export interface GetNextBatchSuccess {
  data: ICheckpointLocation[];
  next?: string;
  type: typeof LOCATIONS_MANAGE__GET_NEXT_SUCCESS;
}

interface SetIsLoadingAction {
  type: typeof LOCATIONS_MANAGE__SET_IS_LOADING;
  bool: boolean;
}

interface SetPageSizeAction {
  type: typeof LOCATIONS_MANAGE__SET_PAGE_SIZE;
  size: number;
}

export type LocationsManageActionTypes =
  | LocationsManageErrorAction
  | GetNextBatchSuccess
  | LaunchLocationsAction
  | SetIsLoadingAction
  | AddNewLocationAction
  | DeleteLocationAction
  | EditLocationAction
  | SetPageSizeAction;

export type TLaunchLocations = (args: {
  searchText?: string;
}) => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  LocationsManageActionTypes
>;

export type TAddNewLocation = (
  args: Omit<ICheckpointLocation, 'id' | 'schoolID'>,
) => ThunkAction<
  Promise<{ isSuccessful: boolean }>,
  RootState,
  unknown,
  LocationsManageActionTypes
>;

export type TEditLocation = (
  args: Omit<ICheckpointLocation, 'schoolID'>,
) => ThunkAction<
  Promise<{ isSuccessful: boolean }>,
  RootState,
  unknown,
  LocationsManageActionTypes
>;

export type TDeleteLocation = (
  id: string,
) => ThunkAction<
  Promise<{ isSuccessful: boolean }>,
  RootState,
  unknown,
  LocationsManageActionTypes
>;

export type TGetNextPage = (
  newPageSize?: number,
) => ThunkAction<Promise<void>, RootState, unknown, LocationsManageActionTypes>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetPageSize = (
  size: number,
) => ThunkAction<void, RootState, unknown, LocationsManageActionTypes>;

export type TSetLocationErrorMsg = (
  message: string,
) => ThunkAction<void, RootState, unknown, LocationsManageActionTypes>;
