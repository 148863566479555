import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from './paths';
import { Spinner } from 'components/spinner';
import Academics from 'domains/pages/splash-page/Academics';

const DeniedAccess = lazy(
  () => import('domains/pages/denied-access/DeniedAccess'),
);
const PageNotFound = lazy(() => import('domains/pages/not-found'));
const PrivatePage = lazy(() => import('domains/pages/private-page'));
const SplashPage = lazy(() => import('domains/pages/splash-page/Splash'));
const AttendanceRoutes = lazy(() => import('./Attendance.routes'));
const FrontOfficeRoutes = lazy(() => import('./FrontOffice.routes'));
const TutorialRoutes = lazy(() => import('./Tutorials.routes'));
const CheckpointRoutes = lazy(() => import('./Checkpoint.routes'));
// const MedicalRoutes = lazy(() => import('./Medical.routes'));
const IdCardsRoutes = lazy(() => import('./IdCards.routes'));
const PeopleRoutes = lazy(() => import('./People.routes'));
const StudentPhotosRoutes = lazy(() => import('./StudentPhotos.routes'));
const ReportsRoutes = lazy(() => import('./Reports.routes'));
const SchoolBusRoutes = lazy(() => import('./SchoolBus.routes'));
const UserProfile = lazy(() => import('domains/user-profile'));
const OauthCallBackScreen = lazy(
  () => import('domains/auth/oauth/OauthCallBackScreen'),
);
const PowerSchoolSSOLinks = lazy(
  () => import('domains/auth/oauth/PowerSchoolSSOLinks'),
);
const ClassLinkSSO = lazy(() => import('domains/auth/oauth/ClassLinkSSO'));
const EventRoutes = lazy(() => import('./Event.routes'));
const TermsOfService = lazy(
  () => import('domains/pages/terms-of-service/TermsOfService'),
);
const ResetPassword = lazy(
  () => import('domains/auth/reset-password/ResetPassword'),
);
const PrivacyPolicy = lazy(
  () => import('domains/pages/privacy-policy/PrivacyPolicy'),
);
const Login = lazy(() => import('domains/auth/login/Login'));
const ForgotPassword = lazy(
  () => import('domains/auth/forgot-password/ForgotPassword'),
);

export const AppRoutes: React.FC = () => {
  // TODO
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Spinner />
          </div>
        }
      >
        <Routes>
          <Route
            path={`${PATHS.frontOffice}/*`}
            element={<FrontOfficeRoutes />}
          />
          <Route
            path={`${PATHS.attendance}/*`}
            element={<AttendanceRoutes />}
          />
          <Route
            path={`${PATHS.checkpoints}/*`}
            element={<CheckpointRoutes />}
          />
          <Route path={`${PATHS.tutorial}/*`} element={<TutorialRoutes />} />
          <Route path={`${PATHS.event}/*`} element={<EventRoutes />} />
          <Route path={`${PATHS.idCards}/*`} element={<IdCardsRoutes />} />
          {/* <Route path={`${PATHS.medical}/*`} element={<MedicalRoutes />} />*/}
          <Route path={`${PATHS.people}/*`} element={<PeopleRoutes />} />
          <Route
            path={`${PATHS.studentPhotos}/*`}
            element={<StudentPhotosRoutes />}
          />
          <Route path={`${PATHS.reports}/*`} element={<ReportsRoutes />} />
          <Route path={`${PATHS.schoolBus}/*`} element={<SchoolBusRoutes />} />
          {/* <Route path={`${PATHS.surveys}/*`} element={<SurveysRoutes />} /> */}

          <Route path={PATHS.profile} element={<PrivatePage />}>
            <Route index element={<UserProfile />} />
          </Route>
          <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
          <Route path={PATHS.login} element={<Login />} />
          <Route path={PATHS.oauthCallback} element={<OauthCallBackScreen />} />
          <Route path={PATHS.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={PATHS.resetPassword} element={<ResetPassword />} />
          <Route path={PATHS.termsOfService} element={<TermsOfService />} />
          <Route
            path={PATHS.powerschoolSSOLinks}
            element={<PowerSchoolSSOLinks />}
          />
          <Route path={PATHS.classLinkSSO} element={<ClassLinkSSO />} />
          <Route path={PATHS.pageNotFound} element={<PageNotFound />} />
          <Route path={PATHS.deniedAccess} element={<DeniedAccess />} />
          <Route path={PATHS.home} element={<SplashPage />} />
          <Route path={PATHS.academics} element={<Academics />} />
          <Route
            path="*"
            element={<Navigate replace to={PATHS.pageNotFound} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
