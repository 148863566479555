import styled from 'styled-components';

export const Path = styled.path``;

export const Svg = styled.svg`
  width: 14px;
  height: 13px;
  fill: ${({ theme }) => theme.secondaryFontDark};
  cursor: pointer;
`;
