import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IAttendance, PartialAttendance } from 'types/attendance.types';
import { ISection } from 'types/section.types';
import { IStudent } from 'types/student.types';

// Actions
export const HOMEROOM__ERROR = 'HOMEROOM__ERROR';
export const HOMEROOM__GET_ATTENDANCES = 'HOMEROOM__GET_ATTENDANCES';
export const HOMEROOM__LAUNCH_SUCCESS = 'HOMEROOM__LAUNCH_SUCCESS';
export const HOMEROOM__SET_CURRENT_SECTION = 'HOMEROOM__SET_CURRENT_SECTION';
export const HOMEROOM__SET_DATE_RANGE = 'HOMEROOM__SET_DATE_RANGE';
export const HOMEROOM__SET_IS_LOADING = 'HOMEROOM__SET_IS_LOADING';
export const HOMEROOM__SET_IS_UPDATING = 'HOMEROOM__SET_IS_UPDATING';
export const HOMEROOM__UPDATE_ATTENDANCES = 'HOMEROOM__UPDATE_ATTENDANCES';

// Action types
interface GetAttendancesSuccess {
  type: typeof HOMEROOM__GET_ATTENDANCES;
  sectionAttendances: IAttendance[];
}

interface HomeroomFailAction {
  error: string;
  type: typeof HOMEROOM__ERROR;
}

interface LaunchHomeroomSuccessAction {
  sections: ISection[];
  type: typeof HOMEROOM__LAUNCH_SUCCESS;
}

interface SetCurrentSectionAction {
  sectionID: string;
  students: IStudent[];
  type: typeof HOMEROOM__SET_CURRENT_SECTION;
}

interface SetDateRangeAction {
  endDate: Date;
  startDate: Date;
  type: typeof HOMEROOM__SET_DATE_RANGE;
}

interface SetIsLoadingAction {
  bool: boolean;
  type: typeof HOMEROOM__SET_IS_LOADING;
}

interface SetIsUpdatingAction {
  bool: boolean;
  type: typeof HOMEROOM__SET_IS_UPDATING;
}

interface UpdateAttendancesAction {
  type: typeof HOMEROOM__UPDATE_ATTENDANCES;
}

export type HomeroomActionTypes =
  | GetAttendancesSuccess
  | HomeroomFailAction
  | LaunchHomeroomSuccessAction
  | SetCurrentSectionAction
  | SetDateRangeAction
  | SetIsLoadingAction
  | SetIsUpdatingAction
  | UpdateAttendancesAction;

// Action handlers
export type TGetCurrentSectionAttendances = (args: {
  endDate: Date;
  sectionID: string;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, HomeroomActionTypes>;

export type TLaunchHomeroom = () => ThunkAction<
  void,
  RootState,
  unknown,
  HomeroomActionTypes
>;

export type TLaodSectionAttendances = () => ThunkAction<
  void,
  RootState,
  unknown,
  HomeroomActionTypes
>;

export type TSetCurrentSection = (args: {
  sectionID: string;
}) => ThunkAction<void, RootState, unknown, HomeroomActionTypes>;

export type TSetDateRange = (args: {
  endDate: Date;
  startDate: Date;
}) => ThunkAction<void, RootState, unknown, HomeroomActionTypes>;

export type TSetIsHomeroomLoading = (bool: boolean) => SetIsLoadingAction;

export type TSetIsUpdatingHomeroom = (bool: boolean) => SetIsUpdatingAction;

export type TUpdateAttendances = (args: {
  updates: { [attendanceID: string]: PartialAttendance };
}) => ThunkAction<
  Promise<{
    isSuccessful: boolean;
  }>,
  RootState,
  unknown,
  HomeroomActionTypes
>;
