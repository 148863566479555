import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { reverseUserMapper, userMapper } from '../../mappers/user.mappers';

import { Adapter, Response } from '../../types/api.types';
import { IApiUser } from '../../types/userApi.types';
import { IUser } from 'types/user.types';

interface IEditUserArgs {
  userData: Partial<IUser> & { id: string };
}

export default async ({ userData }: IEditUserArgs): Promise<Adapter<IUser>> => {
  const updatedUserData = reverseUserMapper(userData);

  return API.patch<Response<IApiUser>>(`/users/${userData.id}`, updatedUserData)
    .then(({ data: response }) => {
      const user = userMapper(response.data);

      return { success: true, data: user };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
