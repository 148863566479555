import styled from 'styled-components';

interface ISvgIntent {
  intent?: 'primary' | 'danger';
}

export const Svg = styled.svg<ISvgIntent>`
  fill: ${({ theme, intent }) =>
    intent === 'primary' ? theme.secondaryBlueDark : theme.secondaryRed};
  width: 19px;
  height: 17px;
  margin-right: 5px;
`;
