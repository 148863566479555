import getNext from 'data/adapters/getNext';
import getSchoolUsers from 'data/adapters/school/getSchoolUsers';
import getUserData from 'data/adapters/users/getUserData';

import { IStudent } from 'types/student.types';
import { UserRolesEnum } from 'types/user.types';
import * as Actions from './studentsActions.types';
import { IParsedContact } from 'domains/people/students/view-student/components/contact-info/Contacts';

export const clearCurrentStudent: Actions.TClearCurrentStudent = () => ({
  type: Actions.STDNTS__CLEAR_CURRENT_STUDENT,
});

export const clearStudentsSearchText: Actions.TClearSearchText = () => ({
  type: Actions.STDNTS__CLEAR_SEARCH_TEXT,
});

export const getNextBatchOfStudents: Actions.TGetNextPage = (
  newPageSize,
) => async (dispatch, getState) => {
  try {
    dispatch(setIsStudentsLoading(true));

    const state = getState();
    let nextPath = state.students.next;

    if (!nextPath) throw 'No more data available';

    if (newPageSize) {
      nextPath = nextPath?.replace(
        /page_size=[0-9][0-9]?/,
        `page_size=${newPageSize}`,
      );
    }

    const { data, error, success, next } = await getNext<IStudent>({
      path: nextPath,
      type: 'user',
    });

    if (!success || !data) throw error;

    dispatch({
      data,
      next,
      type: Actions.STDNTS__GET_NEXT_PAGE,
    });
  } catch (err) {
    dispatch({ type: Actions.STDNTS__LAUNCH_FAIL });
  } finally {
    dispatch(setIsStudentsLoading(false));
  }
};

export const getNextStudentsResults: Actions.TStudentsThunkAction = () => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsStudentsLoading(true));

    const state = getState();
    const nextPath = state.students.nextSearchLink;

    if (!nextPath) throw 'No more data available';

    const { data, error, success, next } = await getNext<IStudent>({
      path: nextPath,
      type: 'user',
    });

    if (!success || !data) throw error;

    dispatch({
      data,
      next,
      type: Actions.STDNTS__GET_NEXT_RESULTS_PAGE,
    });
  } catch (err) {
    dispatch({ type: Actions.STDNTS__LAUNCH_FAIL });
  } finally {
    dispatch(setIsStudentsLoading(false));
  }
};

export const handleStudentsSearchInputChange: Actions.THandleSearchInputChange = (
  searchText,
  pageSize,
) => async (dispatch, getState) => {
  try {
    dispatch(setIsStudentsLoading(true));

    const state = getState();
    const schoolID = state.auth.activeSchool?.id;

    if (!schoolID) throw 'Cannot get current school';

    const { data, error, success, next } = await getSchoolUsers({
      schoolID,
      role: UserRolesEnum.student,
      pageSize,
      searchText,
    });

    if (!success || !data) throw error;

    dispatch({
      data: data as IStudent[],
      next,
      searchText,
      type: Actions.STDNTS__HANDLE_SEARCH_TEXT_CHANGE,
    });
    return { result: data as IStudent[] };
  } catch (err) {
    dispatch({ type: Actions.STDNTS__LAUNCH_FAIL });
    return { result: [] };
  } finally {
    dispatch(setIsStudentsLoading(false));
  }
};

export const launchStudentView: Actions.TLaunchStudentView = ({ id }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsStudentsLoading(true));

  try {
    const schoolID = getState().auth.activeSchool;

    if (!schoolID) {
      throw 'Cannot get current organization';
    }

    const { data, error, success } = await getUserData({ userID: id });

    if (!data || !success) {
      throw error;
    }

    dispatch({
      data: data as IStudent,
      type: Actions.STDNTS__LAUNCH_STUDENT_SUCCESS,
    });
    dispatch(setIsStudentsLoading(false));
    return { student: data as IStudent };
  } catch (err) {
    dispatch({ type: Actions.STDNTS__LAUNCH_STUDENT_FAIL });
    dispatch(setIsStudentsLoading(false));
    return { student: undefined };
  }
};

export const setCurrentContact: Actions.TSetCurrentContact = (
  contact: IParsedContact | null,
) => async (dispatch) => {
  dispatch({
    type: Actions.STDNTS__SET_CURRENT_CONTACT,
    contact: contact,
  });
};

export const launchStudentSelection: Actions.TStudentsThunkAction = () => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(setIsStudentsLoading(true));

    const state = getState();
    const schoolID = state.auth.activeSchool?.id;
    const pageSize = parseInt(
      window.localStorage.getItem('student_page_size') ||
        `${state.students.pageSize || 20}`,
    );

    if (!schoolID) throw 'Cannot get current school';

    const { data, error, success, next } = await getSchoolUsers({
      schoolID,
      role: UserRolesEnum.student,
      pageSize,
    });

    if (!success || !data) throw error;

    dispatch({
      data: data as IStudent[],
      next,
      pageSize,
      type: Actions.STDNTS__LAUNCH_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: Actions.STDNTS__LAUNCH_FAIL });
  } finally {
    dispatch(setIsStudentsLoading(false));
  }
};

export const setIsStudentsLoading: Actions.TSetIsLoading = (bool) => ({
  type: Actions.STDNTS__SET_IS_LOADING,
  bool,
});

export const setStudentsPageSize: Actions.TSetPageSize = (size) => async (
  dispatch,
  getState,
) => {
  window.localStorage.setItem('student_page_size', size + '');

  const state = getState();
  const { students, next } = state.students;

  if (!!next && students.length < size) {
    dispatch(getNextBatchOfStudents(size));
  }

  dispatch({
    type: Actions.STDNTS__SET_PAGE_SIZE,
    size,
  });
};

export const updateStudent: Actions.TUpdateStudent = (student) => async (
  dispatch,
) => {
  dispatch({
    type: Actions.STDNTS__UPDATE_STUDENT,
    student: student,
  });
};
