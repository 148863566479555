import { Reducer } from 'redux';
import { IAttendance } from 'types/attendance.types';

import * as Actions from './AttendanceLogsActions.types';

export interface State {
  attendances: IAttendance[];
  pageSize: number;
  attendancesNext?: string | undefined;
  error: string;
  isLoading: boolean;
  startDate: Date;
  endDate: Date;
  search_text?: string;
  trackingMethod?: Actions.IAttendanceLogsTrackingMethod | '';
  status?: string;
}

const initialState: State = {
  pageSize: 20,
  attendances: [],
  attendancesNext: undefined,
  error: '',
  isLoading: false,
  startDate: new Date(),
  endDate: new Date(),
  search_text: '',
  trackingMethod: '',
  status: '',
};

const attendanceLogsReducer: Reducer<
  State,
  Actions.AttendanceLogsActionTypes
> = (
  state: State = initialState,
  action: Actions.AttendanceLogsActionTypes,
) => {
  switch (action.type) {
    case Actions.ATTENDANCE_LOGS__LOAD_ATTENDANCES: {
      return {
        ...state,
        error: '',
        search_text: action.search_text,
        attendances: action.attendances,
        attendancesNext: action.next,
      };
    }

    case Actions.ATTENDANCE_LOGS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.ATTENDANCE_LOGS__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.ATTENDANCE_LOGS__HANDLE_DATE_RANGE_CHANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    case Actions.ATTENDANCE_LOGS__HANDLE_FILTERS_UPDATE: {
      return {
        ...state,
        trackingMethod: action.trackingMethod ?? state.trackingMethod,
        status: action.status ?? state.status,
      };
    }

    case Actions.ATTENDANCE_LOGS__GET_NEXT_PAGE: {
      return {
        ...state,
        attendances: [...state.attendances, ...action.data],
        attendancesNext: action.next,
      };
    }

    default:
      return state;
  }
};

export default attendanceLogsReducer;
