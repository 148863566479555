import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '..';

import {
  ButtonContainer,
  ContentContainer,
  Inner,
  MainActionButton,
  P,
} from './SimpleModalStyles';

interface Props {
  closeModal: (e: React.MouseEvent) => void;
  content?: JSX.Element;
  onConfirm: (e: React.MouseEvent) => void;
  prompt: string;
  shouldShow: boolean;
}

const SimpleModal: React.FC<Props> = ({
  closeModal,
  content,
  onConfirm,
  prompt,
  shouldShow,
}: Props) => {
  const { t } = useTranslation();

  const tMessage = t(prompt);
  const tOk = t('Ok');

  return (
    <Modal handleClose={closeModal} shouldShow={shouldShow} width="430px">
      <Inner>
        <P>{tMessage}</P>

        {content && <ContentContainer>{content}</ContentContainer>}

        <ButtonContainer>
          <MainActionButton onClick={onConfirm}>{tOk}</MainActionButton>
        </ButtonContainer>
      </Inner>
    </Modal>
  );
};

export default SimpleModal;
