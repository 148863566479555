import { morphism, StrictSchema } from 'morphism';
import {
  IApiAttendance,
  IApiAttendanceEventType,
  IApiAttendancePayload,
  IApiAttendanceStatus,
  TUpadtedAttendance,
} from '../../types/attendanceApi.types';
import {
  IAttendance,
  IAttendanceEventType,
  IAttendanceStatus,
  SyncStatusEnum,
} from 'types/attendance.types';
import { ICalendarDateSchema } from './schoolApi.schemas';
import { AttendanceStatusMapper } from '../attendace.mappers';

export const IAttendanceSchema: StrictSchema<IAttendance, IApiAttendance> = {
  calendarDate: (iteratee) =>
    iteratee.calendar_date
      ? morphism(ICalendarDateSchema, iteratee.calendar_date)
      : null,
  comment: 'comment',
  createdAt: (iteratee) => new Date(iteratee.created_at),
  eventDate: (iteratee) => new Date(iteratee.event_date),
  eventType: 'event_type.name',
  id: 'id',
  isStudentDay: 'calendar_date.is_student_day',
  isTeacherDay: 'calendar_date.is_teacher_day',
  locationID: 'location',
  schoolID: 'school',
  sectionID: 'section',
  status: (iteratee) => AttendanceStatusMapper(iteratee.status),
  student: ({ student }) => {
    if (typeof student === 'string') return undefined;

    // in case of expanded student object in the attendances response
    return {
      id: student.user_account_id,
      firstName: student.first_name,
      lastName: student.last_name,
      photoUrl: student.photo_url,
    };
  },
  studentID: ({ student }) => {
    if (typeof student === 'string') return student;
    return student.user_account_id;
  },
  sync: (iteratee) => {
    if (!iteratee.sync || !iteratee.sync.status) return undefined;

    const { date, message, status } = iteratee.sync;

    return {
      date: date ? new Date(date) : undefined,
      message: message || undefined,
      status: status as SyncStatusEnum,
    };
  },
  transportationType: 'transportation_type',
  trackingMethod: 'tracking_method',
  reasonCode: 'reason_code',
  contact: 'contact',
  tags: 'tags',
  updatedAt: (iteratee) => new Date(iteratee.updated_at),
  bellScheduleItemDetails: 'bell_schedule_item',
};

export const IAttendanceEventSchema: StrictSchema<
  IAttendanceEventType,
  IApiAttendanceEventType
> = {
  id: 'id',
  name: 'name',
  key: 'key',
};

export const IAttendanceStatusSchema: StrictSchema<
  IAttendanceStatus,
  IApiAttendanceStatus
> = {
  abbr: 'abbr',
  courseType: ({ course_type }) => course_type ?? undefined,
  districtID: ({ district }) => district ?? undefined,
  hexColor: ({ hex_color }) => hex_color ?? undefined,
  id: 'id',
  isActive: ({ is_active }) => is_active ?? undefined,
  name: 'name',
  order: ({ order }) => order ?? undefined,
  school: ({ school }) => school ?? undefined,
  sisID: ({ sis_id }) => sis_id ?? undefined,
  type: 'type',
  yearID: ({ year_id }) => year_id ?? undefined,
};

export const IReverseUpdatedAttendanceSchema: StrictSchema<
  IApiAttendancePayload,
  TUpadtedAttendance
> = {
  barcode_scan_value: (iteratee) => iteratee.barcodeScanValue || undefined,
  calendar_date: (iteratee) => iteratee.calendarDate?.id || null,
  comment: (iteratee) => iteratee.comment || null,
  event_date: (iteratee) => iteratee.eventDate?.toISOString() || undefined,
  event_type: ({ eventType }) => eventType || undefined,
  id: ({ id }) => id || undefined,
  school: ({ schoolID }) => schoolID || undefined,
  status: ({ status }) => status || undefined,
  student: ({ studentID }) => studentID,
  section: ({ sectionID }) => sectionID || undefined,
  // user_account: ({ userAccountID }) => userAccountID || undefined,
  tracking_method: ({ trackingMethod }) => trackingMethod || undefined,
  reason_code: ({ reasonCode }) => reasonCode || undefined,
  contact: ({ contact }) => contact || undefined,
  transportation_type: ({ transportationType }) =>
    transportationType || undefined,
  bell_schedule_item: ({ bellScheduleItem }) => bellScheduleItem || undefined,
  tags: (iteratee) => iteratee.tags || undefined,
};
