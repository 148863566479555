import { IVideoTutorial } from 'types/videoTutorial.types';
import { firestore } from 'data/firebase';
import firebase from 'firebase/app';
import { PAGINATION_LIMIT } from './utils';
import getAuditLogs from './getAuditLogs';

interface IGetVideosArgs {
  from?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
  userRole: string;
  userID: string;
  schoolID: string;
}

interface IGetVideosResponse {
  data: IVideoTutorial[];
  error?: string;
  nextPage?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null;
}

export default async ({
  from,
  userRole,
  schoolID,
  userID,
}: IGetVideosArgs): Promise<IGetVideosResponse> => {
  try {
    let query = firestore
      .collection('videos')
      .where('roles', 'array-contains', userRole)
      .orderBy('order', 'asc');

    if (from) {
      query = query.startAfter(from);
    }

    const snapshot = await query.limit(PAGINATION_LIMIT).get();

    let newVideos = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    //fetch related logs and check if watched
    const videoIDSList = newVideos?.map((video) => video.id) ?? [];
    if (videoIDSList.length) {
      const { data } = await getAuditLogs({
        schoolID: schoolID,
        videoIds: videoIDSList,
        userID: userID,
      });
      newVideos = newVideos.map((video) => {
        return {
          ...video,
          watched: data?.includes(video.id),
        };
      });
    }

    if (snapshot.docs.length > 0) {
      return {
        data: newVideos as IVideoTutorial[],
        nextPage: snapshot.docs[snapshot.docs.length - 1],
      };
    } else {
      return { data: newVideos as IVideoTutorial[], nextPage: null };
    }
  } catch (error) {
    console.error('Error When fetching videos:', error);
    return { data: [], error: error as string };
  }
};
