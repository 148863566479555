import { morphism, StrictSchema } from 'morphism';
import {
  IDistrictSchema,
  IOauthDistrictSchema,
} from './schemas/districtApi.schemas';
import { IDistrict, IOauthDistrict } from 'types/district.types';
import { IApiDistrict, IApiOauthDistrict } from 'data/types/districtsApi.types';

export const districtMapper = (district: IApiDistrict) =>
  morphism<StrictSchema<IDistrict, IApiDistrict>>(IDistrictSchema, district);

export const oauthDistrictMapper = (district: IApiOauthDistrict) =>
  morphism<StrictSchema<IOauthDistrict, IApiOauthDistrict>>(
    IOauthDistrictSchema,
    district,
  );
