import { morphism, StrictSchema } from 'morphism';
import {
  IBusAttendanceSchema,
  IBusMessageSchema,
  IBusPlanStudentSchema,
  IBusRouteLabelSchema,
  IBusRoutePlanSchema,
  IBusRouteSchema,
  IBusSchema,
  IReverseBusMessageSchema,
  IReverseBusRoutePlanLocationSchema,
  IReverseBusRoutePlanSchema,
  IReverseBusSchema,
  IReversePlanStudentSchema,
} from './schemas/schoolBusApi.schemas';
import {
  IApiBus,
  IApiBusAttendance,
  IApiBusMessage,
  IApiBusRoute,
  IApiBusRouteLabel,
  IApiBusRoutePlan,
  IApiBusRoutePlanLocation,
  IApiBusRoutePlanStudent,
} from 'data/types/schoolBusApi.types';
import {
  IBus,
  IBusAttendance,
  IBusMessage,
  IBusRoute,
  IBusRouteLabel,
  IBusRoutePlan,
  IBusRoutePlanLocation,
  IBusRoutePlanStudent,
  INewBusMessageInstance,
} from 'types/schoolBus.types';

export const busMapper = (bus: IApiBus) =>
  morphism<StrictSchema<IBus, IApiBus>>(IBusSchema, bus);

export const reverseBusMapper = (bus: Partial<IBus>) =>
  morphism<StrictSchema<Partial<IApiBus>, Partial<IBus>>>(
    IReverseBusSchema,
    bus,
  );

export const reverseBusMessageMapper = (bus: INewBusMessageInstance) =>
  morphism<StrictSchema<Partial<IApiBusMessage>, INewBusMessageInstance>>(
    IReverseBusMessageSchema,
    bus,
  );

export const busRouteLabelMapper = (bus: IApiBusRouteLabel) =>
  morphism<StrictSchema<IBusRouteLabel, IApiBusRouteLabel>>(
    IBusRouteLabelSchema,
    bus,
  );

export const busRouteMapper = (route: IApiBusRoute) =>
  morphism<StrictSchema<IBusRoute, IApiBusRoute>>(IBusRouteSchema, route);

export const busRoutePlanMapper = (plan: IApiBusRoutePlan) =>
  morphism<StrictSchema<IBusRoutePlan, IApiBusRoutePlan>>(
    IBusRoutePlanSchema,
    plan,
  );

export const reverseBusRoutePlanMapper = (plan: Partial<IBusRoutePlan>) =>
  morphism<StrictSchema<IApiBusRoutePlan, Partial<IBusRoutePlan>>>(
    IReverseBusRoutePlanSchema,
    plan,
  );

export const reverseBusRoutePlanLocationMapper = (
  location: Partial<IBusRoutePlanLocation>,
) =>
  morphism<
    StrictSchema<
      Partial<IApiBusRoutePlanLocation>,
      Partial<IBusRoutePlanLocation>
    >
  >(IReverseBusRoutePlanLocationSchema, location);

export const planStudentMapper = (record: Partial<IApiBusRoutePlanStudent>) =>
  morphism<
    StrictSchema<IBusRoutePlanStudent, Partial<IApiBusRoutePlanStudent>>
  >(IBusPlanStudentSchema, record);

export const reversePlanStudentMapper = (
  record: Partial<IBusRoutePlanStudent>,
) =>
  morphism<
    StrictSchema<IApiBusRoutePlanStudent, Partial<IBusRoutePlanStudent>>
  >(IReversePlanStudentSchema, record);

export const busAttendanceMapper = (record: IApiBusAttendance) =>
  morphism<StrictSchema<IBusAttendance, IApiBusAttendance>>(
    IBusAttendanceSchema,
    record,
  );

export const busMessageMapper = (record: IApiBusMessage) =>
  morphism<StrictSchema<IBusMessage, IApiBusMessage>>(
    IBusMessageSchema,
    record,
  );
