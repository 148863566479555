// Actions
export const BUS_ATDNC__DATE_RANGE_CHANGE = 'BUS_ATDNC__DATE_RANGE_CHANGE';
export const BUS_ATDNC__SET_IS_LOADING = 'BUS_ATDNC__SET_IS_LOADING';
export const BUS_ATDNC__SET_CURRENT_ROUTE = 'BUS_ATDNC__SET_CURRENT_ROUTE';

// Action types
export interface HandleDateRangeChangeAction {
  type: typeof BUS_ATDNC__DATE_RANGE_CHANGE;
  startDate: Date;
  endDate: Date;
}

export interface SetCurrentRouteIDAction {
  type: typeof BUS_ATDNC__SET_CURRENT_ROUTE;
  routeID: string;
}

export interface SetIsLoadingAction {
  type: typeof BUS_ATDNC__SET_IS_LOADING;
  bool: boolean;
}

export type BusAttendenceActionTypes =
  | HandleDateRangeChangeAction
  | SetCurrentRouteIDAction
  | SetIsLoadingAction;

// Action handlers
export type THandleDateRangeChange = (args: {
  startDate: Date;
  endDate: Date;
}) => HandleDateRangeChangeAction;

export type TSetCurrentRouteID = (args: {
  routeID: string;
}) => SetCurrentRouteIDAction;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;
