import { IDimensions } from 'hooks/useDimensions';
import React from 'react';
import { components, OptionProps, OptionTypeBase } from 'react-select';
import Check from './components/check/Check';
import { CheckContainer, Inner, Text } from './OptionStyles';

type Props = OptionProps<OptionTypeBase, boolean> & {
  dimensions: IDimensions;
};

const Option: React.FC<Props> = (props: Props) => {
  const { data, isSelected, options, dimensions } = props;

  const index = options?.findIndex((val: OptionTypeBase) => val.id === data.id);
  const isFirst = index === 0;
  const maxTextWidth = (dimensions.width || 20) - 20;

  return (
    <components.Option {...props}>
      <Inner showTopBorder={!isFirst}>
        <Text maxWidth={maxTextWidth}>{data.label}</Text>
        <CheckContainer isSelected={isSelected}>
          <Check />
        </CheckContainer>
      </Inner>
    </components.Option>
  );
};

export default Option;
