import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { HeaderButton } from '..';
import {
  ActiveBadge,
  ActiveButtonContainer,
  Container,
  TransparentButton,
} from './ScannerCameraToggleStyles';
import ScanGif from '../../../assets/images/gif/scan-gif.gif';

interface Props {
  isDisabled?: boolean;
  onNavigateToCam: () => void;
  isScanningEnabled?: boolean;
  onToggleScan?: () => void;
}

export const ScannerCameraToggle: React.FC<Props> = ({
  isDisabled,
  onNavigateToCam,
  isScanningEnabled,
  onToggleScan,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const tScanner = isScanningEnabled ? t('Scanning') : t('Scan');
  const tCam = t('Web Cam');
  const tActive = t('Active');

  const renderScanButton = () => {
    if (!isScanningEnabled) {
      return (
        <TransparentButton isDisabled={isDisabled} onClick={onToggleScan}>
          {tScanner}
        </TransparentButton>
      );
    }
    return (
      <ActiveButtonContainer isDisabled={isDisabled || !isScanningEnabled}>
        <HeaderButton
          isDisabled={isDisabled}
          onClick={onToggleScan}
          custom={{
            backgroundColor: theme.primaryBlueLight,
            color: 'white',
          }}
        >
          {tScanner}
          {isScanningEnabled && (
            <>
              <img src={ScanGif} width={30} style={{ marginLeft: 10 }} />
            </>
          )}
        </HeaderButton>
        {isScanningEnabled && <ActiveBadge>{tActive}</ActiveBadge>}
      </ActiveButtonContainer>
    );
  };

  return (
    <Container>
      {renderScanButton()}
      <TransparentButton
        isDisabled={isDisabled}
        onClick={isDisabled ? undefined : onNavigateToCam}
      >
        {tCam}
      </TransparentButton>
    </Container>
  );
};
