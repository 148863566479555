import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  margin-top: 60px;
  height: 200px;
  background: #edece8;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1300px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: 930px) {
    padding-top: 30px;
    height: auto;
  }
`;

export const InnerContainer = styled.div`
  max-width: 1420px;
  width: 1420px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 930px) {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media only screen and (max-width: 1300px) {
    gap: 20px;
    justify-content: space-between;
  }

  @media only screen and (max-width: 433px) {
    flex-direction: column;
  }
`;

export const BottomNavText = styled.div`
  font-family: ${({ theme }) => theme.fontPro};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 17px;
  line-height: 22px;
  color: #07081f;
  letter-spacing: -0.408px;

  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  z-index: 1;

  &.desktop {
    @media (max-width: ${({ theme }) => theme.widthTablet}) {
      display: none;
    }
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 300px) {
    font-size: 12px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-end;
  justify-content: center;
  padding-top: 45px;

  @media only screen and (max-width: 930px) {
    align-items: center;
    padding-top: 30px;
    margin-bottom: 23px;
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @media only screen and (max-width: 930px) {
    width: 100%;
  }

  @media only screen and (max-width: 471px) {
    width: 100%;
    gap: 1;
    justify-content: space-between;
  }

  @media only screen and (max-width: 300px) {
    width: 100%;
    flex-direction: column;
    gap: 1;
    justify-content: space-between;
  }
`;

export const RightsReservedText = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #07081f;
`;
