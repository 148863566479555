import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { Button } from '../button';
import { Triangle } from 'components/icons';

import { Theme } from 'assets/styles';
import {
  ButtonContainer,
  IconContainer,
  TextContainer,
  ToolTipItem,
  ToolTipItems,
  ToolTipText,
  Wrapper,
} from './ActionsDropdownStyles';

type Props = {
  actions: {
    label: string;
    icon?: JSX.Element | undefined;
    onClick: React.MouseEventHandler<HTMLDivElement>;
    intent?: 'primary' | 'danger' | 'success';
  }[];
  children: React.ReactNode;
  id: string;
};

export const ActionsDropdown: React.FC<Props> = ({
  actions,
  children,
  id,
}: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <ButtonContainer data-event="click" data-for={'options-' + id} data-tip>
        <Button>
          <TextContainer>
            {children}
            <Triangle dark down={isOpen} />
          </TextContainer>
        </Button>
      </ButtonContainer>

      <ReactTooltip
        afterHide={() => setIsOpen(false)}
        afterShow={() => setIsOpen(true)}
        backgroundColor={Theme.primaryWhite}
        className="tooltip"
        clickable={true}
        effect="solid"
        globalEventOff="click"
        id={'options-' + id}
        place="bottom"
        scrollHide={true}
      >
        <ToolTipItems>
          {actions.length &&
            actions.map(({ label, icon, onClick, intent = 'primary' }) => (
              <ToolTipItem onClick={onClick} key={`option-${label}`}>
                {icon && <IconContainer>{icon}</IconContainer>}
                <ToolTipText intent={intent}>{t(label)}</ToolTipText>
              </ToolTipItem>
            ))}
        </ToolTipItems>
      </ReactTooltip>
    </Wrapper>
  );
};
