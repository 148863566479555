import { morphism, StrictSchema } from 'morphism';
import { IMapLocationSchema } from './schoolBusApi.schemas';
import { IApiCalendarDate, IApiSchool } from '../../types/schoolApi.types';
import { ICalendarDate, ISchool } from 'types/school.types';

export const ICalendarDateSchema: StrictSchema<
  ICalendarDate,
  IApiCalendarDate
> = {
  day: 'day',
  id: 'id',
  isStudentDay: 'is_student_day',
  isReadonly: 'is_readonly',
  isTeacherDay: 'is_teacher_day',
};

export const ISchoolSchema: StrictSchema<ISchool, IApiSchool> = {
  districtID: 'district',
  highGrade: 'high_grade',
  id: 'id',
  location: (iteratee) => morphism(IMapLocationSchema, iteratee.location),
  logoUrl: (iteratee) => iteratee.logo_url || undefined,
  lowGrade: 'low_grade',
  name: 'name',
  phone: 'phone',
  principle: (iteratee) => iteratee.principle || undefined,
  schoolNumber: 'school_number',
  sisID: 'sis_id',
  grades: 'grades',
};
