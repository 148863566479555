import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IAttendance, INewAttendance } from 'types/attendance.types';
import { ICheckpointEvent, ICheckpointLocation } from 'types/checkpoint.types';

export const CHCKPNT__ADD_ATTENDANCE = 'CHCKPNT__ADD_ATTENDANCE';
export const CHCKPNT__ERROR = 'CHCKPNT__ERROR';
export const CHCKPNT__GET_NEXT_SUCCESS = 'CHCKPNT__GET_NEXT_SUCCESS';
export const CHCKPNT__LAUNCH_LOCATIONS = 'CHCKPNT__LAUNCH_LOCATIONS';
export const CHCKPNT__SELECT_DATE = 'CHCKPNT__SELECT_DATE';
export const CHCKPNT__SELECT_LOCATION = 'CHCKPNT__SELECT_LOCATION';
export const CHCKPNT__SET_IS_LOADING = 'CHCKPNT__SET_IS_LOADING';
export const CHCKPNT__SET_IS_PRINTING_ON = 'CHCKPNT__SET_IS_PRINTING_ON';
export const CHCKPNT__SET_PAGE_SIZE = 'CHCKPNT__SET_PAGE_SIZE';
export const CHCKPNT__RESET = 'CHCKPNT__RESET';
export const CHCKPNT__DELETE_LOCATION = 'LOCATIONS__DELETE_LOCATION';

interface DeleteCheckpointAction {
  type: typeof CHCKPNT__DELETE_LOCATION;
  id: string;
}

interface AddAttendanceAction {
  type: typeof CHCKPNT__ADD_ATTENDANCE;
  newRecord: ICheckpointEvent;
}
interface CheckpointErrorAction {
  type: typeof CHCKPNT__ERROR;
  errorMessage: string;
}

interface LaunchLocationsAction {
  type: typeof CHCKPNT__LAUNCH_LOCATIONS;
  locations: ICheckpointLocation[];
}

export interface GetNextBatchSuccess {
  data: ICheckpointEvent[];
  next?: string;
  type: typeof CHCKPNT__GET_NEXT_SUCCESS;
}

interface SetSelectedLocationAction {
  type: typeof CHCKPNT__SELECT_LOCATION;
  events: ICheckpointEvent[];
  id: string;
  next?: string;
}

interface SetSelectedDateAction {
  type: typeof CHCKPNT__SELECT_DATE;
  date: Date;
}

interface SetIsLoadingAction {
  type: typeof CHCKPNT__SET_IS_LOADING;
  bool: boolean;
}

interface SetIsPrintingOnAction {
  type: typeof CHCKPNT__SET_IS_PRINTING_ON;
  isPrintingOn: boolean;
}

interface ResetAction {
  type: typeof CHCKPNT__RESET;
}

interface SetPageSizeAction {
  type: typeof CHCKPNT__SET_PAGE_SIZE;
  size: number;
}

export type CheckpointActionTypes =
  | AddAttendanceAction
  | CheckpointErrorAction
  | GetNextBatchSuccess
  | LaunchLocationsAction
  | SetIsLoadingAction
  | SetIsPrintingOnAction
  | SetPageSizeAction
  | SetSelectedDateAction
  | ResetAction
  | SetSelectedLocationAction
  | DeleteCheckpointAction;

export type TAddLocationAttendance = (args: {
  data: INewAttendance;
}) => ThunkAction<
  Promise<{ isSuccessful: boolean; data?: IAttendance[]; error?: string }>,
  RootState,
  unknown,
  CheckpointActionTypes
>;

export type TDeleteCheckpoint = (
  id: string,
) => ThunkAction<
  Promise<{ isSuccessful: boolean }>,
  RootState,
  unknown,
  CheckpointActionTypes
>;

export type TLaunchLocations = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  CheckpointActionTypes
>;

export type TGetNextPage = (
  newPageSize?: number,
) => ThunkAction<Promise<void>, RootState, unknown, CheckpointActionTypes>;

export type TSelectLocation = (args: {
  id: string;
  date?: Date;
}) => ThunkAction<Promise<void>, RootState, unknown, CheckpointActionTypes>;

export type TSelectDate = (args: {
  date: Date;
}) => ThunkAction<Promise<void>, RootState, unknown, CheckpointActionTypes>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;

export type TReset = () => ResetAction;

export type TSetIsPrintingOn = (isPrintingOn: boolean) => SetIsPrintingOnAction;

export type TSetPageSize = (
  size: number,
) => ThunkAction<void, RootState, unknown, CheckpointActionTypes>;
