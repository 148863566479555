import { Reducer } from 'redux';
import { Row } from 'react-table';
import { sub } from 'date-fns';

import prepareSurveyResponseData from './utils/prepareSurveyResponseData';

import { ISurveyReport, SurveyReportViewsEnum } from 'types/reports.types';
import * as Actions from './SurveyReportsActions.types';

export type State = {
  endDate: Date;
  filteredRows: Row<Record<string, unknown>>[];
  isFilterActive: boolean;
  isLoading: boolean;
  isTotalCompletedSelected: boolean;
  isTotalIncompletedSelected: boolean;
  reports: ISurveyReport[];
  startDate: Date;
  view: SurveyReportViewsEnum;
};

const initialState = {
  endDate: new Date(),
  filteredRows: [],
  isFilterActive: false,
  isLoading: false,
  isTotalCompletedSelected: true,
  isTotalIncompletedSelected: true,
  reports: [],
  startDate: sub(new Date(), { days: 1, months: 2 }),
  view: SurveyReportViewsEnum.dailyParticipation,
};

const SurveyReportsReducer: Reducer<State, Actions.SurveyReportsActionTypes> = (
  state: State = initialState,
  action: Actions.SurveyReportsActionTypes,
) => {
  switch (action.type) {
    case Actions.SRVY_REPORT__SET_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }

    case Actions.SRVY_REPORT__SET_FILTERED_ROWS: {
      return {
        ...state,
        filteredRows: action.filteredRows,
      };
    }

    case Actions.SRVY_REPORT__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.SRVY_REPORT__LAUNCH_SUCCESS: {
      const preparedData = prepareSurveyResponseData(action.data);
      return {
        ...state,
        reports: preparedData,
      };
    }

    case Actions.SRVY_REPORT__TOGGLE_IS_TOTAL_COMPLETED_SELECTED: {
      return {
        ...state,
        isTotalCompletedSelected: !state.isTotalCompletedSelected,
      };
    }

    case Actions.SRVY_REPORT__TOGGLE_IS_TOTAL_INCOMPLETED_SELECTED: {
      return {
        ...state,
        isTotalIncompletedSelected: !state.isTotalIncompletedSelected,
      };
    }

    case Actions.SRVY_REPORT__TOGGLE_IS_FILTER_ACTIVE: {
      return {
        ...state,
        isFilterActive: !state.isFilterActive,
      };
    }

    case Actions.SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_SUCCESS: {
      return {
        ...state,
      };
    }

    case Actions.SRVY_REPORT__GET_RESPONSES_IN_DATE_RANGE_FAIL: {
      return {
        ...state,
      };
    }

    case Actions.SRVY_REPORT__HANDLE_DATE_RANGE_CHANGE: {
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    default:
      return state;
  }
};

export default SurveyReportsReducer;
