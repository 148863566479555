import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DownloadText,
  DownloadButton as Button,
  DownloadTextContainer,
  DownloadTitle,
} from './DownloadButtonStyles';

interface Props {
  title: string;
  text: string;
  link: string;
  icon: React.ReactNode;
}

export const DownloadButton: React.FC<Props> = ({
  title,
  text,
  icon,
  link,
}) => {
  const { t } = useTranslation();

  const tTitle = t(title);
  const tText = t(text);

  return (
    <Button href={link} target="_blank" rel="noopener noreferrer">
      {icon}
      <DownloadTextContainer>
        <DownloadTitle>{tTitle}</DownloadTitle>
        <DownloadText>{tText}</DownloadText>
      </DownloadTextContainer>
    </Button>
  );
};
