import API from 'data/axios';
import { reverseBusRoutePlanMapper } from 'data/mappers/schoolBus.mappers';
import { IBusRoutePlan } from 'types/schoolBus.types';

import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../../types/api.types';

interface ICreateNewPlanArgs {
  data: Partial<IBusRoutePlan>;
}

export default async ({ data }: ICreateNewPlanArgs): Promise<Adapter> => {
  const newPlan = reverseBusRoutePlanMapper(data);

  return API.post(`/bus/route_plans`, newPlan)
    .then(() => ({ success: true }))
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
};
