import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title,
  Container,
  RootContainer,
  MobileContainer,
} from './TrustedByStyles';
import {
  AmazonLogo,
  AppleLogo,
  CombinatorLogo,
  GoogleLogo,
  MicrosoftLogo,
  PowerschoolLogo,
} from './icons';

import { ReactComponent as CleverLogo } from './ico_clever.svg';
import { ReactComponent as ClassLinkLogo } from './ico_classlink.svg';
import useDimensions from 'hooks/useDimensions';

const trustedBy = 'Trusted by';

export const TrustedBy = () => {
  const { t } = useTranslation();

  const tTitle = t(trustedBy);

  const { ref, dimensions } = useDimensions();
  const isMobile = useMemo(
    () => (dimensions?.width ? dimensions.width <= 1100 : false),
    [dimensions],
  );

  const isXS = useMemo(
    () => (dimensions?.width ? dimensions.width <= 360 : false),
    [dimensions],
  );

  return (
    <RootContainer id="partners" ref={ref}>
      <Title>{tTitle}:</Title>
      {!isMobile ? (
        <Container>
          <CombinatorLogo />
          <AppleLogo />
          <CleverLogo />
          <GoogleLogo />
          <MicrosoftLogo />
          <AmazonLogo />
          <PowerschoolLogo />
          <ClassLinkLogo />
        </Container>
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <MobileContainer>
            <CombinatorLogo />
            <AppleLogo />
            <CleverLogo />
            <GoogleLogo />
          </MobileContainer>
          <MobileContainer>
            <MicrosoftLogo />
            <AmazonLogo />
            <PowerschoolLogo />
          </MobileContainer>
          <Container>
            <ClassLinkLogo
              style={{
                width: isXS
                  ? dimensions?.width
                    ? dimensions.width * 0.3
                    : 0
                  : undefined,
              }}
            />
          </Container>
        </div>
      )}
    </RootContainer>
  );
};
