import { Reducer } from 'redux';
import { IAttendance, IStudentAttendance } from 'types/attendance.types';
import { ISection } from 'types/section.types';
import { IStudent } from 'types/student.types';
import { AttendanceViewsEnum } from 'types/event.types';

import * as Actions from './AttendanceActions.types';

export interface IAttendanceByData {
  TotalPresent: number;
  TotalAbsent: number;
  TotalStudents: number;
}

export interface State {
  currentSectionID?: string;
  endDate: Date;
  error: string;
  isLoading: boolean;
  isUpdating: boolean;
  sectionAttendances: IAttendance[];
  sectionAttendanceNextLink?: string | undefined;
  sections: ISection[];
  startDate: Date;
  students: IStudent[];
  view: string;
  sectionStudents: IStudentAttendance[];
  isAbsentSelected: boolean;
  isPresentSelected: boolean;
  isAllSelected: boolean;
  attendanceByData: IAttendanceByData;
  checkedStudentsCheckIn: IStudentAttendance[];
  checkedStudentsCheckOut: IStudentAttendance[];
  allSectionStudentsNextLink?: string | undefined;
  missedStudents: string[];
}

const today = new Date();
const initialState = {
  currentSectionID: undefined,
  endDate: today,
  error: '',
  isLoading: false,
  isUpdating: false,
  sectionAttendances: [],
  sections: [],
  startDate: today,
  students: [],
  view: AttendanceViewsEnum.checkIn,
  sectionStudents: [],
  isAbsentSelected: false,
  isPresentSelected: false,
  isAllSelected: true,
  attendanceByData: {
    TotalPresent: 0,
    TotalAbsent: 0,
    TotalStudents: 0,
  },
  checkedStudentsCheckIn: [],
  checkedStudentsCheckOut: [],
  sectionAttendanceNextLink: undefined,
  allSectionStudentsNextLink: undefined,
  missedStudents: [],
};

const AttendanceReducer: Reducer<State, Actions.EventActionTypes> = (
  state: State = initialState,
  action: Actions.EventActionTypes,
) => {
  switch (action.type) {
    case Actions.EVENT__ATTENDANCE__SET_MISSED_STUDENTS: {
      return {
        ...state,
        missedStudents: action.missedStudents,
      };
    }
    case Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_IN: {
      return {
        ...state,
        checkedStudentsCheckIn: action.checkedStudentsCheckIn,
      };
    }

    case Actions.EVENT__ATTENDANCE__SET_CHECKED_STUDENTS_CHECK_OUT: {
      return {
        ...state,
        checkedStudentsCheckOut: action.checkedStudentsCheckOut,
      };
    }

    case Actions.ATTENDANCE__SET_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }

    case Actions.ATTENDANCE__TOGGLE_IS_ABSENT_SELECTED: {
      const isPresentSelected = false;
      const isAbsentSelected = true;
      const isAllSelected = false;
      return {
        ...state,
        isAbsentSelected,
        isPresentSelected,
        isAllSelected,
      };
    }

    case Actions.ATTENDANCE__TOGGLE_IS_PRESENT_SELECTED: {
      const isPresentSelected = true;
      const isAbsentSelected = false;
      const isAllSelected = false;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        isAllSelected,
      };
    }

    case Actions.ATTENDANCE__TOGGLE_IS_ALL_SELECTED: {
      const isPresentSelected = false;
      const isAbsentSelected = false;
      const isAllSelected = true;
      return {
        ...state,
        isPresentSelected,
        isAbsentSelected,
        isAllSelected,
      };
    }

    case Actions.EVENT__GET_SECTION_STUDENTS: {
      return {
        ...state,
        error: '',
        sectionStudents: action.students,
        attendanceByData: action.attendanceByData,
      };
    }

    case Actions.EVENT__COUNTS: {
      return {
        ...state,
        error: '',
        attendanceByData: action.attendanceByData,
      };
    }

    case Actions.EVENT__GET_ATTENDANCES: {
      return {
        ...state,
        error: '',
        sectionAttendances: action.sectionAttendances,
        sectionAttendanceNextLink: action.sectionAttendanceNextLink,
      };
    }

    case Actions.EVENT__ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.EVENT__LAUNCH_SUCCESS: {
      return {
        ...state,
        currentSectionID: undefined,
        sectionStudents: [],
        error: '',
        sectionAttendances: [],
        sections: action.sections,
      };
    }

    case Actions.EVENT__SET_CURRENT_SECTION: {
      return {
        ...state,
        currentSectionID: action.sectionID,
        error: '',
        students: action.students,
        allSectionStudentsNextLink: action.allSectionStudentsNextLink,
      };
    }

    case Actions.EVENT__SET_DATE_RANGE: {
      return {
        ...state,
        endDate: action.endDate,
        startDate: action.startDate,
      };
    }

    case Actions.EVENT__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.EVENT__SET_IS_UPDATING: {
      return {
        ...state,
        isUpdating: action.bool,
      };
    }

    default:
      return state;
  }
};

export default AttendanceReducer;
