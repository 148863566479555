import styled from 'styled-components';

export const DownloadButton = styled.a`
  background: #07081f;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-right: 22px;
  padding-left: 27px;
  padding-top: 9px;
  padding-bottom: 11px;
  align-items: center;
  width: 224px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }

  @media only screen and (max-width: 930px) {
    width: 100%;
    justify-content: center;
  }

  @media only screen and (max-width: 471px) {
    width: 100%;
    padding-right: 4px;
    padding-left: 14px;
    gap: 6px;
  }

  @media only screen and (max-width: 300px) {
    justify-content: center;
    gap: 12px;
  }
`;

export const DownloadTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DownloadText = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  font-feature-settings: 'case' on;
  color: #ffffff;

  @media only screen and (max-width: 384px) {
    font-size: 12px;
  }
`;

export const DownloadTitle = styled.span`
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-feature-settings: 'case' on;
  color: rgba(255, 255, 255, 0.8);

  @media only screen and (max-width: 384px) {
    font-size: 8px;
  }
`;
