import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IMedicalAlert } from 'types/medical.types';
import { TQuestion } from 'types/surveys.types';

// Actions
export const MED_ALERTS__GET_ALERTS_FAIL = 'MED_ALERTS__GET_ALERTS_FAIL';
export const MED_ALERTS__GET_ALERTS_SUCCESS = 'MED_ALERTS__GET_ALERTS_SUCCESS';

// Action types
export interface IGetMedicalAlertFailAction {
  type: typeof MED_ALERTS__GET_ALERTS_FAIL;
  error: string;
}

export interface IGetMedicalAlertSuccessAction {
  type: typeof MED_ALERTS__GET_ALERTS_SUCCESS;
  data: {
    alertData: IMedicalAlert[];
    surveyQuestions: TQuestion[];
  };
}

export type MedicalAlertsActionTypes =
  | IGetMedicalAlertFailAction
  | IGetMedicalAlertSuccessAction;

// Action handlers
export type TGetMedicalAlertsData = () => ThunkAction<
  void,
  RootState,
  unknown,
  MedicalAlertsActionTypes
>;
