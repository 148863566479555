import React from 'react';
import { Theme } from 'assets/styles';
import { Path1, Svg } from './TimesStyles';

interface Props {
  fill?: string;
  light?: boolean;
  location?: 'filter' | 'table';
}

const TimesIcon: React.FC<Props> = ({
  fill = Theme.colorDanger,
  light = false,
  location,
}: Props) => {
  return (
    <Svg
      location={location}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path1
        clipRule="evenodd"
        d="M0 9.5C0 4.2619 4.2619 0 9.5 0C14.7381 0 19 4.2619 19 9.5C19 14.7381 14.7381 19 9.5 19C4.2619 19 0 14.7381 0 9.5ZM13.2929 7.35973C13.6476 6.92488 13.6222 6.28345 13.2169 5.87811C12.7845 5.44576 12.0835 5.44576 11.6512 5.87811L9.51082 8.018L7.37073 5.87811L7.28665 5.80211C6.8518 5.44745 6.21037 5.47278 5.80504 5.87811C5.37268 6.31047 5.37268 7.01146 5.80504 7.44381L7.94478 9.58404L5.80504 11.7243L5.72904 11.8083C5.37437 12.2432 5.3997 12.8846 5.80504 13.29C6.23739 13.7223 6.93838 13.7223 7.37073 13.29L9.51082 11.1501L11.6512 13.29L11.7353 13.366C12.1701 13.7206 12.8116 13.6953 13.2169 13.29C13.6492 12.8576 13.6492 12.1566 13.2169 11.7243L11.0769 9.58404L13.2169 7.44381L13.2929 7.35973Z"
        fill={light ? fill : 'white'}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 9.5C0 4.2619 4.2619 0 9.5 0C14.7381 0 19 4.2619 19 9.5C19 14.7381 14.7381 19 9.5 19C4.2619 19 0 14.7381 0 9.5ZM13.2929 7.35973C13.6476 6.92488 13.6222 6.28345 13.2169 5.87811C12.7845 5.44576 12.0835 5.44576 11.6512 5.87811L9.51082 8.018L7.37073 5.87811L7.28665 5.80211C6.8518 5.44745 6.21037 5.47278 5.80504 5.87811C5.37268 6.31047 5.37268 7.01146 5.80504 7.44381L7.94478 9.58404L5.80504 11.7243L5.72904 11.8083C5.37437 12.2432 5.3997 12.8846 5.80504 13.29C6.23739 13.7223 6.93838 13.7223 7.37073 13.29L9.51082 11.1501L11.6512 13.29L11.7353 13.366C12.1701 13.7206 12.8116 13.6953 13.2169 13.29C13.6492 12.8576 13.6492 12.1566 13.2169 11.7243L11.0769 9.58404L13.2169 7.44381L13.2929 7.35973Z"
        fill={light ? 'white' : fill}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default TimesIcon;
