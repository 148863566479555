import styled from 'styled-components';
import { Container as DatePrickerContainer } from 'components/date-picker/date-picker-componenet/DatePickerStyles';

export const Title = styled.h1``;

export const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 15px;

  + ${DatePrickerContainer} {
    margin-top: 35px;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    gap: 10px;
  }
`;
