import {
  createNewBusMessage,
  deleteBusMessage as deleteBusMessageAdapter,
  editBusMessage as editBusMessageAdapter,
} from 'data/adapters/school-bus/messages';
import { getBusMessagesResources } from 'state/resources';
import createNewBus from 'data/adapters/school-bus/bus/createNewBus';
import deleteBusObject from 'data/adapters/school-bus/bus/deleteBusObject';
import editBusObject from 'data/adapters/school-bus/bus/editBusObject';
import getAllBusMessages from 'data/adapters/school-bus/messages/getAllBusMessages';
import getBuses from 'data/adapters/school-bus/bus/getBuses';

import { parseError } from 'utils/errors.utils';

import * as Actions from './BusActions.types';

export const addNewBus: Actions.TAddNewBus = ({ data }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const state = getState();
    const districtID = state.auth.user?.districtID;

    if (!districtID) {
      throw 'Could not get current district';
    }

    const { error, success } = await createNewBus({
      data: { ...data, districtID },
    });

    if (!success) {
      throw error;
    }

    await dispatch(launchBuses());

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addBusAlert: Actions.TAddNewBusAlert = ({ data }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const state = getState();
    const { busMessageTypes } = state.resources;
    const typeID = busMessageTypes.find(({ key }) => key === 'emergency')?.id;

    if (!typeID) {
      throw 'Error loading resources';
    }

    const newAlert = {
      date: new Date(),
      deliveryMethodIDs: data.deliveryMethodIDs,
      routeID: data.routeID,
      text: data.text,
      typeID,
    };

    const { error, success } = await createNewBusMessage({
      data: newAlert,
    });

    if (!success) {
      throw error;
    }

    const { startDate, endDate } = state.bus;
    await dispatch(loadBusAlerts({ startDate, endDate }));

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteBus: Actions.TDeleteBus = ({ id }) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { error, success } = await deleteBusObject({ id });

    if (!success) {
      throw error;
    }

    await dispatch(launchBuses());

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteBusMessage: Actions.TDeleteBus = ({ id }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const { error, success } = await deleteBusMessageAdapter({ id });

    if (!success) {
      throw error;
    }

    const state = getState();
    const { startDate, endDate } = state.bus;
    await dispatch(loadBusAlerts({ startDate, endDate }));

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const editBusData: Actions.TEditBusData = ({ data }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const state = getState();
    const districtID = state.auth.user?.districtID;

    if (!districtID) {
      throw 'Could not get current district';
    }

    const { error, success } = await editBusObject({
      data: { ...data, districtID },
    });

    if (!success) {
      throw error;
    }

    await dispatch(launchBuses());

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const editBusMessage: Actions.TAddNewBusAlert = ({ data }) => async (
  dispatch,
  getState,
) => {
  dispatch(setIsLoading(true));

  try {
    const { error, success } = await editBusMessageAdapter({
      data,
    });

    if (!success) {
      throw error;
    }

    const state = getState();
    const { startDate, endDate } = state.bus;
    await dispatch(loadBusAlerts({ startDate, endDate }));

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });

    return { success: false };
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const launchBusAlerts: Actions.TBusThunkAction = () => async (
  dispatch,
) => {
  const today = new Date();
  const startDate = today;
  const endDate = today;

  dispatch(getBusMessagesResources());
  dispatch(setBusDateRange({ startDate, endDate }));
};

export const launchBuses: Actions.TBusThunkAction = () => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { data, error, success } = await getBuses();

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.BUS__LAUNCH_BUSES,
      buses: data,
    });
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const loadBusAlerts: Actions.TLoadBusAlerts = ({
  startDate,
  endDate,
}) => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const { data, error, success } = await getAllBusMessages({
      startDate,
      endDate,
    });

    if (!success || !data) {
      throw error;
    }

    dispatch({
      type: Actions.BUS__GET_ALERTS,
      alerts: data.filter(({ type }) => type?.key === 'emergency'),
    });
  } catch (err) {
    const errorMessage = parseError(err);

    dispatch({
      type: Actions.BUS__ERROR,
      errorMessage,
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const resetBusDateRange: Actions.TResetDateRange = () => ({
  type: Actions.BUS__RESET_DATE_RANGE,
});

export const setBusDateRange: Actions.TSetDateRange = ({
  endDate,
  startDate,
}) => async (dispatch) => {
  dispatch({
    endDate,
    startDate,
    type: Actions.BUS__SET_DATE_RANGE,
  });

  dispatch(loadBusAlerts({ startDate, endDate }));
};

export const setIsLoading: Actions.TSetIsLoading = (bool) => ({
  type: Actions.BUS__SET_IS_LOADING,
  bool,
});
