import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { currentSchoolDataMapper } from 'data/mappers/resources.mappers';

import { Adapter } from '../../types/api.types';
import { ICurrentSchoolData } from 'types/resources.types';
import { IApiCurrentSchoolData } from 'data/types/resourcesApi.types';

interface ICurrentSchoolDataResponse {
  data: IApiCurrentSchoolData;
}

export default async (): Promise<Adapter<ICurrentSchoolData>> =>
  API.get<ICurrentSchoolDataResponse>(`/resources/current`)
    .then(({ data: response }) => {
      const { data } = response;
      const currentSchoolData = currentSchoolDataMapper(data);

      return { success: true, data: currentSchoolData };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
