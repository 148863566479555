import API from 'data/axios';

import { parseError } from 'utils/errors.utils';
import { stateMapper } from 'data/mappers/resources.mappers';

import { Adapter, ArrayResponse } from '../../types/api.types';
import { IState } from 'types/resources.types';
import { IApiState } from 'data/types/resourcesApi.types';

export default async (): Promise<Adapter<IState[]>> =>
  API.get<ArrayResponse<IApiState>>(`/resources/states`)
    .then(({ data: response }) => {
      const states = response.data.map(({ data }) => stateMapper(data));

      return { success: true, data: states };
    })
    .catch((error) => {
      const stringError = parseError(error);

      return { success: false, error: stringError };
    });
