import { Reducer } from 'redux';
import _ from 'lodash';
import updateSurveysHistory from './utils/updateSurveysHistory';
import {
  ISurvey,
  IScreeningConfig,
  IconEnum,
  SurveyRolesEnum,
  SurveyViewsEnum,
} from 'types/surveys.types';
import * as Actions from './SurveysActions.types';

export const emptySurvey = {
  header: '',
  id: '',
  active: false,
  name: 'New Survey',
  questions: [],
  checkInConfig: {
    questions: { active: true },
    temperature: { active: true },
    signature: { active: false, type: null },
  },
  checkOutConfig: {
    questions: { active: true },
    temperature: { active: true },
    signature: { active: false, type: null },
  },
};

export type SurveysHistory = ISurvey[][];

export type State = {
  activeSurveyId: string | null;
  hasWriteAccess: boolean;
  currentQuestionSequence: number | null;
  hasBeenChanged: boolean;
  isCreatingNewSurvey: boolean;
  isLoading: boolean;
  isSaved: boolean;
  questionUnderEdit: string;
  showConfirmDeleteSurveyModal: boolean;
  showDeleteFailModal: boolean;
  showNeedsUniqueNameModal: boolean;
  showSaveErrorModal: boolean;
  showSaveModal: boolean;
  showTitleRequiredModal: boolean;
  surveyIdUnderEdit: string;
  surveysHistory: SurveysHistory;
  surveyWithChanges: ISurvey;
  view: SurveyViewsEnum;
};

export const initialState = {
  activeSurveyId: null,
  currentQuestionSequence: null,
  hasBeenChanged: false,
  hasWriteAccess: false,
  isCreatingNewSurvey: false,
  isLoading: false,
  isSaved: true,
  questionUnderEdit: '',
  showConfirmDeleteSurveyModal: false,
  showDeleteFailModal: false,
  showNeedsUniqueNameModal: false,
  showSaveErrorModal: false,
  showSaveModal: false,
  showTitleRequiredModal: false,
  surveyIdUnderEdit: '',
  surveysHistory: [],
  surveyWithChanges: emptySurvey,
  view: SurveyViewsEnum.questions,
};

const surveysReducer: Reducer<State, Actions.SurveysActionTypes> = (
  state: State = initialState,
  action: Actions.SurveysActionTypes,
): State => {
  // TODO: alphabetical order
  const surveys = state.surveysHistory[0] || [];

  switch (action.type) {
    case Actions.SURVEYS__SET_SURVEY_ID_UNDER_EDIT: {
      if (!action.id)
        return {
          ...state,
          surveyIdUnderEdit: '',
        };
      return {
        ...state,
        surveyIdUnderEdit: action.id,
      };
    }

    case Actions.SURVEYS__ADD_SURVEY_SUCCESS: {
      const newSurveys = _.cloneDeep(surveys);
      const newSurvey = action.data;
      newSurveys.push(newSurvey);

      const newSurveysHistory = updateSurveysHistory(
        state.surveysHistory,
        newSurveys,
      );
      return {
        ...state,
        isCreatingNewSurvey: false,
        isSaved: true,
        hasBeenChanged: false,
        surveyWithChanges: newSurvey,
        surveysHistory: newSurveysHistory,
        surveyIdUnderEdit: action.data.id,
      };
    }

    case Actions.SURVEYS__ADD_SURVEY_FAIL: {
      return {
        ...state,
        isSaved: false,
        hasBeenChanged: true,
        showSaveErrorModal: true,
        showSaveModal: false,
        showTitleRequiredModal: false,
      };
    }

    case Actions.SURVEYS__SET_SURVEY_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }

    case Actions.SURVEYS__GET_ALL_SURVEYS_SUCCESS: {
      const newSurveysHistory = updateSurveysHistory(
        state.surveysHistory,
        action.data,
      );
      return {
        ...state,
        surveysHistory: newSurveysHistory,
      };
    }

    case Actions.SURVEYS__GET_SURVEYS_FAIL: {
      return state;
    }

    case Actions.SURVEYS__GET_SURVEY_SUCCESS: {
      return {
        ...state,
        surveyWithChanges: action.data,
        isSaved: false,
        hasBeenChanged: false,
        surveyIdUnderEdit: action.data.id,
        questionUnderEdit: '',
        currentQuestionSequence: null,
        showSaveModal: false,
      };
    }

    case Actions.SURVEYS__HANDLE_NAME_INPUT_CHANGE: {
      const { surveyWithChanges } = state;
      const newSurveyWithChanges = { ...surveyWithChanges };
      newSurveyWithChanges.name = action.e.target.value;
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        hasBeenChanged: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__HANDLE_HEADER_TEXT_INPUT_CHANGE: {
      const { surveyWithChanges } = state;
      const newSurveyWithChanges = { ...surveyWithChanges };
      newSurveyWithChanges.header = action.e.target.value;
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        hasBeenChanged: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__SET_SHOW_SAVE_MODAL: {
      return {
        ...state,
        showSaveModal: action.bool,
      };
    }

    case Actions.SURVEYS__SET_SHOW_TITLE_REQUIRED_MODAL: {
      return {
        ...state,
        showTitleRequiredModal: action.bool,
      };
    }

    case Actions.SURVEYS__TOGGLE_QUESTION_VISIBILITY: {
      const { surveyWithChanges } = state;
      const newSurveyWithChanges = _.cloneDeep(surveyWithChanges);
      const questionToChange = newSurveyWithChanges.questions.find(
        (q) => q.sequence === action.questionSequence,
      );
      if (questionToChange) questionToChange.active = !questionToChange.active;
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        hasBeenChanged: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__UPDATE_QUESTION: {
      const {
        surveyWithChanges,
        questionUnderEdit,
        currentQuestionSequence,
      } = state;
      if (currentQuestionSequence === null) return state;
      const newSurveyWithChanges = _.cloneDeep(surveyWithChanges);
      const questionIndex = newSurveyWithChanges.questions.findIndex(
        (q) => q.sequence === state.currentQuestionSequence,
      );
      if (questionIndex >= 0) {
        newSurveyWithChanges.questions[questionIndex].text = questionUnderEdit;
      }
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        currentQuestionSequence: null,
        showSaveModal: false,
        showTitleRequiredModal: false,
        showSaveErrorModal: false,
        hasBeenChanged: true,
        isSaved: false,
        questionUnderEdit: '',
      };
    }

    case Actions.SURVEYS__DELETE_QUESTION: {
      const { surveyWithChanges } = state;
      const { questionSequence } = action;
      const newSurveyWithChanges = _.cloneDeep(surveyWithChanges);
      const questionIndex = newSurveyWithChanges.questions.findIndex(
        (q) => q.sequence === questionSequence,
      );
      newSurveyWithChanges.questions.splice(questionIndex, 1);
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        hasBeenChanged: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__SET_CURRENT_QUESTION_SEQUENCE: {
      return {
        ...state,
        currentQuestionSequence: action.questionIndex,
      };
    }

    case Actions.SURVEYS__SELECT_QUESTION: {
      const {
        questionUnderEdit,
        currentQuestionSequence,
        surveyWithChanges,
      } = state;
      const newSurveyWithChanges = _.cloneDeep(surveyWithChanges);
      const noChange =
        currentQuestionSequence !== null
          ? newSurveyWithChanges.questions[currentQuestionSequence].text ===
            questionUnderEdit
          : true;
      if (currentQuestionSequence === null || noChange) {
        return {
          ...state,
          currentQuestionSequence: action.questionSequence,
          questionUnderEdit: action.questionText,
        };
      }
      const question = newSurveyWithChanges.questions.find(
        (q) => q.sequence === currentQuestionSequence,
      );
      if (question) question.text = questionUnderEdit;
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        currentQuestionSequence: action.questionSequence,
        questionUnderEdit: action.questionText,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__ON_QUESTION_CHANGE: {
      return {
        ...state,
        questionUnderEdit: action.e.target.value,
      };
    }

    case Actions.SURVEYS__SET_QUESTION_UNDER_EDIT: {
      return {
        ...state,
        questionUnderEdit: action.value,
      };
    }

    case Actions.SURVEYS__ADD_QUESTION: {
      const newQuestion = {
        id: '',
        active: false,
        sequence: action.questionIndex,
        surveyId: state.surveyIdUnderEdit,
        text: 'New Question',
      };

      const newSurveyWithChanges = _.cloneDeep(state.surveyWithChanges);
      newSurveyWithChanges.questions.push(newQuestion);
      return {
        ...state,
        surveyWithChanges: newSurveyWithChanges,
        isSaved: false,
        hasBeenChanged: true,
      };
    }

    case Actions.SURVEYS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.bool,
      };
    }

    case Actions.SURVEYS__LAUNCH_NEW_SURVEY_SUCCESS: {
      return {
        ...state,
        isSaved: false,
        hasBeenChanged: false,
        isCreatingNewSurvey: true,
        surveyWithChanges: emptySurvey,
        showSaveModal: false,
        surveyIdUnderEdit: '',
        questionUnderEdit: '',
      };
    }

    case Actions.SURVEYS__LAUNCH_NEW_SURVEY_FAIL: {
      return state;
    }

    case Actions.SURVEYS__SET_SHOW_SAVE_ERROR_MODAL: {
      return {
        ...state,
        showSaveErrorModal: action.bool,
      };
    }

    case Actions.SURVEYS__DELETE_SURVEY_SUCCESS: {
      const newSurveys = _.cloneDeep(surveys);
      const filtered = newSurveys.filter((s) => s.id !== action.id);
      const newSurveysHistory = updateSurveysHistory(
        state.surveysHistory,
        filtered,
      );

      return {
        ...state,
        surveysHistory: newSurveysHistory,
        showConfirmDeleteSurveyModal: false,
      };
    }

    case Actions.SURVEYS__DELETE_SURVEY_FAIL: {
      return {
        ...state,
        showDeleteFailModal: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__UPDATE_SURVEY_FAIL: {
      return {
        ...state,
        showSaveErrorModal: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__UPDATE_SURVEY_SUCCESS: {
      const surveys = state.surveysHistory[0] || [];
      const newSurveys = _.cloneDeep(surveys);
      const surveyUnderEditIndex = newSurveys.findIndex(
        (s) => s.id === state.surveyWithChanges.id,
      );
      if (surveyUnderEditIndex < 0)
        return {
          ...state,
          hasBeenChanged: true,
          isSaved: true,
          showSaveModal: false,
          surveysHistory: [],
        };

      newSurveys.splice(surveyUnderEditIndex, 1, state.surveyWithChanges);
      const newSurveysHistory = updateSurveysHistory(
        state.surveysHistory,
        newSurveys,
      );

      return {
        ...state,
        hasBeenChanged: true,
        isSaved: true,
        showSaveModal: false,
        surveysHistory: newSurveysHistory,
      };
    }

    case Actions.SURVEYS__TOGGLE_VISIBILITY: {
      const newSurveys = _.cloneDeep(surveys);
      const idx = newSurveys.findIndex((survey) => survey.id === action.id);
      newSurveys[idx].active = !newSurveys[idx].active;
      const newSurveysHistory = updateSurveysHistory(
        state.surveysHistory,
        newSurveys,
      );
      return {
        ...state,
        surveysHistory: newSurveysHistory,
      };
    }

    case Actions.SURVEYS__TOGGLE_SURVEY_VISIBILITY_SUCCESS: {
      return state;
    }

    case Actions.SURVEYS__TOGGLE_SURVEY_VISIBILITY_FAIL: {
      return {
        ...state,
        showSaveErrorModal: true,
      };
    }

    case Actions.SURVEYS__SET_SHOW_NEEDS_UNIQUE_NAME_MODAL: {
      return {
        ...state,
        showNeedsUniqueNameModal: action.bool,
      };
    }

    case Actions.SURVEYS__HANDLE_NEEDS_UNIQUE_NAME: {
      return {
        ...state,
        showNeedsUniqueNameModal: true,
        view: SurveyViewsEnum.general,
        showSaveModal: false,
        showTitleRequiredModal: false,
        showDeleteFailModal: false,
      };
    }

    case Actions.SURVEYS__SET_SHOW_CONFIRM_DELETE_SURVEY_MODAL: {
      return {
        ...state,
        showConfirmDeleteSurveyModal: action.bool,
      };
    }

    case Actions.SURVEYS__LAUNCH_SURVEY_SELECTION: {
      return {
        ...state,
        isCreatingNewSurvey: false,
        isSaved: true,
        hasBeenChanged: false,
        showSaveModal: false,
        questionUnderEdit: '',
        currentQuestionSequence: null,
      };
    }

    case Actions.SURVEYS__UPDATE_SCREENING_CONFIG: {
      const { configType, updateType } = action;
      const updateConfig = (
        config: IScreeningConfig,
        updateType: IconEnum | SurveyRolesEnum,
      ) => {
        switch (updateType) {
          case IconEnum.questions:
            config.questions.active = !config.questions.active;
            break;
          case IconEnum.temperature:
            config.temperature.active = !config.temperature.active;
            break;
          case IconEnum.signature:
            if (config.signature)
              config.signature.active = !config.signature.active;
            break;
          case SurveyRolesEnum.staff:
          case SurveyRolesEnum.guardian:
            if (config.signature) config.signature.type = updateType;
            break;
        }
        return config;
      };

      const surveyWithChanges = Object.assign({}, state.surveyWithChanges);
      if (configType === 'checkIn') {
        const updatedCheckInConfig = updateConfig(
          state.surveyWithChanges.checkInConfig,
          updateType,
        );
        surveyWithChanges.checkInConfig = updatedCheckInConfig;
      } else {
        const updatedCheckOutConfig = updateConfig(
          state.surveyWithChanges.checkOutConfig,
          updateType,
        );
        surveyWithChanges.checkOutConfig = updatedCheckOutConfig;
      }
      return {
        ...state,
        surveyWithChanges,
        hasBeenChanged: true,
        isSaved: false,
      };
    }

    case Actions.SURVEYS__SET_HAS_WRITE_ACCESS: {
      return {
        ...state,
        hasWriteAccess: action.hasWriteAccess,
      };
    }

    case Actions.SURVEYS__SET_ACTIVE_SURVEY_ID: {
      return {
        ...state,
        activeSurveyId: action.activeSurveyId,
      };
    }

    default:
      return state;
  }
};

export default surveysReducer;
